import React, {Suspense} from 'react'
import { Switch, Route, Redirect } from 'react-router-dom';
import {
  Button, Card, CardHeader,
  TabContent, TabPane,
  PageExtV2,
  MyPageFromConfig,
  HUtils,
} from '../_import'
class PagePanelv3 extends PageExtV2 {
  constructor(props){
    super(props);
  }
  componentDidMount(){  
    // this.requestOptions({
    //   cbSuccess: ()=>{
    //     this.requestList()            
    //   }
    // });   
  }
  _renderUI=()=>{    
    const {fnList} = this.props;
    const contents = this.getPageContents();
    console.warn("renderUI:",contents);
    if(contents && contents.length>0){
      let _ui = [];
      for(let i=0;i<contents.length;i++){
        let _item = contents[i];
        _ui.push(
          <MyPageFromConfig configPage={_item} key={i} fnList={{
            ...fnList,
            ...this._sharedFnList,
          }}/>
        )
      }
      return _ui;
    }
  }  
  render() {
    return (
      <div className="page-panelv3">
        {this._renderUI()}
      </div>
    );
  }
}
export default PagePanelv3
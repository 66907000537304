import classnames from 'classnames';
import React, { Component } from 'react';
import FormHelper from "./FormHelper";
import FormType from "./FormType";
class Abs extends Component {
  constructor(props) {
    super(props);
    this.state={
      value: null,
    }
    this._currentValue = null;
  }

  componentDidMount(){
    /** Item:
     * Type: string
     * FieldName: string
     * Label: string
     * Hint: string
     * SubmitAfterChange: bool
     */
  }

  getValue=()=>{
    // return this.state.value;
    return this._currentValue;
  }

  _onUpdateValue=({newValue})=>{
    const {item,opts,values,defaultValues,getOptionOfKey,onSubmit} = this.props;
    this._currentValue = newValue;
    if(item.SubmitAfterChange==true && onSubmit!=null){
      onSubmit();
    }

    // this.setState({
    //   value: newValue
    // },()=>{
    //   if(item.SubmitAfterChange==true && onSubmit!=null){
    //     onSubmit();
    //   }
    // });
  }
  

  render() {
    const {item,opts,values,defaultValues,getOptionOfKey,onSubmit,configForm} = this.props;
    // console.warn('render FormRow',item);
    let _uiType;
    let _opts = Object.assign({},opts);
    _opts.onUpdateValue=this._onUpdateValue;
    _opts.values=values;
    _opts.defaultValues=defaultValues;
    _opts.getOptionOfKey = getOptionOfKey;
    _opts.onSubmit = onSubmit;
    _opts.configForm = configForm;
    if(FormType.type[item.Type]){
      _uiType = FormType.type[item.Type](item,_opts);
    }
    let _direction = FormHelper.getDirection(item,_opts);
    let _flexDirection = "row"
    if(item.Label==null){
      _direction = "column";
    }
    if(_direction!="row"){
      _flexDirection = "column";
    }
    let _styles = item.Style || {};

    return (
      <div className={classnames('mui-form-row',`mui-form-type-${item.Type}`, {'mui-form-row-col':_flexDirection=="column"})} style={_styles.Row}>    
        {
          item.Label!=null &&
          <div className="mui-form-left">
            <label>{item.Label}</label>
          </div>  
        }            
        <div className="mui-form-right">
          <div className="mui-form-item">
            {_uiType}
          </div>
          {
            item.Hint !=null &&
            <div className="mui-form-hint">{item.Hint}</div>
          } 
        </div>                       
      </div>    
    )
  }
}

export default Abs;

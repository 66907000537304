import React from "react"
import { MyTableFilterControlHelpers, MyUIHelpers } from "@macashipo/mlib";
const MTF_filter_row_fix = React.lazy(() => import("./Types/filter_row_fix"));
const MTF_with_myui = React.lazy(() => import("./Types/with_myui"));

const List = {
  with_myui: MTF_with_myui,
  filter_row_fix: MTF_filter_row_fix
};

const ListMyUIMapFilterControl = {
  mtf_filter_row_fix: MTF_filter_row_fix
};
const MyUIOne = {
  init() {
    MyTableFilterControlHelpers.addTypesFromListLazy(List);
    MyUIHelpers.addTypesFromListLazy(ListMyUIMapFilterControl)
  },
};

export default MyUIOne;

import HUtils from './Utils'
import HLink from './Link'
const Auth = {
  HConfig: null,
  kAuthToken: 'AUTH_ACCESS_TOKEN_KEY',
  kAuthInfo: 'AUTH_INFO',
  authToken: null,
  authInfo: null,
  authType: 'bearer',  
  initAuth(HConfig){
    console.warn("initAuth:",HConfig);
    Auth.HConfig = HConfig;
    Auth.checkLastLogin();
  },
  getAuthToken(){
    console.warn("getAuthToken:",Auth.authToken);
    return Auth.authToken;
  },
  getName(){
    return HUtils.Obj.get(Auth.authInfo,"name");
    // if(Auth.authInfo.name){ return Auth.authInfo.name; }
  },
  getUserId(){
    return HUtils.Obj.get(Auth.authInfo,"user_id");
    // if(Auth.authInfo.user_id){ return Auth.authInfo.user_id; }
  },
  getImgUrl(){
    // console.warn("getImgUrl:",Auth.authInfo)
    return HUtils.Obj.get(Auth.authInfo,"avatar_url");
    // if(Auth.authInfo.avatar_url){ return Auth.authInfo.avatar_url; }
  },  
  updateImgUrl(url){
    // return HUtils.Obj.get(Auth.authInfo,"avatar_url");
    if(Auth.authInfo){ 
      Auth.authInfo.avatar_url = url; 
      localStorage.setItem('AUTH_INFO',JSON.stringify(Auth.authInfo));
    }
  },  
  getAppLogo(){
    return HUtils.Obj.get(Auth.authInfo,"appLogo_url");
    // if(Auth.authInfo.appLogo_url){ return Auth.authInfo.appLogo_url; }
  },
  checkLastLogin(isGoHome){
    if(Auth.authToken){
      if(isGoHome===true){
        console.warn("go home, [chua chuyen home]");
        HLink.push('/');
      }
      return true;
    }
    const _token = localStorage.getItem(Auth.kAuthToken);
    if(_token){
      const _info = localStorage.getItem(Auth.kAuthInfo);
      Auth.authToken = _token;
      Auth.authInfo = JSON.parse(_info);
      HUtils.runFnConfig(Auth.HConfig,'afterLogged',[]);
      return true;
    }
    return false;
  },
  isLoggedIn(){
    return Auth.authToken!=null ? true : false;
  },
  login(res){
    console.warn("res.access_token",res.access_token)
    if(res.access_token){
      Auth.authToken = `${Auth.authType} ${res.access_token}`;
      Auth.authInfo = {...res};
      localStorage.setItem('AUTH_ACCESS_TOKEN_KEY',`${Auth.authToken}`);
      localStorage.setItem('AUTH_INFO',JSON.stringify(Auth.authInfo));      
      HUtils.runFnConfig(Auth.HConfig,'afterLogin',[]);
    }
  },
  logOut(){
    localStorage.removeItem(Auth.kAuthToken);
    localStorage.removeItem(Auth.kAuthInfo);
    Auth.authToken = null;
    Auth.authInfo = null;
    HUtils.runFnConfig(Auth.HConfig,'afterLogout',[]);
  }
}
window.HAuthInfo = function(){
  return Auth.authInfo;
};
export default Auth;
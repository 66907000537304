import React, { Component } from 'react';
import ProjectPhoto from './Types/ProjectPhotos';
// import ClientSolid from "./Types/Client_Solid";
// import ClientReduce from "./Types/Client_Reduce";

//v2
import MyPageTypes from '../../components2/MyPage/MyPageTypes'
import MyPageFromConfig from '../../components2/MyPage/MyPageFromConfig'
// const Table = React.lazy(() => import('./Types/Table'));
// const Tab = React.lazy(() => import('./Types/Tab'));
// const Tab_DiagramChart = React.lazy(() => import('./Types/Tab_DiagramChart'));
// const ProjectSelectList = React.lazy(() => import('./Types/ProjectSelectList'));
// const Default = React.lazy(() => import('./Types/_Default'));
// const Panel = React.lazy(() => import('./Types/Panel'));
// const Form = React.lazy(() => import('./Types/Form'));
// const Table_Photos = React.lazy(() => import('./Types/Table_Photos'));
// const Table_Comments = React.lazy(() => import('./Types/Table_Comments'));
// const Tab_Detail = React.lazy(() => import('./Types/Tab_Detail'));
// const Dashboard = React.lazy(() => import('./Types/Dashboard'));
// const DashboardChart = React.lazy(() => import('./Types/Dashboard_Chart'));
// const Table_Block = React.lazy(() => import('./Types/Table_Block'));
// const Table_Remote = React.lazy(() => import('./Types/Table_Remote'));
// const Ninebox = React.lazy(() => import('./Types/Ninebox'));
// const Table_List = React.lazy(() => import('./Types/Table_List'));
// const Client = React.lazy(() => import('./Types/Client'));



function ComponentLoading(props) {
  return (
    <div></div>
  )
}

function getKeyFromOpts(opts){
  return opts!=null&&opts.index!=null?opts.index:'noIndex';
}

function setRef(config,opts,ref){
  if(opts.listRefs){
    let _key = config.Key || config.Type;
    if(_key){
      opts.listRefs[_key] = ref;
    }
  }
}

/**
 * PageGenericType, các kiểu hiển thị
 *
 * @class PageGenericType
 * @static
 * @namespace PageGenericType
 * 
 */
const PageGenericType = {
  addCustomType(key,format){
    if(PageGenericType.Types[key]==null){
      PageGenericType.Types[key] = format;
    }
    else{
      console.warn('CustomType exist:',key)
    }
  },
  Types:{
    // _default:(config,moreProps,opts)=>{
    //   return (
    //     <React.Suspense key={getKeyFromOpts(opts)} fallback={<ComponentLoading />}>
    //       <Default ref={r=>{setRef(config,opts,r);}} configUI={config} opts={opts} {...moreProps}/>
    //     </React.Suspense>  
    //   )
    // }, 
    // tab:(config,moreProps,opts)=>{
    //   return (
    //     <React.Suspense key={getKeyFromOpts(opts)} fallback={<ComponentLoading />}>
    //       <Tab ref={r=>{setRef(config,opts,r);}} configUI={config} opts={opts} {...moreProps}/>
    //     </React.Suspense>  
    //   )
    // },    
    // tab_diagramchart:(config,moreProps,opts)=>{
    //   return (
    //     <React.Suspense key={getKeyFromOpts(opts)} fallback={<ComponentLoading />}>
    //       <Tab_DiagramChart ref={r=>{setRef(config,opts,r);}} configUI={config} opts={opts} {...moreProps}/>
    //     </React.Suspense>  
    //   )
    // },
    // table:(config,moreProps,opts)=>{
    //   return (
    //     <React.Suspense key={getKeyFromOpts(opts)} fallback={<ComponentLoading />}>
    //       <Table ref={r=>{setRef(config,opts,r);}} configUI={config} opts={opts} {...moreProps}/>
    //     </React.Suspense>        
    //   )
    // },
    project_photo:(config,moreProps,opts)=>{
      return (
        <ProjectPhoto key={getKeyFromOpts(opts)} configUI={config} opts={opts} {...moreProps}/>
      )
    },
    // project_selectlist:(config,moreProps,opts)=>{
    //   return (
    //     <React.Suspense key={getKeyFromOpts(opts)} fallback={<ComponentLoading />}>
    //       <ProjectSelectList ref={r=>{setRef(config,opts,r);}} configUI={config} opts={opts} {...moreProps}/>
    //     </React.Suspense>  
    //   )
    // }, 
    // panel:(config,moreProps,opts)=>{
    //   return (
    //     <React.Suspense key={getKeyFromOpts(opts)} fallback={<ComponentLoading />}>
    //       <Panel ref={r=>{setRef(config,opts,r);}} configUI={config} opts={opts} {...moreProps}/>
    //     </React.Suspense>  
    //   )
    // },
    // form:(config,moreProps,opts)=>{
    //   return (
    //     <React.Suspense key={getKeyFromOpts(opts)} fallback={<ComponentLoading />}>
    //       <Form ref={r=>{setRef(config,opts,r);}} configUI={config} opts={opts} {...moreProps}/>
    //     </React.Suspense>  
    //   )
    // },
    // table_photos:(config,moreProps,opts)=>{
    //   return (
    //     <React.Suspense key={getKeyFromOpts(opts)} fallback={<ComponentLoading />}>
    //       <Table_Photos ref={r=>{setRef(config,opts,r);}} configUI={config} opts={opts} {...moreProps}/>
    //     </React.Suspense>  
    //   )
    // },
    // table_comments:(config,moreProps,opts)=>{
    //   return (
    //     <React.Suspense key={getKeyFromOpts(opts)} fallback={<ComponentLoading />}>
    //       <Table_Comments ref={r=>{setRef(config,opts,r);}} configUI={config} opts={opts} {...moreProps}/>
    //     </React.Suspense>  
    //   )
    // },
    // tab_detail:(config,moreProps,opts)=>{
    //   return (
    //     <React.Suspense key={getKeyFromOpts(opts)} fallback={<ComponentLoading />}>
    //       <Tab_Detail ref={r=>{setRef(config,opts,r);}} configUI={config} opts={opts} {...moreProps}/>
    //     </React.Suspense>  
    //   )
    // },
    // dashboard:(config,moreProps,opts)=>{
    //   return (
    //     <React.Suspense key={getKeyFromOpts(opts)} fallback={<ComponentLoading />}>
    //       <Dashboard ref={r=>{setRef(config,opts,r);}} configUI={config} opts={opts} {...moreProps}/>
    //     </React.Suspense>  
    //   )
    // },
    // dashboard_chart:(config,moreProps,opts)=>{
    //   return (
    //     <React.Suspense key={getKeyFromOpts(opts)} fallback={<ComponentLoading />}>
    //       <DashboardChart ref={r=>{setRef(config,opts,r);}} configUI={config} opts={opts} {...moreProps}/>
    //     </React.Suspense>  
    //   )
    // },
    // table_block:(config,moreProps,opts)=>{
    //   return (
    //     <React.Suspense key={getKeyFromOpts(opts)} fallback={<ComponentLoading />}>
    //       <Table_Block ref={r=>{setRef(config,opts,r);}} configUI={config} opts={opts} {...moreProps}/>
    //     </React.Suspense>  
    //   )
    // },
    // table_remote:(config,moreProps,opts)=>{
    //   return (
    //     <React.Suspense key={getKeyFromOpts(opts)} fallback={<ComponentLoading />}>
    //       <Table_Remote ref={r=>{setRef(config,opts,r);}} configUI={config} opts={opts} {...moreProps}/>
    //     </React.Suspense>  
    //   )
    // },
    // ninebox:(config,moreProps,opts)=>{
    //   return (
    //     <React.Suspense key={getKeyFromOpts(opts)} fallback={<ComponentLoading />}>
    //       <Ninebox ref={r=>{setRef(config,opts,r);}} configUI={config} opts={opts} {...moreProps}/>
    //     </React.Suspense>  
    //   )
    // },
    // table_list:(config,moreProps,opts)=>{
    //   return (
    //     <React.Suspense key={getKeyFromOpts(opts)} fallback={<ComponentLoading />}>
    //       <Table_List ref={r=>{setRef(config,opts,r);}} configUI={config} opts={opts} {...moreProps}/>
    //     </React.Suspense>  
    //   )
    // },
    // client:(config,moreProps,opts)=>{
    //   console.warn("client:",config,moreProps,opts,ClientSolid,ClientReduce);
    //   if(config && config.Config && config.Config.ConfigOnServer && config.Config.ConfigOnServer.type){
    //     console.warn("client 2:",config.Config.ConfigOnServer.type,MyPageTypes.Types,moreProps);
    //     if(MyPageTypes && MyPageTypes.Types[config.Config.ConfigOnServer.type.toLowerCase()]){
    //       return (
    //         <React.Suspense key={getKeyFromOpts(opts)} fallback={<ComponentLoading />}>
    //           <MyPageFromConfig configPage={config.Config} {...moreProps}/>
    //         </React.Suspense>            
    //       )
    //     }
    //     if(ClientSolid && ClientSolid.Types && ClientSolid.Types[config.Config.ConfigOnServer.type]){
    //       return ClientSolid.Types[config.Config.ConfigOnServer.type](config,moreProps,opts);
    //     }
    //     else if(ClientReduce && ClientReduce.Types && ClientReduce.Types[config.Config.ConfigOnServer.type]){
    //       return ClientReduce.Types[config.Config.ConfigOnServer.type](config,moreProps,opts);
    //     }
    //   }
    //   return (
    //     <React.Suspense key={getKeyFromOpts(opts)} fallback={<ComponentLoading />}>
    //       <Client ref={r=>{setRef(config,opts,r);}} configUI={config} opts={opts} {...moreProps}/>
    //     </React.Suspense>  
    //   )
    // },
    // mobile_table:(config,moreProps,opts)=>{//tro ve table, de man hinh ben mobile van hien duoc ben admin
    //   return (
    //     <React.Suspense key={getKeyFromOpts(opts)} fallback={<ComponentLoading />}>
    //       <Table ref={r=>{setRef(config,opts,r);}} configUI={config} opts={opts} {...moreProps}/>
    //     </React.Suspense>        
    //   )
    // },
  }
}

if(window._M){
  window._M.MyPageTypesV1 = PageGenericType;
}
export default PageGenericType;
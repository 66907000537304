import React, { Component } from 'react';
import MySVGHelpers from './MySVGHelpers';
import MySVGTypes from './MySVGTypes';
class MySVG extends Component {
  constructor(props) {
    super(props);
    this.state = { 
    };
  }

  componentDidMount(){
  }

  render() {
    const {type} = this.props;
    if(type && MySVGTypes.Types[type]){
      const _ui = MySVGTypes.Types[type](this.props);
      return _ui;
    }
    return (
      <div>MySVG No Type</div>
    )
  }
}

export default MySVG;

import React from 'react'
import { MyLib, MyLibHelpers, CSuppense } from '@macashipo/mlib';

// const MLRater = React.lazy(() => import('./Types/rater'));
const MLCode_mirror = React.lazy(() => import('./Types/code_mirror'));
const MLReactquill = React.lazy(() => import('./Types/reactquill'));
const MLReactmuticarousel = React.lazy(() => import('./Types/reactmuticarousel'));
const MLReactdraggable = React.lazy(() => import('./Types/reactdraggable'));
const MLReactslick = React.lazy(() => import('./Types/reactslick'));
const MLChromepicker = React.lazy(() => import('./Types/chromepicker'));
const MLDaterangepicker = React.lazy(() => import('./Types/daterangepicker'));
const MLReactdatepicker = React.lazy(() => import('./Types/reactdatepicker'));
const MLSelect2 = React.lazy(() => import('./Types/select2'));

const ListLazy = {
  code_mirror: MLCode_mirror,
  reactquill: MLReactquill,
  reactmuticarousel: MLReactmuticarousel,
  reactdraggable: MLReactdraggable,
  reactslick: MLReactslick,
  chromepicker: MLChromepicker,
  daterangepicker: MLDaterangepicker,
  reactdatepicker: MLReactdatepicker,
  select2: MLSelect2,
}
MyLibHelpers.addTypesFromListLazy(ListLazy)
export default MyLibHelpers;
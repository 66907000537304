import React, { Component } from 'react';
import { Tooltip } from '../../../../mreact/core';

function group({ key = "group1", childs = "", width = "auto", height = "auto", style = { display: "block" } }) {
  return (
    <g key={key} width={width} height={height} style={style}>
      {childs}
    </g>
  );
};

function line({ ref = null, key = "line1", x1 = 0, y1 = 0, x2 = 0, y2 = 0, stroke = "#000000", strokeWidth = "1" }) {
  return (
    <line ref={ref} key={key} x1={x1} y1={y1} x2={x2} y2={y2} stroke={stroke} strokeWidth={strokeWidth}></line>
  );
};

function polyline({ ref = null, key = "polyline1", points ="",  stroke = "#000000", strokeWidth = "1" }) {
  return (
    <polyline ref={ref} key={key} points={points}  fill="none" stroke={stroke} strokeWidth={strokeWidth}
      marker-start="url(#dot)"  marker-end="url(#arrow)"
    ></polyline>
    // marker-mid="url(#dot)" 
  );
};

function text({ ref = null, key = "text1", x = 0, y = 0, fill = "black", fontSize = "12", fontWeight = "", text = "Timespan here", textAnchor = "middle", transform = "", className = "" },opts={}) {
  let _moreProps = {};
  if(opts && opts.style){
    _moreProps.style = opts.style;
  }
  return (
    <text ref={ref} key={key} x={x} y={y} fill={fill} fontSize={fontSize} textAnchor={textAnchor} fontWeight={fontWeight} transform={transform} className={className} {..._moreProps}>{text}</text>
  );
};
/*
Tooltip
- Tooltip bao lấy circle
- import { Tooltip } from '../../../../mreact/core';
 */
function circle({ key = "circle1", cx = 0, cy = 0, r = 0, stroke = "black", strokeWidth = 0, fill = "black", fillOpacity = "1", title = '', className = "test" }) {
  let html = (
    <circle key={key} className={className} cx={cx} cy={cy} r={r} fill={fill} fillOpacity={fillOpacity} stroke={stroke} strokeWidth={strokeWidth}></circle>
  );
  if (title) html = <Tooltip key={key} placement="top" overlay={title} trigger='hover focus click'>{html}</Tooltip>

  return html;
};

function rect(config={},opts={}) {
  const { ref = null, key = "rect1", x = 0, y = 0, rx = 0, ry = 0, width = 0, height = 0, stroke = "black", strokeWidth = 0, fill = "black", strokeDasharray=null, strokeLinecap=null, fillOpacity = "1", title = "", filter = null } = config;
  let html = (
    <rect onClick={()=>{
      if(opts && opts.onClickItem){        
        opts.onClickItem(config,opts);
      }
    }} ref={ref} key={key} x={x} y={y} rx={rx} ry={ry} width={width} height={height} fillOpacity={fillOpacity} stroke={stroke} strokeWidth={strokeWidth} strokeDasharray={strokeDasharray} strokeLinecap={strokeLinecap} fill={fill} filter={filter} >
    </rect>
  );  
  //20190627 KhanhTL, không show title nũa
  // if (title) {
  //   html = (
  //     <Tooltip key={key} placement="top" overlay={title} trigger='hover focus click'>
  //       {html}
  //     </Tooltip>
  //   );
  // };
  return html;
};

/*
marker-end="url(#arrow)":
- #arrow: was defined in  <defs key="defsDefault">
- ref:  https://riptutorial.com/svg/example/17056/start--mid-and-end-markers-on-line--polyline--polygon-and-path-elements
        https://developer.mozilla.org/en-US/docs/Web/SVG/Element/marker
 */
function path({ key = "path1", d = "", stroke = null, strokeWidth = 0, fill = "green", strokeDasharray,markerEnd="url(#arrow)" }) {
  return (
    <path key={key} d={d} fill={fill} stroke={stroke} strokeWidth={strokeWidth} strokeDasharray={strokeDasharray} 
    markerEnd={markerEnd}
    />
  );
};

function svg({ key = "svgGroup1", width = "100%", height = "100%", childs = "", style = { display: "block" } }) {
  return (
    <svg key={key} width={width} height={height} style={style}>
      {childs}
    </svg>
  )
}

function ColorLuminance(hex, lum) {
  // validate hex string
  hex = String(hex).replace(/[^0-9a-f]/gi, '');
  if (hex.length < 6) {
    hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
  }
  lum = lum || 0;

  // convert to decimal and change luminosity
  var rgb = "#", c, i;
  for (i = 0; i < 3; i++) {
    c = parseInt(hex.substr(i * 2, 2), 16);
    c = Math.round(Math.min(Math.max(0, c + (c * lum)), 255)).toString(16);
    rgb += ("00" + c).substr(c.length);
  }

  return rgb;
};

export default {
  svg: svg,
  group: group,
  line: line,
  text: text,
  circle: circle,
  rect: rect,
  path: path,
  colorLuminance: ColorLuminance,
};
import React from "react"
import {MyPageTypes} from "../_import"
import {CSuspense} from "../_import"
// const PQuiz = React.lazy(() => import("./Types/quiz"))
const PQuizZing = React.lazy(() => import("./Types/quiz_zing"))
const MyPageOne = {
  init(){
    MyPageTypes.addTypes("quiz",(props)=>{return CSuspense(props,PQuizZing)} )
    MyPageTypes.addTypes("quiz_zing",(props)=>{return CSuspense(props,PQuizZing)} )
  }
}

export default MyPageOne
import React from 'react';
/**
 * Form Type: html.
 *
 * @class html
 * @static
 * @namespace html
 * @memberof FormType
 * 
 * @example
 * 
 */
class FormHtml extends React.Component {
  static propTypes = {
  }
  static defaultProps = {
  }
  constructor(props) {
    super(props);
    this.state={
    };
    
  }

  /**
   * Thuộc tính sử dụng trong more:
   * 
   * @method more
   * @memberof FormType.html

   * 
  */
  more() {
  }

  render() {
    const {item, opts} = this.props
    console.warn('Form Html this.props: ', item, opts, this.props);
    let _html = {__html: item.Content || "" };

    return (
      <div className="mui-form-text" dangerouslySetInnerHTML={_html}>
        
      </div>
    )
  }
}

export default FormHtml;
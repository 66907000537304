import React from 'react';
import { MyPage } from "@macashipo/mlib";

const TheLayout = React.lazy(() => import('./layouts/index'));
// const LoginPage = React.lazy(() => import('../../v1/views/Login/Login'));

//SolidV1
// const V1_Welcome = React.lazy(() => import('../../v1/views/Login/Welcome'));
// const V1_ProjectDefect = React.lazy(() => import('../../v1/views/ProjectDefect/_ProjectDefect'));

const routes = [
  { path: '/login', exact: true, name: 'Login', component: (props)=>{
    return <MyPage configPage={{UIType:"login_with2fa"}} {...props}/>
  } },
  { path: '/login/:id', exact: true, name: 'Login', component: (props)=>{
    return <MyPage configPage={{UIType:"login_with2fa"}} {...props}/>
  } },
  // { path: '/login', exact: true, name: 'Login', component: LoginPage },
  // { path: '/login/:id', exact: true, name: 'Login', component: LoginPage },
  { path: '/', exact: false, name: 'Blank', component: TheLayout },  
];

const routes_private = [
  // { path: '/welcome', exact: true, name: 'Welcome', component: V1_Welcome },//Test V1
  // { path: '/project/defect/:id', exact: true, name: 'ProjectDefect', component: V1_ProjectDefect },//Test V1
];


const routes_dev = [
  // ...RoutesDev
]

const routes_simulate = [
  // { UIUrl:'/', ScreenCode:'PAGE_CLIENT_HOME', Title:'Screen List', APIName: null, UIType: "SC_Home", Config: "", RequestData: null, Id:"1"},
  // { UIUrl:'/welcome', ScreenCode:'Welcome', Title:'', APIName: null, UIType: "welcome", Config: "", RequestData: null, Id:"1"},
  // { UIUrl:'/dev/category-list', ScreenCode:'Test', Title:'', APIName: null, UIType: "dev_categorylist", Config: "", RequestData: null, Id:"1"},
  // { UIUrl:'/dev/doc', ScreenCode:'Test', Title:'', APIName: null, UIType: "dev_doc", Config: "", RequestData: null, Id:"1"},
  //Solid
  // { UIUrl:'/plan_week_v2', ScreenCode:'PlanWeekV2', Title:'', APIName: null, UIType: "client", Config: '{"type":"solid_planweekv2"}', RequestData: null, Id:"123"},
  // { UIUrl:'/plan_week_pm', ScreenCode:'PlanWeekPM', Title:'', APIName: null, UIType: "client", Config: '{"type":"solid_planweekpm"}', RequestData: null, Id:"124"},
  //Test
  // { UIUrl:'/test', ScreenCode:'Test', Title:'', APIName: null, UIType: "test", Config: '{"type":"mychart"}', RequestData: null, Id:"1"},
  // { UIUrl:'/test/myuiinput', ScreenCode:'Test', Title:'', APIName: null, UIType: "test", Config: '{"type":"myuiinput"}', RequestData: null, Id:"2"},
  // { UIUrl:'/test/mybutton', ScreenCode:'Test', Title:'', APIName: null, UIType: "test", Config: '{"type":"mybutton"}', RequestData: null, Id:"3"},
  // { UIUrl:'/test/libs', ScreenCode:'Test', Title:'', APIName: null, UIType: "test", Config: '{"type":"libs"}', RequestData: null, Id:"3"},
  // { UIUrl:'/test/libs/mv3', ScreenCode:'Test', Title:'', APIName: null, UIType: "test", Config: '{"type":"libs_mv3"}', RequestData: null, Id:"3"},
  // { UIUrl:'/test', ScreenCode:'Test', Title:'', APIName: null, UIType: "test", Config: '{"type":"mytablefilter"}', RequestData: null, Id:"1"},  
  // { UIUrl:'/test/mycard', ScreenCode:'Test', Title:'', APIName: null, UIType: "test", Config: '{"type":"mycard"}', RequestData: null, Id:"1"},  
  // { UIUrl:'/test/myicon', ScreenCode:'Test', Title:'', APIName: null, UIType: "test", Config: '{"type":"myicon"}', RequestData: null, Id:"1"},
  // { UIUrl:'/test/mylayout/admin', ScreenCode:'Test', Title:'', APIName: null, UIType: "test", Config: '{"type":"mylayout_admin"}', RequestData: null, Id:"1"},  
  // { UIUrl:'/test/mycss/textshadow', ScreenCode:'Test', Title:'', APIName: null, UIType: "test", Config: '{"type":"mycss_textshadow"}', RequestData: null, Id:"1"},
  // { UIUrl:'/test/mycss/boxshadow', ScreenCode:'Test', Title:'', APIName: null, UIType: "test", Config: '{"type":"mycss_boxshadow"}', RequestData: null, Id:"1"},
  // { UIUrl:'/test/mylayout/treeview', ScreenCode:'Test', Title:'', APIName: null, UIType: "test", Config: '{"type":"mylayout_treeview"}', RequestData: null, Id:"1"},
  // { UIUrl:'/test/mylayout/column_resizer', ScreenCode:'Test', Title:'', APIName: null, UIType: "test", Config: '{"type":"mylayout_column_resizer"}', RequestData: null, Id:"1"},
  // { UIUrl:'/test/mylayout/ganttchart', ScreenCode:'Test', Title:'', APIName: null, UIType: "test", Config: '{"type":"mylayout_ganttchart"}', RequestData: null, Id:"1"},
  // { UIUrl:'/test/mylib/react-slick', ScreenCode:'Test', Title:'', APIName: null, UIType: "test", Config: '{"type":"mylib_reactslick"}', RequestData: null, Id:"1"},
  { UIUrl:'/test/myui/blank', ScreenCode:'Test', Title:'', APIName: null, UIType: "test", Config: '{"type":"myui_blank"}', RequestData: null, Id:"1"},
  // { UIUrl:'/test/myui/select_list', ScreenCode:'Test', Title:'', APIName: null, UIType: "test", Config: '{"type":"myui_select_list"}', RequestData: null, Id:"1"},
  { UIUrl:'/test', ScreenCode:'Test', Title:'', APIName: null, UIType: "test", Config: '{"type":"test"}', RequestData: null, Id:"1"},
]

const routes_test = [
]

export {
  routes,
  routes_private,
  routes_dev,
  routes_simulate,
  routes_test
}

import React from "react"
import { MyPageHelpers } from "@macashipo/mlib";
const MPSetting2FA = React.lazy(() => import("./Types/setting2fa"));
const List = {
  setting2fa: MPSetting2FA
};
const MyPageOne = {
  init() {
    MyPageHelpers.addTypesFromListLazy(List);
  },
};

export default MyPageOne;

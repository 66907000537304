import Moment from 'moment';
import React, { Component } from 'react';
import {
  Button, Card, CardBody, CardFooter, CardHeader, CardImg
} from '../../../_importComponent';
import ModalPhotoViewer from '../../../components/ModalPhotoViewer';
import * as H from '../../../helpers';
import ApiGeneric from '../../../services/generic';
import PageExt from '../PageExt';
import {MyLib} from '../../../_importMyComponent'
/**
 * PageProjectPhotoGeneric.
 *
 * @class PageProjectPhotoGeneric
 * @static
 * @namespace PageGeneric
 * 
 */
class PageProjectPhotoGeneric extends PageExt {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,    
      expandAll: false,
      collapseAll: true,
      isShowContent: true,
      isUsingStaticData: false,
      isHideWhenEmpty: false,
    };

    this._projectId = this.getProjectId();
    this._screenCode = this.getScreenCode();    
    this.parseDefaultValueFromConfigUI(this.state);
    if(this.state.isUsingStaticData==true){
      let _data = this.getStaticData();
      let _f = this._getDefaultFilter(_data);
      this.state.photos = _data;
      this.state.startDate = _f.startDate;
      this.state.endDate = _f.endDate;
    }
  }

  _sampleConfigUI=()=>{
    this._configUI = {
      Type: 'project_photo',
      Config: {
        ApiMethod: 'POST',
        ApiController: 'ProjectPhoto',
        ApiName: 'GetPhotoReport',
      },
    }
  }

  componentDidMount(){
    if(this.state.isUsingStaticData==false){
      this._requestPhotos();
    }   
  }

  _getDefaultFilter=(photos)=>{
    let _f = {};
    if (photos != null) {
      let _max = 0;
      for (let _item of photos) {
        let _createdAt = _item.CreatedAt;
        if (_createdAt > _max) {
          _max = _createdAt;
        }
      }
      if (_max > 0) {
        let _milisecondOneDay = 1000 * 60 * 60 * 24;
        _f.endDate = Moment(_max + _milisecondOneDay);
        _f.startDate = Moment(_max - (_milisecondOneDay * 7 * 8));
      }
    }
    return _f;
  }

  _getPhotosFiltered=()=>{
    const {groupBy,photos,startDate,endDate} = this.state;
    let _photoFiltered = [];
    let _listWithKey = {};
    if(photos){
      for(let i in photos){
        let _item = photos[i];
        let _createdAt = _item.CreatedAt;
        let _isValid = false;
        if (!startDate) {
          _isValid = true;
        } else {
          let _startDateMili = startDate._d.getTime();
          _isValid = (_createdAt > _startDateMili);
          if (endDate) {
            let _endDateMili = endDate._d.getTime();
            _isValid &= (_createdAt < _endDateMili);
          };
        };
        if (!_isValid) continue;
        let _key;
        switch (groupBy) {
          case "WPG":
            _key = _item.WPGId;
            break;
          case "week":
            _key = H.Global.Date.dateStringWithFormat(_item.CreatedAt, "YYYY-[W]WW");
            break;
          case "date":
            _key = H.Global.Date.dateStringWithFormat(_item.CreatedAt, "YYYY-MM-DD");
            break;
          case "Project":
            _key = _item.ProjectId_Name;
            break;
          default:
            _key = _item.Groupby;
            break;
        };
        console.log("GroupBy", groupBy, _key);
        let _newItem = {
          url: _item.FileUrl_FullSize,
          FileUrl: _item.FileUrl,
          FileUrl_Download: _item.FileUrl_Download,
          FileName: _item.FileName,
          CreatedAt: _item.CreatedAt,
        };

        _listWithKey[_key] = _listWithKey[_key] || [];
        _photoFiltered.push(_newItem);
        _listWithKey[_key].push(_newItem);
      }      
    }
    return {
      photos: _photoFiltered,
      listWithKey: _listWithKey
    };
  }

  _onChangeFilter=(e)=>{
    let _value = e.target.value;
    this.setState({
      groupBy: _value
    });
  }

  _onExpandAll=()=>{
    this.setState({
      expandAll: true,
      collapseAll: false,
    });
  };

  _onCollapseAll=()=>{
    this.setState({
      expandAll: false,
      collapseAll: true,
    });
  };

  _requestPhotos=()=>{
    this.setState({
      isLoading: true,
    },()=>{
      ApiGeneric.generic({
        request: this.getRequestMethodFromConfig(),
        data: this.getRequestQuery({
          ProjectId: this.getProjectId(),
          ScreenCode: this.getScreenCode()
        }),
        successCallBack: (response) => {          
          let _f = this._getDefaultFilter(response.Data);
          this.setState({
            photos: response.Data,
            startDate: _f.startDate,
            endDate: _f.endDate
          });
        },
        errorCallBack: (error, response) => {
          console.log('_onRequestPhotos errorCallBack:', error, response);
          this.setState({ error: error != null ? error : response, isLoading: false });
        }
      });
    });
  }

  _renderEmpty=()=>{
    if(this.state.isHideWhenEmpty==true){
      return (
        <div></div>
      )
    }
    return (
      <Card className="s_boxshadow" style={{visibility:this.props.hideWhenEmpty==true?"hidden":"visible"}}>
        <CardHeader style={{ padding: '10px', fontWeight: 'bold' }}>
          {this.props.title || "Photos"}
        </CardHeader>
        <CardBody style={{ padding: '10px' }}>
          {this.state.photos!=null? H.Text.get('msg_nophoto'): H.Text.get('msg_loading')}
        </CardBody>
      </Card>
    )
  }
  _renderPhotos=()=>{
    let _obj = this._getPhotosFiltered();
    let _ui = [];
    if(_obj.photos){
      let _keys = Object.keys(_obj.listWithKey);
      for(let k of _keys){
        _ui.push(
          <PhotoRow key={k} title={k} photos={_obj.listWithKey[k]} expandAll={this.state.expandAll}/>
        )
      }
    }
    if(_ui.length>0){
      return (
        <div>
          <div className="sp_row">
            <button onClick={() => this._onCollapseAll()} >Collapse all</button>
            <button onClick={() => this._onExpandAll()} style={{ marginLeft: '10px' }}>Expand all</button>
            <span style={{ marginLeft: '10px' }}>
              {
                this.state.startDate!=null ?
                `Có ${_obj.photos.length} file(s) được lọc lại từ ${this.state.photos.length} files`
                :
                `Có tất cả ${_obj.photos.length} file(s) được tìm thấy`
              }
            </span>
          </div>
          <div>
            {_ui}
          </div>
        </div>
      );
    }     
  }
  render() {    
    if(this.state.photos && this.state.photos.length>0){
      return (
        <Card className={this.props.isShadow===true?"s_boxshadow":""}>
          <CardHeader style={{padding:'5px',display:'flex',flexDirection:'row',}}>
            <Button style={{color:'#44cfae',padding:'0px',margin:'0px'}} color="link" onClick={()=>{
              this.setState({isShowContent:!this.state.isShowContent})
            }}>
              <i style={{fontSize:'25px'}} className={`fa ${this.state.isShowContent?'fa-chevron-circle-up':'fa-chevron-circle-down'}`}></i>
            </Button>
            <div style={{display:'flex',alignItems:'center',marginLeft:'5px'}} className="s_title_page">
              {this.getTitle()}
            </div>
          </CardHeader>
          {
            this.state.isShowContent &&
            <CardBody style={{ padding: '10px' }}>
              <div className="sp_row dFfdRjcSB">
                <div className="sp_lb">{H.Text.get('msg_list_files')}</div>{
                  this.props.showEye == true &&
                  <div>
                    <Button onClick={(e)=>{
                      var _e = e.currentTarget;
                      if(_e){
                        var _element = document.getElementById(`page-pj-photos`);
                        _element.classList.toggle("no-print");
                      }
                  }} color="link" style={{marginRight: '5px'}} className="no-print"><i className="fa fa-print"></i></Button>
                  </div>
                }                
              </div>
              <div className="sp_row">
                <MyLib type="daterangepicker" locale={{
                  format: 'DD/MM/YYYY',
                  }} showDropdowns onApply={(event, picker)=>{
                    this.setState({
                      startDate: picker.startDate,
                      endDate: picker.endDate
                    });
                  }}>
                  {
                    this.state.startDate != null ?
                    <button>Filter {this.state.startDate.format('DD/MM/YYYY')} - {this.state.endDate.format('DD/MM/YYYY')}</button>
                    :
                    <button>Filter</button>
                  }
                </MyLib>
                <button style={{ marginLeft: '10px' }} onClick={() => {
                  this.setState({
                    startDate: null,
                    endDate: null,
                  })
                }}>Bỏ Filter</button>
                <label style={{ marginLeft: '10px', textAlign: "right", fontWeight: "bold", width: "60px" }}>Group: </label>
                <select style={{ marginLeft: '10px', width: "200px" }} defaultValue="date" onChange={(e) => this._onChangeFilter(e)}>
                  <optgroup label="Create Date">
                    <option value="date">Date</option>
                    <option value="week">Week</option>
                  </optgroup>
                  <optgroup label="Others">
                    <option value="WPG">WPG</option>
                    <option value="Project">Project</option>
                  </optgroup>
                </select>
              </div>
              {this._renderPhotos()}
            </CardBody>
          }          
        </Card>          
      )
    }
    else{
      return this._renderEmpty()
    }
  }
}

class PhotoRow extends Component {
  constructor(props) {
    super(props);
    this.state={
      isExpand: false,
      expandAll: false,
    }

    if(this.props.expandAll==true){
      this.state.isExpand=true;
      this.state.expandAll=true;
    }
  }

  _onToggleCard=()=>{
    this.setState({
      isExpand: !this.state.isExpand
    })
  }

  componentWillReceiveProps(newProps) {
    const { photos, title, expandAll } = newProps;
    if(expandAll==true){
      this.setState({
        isExpand: true,
        expandAll: true
      });
    }
    else{
      this.setState({
        isExpand: false,
        expandAll: false
      });
    }
  };

  _renderPhotos=()=>{
    const {photos} = this.props;
    let _ui = [];

    for(let i in photos){
      let _item = photos[i];
      _ui.push(
        <Card className="sp_list_item" key={`photoCard_${i}`}>
          <Button color="link" style={{ padding: '0px' }} onClick={() => {
            ModalPhotoViewer.Helper.show(photos, i);
          }}>
            <CardImg top width="100%" height="180px" src={_item.FileUrl} alt="" loading="lazy"/>
          </Button>
          <CardFooter style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', padding: '3px' }}>
            <div style={{ width: '100%', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
              <Button color="link" target="_blank" href={_item.FileUrl_Download}>{_item.FileName}</Button>
            </div>
            <div>
              {H.Global.Date.dateStringWithFormat(_item.CreatedAt, 'MMM DD YYYY')}
            </div>
          </CardFooter>
        </Card>
      );
    }
    return _ui;
  }
  render(){
    const {photos,title} = this.props;
    // console.warn('render PhotoRow:',this.state,this.props);
    return (
      <div>
        <Card className="">
          <CardHeader tag="h3" style={{ padding: "10px 10px 0px 0px" }}>
            <div className="nav">
              <Button color="link" onClick={() => this._onToggleCard()}>
                <i style={{ fontSize: '25px' }} className={`fa ${this.state.isExpand ? 'fa-chevron-circle-up' : 'fa-chevron-circle-down'}`}></i>
              </Button>
              <div className="s_title_page">{`${title} (${photos.length})`}</div>
            </div>
          </CardHeader>
          <CardBody style={{ display: this.state.isExpand ? "block" : "none" }}>
            <div className="sp_list">
            {
              this._renderPhotos()
            }
            </div>
          </CardBody>
        </Card>        
      </div>
    )    
  }
}

export default PageProjectPhotoGeneric;

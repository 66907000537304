import React from "react";
import ConfigTabV3 from "../../../model/ConfigTabV3";
import {
  connect, HApiAxios, HUtils, MyModal
} from "../_import";
import Tabv3_Lev3 from "./tabv3_lev3";
class PageTab3L3_Department extends Tabv3_Lev3 {
  constructor(props) {
    super(props);
    this.state = {
    };
    this.initTab();
    this._parseParams();
  }
  _parseParams=()=>{
  }
  componentDidMount() {
    if (this._isNeedRequestOptions()) {
      this.requestOptions({
        cbSuccess: () => {},
      });
    }
    this.getDepartmentList();
  }
  getSharedQueryDataMore=()=>{
    const { departmentCurrent, departmentSwitchIndex } = this.props;
    if (departmentSwitchIndex !== -1 && departmentCurrent) {    
      return {
        DepartmentId: departmentCurrent.Id
      }  
    }
  }
  getDepartmentList = () => {
    const { departmentSwitchIndex, setObjInSolidV3} = this.props;
    HApiAxios.generic({
      request: {
        method: "POST",
        path: "Department4SolidDashboard",
        name: "List",
      },
      data: {},
      successCallBack: (response) => {
        let _departmentList = HUtils.Obj.get(response, "Data.Data") || [];
        let _info = ConfigTabV3.parseDepartmentList(_departmentList);
        console.warn("_infoDepartment",_info,_departmentList)
        setObjInSolidV3({
          departmentList: _departmentList,
          departmentCurrent: _info.Data,
          departmentActiveIndex:_info.Index,
          departmentSwitchIndex: departmentSwitchIndex!=-1?_info.Index:departmentSwitchIndex,//update departmentSwitchIndex lai cho dung voi project
        })   
        this.setState({
          loadedTab: [this.getTabId({index:this.state.activeTabIndex})]
        });
      },
      errorCallBack: (error, response) => {
        console.warn("error: ", error, response);
      },
    });
  };
  getCurrentDepartmentName = ()=>{
    const {departmentCurrent} = this.props;
    if(departmentCurrent){
      return departmentCurrent.Name;
    }
  }
  onSwitch = (index) => {
    const { fnList, setSwitchDepartment, departmentCurrent } = this.props;
    if(index==-1){
      ConfigTabV3.switchOffSolidDepartment()
    }
    else if(departmentCurrent){
      ConfigTabV3.switchSolidDepartment(departmentCurrent.Id,departmentCurrent,index,{
        needClearTab: true
      });
    }
    setSwitchDepartment(index,departmentCurrent);
    if(fnList && fnList.fntv1OnReloadUI){
      fnList.fntv1OnReloadUI();
    }      
  };
  onChangeDepartment = (item) => {
    const { departmentList, fnList, setObjInSolidV3 } = this.props;
    console.warn("onChangeDepartment:",item);
    let _index = 0;
    let _item = departmentList.find((v, i) => {
      if (v.Id === item.Id) {
        _index = i;
      }
      return v.Id === item.Id;
    });
    if (_item) {
      if(fnList && fnList.fntv1OnReloadUI){
        fnList.fntv1OnReloadUI();
      }    

      setObjInSolidV3({
        departmentCurrent: _item,
        departmentActiveIndex: _index,
        departmentSwitchIndex: _index,
      })

      //set url
      ConfigTabV3.switchSolidDepartment(_item.Id,_item,_index,{
        needClearTab: true
      });

      MyModal.Helper.hide();
    }
  };

  renderPreLeftMenu = () => {
    const { departmentList, departmentSwitchIndex, departmentActiveIndex} = this.props;
    return (
      <div
        className="switch-header"
        style={{
          marginRight: "50px",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginRight: "8px",
            fontWeight: 500,
          }}
        >
          <label className="label-switch" style={{ padding: "0px 5px" }}>
            <div
              className="toggle-switch"
              style={{
                boxShadow: "none",
                backgroundColor: "rgb(111 119 130 / 52%)",
              }}
            >
              <input
                className="toggle-state"
                type="checkbox"
                name="check"
                checked={departmentSwitchIndex === -1 ? false : true}
                onChange={() =>
                  this.onSwitch(departmentSwitchIndex === -1 ? departmentActiveIndex : -1)
                }
              />
              <div className="indicator" style={{ boxShadow: "none" }}></div>
            </div>
          </label>
          <div
            style={{
              display: "flex",
              flexWrap: "wrap"
            }}
          >
            <div
              style={{
                color: departmentSwitchIndex === -1 ? "#c74634" : "var(--grey-primary)",
                cursor: "pointer",
              }}
              onClick={() => {
                if (departmentActiveIndex === departmentSwitchIndex) {
                  this.onSwitch(-1)
                }
              }}
            >
              Solid
            </div>
            <div
              style={{
                margin: "0px 2px",
                color: "var(--grey-primary)",
              }}
            >
              /
            </div>
            <div
              style={{
                color:
                departmentSwitchIndex === departmentActiveIndex
                    ? "#c74634"
                    : "var(--grey-primary)",
                cursor: "pointer",
              }}
              onClick={() => {
                console.warn("onClick:",departmentActiveIndex,departmentSwitchIndex)
                if (departmentActiveIndex === departmentSwitchIndex) {
                  MyModal.Helpers.showMyUI(
                    "list_card",
                    {
                      dataList: departmentList,
                      cardType: "card_project_select",
                      configPagination: {
                        using: true,
                        pageSize: 10,
                      },
                      fnList: {
                        fnSelectProject: (pj) => {
                          this.onChangeDepartment(pj);
                        },
                      },
                    },
                    {
                      title: "Choose Department",
                      closeButton: true,
                      size: "lg",
                    }
                  );
                }
                else{
                  this.onSwitch(departmentSwitchIndex === -1 ? departmentActiveIndex : -1)
                }
              }}
            >
              {this.getCurrentDepartmentName()}
            </div>
          </div>
        </div>
      </div>
    );
  };
  render() {
    const {departmentCurrent,waitingOptionsTab1} = this.props;
    if(waitingOptionsTab1){
      return (
        <div className="app">
          {this.renderTab()}        
        </div>
      )
    }
    return (
      <div className="app">
        {this.renderTab()}        
        {departmentCurrent && this.renderContent()}
      </div>
    );
  }
}
// export default PageTab3L3_Project;
const mapStateToProps = state =>{
  return{
    departmentCurrent: state.solidv3.departmentCurrent,
    departmentList: state.solidv3.departmentList,
    departmentSwitchIndex: state.solidv3.departmentSwitchIndex,
    departmentActiveIndex: state.solidv3.departmentActiveIndex,
    waitingOptionsTab1: state.solidv3.waitingOptionsTab1,
  }
}
const mapDispatchToProps=dispatch=>{
  return{
    setObjInSolidV3: (obj)=>{
      dispatch({type: 'setObjInSolidV3',...obj});      
    },
    setSwitchDepartment: (index,current)=>{
      dispatch({type: 'setObjInSolidV3',departmentSwitchIndex:index,departmentCurrent: current})
    },
  }
}
export default connect(mapStateToProps,mapDispatchToProps)(PageTab3L3_Department)
import React from 'react';

import MyButton_FileManager from './Types/file_manager';

const Default = React.lazy(() => import('./Types/_default'));
const Api = React.lazy(() => import('./Types/api'));
const Form = React.lazy(() => import('./Types/form'));
const Upload = React.lazy(() => import('./Types/upload'));
const Link = React.lazy(() => import('./Types/link'));
const CS_QS2_TextareaFull = React.lazy(() => import('./Types/cs_qs2_textareafull'));
const Modal = React.lazy(() => import('./Types/modal'));
const ModalPage = React.lazy(() => import('./Types/modal_page'));
const Action = React.lazy(() => import('./Types/action'));
const InputQR = React.lazy(() => import('./Types/input_qr'));
const MyButton_With_MyUI = React.lazy(() => import('./Types/with_myui'));
const Print = React.lazy(() => import('./Types/print'));
const MB_switch_view = React.lazy(() => import('./Types/switch_view'));

function ComponentLoading(props) {
  return (
    <div></div>
  )
}

const ButtonTypes = {
  addTypes(key,fnUI){
    if(ButtonTypes.UI[key]==null){
      ButtonTypes.UI[key] = fnUI;
    }
    else{
      console.warn('addTypes exist:',key)
    }
  },
  UI:{
    _default:(props)=>{ return( <React.Suspense fallback={<ComponentLoading />}> <Default {...props}/> </React.Suspense> ) },
    api:(props)=>{ return( <React.Suspense fallback={<ComponentLoading />}> <Api {...props}/> </React.Suspense> ) },
    form:(props)=>{ return( <React.Suspense fallback={<ComponentLoading />}> <Form {...props}/> </React.Suspense> ) },
    upload:(props)=>{ return( <React.Suspense fallback={<ComponentLoading />}> <Upload {...props}/> </React.Suspense> ) },
    link:(props)=>{ return( <React.Suspense fallback={<ComponentLoading />}> <Link {...props}/> </React.Suspense> ) },
    cs_qs2_textareafull:(props)=>{ return( <React.Suspense fallback={<ComponentLoading />}> <CS_QS2_TextareaFull {...props}/> </React.Suspense> ) },
    modal:(props)=>{ return( <React.Suspense fallback={<ComponentLoading />}> <Modal {...props}/> </React.Suspense> ) },
    modal_page:(props)=>{ return( <React.Suspense fallback={<ComponentLoading />}> <ModalPage {...props}/> </React.Suspense> ) },
    action:(props)=>{ return( <React.Suspense fallback={<ComponentLoading />}> <Action {...props}/> </React.Suspense> ) },
    input_qr:(props)=>{ return( <React.Suspense fallback={<ComponentLoading />}> <InputQR {...props}/> </React.Suspense> ) },
    with_myui:(props)=>{ return(<React.Suspense fallback={<ComponentLoading />}><MyButton_With_MyUI {...props}/></React.Suspense>)},
    file_manager:(props)=>{ return(<MyButton_FileManager {...props}/>)},
    print:(props)=>{ return(<React.Suspense fallback={<ComponentLoading />}><Print {...props}/></React.Suspense>)},
    switch_view: (props)=>{ return(<React.Suspense fallback={<ComponentLoading />}><MB_switch_view {...props}/></React.Suspense>)},
  }
}
window.MyButtonTypes = ButtonTypes.UI;
export default ButtonTypes;
import Api from './api';
import Constant from '../helpers/Constant';

const name_prefix = 'project_sitephoto_';
export default {
  request:{
    options:{
      type: name_prefix + 'options',
      url: '/api/v1/ProjectSitePhoto/Options',
    },
    list:{
      type: name_prefix + 'list',
      url: '/api/v1/ProjectSitePhoto/List',
    },
    update_field:{
      type: name_prefix + 'update_field',
      url: '/api/v1/ProjectSitePhoto/UpdateFields',
    },
    add:{
      type: name_prefix + 'add',
      url: '/api/v1/ProjectSitePhoto/Add',
    },
    addmulti:{
      type: name_prefix + 'addmulti',
      url: '/api/v1/ProjectSitePhoto/AddMulti',
    },
    delete:{
        type: name_prefix + 'delete',
        url: '/api/v1/ProjectSitePhoto/Delete',
    },
    options_sitephoto:{
      type: name_prefix + 'options_sitephoto',
      url: '/api/v1/ProjectSitePhoto/SitePhotoOptions',
    },
    update_wpg:{
      type: name_prefix + 'update_wpg',
      url: '/api/v1/ProjectSitePhoto/UpdateWPG',
    },
  },
  get_options(ProjectId,options={}){
    options.data = {
      ProjectId: ProjectId,
    };
    Api.post(this.request.options,options)
  },
  list(ProjectId,page,options={}){
    options.data = {
      ProjectId: ProjectId,
      PageSize: Api.page_size,
      Page: page,
    }
    Api.post(this.request.list,options);
  },
  update_field(ProjectId,id,data,options={}){
    options.data = {
      ProjectId: ProjectId,
      Id: id,
      Values: data,
      /*Values: [{
        "FieldName": "string",
        "OldValue": {},
        "NewValue": {}
      ]} */
    };
    Api.post(this.request.update_field,options)
  },
  add(ProjectId,options={}){
    options.data = {
      ProjectId: ProjectId,
    }
    Api.post(this.request.add,options);
  },
  delete(id,ProjectId,options={}){
    options.data = {
      ProjectId: ProjectId,
      Id: id,
    }
    Api.post(this.request.delete,options);
  },
  addmulti(ProjectId,WPGIds,options={}){
    options.data = {
      ProjectId: ProjectId,
      WPGIds: WPGIds,
    }
    Api.post(this.request.addmulti,options);
  },
  get_options_sitephoto(ProjectId,options={}){
    options.data = {
      ProjectId: ProjectId,
    };
    Api.post(this.request.options_sitephoto,options)
  },
  update_wpg(ProjectId,ScopeId,IM_WPG_Grp,WPGId,options={}){
    options.data = {
      Id: ScopeId,
      ProjectId: ProjectId,
      IM_WPG_Grp: IM_WPG_Grp,
      WPGId: WPGId,
    };
    Api.post(this.request.update_wpg,options)
  },
}
import NumeralJs from '../libs/NumeralJs/numeral';
const HNumber = {
  round(num,numRound=2){ //mặc định làm tròn đến 2 chữ số thập phân
    if (num!=null && Number.isNaN(Number(num))!=true && typeof(Number(num))==="number"){
      let _result = num;
      if (numRound>=0){
        _result = Number(num).toFixed(numRound); //hàm làm tròn số thập phân
      }else{
        let _sochia = Math.pow(10,-numRound);
        let _songuyen = (Number(num)/_sochia).toFixed(0);
        _result = _songuyen*_sochia;
      }
      return Number(_result); //đảm bảo trả về kiểu number (vì sau khi sd hàm toFixed thì kiểu sẽ đổi thành string)
    }  
    return null;
  },
  getNumber(n){
    if(typeof n == "number"){
      return n;
    }
    else{
      return Number(n);
    }
  },
  isInt(n){
    return Number(n) === n && n % 1 === 0;
  },
  isFloat(n){
    return Number(n) === n && n % 1 !== 0;
  },
  format(n,format){
    if(format){
      return NumeralJs(n).format(format);
    }
    return n;
  }
}
window.HNumber = HNumber;
export default HNumber; 
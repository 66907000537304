import React, { Component } from 'react';
import {
  Alert,
  Button, Card, CardFooter, CardImg, Col,
  Modal, ModalBody, ModalFooter, ModalHeader,
  Nav, NavItem, NavLink, Row,
  TabContent, TabPane
} from '../../../_importComponent';
import {ApiGeneric,ApiProjectPhoto} from '../../../_importService';
import { HText, HUI, HConfig } from '../../../_import';
import { LoadingV1 } from '../../../_importV1';
import {MyUI} from "@macashipo/mlib"
import { Dropzone } from '../../../_importLibs';
import ModalPhotoViewer from '../../../components/ModalPhotoViewer';
import InputControl from '../../Components/InputEditor/CustomControl';


class ModalPhoto extends Component {
  constructor(props) {
    super(props);

    this.state = { 
      isShow: false,
      isLoading: false,
      isUploadingFiles: false,
      isShowAlert: false,
      msgAlert: '',
      error: null,
      activeTab: '1',
      currentRow: {},
      fieldName: '',
      data: {},
      screenCode: null,
      canUpload: true,
    };
    // console.log('ModalPhoto constructor: ',props);
  }

  componentDidMount(){

  }

  show(row,fieldName,screenCode,opts){
    let _canUpload = true;
    if(row && row._CanUpload===false){
      _canUpload = row._CanUpload;
    }
    if(opts && opts.canUpload===false){
      _canUpload = false;
    }
    this._opts = opts;
    this.setState({
      isShow:true,
      currentRow: row,
      fieldName: fieldName,
      activeTab: _canUpload?'1':'2',
      data: {},
      screenCode: screenCode,
      canUpload: _canUpload,
    },()=>{
      this._onRequestGetPhotos(row.Id,fieldName);
    })
  }

  hide=()=>{
    this.setState({
      isShow: false
    })
  }

  _onDismissAlert=()=>{
    this.setState({ isShowAlert: false, msgAlert: '' });
  }

  _showAlert=(msg)=>{
    this.setState({ isShowAlert: true, msgAlert: msg });
  }

  _getProjectId=()=>{
    let _projectId = this.props.projectId;
    if(_projectId=="" && this.state.currentRow.ProjectId){ //Update ProjectId when not have prop projectId, default get field ProjectId in Row
      _projectId = this.state.currentRow.ProjectId;
    }
    // console.warn(`_getProjectId:`,_projectId,this.props)
    return _projectId; 
  }

  _onRequestGetPhotos=(Id,fieldName)=>{
    console.log('_onRequestGetPhotos:',this);
    let _projectId = this._getProjectId();
    let _screenCode = this.state.screenCode;
    // if(_projectId!=null){
      this.setState({isLoading:true},()=>{
        let _valueId = this.state.currentRow.Id;        
        let _fieldName = this.state.fieldName;
        if(this._opts){
          if(this._opts.fID){
            _valueId = this.state.currentRow[this._opts.fID];
          }
          if(this._opts.fFN){
            _fieldName = this._opts.fFN;
          }
          
        }
        ApiProjectPhoto.get_photos(_projectId,_screenCode,_valueId,_fieldName,{
          successCallBack:(response)=>{
            console.log('successCallBack:',response);
            this.setState({
              data: response.Data,
              error:null,
              isLoading:false
            });
            this._buildArrPhoto(response.Data);
          },
          errorCallBack:(error,response)=>{
            console.log('errorCallBack:',error,response);
            this.setState({error:error!=null?error:response,isLoading:false});
          }
        })
      })
    // }
  }
  
  _onClickTab=(tabId)=>{
    this.setState({
      activeTab:tabId
    })
  }

  _onDrop=(files,rejected)=>{
    const {onReloadTable} = this.props;
    let _screenCode = this.state.screenCode;
    
    console.log(files);
    console.log(rejected);
    if(rejected!=null && rejected.length>0){
      if(files!=null && files.length==0){
        HUI.Alert.showMsg(HText.get('msg_error'),HText.get('msg_error_upload_no_support'));
      }
    }
    if(files!=null && files.length>0){
      this.setState({
        isUploadingFiles: true
      },()=>{
        let _f = files[0];
        console.log("File name: " + _f.name);
        console.log("File size: " + _f.size);
        console.log("File type: " + _f.type);
        let _projectId = this._getProjectId();
        let _valueId = this.state.currentRow.Id;        
        let _fieldName = this.state.fieldName;
        if(this._opts){
          if(this._opts.fID){
            _valueId = this.state.currentRow[this._opts.fID];
          }
          if(this._opts.fFN){
            _fieldName = this._opts.fFN;
          }          
        }

        let _moreQueryData = {};
        if(this._opts && this._opts.getMoreQueryData){
          _moreQueryData = this._opts.getMoreQueryData();
        }
        ApiProjectPhoto.uploadMultifiles(_projectId,_screenCode,_valueId,_fieldName,files,{
          moreQueryData: _moreQueryData,
          onUploadProgress:(progressEvent)=>{
            let _percentCompleted = Math.round( (progressEvent.loaded * 100) / progressEvent.total );
            console.log('onUploadProgress _percentCompleted:',_percentCompleted,progressEvent.loaded,progressEvent.total);
          },
          onDownloadProgress: (progressEvent)=>{
            let _percentCompleted = Math.round( (progressEvent.loaded * 100) / progressEvent.total );
            console.log('onDownloadProgress _percentCompleted:',_percentCompleted,progressEvent.loaded,progressEvent.total);
          },
          successCallBack:(response)=>{
            console.log('_onDrop:',response);
            let _data = this.state.data;
            if(response!=null && response.Data!=null){
              _data = response.Data;
              this._buildArrPhoto(response.Data);
            }
            this.setState({
              isUploadingFiles: false,
              data: _data,
            },()=>{
              if(onReloadTable!=null){onReloadTable();}
              if(this._opts && this._opts.cbSuccess){
                this._opts.cbSuccess(response.Data);
              }
            })
          },
          errorCallBack:(error,response)=>{
            console.log('_onDrop error:',error,response);
            this.setState({
              isUploadingFiles: false,
            })
          }
        })
      })
    }
  }

  _onRequestSaveAvatar=(item)=>{
    const {onReloadTable} = this.props;
    let _screenCode = this.state.screenCode;

    console.log('_onRequestSaveAvatar:',item);
    if(item!=null && item.FileUrl!=null){
      let _projectId = this._getProjectId();
      ApiProjectPhoto.save_avatar(_projectId,_screenCode,this.state.currentRow.Id,this.state.fieldName,item.FileId,{
        onUploadProgress:(progressEvent)=>{
          let _percentCompleted = Math.round( (progressEvent.loaded * 100) / progressEvent.total );
          console.log('_percentCompleted:',_percentCompleted);
        },
        successCallBack:(response)=>{
          console.log('_onRequestSaveAvatar:',response);
          let _data = this.state.data;                    
          if(response!=null && response.Data!=null){
            _data = response.Data;
          }
          if(response!=null && response.Msg){
            HUI.Toast.showSuccess(response.Msg);
          }
          this.setState({
            data: _data
          },()=>{
            if(onReloadTable!=null){onReloadTable();}
          })
        },
        errorCallBack:(error,response)=>{
          console.log('_onRequestSaveAvatar error:',error,response);
        }
      })
    }
  }

  _onRequestAddLink=({link,name})=>{
    let _screenCode = this.state.screenCode;
    this.setState({
      isAdddingLink: true,
    },()=>{
      ApiGeneric.generic({
        request:{
          method: 'POST',
          path: 'ProjectPhoto',
          name: 'AddFiles',
        },
        // customHeader = {
        // },
        data:{
          ProjectId: this._getProjectId(),
          ScreenCode: _screenCode,
          Id: this.state.currentRow.Id,
          FieldName: this.state.fieldName,
          Files: [{
            Url: link,
            Name: name,
          }],
        },
        successCallBack:(response)=>{
          if(response.Msg){
            HUI.Toast.showSuccess(response.Msg);
          }
          if(this._cInputLink && this._cInputLinkName){
            this._cInputLink.setValue("");
            this._cInputLinkName.setValue("");
          }
          this.setState({
            isAdddingLink: false,
          });
        },
        errorCallBack:(error,response)=>{
          this.setState({
            isAdddingLink: false,
          });
        }
      });
    })
  }

  _buildArrPhoto=(data)=>{
    let _arrPhoto = [];
    if(data!=null && data.Photos!=null){
      for(let i=0;i<data.Photos.length;i++){
        let _item = data.Photos[i];
        _arrPhoto.push({
          title: _item.FileName,
          url: _item.FileUrl_FullSize,
        })
      }
    }
    this.arrPhoto = _arrPhoto;
  }

  _onRequestUpdateFieldsFile=(cellName, cellValue,item)=>{
    ApiGeneric.generic({
      request:{
        method: 'POST',
        path: 'ProjectPhoto',
        name: 'UpdateFields_File'
      },
      data:{
        ProjectId: this._getProjectId(),
        Id:item.Id,
        Values: [
          {
            FieldName: cellName,
            NewValue: cellValue
          }
        ]
      },
      successCallBack:(response)=>{
          HUI.Toast.showSuccess(response.Msg);
          if (response.Data && response.Data.CheckQualityColor){
            item.CheckQualityColor = response.Data.CheckQualityColor;
          }
          this.forceUpdate();
      },
      errorCallBack:(error,response)=>{
      }
    })
  }

  _renderAllFile=()=>{
    let _ui = [];
    const {data} = this.state;
    if(data!=null && data.Photos!=null){
      for(let i=0;i<data.Photos.length;i++){
        let _item = data.Photos[i];
        _ui.push(
          <Card className="sp_list_item" key={i}>
            <Button color="link" style={{padding:'0px',minHeight:'180px'}} onClick={()=>{
              console.log('onClick:',this.arrPhoto);
              ModalPhotoViewer.Helper.show(this.arrPhoto,i);
            }}>
              <CardImg top width="100%" height="180px" src={_item.FileUrl} alt="" />
            </Button>
            <CardFooter style={{display:'flex',flexDirection:'row',justifyContent:'space-between',padding:'3px',alignItems:'center'}}>
              <div style={{width:'100%',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis'}}>
                <Button color="link" target="_blank" href={_item.FileUrl_Download}>{_item.FileName}</Button>
              </div>
              {
                this.state.canUpload &&
                <div>
                  <Button style={{visibility:_item.IsAvatar ==false?'visible':'hidden'}} color="link" onClick={()=>{
                    HUI.Alert.showMsgQuestion(HText.get('text_changeav'),HText.get('msg_confirm_file'),()=>{
                      console.log('ok');
                      this._onRequestSaveAvatar(_item);
                    },()=>{
                      console.log('dissmiss');
                    })
                  }}><i style={{fontSize:'20px'}} className={"fa fa-check-square-o"}/></Button>
                </div>
              }
            </CardFooter>
            <div>
              {
                _item.CheckQualityColor_CanShow==true&&
                // this._renderQuality(_item)
                <MyUI type="check_quality"
                  value={_item.CheckQualityColor}
                  // canEdit={_item.CheckQualityColor_CanEdit}
                  canEdit={false}
                  fnRequestUpdate={(value)=>{
                    this._onRequestUpdateFieldsFile("CheckQualityColor",value,_item)
                  }}
                />
              }
            </div>
          </Card>
        );
      }
    }
    return (
      <div className="sp_list">
        {
          _ui
        }
      </div>
    )
  }
  _renderHeader=()=>{
    if(this._opts && this._opts.customHeader){
      return this._opts.customHeader({component:this});
    }
    return (
      <ModalHeader toggle={()=>{
        this.hide();
      }}>{
        HText.get('text_managerfile')
      }</ModalHeader>
    )
  }
  render() {
    if(this.state.isShow==false){
      return (
        <div></div>
      )
    }
    return (
      <Modal fade={false} className="modal-lg" isOpen={this.state.isShow} 
        toggle={()=>{
          this.hide();
        }}>
        {this._renderHeader()}
        <ModalBody>
          <div>
            <Nav tabs>
              <NavItem>
                <NavLink
                  className={`${this.state.activeTab==1?'active':''} s_cp`}
                  onClick={() => { this._onClickTab('1'); }}
                >
                  {HText.get('tab_avatar')}
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={`${this.state.activeTab==2?'active':''} s_cp`}
                  onClick={() => { this._onClickTab('2'); }}
                >
                  {HText.get('tab_allfiles')}
                </NavLink>
              </NavItem>
              {
                this.state.canUpload &&
                <NavItem>
                  <NavLink
                    className={`${this.state.activeTab==3?'active':''} s_cp`}
                    onClick={() => { this._onClickTab('3'); }}
                  >
                    {HText.get('tab_addfilefromlink')}
                  </NavLink>
                </NavItem>
              }
              {
                this.state.canUpload && 
                <NavItem style={{padding:'0px 10px 0px 10px'}}>
                  <Button
                    className={``}
                    onClick={() => { 
                      console.log('open Dropzone',this._cDropzone);
                      if(this._cDropzone!=null){
                        this._cDropzone.open();
                      }
                    }}
                  >
                    {
                      this.state.isUploadingFiles==true?
                      <i className={"fa fa-circle-o-notch fa-spin"}/>
                      :
                      "Upload file"
                    }
                    <Dropzone ref={(r)=>{this._cDropzone=r;}} 
                      accept={HConfig.fileUploadSupport}
                      onDrop={this._onDrop} style={{}}>
                    {({getRootProps, getInputProps, isDragActive, open}) => {
                        return (
                          <div
                            {...getRootProps()}
                            className={`dropzone ${isDragActive?'dropzone--isActive':''}`}
                            // style={_styleDropzone}
                          >
                            <input {...getInputProps()} />
                          </div>
                        )
                      }}
                    </Dropzone>
                  </Button>
                </NavItem>
              }
              {
                this.state.data !=null && this.state.data.FolderUrl !=null && this.state.canUpload &&
                <NavItem>
                  <Button
                    className={``}
                    color="link"
                    href={this.state.data.FolderUrl}
                    target="_blank"
                    // onClick={() => { this._onClickTab('2'); }}
                  >
                    {HText.get('text_openfolder')}
                  </Button>
                </NavItem>
              }
            </Nav>
            <TabContent activeTab={this.state.activeTab}>
              <TabPane tabId="1">
                <Row>
                  <Col sm="12">
                  {
                    this.state.data!=null && this.state.data.AvatarPhoto !=null && this.state.data.AvatarPhoto.FileUrl_FullSize!=null ?
                    <div>
                      <Button style={{position:'absolute',right:'15px'}} target="_blank" href={this.state.data.AvatarPhoto.FileUrl_Download}><i className="fa fa-external-link"></i></Button>
                      <img style={{width:'100%',height:'100%'}} src={this.state.data.AvatarPhoto.FileUrl_FullSize}/>
                      <p>File: {this.state.data.AvatarPhoto.FileName}</p>
                    </div>
                    :
                    <div>
                      <p>{HText.get('text_noavatar')}</p>
                    </div>
                  }
                    
                  </Col>
                </Row>
              </TabPane>
              <TabPane tabId="2">
                <Row>
                  <Col sm="12">
                    {
                      this._renderAllFile()
                    }
                  </Col>
                </Row>
              </TabPane>
              <TabPane tabId="3">
                <Row>
                  <Col sm="12">
                    <div>{HText.get('text_inputlinkgd')}:</div>
                    <InputControl
                      ref={r=>{this._cInputLink=r;}}
                      type={'text'} 
                    />
                    <div>{HText.get('text_inputname')}:</div>
                    <InputControl
                      ref={r=>{this._cInputLinkName=r;}}
                      type={'text'} 
                    />
                    <br />
                    <Button
                      color="primary"
                      onClick={()=>{
                        if(this.state.isAdddingLink!=true && this._cInputLink && this._cInputLinkName){
                          let _link = this._cInputLink.getValue();
                          let _name = this._cInputLinkName.getValue();
                          console.log('link:',_link,_name);
                          this._onRequestAddLink({
                            link: _link,
                            name: _name
                          })
                        }
                      }}>
                      {
                        this.state.isAdddingLink ? 
                        <i className={"fa fa-circle-o-notch fa-spin"}/>
                        :
                        "Thêm"
                      }
                    </Button>
                  </Col>
                </Row>
              </TabPane>
            </TabContent>
          </div>
          
          <Alert color="warning" isOpen={this.state.isShowAlert} toggle={this._onDismissAlert}>
            {this.state.msgAlert}
          </Alert>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={()=>{
            this.hide();
          }}>{HText.get('btn_close')}</Button>
        </ModalFooter>
        {
          (this.state.isLoading==true || this.state.isUploadingFiles==true) && 
          <LoadingV1 styleItem={{backgroundColor:'white'}}/>
        }
        
      </Modal>
    )
  }
}

export default ModalPhoto;

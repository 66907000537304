import React from "react";
import { Button, HC } from '../_import'
import { KSolid_EE } from "src/helpers/KSolid"
import {HEE, HUtils} from "@macashipo/mlib"
class MyUISearchGeneral extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      placeHolder: '',
      textSearch: '',
      screenCode: '',
      autoFocus: false,
    }    
  }
  componentDidMount(){
    HEE.on(KSolid_EE.ev_fullsearch_updateholder,this._onUpdateFullSearch)
  }
  componentWillUnmount(){
    HEE.off(KSolid_EE.ev_fullsearch_updateholder,this._onUpdateFullSearch)
  }
  _onUpdateFullSearch=({searchPlaceHolder,textSearch,configPage}={})=>{
    console.log("searchPlaceHolder",searchPlaceHolder,this.state.screenCode)
    let _textSearch = textSearch;
    let _screenCode = HUtils.get(configPage,"ScreenCode")
    if(_screenCode==this.state.screenCode){
      _textSearch = this.state.textSearch
    }
    this.setState({
      placeHolder: searchPlaceHolder || "",
      textSearch: _textSearch,
      screenCode: _screenCode
    })
  }
  _onSearch=()=>{
    const {fnList} = this.props;
    HC.rf(fnList,'onSearch',[this.state.textSearch]);
  }
  focusInput=()=>{
    console.log("focus",this._rInput)
    setTimeout(()=>{
      if(this._rInput){
        this._rInput.focus()
      }
    },200)    
  }
  render() {
    const {openFullSearch} = this.props;
    console.log("render full search:",this.props)
    return (
      <div className={openFullSearch ? "full-search open" : "full-search"}>
        <div style={{paddingBottom:"0px"}}>
          <input 
            ref={(r)=>{
              this._rInput = r;
              // if(this._rInput){
              //   this._rInput.focus()
              // }
            }} 
            autoFocus={this.state.autoFocus} placeholder={this.state.placeHolder} style={{ flex: 1 }} value={this.state.textSearch} 
            onChange={(ev)=>{
              this.setState({
                textSearch: ev.currentTarget.value
              })
            }} onKeyPress={(ev)=>{
              if(ev.key === 'Enter'){
                this._onSearch();
              }
            }}
          />
          <button onClick={this._onSearch}>Search</button>
        </div>
        <div style={{color:"#999999",padding:"0px 15px",paddingBottom:"20px",fontStyle:"italic"}}>{this.state.textSearch!=null &&  this.state.textSearch.length>0 && this.state.placeHolder}</div>
      </div>
    )
  }
}
export default MyUISearchGeneral;

import HUtils from './Utils';
const HConstant = {
  // AppName: 'SolidDev',
  HConfig: null,
  initConstant(HConfig){
    HConstant.HConfig = HConfig;
  },
  gc(key){//get config in HConfig
    if(HConstant.HConfig){
      return HUtils.runFnList(HConstant.HConfig.fnList,"getConfig",this,[key]); 
    }    
  },
  rc(fnName,args=[]){
    if(HConstant.HConfig){
      return HUtils.runFnList(HConstant.HConfig.fnList,fnName,this,args,{disabledWarn:true}); 
    }
  },
  rf(fnList,fnName,args,opts){
    return HUtils.runFnList(fnList,fnName,this,args || [],opts);
  },
  getConfig(configObj,path,key,df){
    var _v = HUtils.Obj.get(configObj,key);
    if(_v!=null){
      return _v;
    }
    let _df = df;
    if(df==null){
      _df = HUtils.Obj.get(HConstant,`${path}._default.${key}`);
      if(typeof _df == "object"){
        if(Array.isArray(_df)){
          _df = [];
        }
        else{
          _df = {};
        }        
      }
    }
    return _df;
  },
  PathConfig:{
    ConfigInMore: "ConfigInMore",
  },
  ColorBase: ["success","danger","primary","secondary","link","warning"],
  Consolelog: {
    alert: 'background: #ff0000 ; color: #fff',
    api: 'background: #009933; color: #fff',
    constructor: 'background: #2c3e50 ; color: #fff',
    debug_time: 'background: #ffeb3b ; color: #000',
    debug: 'background: #cc48cc; color: #fff',
    location: 'background: #855cd0 ; color: #fff',
    request: 'background: #222; color: #bada55',
    screen: 'background: #27ae60 ; color: #fff',
  },
  Format:{
    ProjectDayTime: 'DD-MM-YY HH:mm',
    DateTimeInput: 'DD-MMMYY',
  },
  BaseLineItem:{
    specs: 'specs',
    dm_vattu: 'dm_vattu',
    dm_thietbi: 'dm_thietbi',
    safety: 'safety',
    tieuchi_nt: 'tieuchi_nt',
    boq: 'boq',
    quidinh: 'quidinh',
    timecontrol: 'timecontrol',
    risk: 'risk',
  },
  ExpandItem:{
    qm_tccl: 'qm_tccl',
    qm_mtskph: 'qm_mtskph',
    qm_bpkp: 'qm_bpkp',
  },
  EmptyOption:{
    label: '(Trống)',
    value: '___',
  },
  LocalKey:{
    PROJECT_SCREENS: 'PROJECT_SCREENS',
    APP_MENU: 'APP_MENU',
    CONFIGS: 'CONFIGS',
    SIDE_MENU: 'SIDE_MENU',
    CATEGORY_LIST: 'CATEGORY_LIST',
    APPLOGO_URL: 'APPLOGO_URL',
    V3_CurrProject: 'V3_CurrProject',
    V3_CurrPorfolioIndex: 'V3_CurrPorfolioIndex',
  },
  TableConfig: {
    _key: "TableConfig",
    _default:{
      addExtraColumns: {},
      borderLight: false,
      canDelete: true,
      classNameTable: "",
      configTotal:{},
      customAction: null,
      customDelete: null,
      customProps: {},
      deleteWithData: false,
      expandBodyClass: "react-bs-container-row-expand",
      fieldId: "Id",
      hasBigBorderBottom: false,
      hasCustomHeader: false,
      hasExpand: false,
      hasPaginationShowsTotal: true,
      hasPagingRemote: false,
      hasWrapHeader: false,
      hideColDelete: false,
      hideColDeleteOnSmallWidth: false,
      hideMsgUpdate: false,
      hideSizePerPage: false,
      isAlwaysUpdateCellAfterUpdate: false,
      maxHeight: "",
      noBorderCol: false,
      optionsTable: {},
      show: true,
      showDetails: false,
      showDetailsMulti: false,
      showHeader: true,
      showID: false,
      showLoadingWhenFilter: null,
      showTotal: false,
      staticDataReload: null,
      tableProps: {},
      showClassFieldOnCell: false
    },
    addExtraColumns: "addExtraColumns",
    borderLight: "borderLight",
    canDelete: "canDelete",//Duoc phep xoa row ko
    classNameTable: "classNameTable",
    configTotal: "configTotal",
    customAction: "customAction",
    customDelete: "customDelete",
    customProps: "customProps",
    deleteContent: "deleteContent",//string with [] if fieldName of Row
    deleteContentHtml: "deleteContentHtml",
    deleteTitle: "deleteTitle",//string with [] if fieldName of Row
    deleteWithData: "deleteWithData",//true: Xoa row gui Id, false:Id trong url
    expandBodyClass: "expandBodyClass",
    fCanDelete: "fCanDelete",//fieldName of Row 
    fieldId: "fieldId",//Field Id cua data
    hasBigBorderBottom: "hasBigBorderBottom",
    hasCustomHeader: "hasCustomHeader",
    hasExpand: "hasExpand",//Co detail expand ko
    hasPaginationShowsTotal: "hasPaginationShowsTotal",
    hasPagingRemote: "hasPagingRemote",//co su dung paging server ko
    hasWrapHeader: "hasWrapHeader",
    hideColDelete: "hideColDelete",//Co hien thi cot delete hay ko, mac dinh khi canDelete = true thi hideColDelete = false
    hideColDeleteOnSmallWidth: "hideColDeleteOnSmallWidth",//An cot delete khi width nho
    hideMsgUpdate: "hideMsgUpdate",
    hideSizePerPage: "hideSizePerPage",
    isAlwaysUpdateCellAfterUpdate: "isAlwaysUpdateCellAfterUpdate",
    maxHeight: "maxHeight",
    needReloadAfterDelete: "needReloadAfterDelete",//reload list sau khi delete row,
    needReloadAfterUpdate: "needReloadAfterUpdate",//co reload list sau khi update row hay khong
    needReloadAfterUpdateFields: "needReloadAfterUpdateFields",//co reload list sau khi update 1/n field hay khong: array
    noBorder: "noBorder",
    noBorderCol: "noBorderCol",//An border col ko
    optionsTable: "optionsTable",
    show: "show",
    showDetails: "showDetails",
    showDetailsMulti: "showDetailsMulti",
    showHeader: "showHeader",
    showID: "showID",//Co show cot Id hay ko
    showLoadingWhenFilter: "showLoadingWhenFilter",
    showTotal: "showTotal",
    staticDataReload: "staticDataReload",
    tableProps: "tableProps",
    belowTable: "belowTable",
    forceFullExpandDetail: "forceFullExpandDetail",
    listHideCols: "listHideCols",
    showClassFieldOnCell: "showClassFieldOnCell", //gắn className cho cell phục vụ testing
  },
  ScreenConfig:{
    Page: "Page",
    Header: "Header",
    Table: "Table",
    Detail: "Detail",
    AddNew: "AddNew",
    Expander: "Expander",
    Filter: "Filter",
    BelowFilter: "BelowFilter",
  },
  PageConfig:{
    _key: 'PageConfig',
    _default:{
      isNotChangePageTitle: false,
    },
    componentBelowFilter: "componentBelowFilter",//component below filter
    componentBelowHeader: "componentBelowHeader",//component below header
    componentFooter: "componentFooter",//component footer
    isGetOptions: "isGetOptions",//co lay options hay ko
    isNotChangePageTitle: "isNotChangePageTitle",//Ko tu dong thay doi title browser, df: false
    isNotShowLoading: "isNotShowLoading", //Ko show load, chay theo isNotChangePageTitle hoac ban than no, df: false
    isWithModalControl: "isWithModalControl",
    isWithProjectId: "isWithProjectId",
    staticOptions: "staticOptions",//options tinh ngoai truyen vao
    topHeader: "topHeader",//obj: myui top header: show, type, style
    topTable: "topTable",//obj: myui top table: show, type, style
    uiTypeKeys: "uiTypeKeys",//uikeys khai bao uitype su dung o man hinh
    updateUIWhenResize: "updateUIWhenResize",//call onResize when resize
    title: "title",
  },
  HeaderConfig:{
    _default:{
      showSearch: false
    },
    show: "show",//co show header hay ko
    showProjectHeader: "showProjectHeader",//show project header cua solid
    showSearch: "showSearch",//show search tren header
    title: "title",
  },
  ExpanderConfig:{
    hasPageExpand: "hasPageExpand",
    defaultExpand: "defaultExpand",
  },
  DetailConfig:{
    controller: "controller",
    multi: "multi",
    queryFromParent: "queryFromParent",
    needReloadMaster: "needReloadMaster",
    needReloadMasterAfterAddNew: "needReloadMasterAfterAddNew",
    needReloadMasterAfterUpdateField: "needReloadMasterAfterUpdateField",
    needReloadMasterAfterDeleteItem: "needReloadMasterAfterDeleteItem",
    needReloadMasterAfterList: "needReloadMasterAfterList",
    userOptionsInMaster: "userOptionsInMaster",
    bgColor: "bgColor",
    bgF: "bgF",
    prefixFieldKeyUI: "prefixFieldKeyUI",
    isRow: "isRow",
  },
  FilterConfig:{
    show: "show", //show filter hay ko
    showSearch: "showSearch",
    searchNoSymbol: "searchNoSymbol",
    searchInstant: "searchInstant",
    searchServer: "searchServer",
    moreSearch: "moreSearch",
    configColumnFilter:{//vung column filter o v3
      _key: "configColumnFilter",
      show: "show",
      applyDetail: "applyDetail",
    }
  },
  FMConfig:{
    _default:{
      title: "",
      pageSize: 24,
      ratioImg: 1,//1 vuong, 0.5, cao>rong, 2: rong hon cao
      lineName: 2,
      // styleItem:{//test
      //   borderRadius: '10px',
      //   overflow: 'hidden'
      // }
    },
    title: "title",//title
    numCol: "numCol",//numCol
    pageSize: "pageSize",//pageSize
    lineName: "lineName",//line can show Name, 0 will hidden, df: 2
    styleItem: "styleItem",//style chung
    ratioImg: "ratioImg", //Ratio img
    needReloadList: "needReloadList",//reload list sau khi upload,
    hideBtnExtLink: "hideBtnExtLink",//co an nut extend link ko
    Options: "Options",//Options chung chung
    Permission: "Permission",//Permission chung
    CanChangeAvatar: "Permission.CanChangeAvatar",
    CanChangeStandardTemplate: "Permission.CanChangeStandardTemplate",
  },
  MyTableCellMore:{    
  },
  ConfigInMore:{//for cell more, button more
    myui: "myui", //config MyUI,
    button: "button", //config Button
    modal: "modal", //Config Modal
    configApi: "configApi",//Config api
    configPagination: "configPagination",
    configEditIcon:"configEditIcon",
    configBadge:"configBadge",//badge for files, old: badge
    configLinkIcon:"configLinkIcon",//show link obj: show, fLink, title, icon, newWindow
    configFileIcon:"configFileIcon",//show link obj: show, fLink, title, icon, newWindow
    configCheckIcon: "configCheckIcon",
    configAddable: "configAddable",//config addable cho cac nut select: show, alwayShow, form, myui(obj)
    confirmMsg: "confirmMsg",//confirm Msg, --- fConfirmMsg
    titleConfirm: "titleConfirm",//title confirm
    needSendMsg:"needSendMsg",//Need send msg
    needCloseModal: "needCloseModal",//Close Modal hien tai, dung cho button hoac cell show modal
    needReloadList: "needReloadList",//
    autoOpenLink: "autoOpenLink",//tao san
    custom: "custom",//Nhung config ko co dinh
    apiCheckShowModal:"apiCheckShowModal",//config api goi truoc khi show modal
    expand: "expand",//obj: dung cho type modal_score, 
    hideZero: "hideZero",//An di neu gia tri la 0, danh cho cell number ...
    numDecimal: "numDecimal",//so le thap phan
    needMultip: "needMultip",//percent can x 100 truoc khi them %
    listAction: "listAction",//list button action
    formConfig: "formConfig",//form config
    showPhoto: "showPhoto",//su dung o link, show photo
    fPhoto: "fPhoto",//link: su dung image field khac
    urlPhoto: "urlPhoto", //tuong tu fPhoto
    maxHeight: "maxHeight",// link: img max height
    maxWidth: "maxWidth",//link: img max width
    noScale: "noScale",//link: img 
    styleImg: "styleImg",//link: img style
    hasExpandArea: "hasExpandArea",//cell: co vung expand left hay ko
    fID: "fID",//array_editable
    fProjectId: "fProjectId",//array_editable
    fList: "fList",//array_editable
    fIDRemove: "fIDRemove",//array_editable
    apiPath: "apiPath",//array_editable
    apiNameAdd: "apiNameAdd",//array_editable
    apiNameRemove: "apiNameRemove",//array_editable
    orderBy: "orderBy",//array_editable
    orderByType: "orderByType",//array_editable
    canToggle: "canToggle",//array_editable
    readonly_array_field: "readonly_array_field",//array_editable
  },
  ConfigMyUI:{
    type: "type",//type 
    props: "props",// config props
  },
  ConfigModal:{
    _key: 'ConfigModal',
    _default:{
      key: "modal",
      title: "Modal",
      className: "modal-lg"
    },
    key: "key",
    title: "title",
    className: "className",
  },
  PrefixMyUI:{
    p:"p/",//2 params : getObj(this.props,key)
    fn:"fn/",//3 params: run fnList(key,arr)
  },
  ConfigPage:{
    type: "type",
    needReceiveMsg: "needReceiveMsg",//Cau hinh needReceiveMsg o Config cua trang
    listUI: "listUI",//list UI MyUI, obj
    pTableList: "pTableList",
    pTableBlock: "pTableBlock",
    autoReload:"autoReload",//tu dong reload page sau milisecond (number)
    buttonsOnTab: "buttonsOnTab",//
    defaultTab: "defaultTab",//tab default cua kieu tab//chua xai
    isDefaultTab: "isDefaultTab",//tab default cua kieu tab
  },
  EventMsg:{
    updateRowInList: "updateRowInList",//send data 1 row, receive: update to list
    reloadList: "reloadList", // reload table
  },
  PTableList:{
    _key: 'PTableList',
    _default:{
      numCols: 1,
      titleStyle: {},
      titleAlign: 'right',
      titleClass: '',
      hideTitle: false
    },
    numCols: "numCols",// so cap cua 1 dong
    colsWidth: "colsWidth", //{0:"20%",1:"80%"}: cot 1 20%, cot 2 80%,
    titleStyle: "titleStyle",
    titleAlign: "titleAlign",
    titleClass: "titleClass",
    hideTitle: "hideTitle"
  },
  ParramURL:{
    pj: "pj",//project Id
    t0: "t0",//tab index 0
    t1: "t1",
    t2: "t2",
    t3: "t3",
    t4: "t4",
    t5: "t5",
    pf: "pf",//project porfolio index: -1: all
    ss: "st",//solid Staff
    sd: "sd",//solid deparment
    uicode: "uicode",//for standard
    DomainId: "DomainId",//for standard
    CodeL1Id: "CodeL1Id",//for standard
  },
  PTableBlock:{
    _key: 'PTableBlock',
    _default: {
      col: 6,
      xs: 3,
      sm: 6,
      md: 9,
      lg: 12
    },
    col: "col",
    xs: "xs",
    sm: "sm",
    md: "md",
    lg: "lg"
  },
  UIFnPage:{//fn from MyPage
    fnRequestUpdate: "fnRequestUpdate",//fnRequestUpdate(row, fieldName, cellValue, opts)
    fnReloadList: "fnReloadList",//load lai list
    fnReloadTable: "fnReloadTable",//old: tuong tu fnReloadList
    fnReloadOptionsAndList: "fnReloadOptionsAndList",
    fnGetExpandList: "fnGetExpandList",
    fnForceUpdateTable: "fnForceUpdateTable",//forceupdate table, chi moi co ben category
    fnHeaderChangeExpand: "fnHeaderChangeExpand",
    fnGetCanEditOfField: "fnGetCanEditOfField",
    fnGetCanShowOfField: "fnGetCanShowOfField",
    fnGetCurrentProject: "fnGetCurrentProject",//get Current Project tu tren xuong
    fnGetCurrentProjectId: "fnGetCurrentProjectId",//get Current Project Id tu tren xuong, ko xai nua
    fnGetOptions: "fnGetOptions",//get Options cua page
    fnGetConfigFromScreenConfig: "fnGetConfigFromScreenConfig",
    fnGetListExtraData: "fnGetListExtraData",//get extradata
    fnGetExtraDataTable: "fnGetExtraDataTable",// tuong tu fnGetListExtraData
    fnGetListData: "fnGetListData",//get list data
    fnGetDataTable: "fnGetDataTable",//tuong tu get list data
    fnGetListObj: "fnGetListObj",//get list obj
  },
  UIFnCell:{//fn from cell, fnc___
    fncGetRow: "fncGetRow",//get row from cell
    fncGetMore: "fncGetMore",//get more of cell
    fncGetQuery: "fncGetQuery",//get query from more of cell
    fncNeedReload: "fncNeedReload",//parse need reload, reload list, reload table...
    fncGetConfigByKeyInMore: "fncGetConfigByKeyInMore",//get config in more of cell: (key)
  },
  UIFnModal:{//fn from modal, fnm___
    fnmHide: "fnmHide",    
    fnmShowLoading:"fnmShowLoading",
    fnmHideLoading:"fnmHideLoading",
    fnmGetConfigByKeyInMore: "fnmGetConfigByKeyInMore",//get config in more, more truyen tu cel, button qua configMore
    fnmGetModalData: "fnmGetModalData",//get data, cell: row, 
  },
  UIFnButton:{//fn from modal, fnb___
    fnbTest: "fnbTest",//vidu thoi
  },
  UIFnTableDetail:{
    fntdGetExpandLevel: "fntdGetExpandLevel",
    fntdIsPageDetail: "fntdIsPageDetail",
  },
  UIFnCore:{//fnFrom MyUI Core
    myData: "myData",//get data from this.props.data
    myChange: "myChange",//call function from onChange
    myBlur: "myBlur",//call function from onBlur
  },  
  UIAllEvent:{
    key: ["onC","onB","onCl"]
  },
  UIOnChange:{
    key: "onC",
    updateField: "updateField",//update field
  },
  UIOnBlur:{
    key: "onB",
    updateField: "updateField",//update field
  },
  UIOnClick:{
    key: "onCl",    
  },
  ApiMethod:{
    GET: "GET",
    POST: "POST",
  },
  ApiName:{
    Options: "Options",
    Add: "Add",
    List: "List",
    Delete: "Delete",
    Detail: "Detail",
    UpdateFields: "UpdateFields"
  },
  AppConfig:{
    dateFormat: "dateFormat",
    dateFormatRDP: "dateFormatRDP",
    dateFormatRDP: "dateFormatRDP",//date format react date picker
    detailHeaderOnlyButtonList: "detailHeaderOnlyButtonList",
    expandBgColor: "expandBgColor",
    fileUploadSupport: "fileUploadSupport",
    hasRequestFirst: "hasRequestFirst",//co goi requestFirst khi mo web ko
    home_url: "home_url",//home url - redirect sau khi login
    initClassBody: "initClassBody",//class init body
    preCurrency: "preCurrency",//ki tu truoc so tien
    sufCurrency: "sufCurrency",//ki tu sau so tien
    symbolDecimal: "symbolDecimal",//ki tu dau thap phan
    symbolThousand: "symbolThousand",//ki tu dau chia phan ngan
    title: "title",//title app
    widthMobile: "widthMobile",//kiem tra nho hon se la mobile, neu dung width de kiem tra
    appName: "appName",//app name, truyen len khi request de biet request tu app nao
    numberFormat_ReadonlyNumber: "numberFormat_ReadonlyNumber",
    mtf_datefromto_showRange: "mtf_datefromto_showRange",
    showClassFieldOnCell: "showClassFieldOnCell",
  },
  AppFnList:{
    getAuthIsLogged: "getAuthIsLogged",//kiem tra dang nhap hay chua
    getCurrentUserAvatar: "getCurrentUserAvatar",
    logout: "logout",
    apiUnAuth: "apiUnAuth",
    getCurrentUserName: "getCurrentUserName",
    convertConfigPage: "convertConfigPage",//dung khi co nhu cau thay doi cau hinh configPage tu server
    afterRequestFirstSetting: "afterRequestFirstSetting",//arg: [response]
    afterParseFirstSetting: "afterParseFirstSetting",//arg: [data firstSetting]
  },
  MyTableFilterTypesV1:{
    search: "search",//chua test
    text: "text",//chua test
    select: "select",//chua test
    select2: "select2",//chua test
    checkbox: "checkbox",//chua test
    datefromto: "datefromto",//chua test
    datefrom: "datefrom",//chua test
    dateto: "dateto",//chua test
    switch: "switch",//chua test - chua co
    hidecol: "hidecol",//chua test - chua co
    custom: "custom",//chua test
  },
  MyPage:{
    FnList:{
      fnMyPage: "fnMyPage",
      getSharedQueryData: "getSharedQueryData",
      reloadList: "reloadList"
    }
  },
  runMyPageFnList(fnList,context,name,args=[]){
    return HUtils.runFnList(fnList,HConstant.MyPage.FnList.fnMyPage,context,[name,args]);
  }
}

// var userAgent = window.navigator.userAgent;
// var isEdge    = /Edge/.test(userAgent); // thank you MS
// var isChrome  = /chrome/i.test(userAgent) && !isEdge; 
// var isSafari  = /safari/i.test(userAgent) && !isEdge; 
// var isMobile  = /mobile/i.test(userAgent);
// var isIEWin7  = /Windows NT 6.1/i.test(userAgent) && /rv:11/i.test(userAgent);
// var isOldSafari = isSafari && (/Version\/8/i.test(userAgent) || /Version\/9/i.test(userAgent));
// var isEnabledForBrowser = (isChrome || isSafari || isIEWin7) && !isMobile;

window._M && window._M.addObj("HConstant",HConstant);
export default HConstant;
import React, { Component } from 'react';
import { HLink, HUtils } from '../../apps/Helpers';
import HH from '../../helpers/H';
import {fnListUI} from "src/helpers/fnList"
/**
 * PageExt.
 *
 * @class PageExt
 * @static
 * @namespace PageExt
 * 
 */
class PageExt extends Component {
  constructor(props) {
    super(props);
    
    this.state = {};
    this._currentFilter = {};
    this._dataConfigFilter = [];
  }

  componentDidMount(){
    console.warn('Page componentDidMount',this);
  }

  /**
   * Lấy `projectId` từ `this.props` hoặc từ url 
   * 
   * @method getProjectId
   * @memberof PageExt
   * 
  */
  getProjectId=()=>{
    if(this._projectId){
      return this._projectId;
    }

    const {match,projectId} = this.props;
    if(match && match.params!=null){
      if(match.params.id!=null){
        this._projectId = match.params.id;
      }
      if(match.params.projectId!=null){
        this._projectId = match.params.projectId;
      }
    }
    if(projectId){
      this._projectId = projectId;
    }
    return this._projectId;
  }

  /**
     * Lấy `DetailId` từ url
     * 
     * @method getDetailId
     * @memberof PageExt
     * 
    */
  getDetailId=()=>{
    if(this._detailId){
      return this._detailId;
    }
    const {match,detailId} = this.props;
    if(match && match.params!=null){
      if(match.params.id!=null){
        this._detailId = match.params.id;
      }
      if(match.params.DetailID!=null){
        this._detailId = match.params.DetailID;
      }
    }
    if(detailId){
      this._detailId = detailId;
    }
    return this._detailId;
  }

  /**
   * Lấy `screenCode` từ `this.props`
   * 
   * @method getScreenCode
   * @memberof PageExt
   * 
  */
  getScreenCode=()=>{
    if(this._screenCode){
      return this._screenCode;
    }
    const {screenCode} = this.props;
    let _config = this.getConfig();
    // console.warn('getScreenCode:',this,_config);
    if(_config && _config.ScreenCode){
      this._screenCode = _config.ScreenCode; 
      return this._screenCode;
    }
    if(screenCode){
      this._screenCode = screenCode;
    }
    return this._screenCode;
  }

  getParamsURL=()=>{
    return HLink.getAllUrlParams(window.location.href);
  }

  /**
   * Lấy `configUI` từ `this.props`
   * 
   * @method getConfigUI
   * @memberof PageExt
   * 
  */
  getConfigUI=()=>{
    if(this._configUI){
      return this._configUI;
    }

    const {configUI} = this.props;
    if(configUI){
      this._configUI = configUI;
    }
    return this._configUI;
  }

  /**
   * Lấy Config từ `configUI`
   * 
   * @method getConfig
   * @memberof PageExt
   * 
  */
  getConfig=()=>{
    let _obj = {};  
    let _configUI = this.getConfigUI();
    // console.warn('_configUI:',_configUI);
    if(_configUI && _configUI.Config){
      _obj = _configUI.Config;
    }
    return _obj;
  }

  /**
   * Lấy ConfigOnServer từ `config`
   * 
   * @method getConfigOnServer
   * @memberof PageExt
   * 
  */
  getConfigOnServer=()=>{
    let _obj = {};  
    let _config = this.getConfig();
    if(_config && _config.ConfigOnServer){
      _obj = _config.ConfigOnServer;
    }
    return _obj;
  }

  /**
   * Lấy Contents từ `configUI`
   * 
   * @method getContents
   * @memberof PageExt
   * 
  */
  getContents=(notWaitingOptions=false)=>{
    let _obj = [];  
    let _configUI = this.getConfigUI();
    // console.warn('_configUI',_configUI,this.getIsRoot(),this.state);
    if(this.getIsRoot() && notWaitingOptions!=true){
      _configUI = this.state.pageConfig;
    }
    if(_configUI && _configUI.Contents){
      _obj = _configUI.Contents;
    }
    return _obj;
  }

  parseScreenConfigInOptions=(options)=>{
    let _configInOptions = this.getConfigInOptions(options);
    if(_configInOptions && _configInOptions.ScreenConfig!=null){
      if(typeof _configInOptions.ScreenConfig=="string"){
        try {
          this._screenConfig = JSON.parse(_configInOptions.ScreenConfig)
        } catch (error) {
          console.warn("Error parse getScreenConfigInOptions:",_configInOptions);
        }
      }
      else if(typeof _configInOptions.ScreenConfig=="object"){
        this._screenConfig = _configInOptions.ScreenConfig;
      }
    }
  }

  getConfigInOptions=(options)=>{
    let _obj = {}; 
    if(options && options.Configs){
      _obj = options.Configs;
    }
    return _obj;
  }
  getConfigFromScreenConfig=(key)=>{//Config from ScreenConfig: from Options: Table, Header, Filter ...
    const {customConfig} = this.props;
    const screenConfig = this._screenConfig;//khac ver mobile
    let config = {}
    if(screenConfig && screenConfig[key]){
      config = screenConfig[key]
    }
    if(customConfig && customConfig[key]){
      config = {...config, ...customConfig[key]};//
    }
    // van de config tableProps
    return config;
  }
  getConfigFromPageConfig=(key)=>{//Config from PageConfig: Config,ConfigObj
    const {configPage} = this.props;
    let _configObj = configPage!=null?configPage.ConfigObj:null;//<> ver mobile
    if(_configObj && _configObj[key]!=null){
      return _configObj[key];
    }
  }

  getConfigFilter=()=>{
    let _configFilter = {
      show: true, 
      showSearch: true,       //Show search
      searchNoSymbol: false,  //Search ko dau
      require4ShowData: false,//Ko nho dung lam gi
      listInList: [],
      //new
      className: "",          //className MyFitler
      classNameItem: "col-12 col-sm-6 col-md-4 col-lg-3", //className item Myfilter
    };
    if(this._screenConfig && this._screenConfig.Filter){
      _configFilter = Object.assign(_configFilter,this._screenConfig.Filter);
    }
    return _configFilter;
  }

  getConfigHeader=()=>{
    let _configHeader = {
      show: true, 
    };
    let _configFromOptions = this.getConfigFromScreenConfig('Header');
    if(_configFromOptions){
      _configHeader = Object.assign(_configHeader,_configFromOptions);
    }
    return _configHeader;
  }

  /**
   * Lấy value từ key ở trong `Config`
   * 
   * @method getConfigValue
   * @memberof PageExt
   * 
  */
  getConfigValue=(key)=>{
    let _obj = this.getConfig();
    // console.warn('getConfigValue:',_obj);
    if(_obj){
      return _obj[key];
    }    
  }

  /**
   * Lấy query search trên URL 
   * VD: url?a=2&c=1 => {a:2,c:1}
   * 
   * @method getQuerySearch
   * @memberof PageExt
   * 
  */
  getQuerySearch=()=>{
    const {location} = this.props;
    // console.warn(`getQuerySearch:`,this.props,location);
    if(location && location.search){
      try {
        let _querySearch = JSON.parse('{"' + decodeURI(location.search.replace("?","").replace(/&/g, "\",\"").replace(/=/g,"\":\"")) + '"}')
        return _querySearch;
      } catch (error) {
        console.warn('RouterCategory parse location.search error:',error);
      }
    }
  }
  /**
   * Lấy những thông tin chung để gán vào các component con như `projectId`
   * 
   * @method getMorePropsShared
   * @memberof PageExt
   * 
  */
  getMorePropsShared=(opts)=>{
    const {location,configPage} = this.props;
    let _obj = {
      configPage,//configPage tu props
    };
    if(this._projectId){
      _obj.isWithProjectId = true;
      _obj.projectId = this._projectId;
    }
    if(this._screenCode){
      _obj.screenCode = this._screenCode;
    }
    if (this.props.match){
      _obj.match = this.props.match;
    }
    if(this.props.pageMatch){
      _obj.pageMatch = this.props.pageMatch;
    }
    if(opts){
      if(opts.content && opts.content.Config){
        _obj = Object.assign(_obj,{
          configController: opts.content.Config,
          configPage: opts.content.Config,//configPage tu contents
        });
      }      
    }
    if(location){
      _obj.location = location;
    }
    // console.warn('getMorePropsShared _obj: ', _obj);
    return _obj;
  }

  /**
   * Lấy getConfigApiPath
   * 
   * @method getConfigApiPath
   * @memberof PageExt
   * 
  */
  getConfigApiPath=()=>{
    let _configUI = this.getConfigUI();
    if(_configUI && _configUI.Config){
      return _configUI.Config.ApiController || _configUI.Config.APIName;
    }
  }

  /**
   * Lấy những thông tin cho request chung
   * 
   * @method getRequestMethod
   * @memberof PageExt
   * 
  */
  getRequestMethod=(method='POST',apiPath, apiName, opts)=>{
    let _obj = {};  
    let _configUI = this.getConfigUI();
    if(_configUI && _configUI.Config){
      _obj.method = _configUI.Config.ApiMethod || method;
      _obj.path = apiPath || _configUI.Config.ApiController || _configUI.Config.APIName;
      _obj.name = apiName || _configUI.Config.ApiName;
    }
    // console.warn(`getRequestMethod:`,_obj);
    return _obj;
  }

  /**
   * Lấy những thông tin cho request List
   * 
   * @method getRequestMethodFromConfig
   * @memberof PageExt
   * 
  */
  getRequestMethodFromConfig=(method='POST')=>{
    let _obj = {};  
    let _configUI = this.getConfigUI();
    if(_configUI && _configUI.Config){
      _obj.method = _configUI.Config.ApiMethod || method;
      _obj.path = _configUI.Config.ApiController || _configUI.Config.APIName;
      _obj.name = _configUI.Config.ApiName;
    }
    return _obj;
  }

  /**
   * Lấy những thông tin chung cho request Options
   * 
   * @method getRequestOptionsMethodFromConfig
   * @memberof PageExt
   * 
  */
  getRequestOptionsMethodFromConfig=(method='POST')=>{
    let _obj = {};  
    let _configUI = this.getConfigUI();
    if (_configUI.Config.ApiController==null && _configUI.Config.APIName==null)
    {
      return null;
    }
    if(_configUI && _configUI.Config){
      _obj.method = _configUI.Config.ApiMethod || method;
      _obj.path = _configUI.Config.ApiController || _configUI.Config.APIName;
      _obj.name = _configUI.Config.ApiOptions || 'Options';
    }
    // console.warn('_configUI: ', _configUI);
    return _obj;
  }

   /**
   * Lấy những thông tin chung cho request list
   * 
   * @method getRequestListMethodFromConfig
   * @memberof PageExt
   * 
  */
  getRequestListMethodFromConfig=(method='POST')=>{
    let _obj = {};  
    let _configUI = this.getConfigUI();
    if (_configUI.Config.ApiController==null && _configUI.Config.APIName==null)
    {
      return null;
    }
    if(_configUI && _configUI.Config){
      _obj.method = _configUI.Config.ApiMethod || method;
      _obj.path = _configUI.Config.ApiController || _configUI.Config.APIName;
      _obj.name = _configUI.Config.ApiOptions || 'List';
    }
    // console.warn('_configUI: ', _configUI);
    return _obj;
  }

  /**
   * Lấy những thông tin chung cho request list
   * 
   * @method getRequestWithName
   * @memberof PageExt
   * 
  */
  getRequestWithName=(method='POST',name)=>{
    let _obj = {};  
    let _configUI = this.getConfigUI();    
    if (_configUI.Config.ApiController==null && _configUI.Config.APIName==null)
    {
      return null;
    }
    if(_configUI && _configUI.Config){
      _obj.method = _configUI.Config.ApiMethod || method;
      _obj.path = _configUI.Config.ApiController || _configUI.Config.APIName;
      _obj.name = name;
    }
    console.warn('getRequestWithName: ', _configUI,_obj);
    return _obj;
  }

  /**
   * Lấy những thông tin chung cho request Detail
   * 
   * @method getRequestDetailMethodFromConfig
   * @memberof PageExt
   * 
  */
  getRequestDetailMethodFromConfig=(method='GET', apiName)=>{
    let _obj = {};  
    let _configUI = this.getConfigUI();
    if(_configUI && _configUI.Config){
      _obj.method = _configUI.Config.ApiMethod || method;
      _obj.path = _configUI.Config.ApiController || _configUI.Config.APIName;
      // _obj.name = (_configUI.Config.ApiDetail || `Detail`)+ '/' + this._detailId ;
      _obj.name = (_configUI.Config.ApiDetail || apiName || 'Detail')+ '/' + this._detailId ;
    }
    // console.warn('_configUI: ', _configUI);
    return _obj;
  }

  /**
   * Lấy những thông tin chung cho request List
   * 
   * @method getRequestListMethodFromConfig
   * @memberof PageExt
   * 
  */
  getRequestListMethodFromConfig=(method='POST')=>{
    let _obj = {};  
    let _configUI = this.getConfigUI();
    if(_configUI && _configUI.Config){
      _obj.method = _configUI.Config.ApiMethod || method;
      _obj.path = _configUI.Config.ApiController || _configUI.Config.APIName;
      _obj.name = _configUI.Config.ApiOptions || 'List';
    }
    return _obj;
  }

  /**
   * Lấy query data gửi lên server, bổ sung từ ApiQuery trong Config
   * 
   * @method getRequestQuery
   * @memberof PageExt
   * 
  */
  getRequestQuery=(obj)=>{
    const {configController} = this.props;
    let _obj = {};
    if (this.props.match && this.props.match.params)
    {
      _obj = Object.assign(_obj,this.props.match.params);
    }
    if(this._projectId){
      _obj.ProjectId = this._projectId;
    }
    if(configController && configController.Id){
      _obj.ScreenGUID = configController.Id;
    }
    let _querySearch = this.getQuerySearch();
    if(_querySearch){
      _obj.QuerySearch = _querySearch;
    }
    if(obj){
      _obj = Object.assign(_obj,obj);
    }    
    let _addQuery = this.getConfigValue('RequestData');
    let _addQueryFromServer = this.getConfigOnServer().RequestData;
    // console.warn('getRequestQuery:',_addQuery);
    if(_addQuery){
      _obj = Object.assign(_obj,_addQuery);
    }
    if(_addQueryFromServer){
      _obj = Object.assign(_obj,_addQueryFromServer);
    }
    return _obj;
  }

  /**
   * Lấy query data gửi lên server, bổ sung từ ApiQuery trong Config
   * 
   * @method getRequestOptionsQuery
   * @memberof PageExt
   * 
  */
  getRequestOptionsQuery=(obj)=>{
    // const {configController} = this.props;
    let _obj = {};  
    // Goi o this.getRequestQuery();
    // if(this._projectId){
    //   _obj.ProjectId = this._projectId;
    // }

    // Goi o this.getRequestQuery();
    // if(configController && configController.Id){
    //   _obj.ScreenGUID = configController.Id;
    // }

    // Goi o this.getRequestQuery();
    // if (this.props.match && this.props.match.params)
    // {
    //   _obj = Object.assign(_obj,this.props.match.params);
    // }
    if(obj){
      _obj = Object.assign(_obj,obj);
    }
    let _addQuery = this.getRequestQuery();
    if(_addQuery){
      _obj = Object.assign(_obj,_addQuery);
    }    
    return _obj;
  }

  updateCurrentFilter=(obj,{cb})=>{
    this._currentFilter = obj;
    let _isHasIsServer = false;
    if(this._currentFilter){
      let _keys = Object.keys(this._currentFilter);
      if(_keys && _keys.length>0){
        for(let k of _keys){
          let _filterOfField = this._currentFilter[k];
          if(_filterOfField && _filterOfField.isServer==true){
            _isHasIsServer = true;
            break;            
          }          
        }
      }
    }
    if(cb){
      cb({
        isHasIsServer: _isHasIsServer
      });
    }
  }
  /**
   * Lấy query filter 
   * 
   * @method getRequestFilterQuery
   * @memberof PageExt
   * 
  */
  getRequestFilterQuery=(obj)=>{
    let _obj = {};
    if(this._currentFilter){
      let _keys = Object.keys(this._currentFilter);
      if(_keys && _keys.length>0){
        for(let k of _keys){
          let _filterOfField = this._currentFilter[k];
          if(_filterOfField && _filterOfField.value!=null && _filterOfField.isServer==true){
            if(_filterOfField.fQuery){
              _obj[_filterOfField.fQuery] = this._currentFilter[k].value;
            }
            else{
              _obj[k] = this._currentFilter[k].value;
            }            
          }          
        }
      }
    }
    // console.warn("getRequestFilterQuery:",_obj);
    return _obj;
  }

  /**
   * Lấy query data gửi lên server, bổ sung từ ApiQuery trong Config
   * 
   * @method getRequestListQuery
   * @memberof PageExt
   * 
  */
  getRequestListQuery=(obj)=>{
    // const {configController} = this.props;
    let _obj = {};  
    // Goi o this.getRequestQuery();
    // if(this._projectId){
    //   _obj.ProjectId = this._projectId;
    // }

    // Goi o this.getRequestQuery();
    // if(configController && configController.Id){
    //   _obj.ScreenGUID = configController.Id;
    // }

    // Goi o this.getRequestQuery();
    // if (this.props.match && this.props.match.params)
    // {
    //   _obj = Object.assign(_obj,this.props.match.params);
    //   console.warn("_obj",this.props.match.params);
    // }
    if(obj){
      _obj = Object.assign(_obj,obj);
    }
    let _addQuery = this.getRequestQuery();
    if(_addQuery){
      _obj = Object.assign(_obj,_addQuery);
    }
    return _obj;
  }

  /**
   * Lấy query data gửi lên server, bổ sung từ ApiQuery trong Config
   * 
   * @method getRequestDetailQuery
   * @memberof PageExt
   * 
  */
  getRequestDetailQuery=(obj)=>{
    let _obj = {};  
    let _addQuery = this.getRequestQuery();
    if(_addQuery){
      _obj = Object.assign(_obj,_addQuery);
    }
    return _obj;
  }

  /**
   * Lấy Title từ `configUI`
   * 
   * @method getTitle
   * @memberof PageExt
   * 
  */
  getTitle=()=>{
    let _configUI = this.getConfigUI();
    if(_configUI.Title){
      return _configUI.Title;
    }
  }

  getConfigSharedOnServer=()=>{
    let _configOnServer = this.getConfigOnServer();
    let _config = {
      style:{},
      className:"",
      ..._configOnServer
    };
    return _config;
  }

  /**
   * Lấy những thông tin default từ `configUI` gán vào state
   * 
   * @method parseDefaultValueFromConfigUI
   * @memberof PageExt
   * 
  */
  parseDefaultValueFromConfigUI=(state)=>{
    let _configUI = this.getConfigUI();
    let _config = this.getConfig();
    if(_configUI){
      if(_configUI.Data!=null){
        state.isUsingStaticData = true;
      }
    }
    if(_config){
      if(_config.DefaultShowContent!=null){
        state.isShowContent = _config.DefaultShowContent;
      }   
      if(_config.HideWhenEmpty!=null){
        state.isHideWhenEmpty =  _config.HideWhenEmpty;
      }   
    }
  }

  /**
   * Lấy data có sẵn
   * 
   * @method getStaticData
   * @memberof PageExt
   * 
  */
  getStaticData=(state)=>{
    let _configUI = this.getConfigUI();
    if(_configUI){
      let _data;
      if(_configUI.Data){
        if(typeof _configUI.Data == 'function'){
          _data = _configUI.Data();
        }
        else{
          _data = _configUI.Data;
        }
      }
      return _data;
    }
  }

  /**
   * Gọi api options
   * 
   * @method onRequestOptions
   * @memberof PageExt
   * 
  */
  onRequestOptions=(opts)=>{
    let _data = this.getRequestOptionsQuery({});
    let _request = this.getRequestOptionsMethodFromConfig();
    fnListUI.fnShowLoadingScreen()
    if (_request){
      HH().ApiGeneric.generic({
        request:_request,
        data:_data,
        successCallBack:(response)=>{
          let _options = response.Data;
          let _headerConfig = this.parseHeaderConfigFromOptions(_options);
          let _pageConfig = this.parsePageConfigFromOptions(_options);
          this.parseScreenConfigInOptions(_options);
          HUtils.parseOptionsLogoConfig(_options);
          this.setState({
            options:_options,
            error:null,
            pageConfig: _pageConfig,
            headerConfig:_headerConfig,
            isFetchingOptions: false,
          },()=>{  
            fnListUI.fnShowLoadingScreen({hide:true})
            if(opts && opts.cbSuccess){
              opts.cbSuccess(_options);
            }        
          });          
        },
        errorCallBack:(error,response)=>{
          fnListUI.fnShowLoadingScreen({error:response!=null?response.Msg:'Lỗi lấy Options!',cbTryAgain: ()=>{
            fnListUI.fnShowLoadingScreen()
            this.onRequestOptions();
          }})
          this.setState({error:error!=null?error:response});
        }
      })
    }
  }

  /**
   * Gọi api options detail
   * 
   * @method onRequestOptionsDetail
   * @memberof PageExt
   * 
  */
  onRequestOptionsDetail=(opts)=>{
    let _data = this.getRequestOptionsQuery({});
    let _request = this.getRequestMethod('POST',null,'Options_Detail');
    fnListUI.fnShowLoadingScreen()
    if (_request){
      HH().ApiGeneric.generic({
        request:_request,
        data:_data,
        successCallBack:(response)=>{
          let _optionsDetail = response.Data;
          this.setState({
            optionsDetail:_optionsDetail,
            error:null,
          },()=>{  
            fnListUI.fnShowLoadingScreen({hide:true})
            if(opts && opts.cbSuccess){
              opts.cbSuccess(_optionsDetail);
            }        
          });          
        },
        errorCallBack:(error,response)=>{
          fnListUI.fnShowLoadingScreen({error:response!=null?response.Msg:'Lỗi lấy Options Detail!',cbTryAgain: ()=>{
            fnListUI.fnShowLoadingScreen()
            this.onRequestOptionsDetail();
          }})
          this.setState({error:error!=null?error:response});
        }
      })
    }
  }

   /**
   * Gọi api list
   * 
   * @method onRequestList
   * @memberof PageExt
   * 
  */
 onRequestList=(opts={})=>{
  let _data = this.getRequestListQuery({
    ...this.getRequestFilterQuery(),
    ...opts.customQuery
  });
  let _request = this.getRequestListMethodFromConfig();
  if(opts.notShowLoading){
    console.log("notShowLoading");
  }
  else{
    fnListUI.fnShowLoadingScreen()
  }
  
  if (_request){
    HH().ApiGeneric.generic({
      request:_request,
      data:_data,
      successCallBack:(response)=>{
        if(response && response.Data){
          let _dataList = response.Data.Data;
          let _extraData = response.Data.ExtraData;
          let _dataTotalSize = 0;
          if(response.Data.Total!=null){
            _dataTotalSize = response.Data.Total;
          }
          // let _pageConfig = this.parsePageConfigFromOptions(_options);
          // console.warn("success datalist",this.state.filter,_dataList);          
          this.setState({
            dataList:_dataList,
            extraData:_extraData,
            error:null,
            // pageConfig: _pageConfig,
            isFetchingList: false,
            dataTotalSize: _dataTotalSize,
          },()=>{  
            if(this.state.filter!=null){
              this.onFilterData(this.state.filter);
            }
            if(opts.cbSuccess){
              opts.cbSuccess(_dataList);
            }        
          });
        }        
        fnListUI.fnShowLoadingScreen({hide:true})
      },
      errorCallBack:(error,response)=>{
        fnListUI.fnShowLoadingScreen({error:response!=null?response.Msg:'Lỗi lấy List!',cbTryAgain: ()=>{
          fnListUI.fnShowLoadingScreen()
          this.onRequestList();
        }})
        this.setState({error:error!=null?error:response});
      }
    })
  }
}

  /**
     * Gọi api UpdateFields
     * 
     * @method onRequestUpdateField
     * @memberof PageExt
     * 
    */
   onRequestUpdateField=(row, fieldName, value, opts)=>{
    let _data = this.getRequestListQuery({
      Id: row.Id,
      Values:[{
        FieldName: fieldName,
        NewValue: value,
      }]
    });
    let _request = this.getRequestWithName("POST","UpdateFields");
    console.warn("onRequestUpdateField",row,fieldName,value,opts,_data,_request);
    if (_request){
      HH().ApiGeneric.generic({
        request:_request,
        data:_data,
        successCallBack:(response)=>{
          if(response.Data!=null && response.Data.Id==row.Id){
            let _dataList = this.state.dataList;
            for(let i=0;i<_dataList.length;i++){
              if(_dataList[i].Id == response.Data.Id){
                for(let _key of Object.keys(_dataList[i])){
                  if(_key.startsWith('_')==false){
                    _dataList[i][_key] = response.Data[_key];
                  }
                }
                break;
              }
            }
            if(opts){
              if(opts.fnUpdateUILoading){
                opts.fnUpdateUILoading(false,{...opts});
              }
              if(opts.fnUpdateUIError){
                opts.fnUpdateUIError(null,{...opts});
              }
              if(opts.fnSuccessCallBack){
                opts.fnSuccessCallBack(response,{...opts});
              }
            }          
          }
          fnListUI.fnShowToast(response.Msg)
          this.forceUpdate();
        },
        errorCallBack:(error,response)=>{
        }
      })
    }
  }

  /**
   * Gọi api detail
   * 
   * @method onRequestDetail
   * @memberof PageExt
   * 
  */
  onRequestDetail=()=>{
    // console.warn('onRequestDetail');
    let _data = this.getRequestDetailQuery({});
    let _request = this.getRequestDetailMethodFromConfig();
    // console.warn('_request 1: ', _request);
    if(_request){
      HH().ApiGeneric.generic({
        request:_request,
        data:_data,
        successCallBack:(response)=>{
          let _details = response.Data;
          this.setState({dataDetail:_details,error:null,},()=>{          
          });
  
        },
        errorCallBack:(error,response)=>{
          fnListUI.fnShowLoadingScreen({error:response!=null?response.Msg:'Lỗi lấy Detail!',cbTryAgain: ()=>{
            fnListUI.fnShowLoadingScreen()
            this.onRequestDetail();
          }})
          this.setState({error:error!=null?error:response});
        }
      })
    }
  }

  /**
   * Gọi api detail
   * 
   * @method onRequestDetail2
   * @memberof PageExt
   * 
  */
  onRequestDetail2=()=>{
    // console.warn('onRequestDetail');
    let _data = this.getRequestDetailQuery({});
    let _request = this.getRequestDetailMethodFromConfig('GET','Detail2');
    // console.warn('_request 2: ', _request);
    if(_request){
      HH().ApiGeneric.generic({
        request:_request,
        data:_data,
        successCallBack:(response)=>{
          let _details = response.Data;
          if (_details){
            let _dataDetail = _details.Data;
            let _extraDataDetail = _details.ExtraData;
            let _configUI = null;
            if (_extraDataDetail){
              _configUI = _extraDataDetail.UIConfig;
            }
            this.setState({
              dataDetail:_dataDetail,
              extraDataDetail:_extraDataDetail,
              configUI: _configUI,
              error:null,
  
            },()=>{});
          }
  
        },
        errorCallBack:(error,response)=>{
          fnListUI.fnShowLoadingScreen({error:response!=null?response.Msg:'Lỗi lấy Detail!',cbTryAgain: ()=>{
            fnListUI.fnShowLoadingScreen()
            this.onRequestDetail();
          }})
          this.setState({error:error!=null?error:response});
        }
      })
    }
  }

  /**
   * Lấy options
   * 
   * @method getOptions
   * @memberof PageExt
   * 
  */
  getOptions=()=>{
    return this.state.options || {};
  }

  /**
   * Lấy list
   * 
   * @method getList
   * @memberof PageExt
   * 
  */
  getList=()=>{
    const {dataList,searchText, dataFiltered, filter} = this.state;
    // console.warn('dataFiltered: ', dataFiltered);
    if (searchText){
      return dataFiltered || [];
    }
    else if (filter && Object.keys(filter).length>0){
      return dataFiltered || [];
    }
    else
      return dataList || [];
  }

  /**
   * Lấy getExtraData
   * 
   * @method getExtraData
   * @memberof PageExt
   * 
  */
  getExtraData=()=>{
    const {extraData} = this.state;
    return extraData;
  }

  /**
   * Lấy getFilterConfigFromExtraData
   * 
   * @method getFilterConfigFromExtraData
   * @memberof PageExt
   * 
  */
  getFilterConfigFromExtraData=({customFilterStart}={})=>{
    const {extraData} = this.state;
    let _configFilter = this.getConfigFilter();
    let _config = [];
    if(customFilterStart){
      _config = _config.concat(customFilterStart);
    }
    if(_configFilter.showSearch==true){
      _config.push(
        {
          type: 'search',
          // isFull:true,
          ..._configFilter.configSearch
        }
      )
    }    
    // console.warn('extraData:', extraData);
    if(extraData && extraData.Columns){
      let _cols = extraData.Columns;
      let _keys = Object.keys(_cols);
      if(_keys && _keys.length>0){
        _keys.map((v,i)=>{
          let _col = _cols[v];
          if(_col && _col.Filter && _col.Filter.type){
            let _more = {};
            if(_col.Filter.more){
              try {
                _more = JSON.parse(_col.Filter.more);
              } catch (error) {
                console.warn("error parse json filter more:",_col);
              }
            }
            let _c = {
              type: _col.Filter.type,
              title: _col.Filter.title,
              fieldName: v,
              more: _more
            }
            if(_col.Filter.optionKey){
              if(_col.Filter.optionKey.toLocaleLowerCase()=='inlist' || _col.Filter.optionKey.toLocaleLowerCase().indexOf('_inlist')>-1){
                _c.isUsingOptionInList = true;
              }
              else{
                _c.optionKey = _col.Filter.optionKey;
              }
            }
            _config.push(_c);
          }
        })
      }
    }
    this._dataConfigFilter = _config;
    // console.warn('_config:', _config);
    return _config;
  }

  /**
   * Kiem tra phai page goc khong
   * 
   * @method getIsRoot
   * @memberof PageExt
   * 
  */
  getIsRoot=()=>{
    const {opts} = this.props;
    if(opts && opts.isRoot==true){
      return true;
    }
    return false;
  }

  /**
   * Kiem tra show header khong
   * 
   * @method getIsShowHeader
   * @memberof PageExt
   * 
  */
  getIsShowHeader=(df=false)=>{
    let _isShow = df;
    let _isRoot = this.getIsRoot();
    if(_isRoot){
      _isShow = true;
    }
    let _configOnServer = this.getConfigOnServer();
    if(_configOnServer && _configOnServer.showHeader==true){
      _isShow = true;
    }
    // console.log("_configOnServer:",_configOnServer);
    return _isShow;
  }

  parseConfigUIFromSolid=(config)=>{
    let _contents = [];
    let _config = Object.assign({},config);
    if(config.Config){
      if(typeof config.Config =="string" && config.Config.indexOf("{")==0){
        try {
          _config.ConfigOnServer = JSON.parse(config.Config);
          _config.ConfigObj = JSON.parse(config.Config);
        } catch (error) {
          console.warn('Parse JSON Config error',config.Config);
          _config.ConfigOnServer = config.Config;
        }
      }
      else{
        _config.ConfigOnServer = config.Config;
        _config.ConfigObj = config.Config;
      }
    }
    if(config.Contents && config.Contents.length>0){
      for(let i in config.Contents){
        let _item = config.Contents[i];
        _contents.push(
          this.parseConfigUIFromSolid(_item)
        );
      }      
    }
    let _obj = {
      Title: config.Title,
      Type: config.UIType?config.UIType.toLocaleLowerCase():'table',
      Config:_config,
      Contents: _contents
    };
    return _obj;
  }

  /**
   * parse PageConfig From Options
   * 
   * @method parsePageConfigFromOptions
   * @memberof PageExt
   * 
  */
  parsePageConfigFromOptions=(options)=>{
    if(options && options.PageConfig){
      let _obj = this.parseConfigUIFromSolid(options.PageConfig);
      console.warn("parsePageConfigFromOptions:",_obj);
      return _obj;
    }
  }

  /**
   * parse HeaderConfig From Options
   * 
   * @method parseHeaderConfigFromOptions
   * @memberof PageExt
   * 
  */
  parseHeaderConfigFromOptions=(options)=>{
    let _headerConfig = {};
    if(options && options.HeaderConfig){
      _headerConfig = options.HeaderConfig;
      if (_headerConfig.Title){
        HH().changePageTitle(_headerConfig.Title);      
      }
    }
    return _headerConfig;
  }

   /**
   * parse onSearchText
   * 
   * @method onSearchText
   * @memberof PageExt
   * 
  */
  onSearchText=(searchText,dataList)=>{
    let _arrFiltered = null;
    let _dataList = dataList || this.state.dataList;
    if (searchText && _dataList){
      let _arrSearch = searchText.trim().toLowerCase().split(/\s+/);
      _arrFiltered = _dataList.filter((v,i)=>{
        let _res = false;
        if (v){
          let _fields = Object.keys(v);
          // _fields.map((v2,i2)=>{
          // })
          for (let j=0;j<_fields.length;j++){

            let _s = v[_fields[j]];
            // console.warn('s: ',_s);
            for (let k=0;k<_arrSearch.length;k++){
              if (typeof _s == 'string' &&_s.toLowerCase().indexOf(_arrSearch[k])>-1){
                return true;
              }
            }
          }
        }
        return _res;
      });
    }
    if(dataList){
      return _arrFiltered;
    }
    // console.warn('_arrFiltered: ', _arrFiltered);
    this.setState({
      searchText: searchText,
      dataFiltered:_arrFiltered,
    })
  }

  /**
   * parse onFilterData
   * 
   * @method onFilterData
   * @memberof PageExt
   * 
  */
  onFilterData=(filter,dataList)=>{
    //first time: no filter condition0: true
    console.warn(`onFilterData`,filter,dataList);    
    let _dataList = dataList || this.state.dataList;
    let _arrFiltered = _dataList || [];
    // console.warn('filter: ', filter);
    if (filter && Object.keys(filter).length>0 && _dataList){
      let _keysFilter = Object.keys(filter);
      for (let l=0;l<_keysFilter.length;l++){
        let _fieldName = _keysFilter[l];
        let _filter = filter[_fieldName];              
        if (_filter.value && _dataList){      
          let _filterValue = _filter.value;    
          if(_filter.type=='ArrayFilter'){            
            _arrFiltered = _arrFiltered.filter((v,i)=>{
              if (v){
                let _s = v[_fieldName];
                for (let k=0;k<_filterValue.length;k++){
                  if (_s == _filterValue[k]){
                    return true;
                  }
                }
              }
              if (!v.hasOwnProperty(_fieldName)){
                return true;
              }
              return false;
            });
          }
          else if(_filter.type=='TextFilter'){
            _arrFiltered = _arrFiltered.filter((v,i)=>{
              if (v){
                let _s = v[_fieldName];
                if(_s){
                  let _splitSpace = _filterValue.split(' ');
                  for (let k=0;k<_splitSpace.length;k++){
                    if (_s.toLowerCase().indexOf(_splitSpace[k].toLowerCase())>-1){
                      return true;
                    }
                  }
                }                
              }
              if (!v.hasOwnProperty(_fieldName)){
                return true;
              }
              return false;
            });
          }   
          else if(_filter.type=='SelectFilter'){
            _arrFiltered = _arrFiltered.filter((v,i)=>{
              if (v){
                let _s = v[_fieldName];
                if(_s == _filterValue){
                  return true;
                }                
              }
              if (!v.hasOwnProperty(_fieldName)){
                return true;
              }
              return false;
            });
          }       
        }
      }
    }
    else{
      _arrFiltered = _dataList;
    }
    // console.warn('_arrFiltered: ', _arrFiltered);
    if(this.state.searchText){
      _arrFiltered = this.onSearchText(this.state.searchText,_arrFiltered);
    }
    this.setState({
      filter:filter!=null&&Object.keys(filter).length>0?filter:null,
      dataFiltered:_arrFiltered,
    })
  }

  reloadOptionsAndList=()=>{
    this.onRequestOptions({
      cbSuccess:()=>{
        this.onRequestList();
      }
    });
  }

  render() {    
    return (
      <div className="animated">        
      </div>
    )
  }
}
export default PageExt;

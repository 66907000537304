import React from "react"
import { initI18n } from "./lang/i18n"
import MyUIOne from "./MyUI"
import MyPageOne from "./MyPage"
import MyRoutes from './routes'
import HModule, {addRoutesPublic} from "src/helpers/Module"
import * as MyHelpers from "./helpers"

const ModuleSetting2FA = {
  //========== init ==========
  _key: "setting2fa",
  _fnList: {},
  _config: {},
  _routes: MyRoutes.routes,
  getMyKey: () => {
    return "setting2fa"
  },
  runAfterInit: (HConfig) => {
    HModule.initDefault(ModuleSetting2FA, {MyPageOne,MyUIOne})
    addRoutesPublic(ModuleSetting2FA)
    initI18n()
    MyHelpers.addMenuToSettingMenu()
  },
  //---------- end default init ----------//
}

export default ModuleSetting2FA

import React, { Component } from 'react';
import { MyTableCellHelpers } from '@macashipo/mlib'

import HUtils from '../../helpers/Utils'
import MyComponentsTypes from '../MyComponents/MyComponentsTypes'

import MCTReadonly from '../../components/CellType/Types/readonly'
import MCTDelete from '../../components/CellType/Types/delete'
import MCTSelect from '../../components/CellType/Types/select'
import MCTText from '../../components/CellType/Types/text'
import MCTTextarea from '../../components/CellType/Types/textarea'
import MCTTextareafull from '../../components/CellType/Types/textareafull'
import MCTExpand_multi from '../../components/CellType/Types/expand_multi'
import MCTNumber from '../../components/CellType/Types/number'
import MCTApi from '../../components/CellType/Types/api'
import MCTCheckbox from '../../components/CellType/Types/checkbox'
import MCTReadonly_html from '../../components/CellType/Types/readonly_html'
import MCTLink from '../../components/CellType/Types/link'
import CellTypeStyles from '../../components/CellType/CellTypeStyles.css';

const MCTFiles = React.lazy(() => import('../../components/CellType/Types/files'));
const MCTReadonly_date = React.lazy(() => import('../../components/CellType/Types/readonly_date'));
const MCTAction = React.lazy(() => import('../../components/CellType/Types/action'));
const MCTChart_milestone = React.lazy(() => import('../../components/CellType/Types/chart_milestone'));
const MCTRate = React.lazy(() => import('../../components/CellType/Types/rate'));
const MCTCurrency = React.lazy(() => import('../../components/CellType/Types/currency'));
const MCTCs_wpg_scope = React.lazy(() => import('../../components/CellType/Types/cs_wpg_scope'));
const MCTReadonly_percent = React.lazy(() => import('../../components/CellType/Types/readonly_percent'));
const MCTSelect2_modal = React.lazy(() => import('../../components/CellType/Types/select2_modal'));
const MCTNumber_decimal = React.lazy(() => import('../../components/CellType/Types/number_decimal'));
const MCTDate = React.lazy(() => import('../../components/CellType/Types/date'));
const MCTDateTime = React.lazy(() => import('../../components/CellType/Types/datetime'));
const MCTDateTimeFromTo_InOneDay = React.lazy(() => import('../../components/CellType/Types/datefromto_inoneday'));
const MCTSelect2_addable = React.lazy(() => import('../../components/CellType/Types/select2_addable'));
const MCTQuality_popover = React.lazy(() => import('../../components/CellType/Types/quality_popover'));
const MCTArray_editable_modal = React.lazy(() => import('../../components/CellType/Types/array_editable_modal'));
const MCTReadonly_wrap = React.lazy(() => import('../../components/CellType/Types/readonly_wrap'));
const MCTReadonly_tooltip = React.lazy(() => import('../../components/CellType/Types/readonly_tooltip'));
const MCTReadonly_stt = React.lazy(() => import('../../components/CellType/Types/readonly_stt'));
const MCTReadonly_number = React.lazy(() => import('../../components/CellType/Types/readonly_number'));
const MCTReadonly_nowrap = React.lazy(() => import('../../components/CellType/Types/readonly_nowrap'));
const MCTReadonly_currency = React.lazy(() => import('../../components/CellType/Types/readonly_currency'));
const MCTReadonly_color = React.lazy(() => import('../../components/CellType/Types/readonly_color'));
const MCTReadonly_array = React.lazy(() => import('../../components/CellType/Types/readonly_array'));
const MCTReadonly_autolink = React.lazy(() => import('../../components/CellType/Types/readonly_autolink'));
const MCTJson_editor = React.lazy(() => import('../../components/CellType/Types/json_editor'));
const MCTQuality = React.lazy(() => import('../../components/CellType/Types/quality'));
const MCTMergerow = React.lazy(() => import('../../components/CellType/Types/mergerow'));
const MCTList_text = React.lazy(() => import('../../components/CellType/Types/list_text'));
const MCTList_input = React.lazy(() => import('../../components/CellType/Types/list_input'));
const MCTForm = React.lazy(() => import('../../components/CellType/Types/form'));
const MCTPercent = React.lazy(() => import('../../components/CellType/Types/percent'));
const MCTSelect_icon = React.lazy(() => import('../../components/CellType/Types/select_icon'));
const MCTSelect2 = React.lazy(() => import('../../components/CellType/Types/select2'));
const MCTPop_number_score = React.lazy(() => import('../../components/CellType/Types/pop_number_score'));
const MCTPop_number = React.lazy(() => import('../../components/CellType/Types/pop_number'));
const MCTSelect_addable = React.lazy(() => import('../../components/CellType/Types/select_addable'));
const MCTHtml_editor = React.lazy(() => import('../../components/CellType/Types/html_editor'));
const MCTPickcolor = React.lazy(() => import('../../components/CellType/Types/pickcolor'));

const MCTCustom_evm_filecheck = React.lazy(() => import('../../helpers/App/CellCustomPage'));
//v2
const MCTArray_editable = React.lazy(() => import('./Types/array_editable'));
const MCTProgressbar_Multi = React.lazy(() => import('./Types/progressbar_multi'));
const MCTKPI_Percent = React.lazy(() => import('./Types/kpi_percent'));
const MCTKPI_Check = React.lazy(() => import('./Types/kpi_check'));
const MCTWithMyUI = React.lazy(() => import('./Types/with_myui'));

const MCTVideoPlayer = React.lazy(() => import('../../components/CellType/TypesV2/video_player'));

//csupense
// const CSuppense = MyComponentsTypes.Components.CSuppense;

const List = {
  kpi_check: MCTKPI_Check,
  kpi_percent: MCTKPI_Percent,
  progressbar_multi: MCTProgressbar_Multi,  
  action: MCTAction,
  api: MCTApi,
  array_editable_modal: MCTArray_editable_modal,
  array_editable: MCTArray_editable,
  chart_milestone: MCTChart_milestone,
  checkbox: MCTCheckbox,
  cs_wpg_scope:MCTCs_wpg_scope,
  currency: MCTCurrency,
  custom_evm_filecheck: MCTCustom_evm_filecheck,
  date: MCTDate,
  datetime: MCTDateTime,
  datefromto_inoneday: MCTDateTimeFromTo_InOneDay,
  delete: MCTDelete,
  expand_api: MCTExpand_multi,
  expand_multi: MCTExpand_multi,
  files: MCTFiles,
  form: MCTForm,
  // html_editor: MCTHtml_editor,//su dung module v3_ui
  json_editor: MCTJson_editor,
  link: MCTLink,
  list_input: MCTList_input,
  list_text: MCTList_text,
  mergerow: MCTMergerow,
  number_decimal: MCTNumber_decimal,
  number: MCTNumber,
  percent: MCTPercent,
  pickcolor: MCTPickcolor,
  pop_number_score: MCTPop_number_score,
  pop_number: MCTPop_number,
  quality_popover: MCTQuality_popover,
  quality: MCTQuality,
  rate: MCTRate,
  readonly_array: MCTReadonly_array,
  readonly_autolink: MCTReadonly_autolink,
  readonly_color: MCTReadonly_color,
  readonly_currency: MCTReadonly_currency,
  readonly_date: MCTReadonly_date,
  readonly_html: MCTReadonly_html,
  readonly_nowrap: MCTReadonly_nowrap,
  readonly_number: MCTReadonly_number,
  readonly_percent: MCTReadonly_percent,
  readonly_stt: MCTReadonly_stt,
  readonly_tooltip: MCTReadonly_tooltip,
  readonly_wrap: MCTReadonly_wrap,
  readonly: MCTReadonly,
  select_addable: MCTSelect_addable,
  select_icon: MCTSelect_icon,
  select: MCTSelect,
  select2_addable: MCTSelect2_addable,
  select2_modal: MCTSelect2_modal,
  select2: MCTSelect2,
  text: MCTText,
  textarea: MCTTextarea,
  textareafull: MCTTextareafull,
  video_player: MCTVideoPlayer,
  with_myui: MCTWithMyUI,
}
MyTableCellHelpers.addTypesFromListLazy(List)
MyTableCellHelpers.Types._default = (cell,row,extra,index)=>{
  let _type = HUtils.Obj.get(extra,"Type.type");
  if(MyTableCellTypes.Types[_type]){
    return (
      MyTableCellTypes.Types[_type]({cell,row,extra,index})
    )
  }else{
    HUtils.logExistType(_type);
    return (
      MyTableCellTypes.Types.readonly({cell,row,extra,index})
    )
  }
}
MyTableCellHelpers.Types._auto = (cell,row,extra,index)=>{
  let _type = HUtils.Obj.get(extra,"Type.type");
  if(MyTableCellTypes.Types[_type]){
    return (
      MyTableCellTypes.Types[_type]({cell,row,extra,index})
    )
  }else{
    HUtils.logExistType(_type);
    return (
      MyTableCellTypes.Types.readonly({cell,row,extra,index})
    )
  }
}
const MyTableCellTypes = {
  addType(type,fnUI,opts={}){
    // MyComponentsTypes.addType(type,MyTableCellTypes.Types,fnUI,opts)
    MyTableCellHelpers.addTypes(type,fnUI)
  },
  // addTypeByTag(type,TagC,opts={}){
  //   MyComponentsTypes.addTypeByTag(type,MyTableCellTypes.Types,TagC,opts)
  // },
  Types: MyTableCellHelpers.Types,
  // Types:{
  //   _default: (cell,row,extra,index)=>{
  //     let _type = HUtils.Obj.get(extra,"Type.type");
  //     if(MyTableCellTypes.Types[_type]){
  //       return (
  //         MyTableCellTypes.Types[_type]({cell,row,extra,index})
  //       )
  //     }else{
  //       HUtils.logExistType(_type);
  //       return (
  //         MyTableCellTypes.Types.readonly({cell,row,extra,index})
  //       )
  //     }
  //   },
  //   pickcolor: (props)=>{ return CSuppense(props,MCTPickcolor) },
  //   custom_evm_filecheck: (props)=>{ return CSuppense(props,MCTCustom_evm_filecheck) },
  //   pop_number_score: (props)=>{ return CSuppense(props,MCTPop_number_score) },
  //   pop_number: (props)=>{ return CSuppense(props,MCTPop_number) },
  //   html_editor: (props)=>{ return CSuppense(props,MCTHtml_editor) },
  //   select2: (props)=>{ return CSuppense(props,MCTSelect2) },
  //   select_icon: (props)=>{ return CSuppense(props,MCTSelect_icon) },
  //   form: (props)=>{ return CSuppense(props,MCTForm) },
  //   percent: (props)=>{ return CSuppense(props,MCTPercent) },
  //   list_text: (props)=>{ return CSuppense(props,MCTList_text) },
  //   list_input: (props)=>{ return CSuppense(props,MCTList_input) },
  //   mergerow: (props)=>{ return CSuppense(props,MCTMergerow) },
  //   quality: (props)=>{ return CSuppense(props,MCTQuality) },
  //   action: (props)=>{ return CSuppense(props,MCTAction) },
  //   api: (props)=>{ return CSuppense(props,MCTApi) },
  //   array_editable_modal: (props)=>{ return CSuppense(props,MCTArray_editable_modal) },
  //   array_editable: (props)=>{ return CSuppense(props,MCTArray_editable) },
  //   chart_milestone: (props)=>{ return CSuppense(props,MCTChart_milestone) },
  //   checkbox: (props)=>{ return CSuppense(props,MCTCheckbox) },
  //   currency: (props)=>{ return CSuppense(props,MCTCurrency) },
  //   date: (props)=>{ return CSuppense(props,MCTDate) },
  //   delete: (props)=>{ return CSuppense(props,MCTDelete) },
  //   expand_api: (props)=>{ return CSuppense(props,MCTExpand_multi) },
  //   expand_multi: (props)=>{ return CSuppense(props,MCTExpand_multi) },
  //   files: (props)=>{ return CSuppense(props,MCTFiles) },
  //   link: (props)=>{ return CSuppense(props,MCTLink) },
  //   number_decimal: (props)=>{ return CSuppense(props,MCTNumber_decimal) },
  //   number: (props)=>{ return CSuppense(props,MCTNumber) },
  //   quality_popover: (props)=>{ return CSuppense(props,MCTQuality_popover) },
  //   rate: (props)=>{ return CSuppense(props,MCTRate) },
  //   readonly_array: (props)=>{ return CSuppense(props,MCTReadonly_array) },
  //   readonly_autolink: (props)=>{ return CSuppense(props,MCTReadonly_autolink) },
  //   readonly_color: (props)=>{ return CSuppense(props,MCTReadonly_color) },
  //   readonly_currency: (props)=>{ return CSuppense(props,MCTReadonly_currency) },
  //   readonly_date: (props)=>{ return CSuppense(props,MCTReadonly_date) },
  //   readonly_html: (props)=>{ return CSuppense(props,MCTReadonly_html) },
  //   readonly_nowrap: (props)=>{ return CSuppense(props,MCTReadonly_nowrap) },
  //   readonly_number: (props)=>{ return CSuppense(props,MCTReadonly_number) },
  //   readonly_percent: (props)=>{ return CSuppense(props,MCTReadonly_percent) },
  //   readonly_stt: (props)=>{ return CSuppense(props,MCTReadonly_stt) },
  //   readonly_tooltip: (props)=>{ return CSuppense(props,MCTReadonly_tooltip) },
  //   readonly_wrap: (props)=>{ return CSuppense(props,MCTReadonly_wrap) },
  //   readonly: (props)=>{ return CSuppense(props,MCTReadonly) },
  //   select: (props)=>{ return CSuppense(props,MCTSelect) },
  //   select_addable: (props)=>{ return CSuppense(props,MCTSelect_addable) },
  //   select2_addable: (props)=>{ return CSuppense(props,MCTSelect2_addable) },
  //   select2_modal: (props)=>{ return CSuppense(props,MCTSelect2_modal) },
  //   text: (props)=>{ return CSuppense(props,MCTText) },
  //   textarea: (props)=>{ return CSuppense(props,MCTTextarea) },
  //   textareafull: (props)=>{ return CSuppense(props,MCTTextareafull) },
  //   json_editor: (props)=>{ return CSuppense(props,MCTJson_editor) },
  //   video_player:(props)=>{ return CSuppense(props,MCTVideoPlayer) },
  // }
}
if(window._M){
  window._M.MyTableCellTypes = MyTableCellTypes;
}
export default MyTableCellTypes;
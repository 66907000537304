import React, { Component } from 'react';
import {
  Button,
  Modal, ModalBody, ModalFooter
} from '../../../_importComponent';
import Category from '../../Category/Category';


class ModalSignatureHistory extends Component {
  constructor(props) {
    super(props);
    const {match} = props;
    this.state ={
      isShow: false,
      isLoading: false,
      isSaving: false,
      isShowAlert: false,
    }
  }
  show(){
    this.setState({
      isShow:true,
      isLoading: false,
      isSaving: false,
      isShowAlert: false,
    })
  }


  _renderContent=()=>{
    const {idSignature,projectId,screenCode} = this.props;
    return (
      <div>
        <Button 
          style={{position:'absolute', right:0, top:0, padding:'0px', height:'25px', width:'25px', color:'thistle'}}
          color="link"
          onClick={()=>{
          this.setState({
            isShow: !this.state.isShow,
          })
        }}><i className="fa fa-close"/></Button>
        <Category 
          title={"Signature History"}
          isWithProjectId={true}
          projectId={projectId}
          screenCode={screenCode} 
          controller={"ProjectSignHistory"}
          config={{
            Page:{
              isNotChangePageTitle: true,
            },
            Header:{
              show: true,
              showProjectHeader: false,
              showProjectTitle: false
            },
            Filter:{
              show: false, 
            },
            Table:{
              tableProps:{
                pagination: false,
                striped: true,
                hover: true,
              },
            },
            Query:{
              ProjectId: projectId,
              ProjectSignatureId:idSignature
            }     
          }}
        /> 
      </div>
    )
  }
  render() {
    return (
      <Modal fade={false} className="modal-lg" isOpen={this.state.isShow} toggle={()=>{this.setState({isShow:!this.state.isShow})}} backdrop="static">
        <ModalBody>          
          {
            this._renderContent()            
          }
          {
            this.state.isSaving ==true &&
            <div className="fm-loading">
              <i className="fa fa-spin fa-refresh"/> Loading...
            </div>
          }
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={()=>{this.setState({isShow:!this.state.isShow})}}>Đóng</Button>
        </ModalFooter>
      </Modal>
    )
  }
}

export default ModalSignatureHistory;



import React, { Component } from 'react';
import MySVGTypes from './MySVGTypes';
const MySVGHelpers = {
  addType(key,fnComponent){
    if(MySVGTypes.Types[key]==null){
        MySVGTypes.Types[key] = fnComponent;
    }
  },
  getContents(props){
    if(props){
      return props.Contents;
    }
  },
  getStyle(props){
    if(props){
      return props.Style;
    }
  },
  Draw:{    
    group: function({ key = "group", childs = "", width = "auto", height = "auto", style = { display: "block" } } = {}) {
      return (
        <g key={key} width={width} height={height} style={style}>
          {childs}
        </g>
      );
    },
    svg: function({ key = "svgGroup", width = "100%", height = "100%", childs = "", style = { display: "block" } } = {}) {
      return (
        <svg key={key} width={width} height={height} style={style}>
          {childs}
        </svg>
      )
    },
  },
  
}

export default MySVGHelpers;
import React, { Component } from 'react';
import { HConfig, HConstant } from '../../_import';
import Category from './Category';

class CTable extends Component {
  constructor(props) {
    super(props);

    this.state={
      isShow: false,
      isAddNew: false,
      extraData: null,
      cols:[],
      error: null,
      isLoading: true,
      data: null,
      moreKeyForReExpand: null,
    };

    this._screenCode = "DETAILS";
    this._options = {};
    this._extraDataLocal = {
      Columns: {},
    }
  }

  componentDidMount(){
    this._requestGetData();
  }

  isShowed=()=>{
    return this.state.isShow;
  }

  hide=(opts)=>{
    this.setState({
      isShow: false,
    })
  }

  show=(opts)=>{
    const {row,options,extraData}=this.props;
    let _isShow = !this.state.isShow;
    if(opts.force==true){
      _isShow = true;
    }

    // if(_isShow && this.state.data==null){
    //   this._requestGetData();
    // }

    this._extraDataLocal = {
      Columns: extraData.Columns_Details,
    }
    this._options = {};
    if(options){
      try {
        this._options = JSON.parse(JSON.stringify(options));
        this._options.Configs = {
          ScreenConfig: "",
          ButtonList: null,
        }
        if(options.ScreenConfig_Details){
          this._options.Configs.ScreenConfig = options.ScreenConfig_Details.ScreenConfig;
          this._options.Configs.ButtonList = options.ScreenConfig_Details.ButtonList;
        }
        console.warn('options:',this._options);
      } catch (error) {        
        console.warn('error parse json:',error);
      }
    }

    this.setState({
      isShow: _isShow,
      isLoading: false,
      data: row.Details,
    },()=>{
      // console.warn('table expand:',this.state,this._extraDataLocal);
      row['_isExpand'] = this.state.isShow;

      if(this.state.isShow){
        this.reload();
      }
      if(opts!=null && opts.cc!=null){
        opts.cc.forceUpdate();
      }
    })
  }

  reExpand=()=>{
    // this._onRequestList();
    const me = this;
    let _key = 0;
    if(this.state.moreKeyForReExpand!=null){
      _key = this.state.moreKeyForReExpand + 1;      
    }
    me.setState({
      moreKeyForReExpand: _key
    },()=>{
      me.setState({
        moreKeyForReExpand: null
      })      
    })
  }

  _requestGetData=()=>{
    const {row}=this.props;
  }

  // loadWithOptions(options){
  //   this.options = options;
  //   // this._onRequestList();
  // }
  reload(){
    // this._onRequestList();
  }

  _requestAddWithProject=({data})=>{
    const {row, extra} = this.props;
  }

  render() {
    const {row} = this.props;
    const {moreKeyForReExpand} = this.state;
    let _more = {};
    let _bgColor = HConstant.gc(HConstant.AppConfig.expandBgColor);
    if(moreKeyForReExpand){
      _more.key = moreKeyForReExpand;
    }
    if(moreKeyForReExpand!=null){
      return (
        <div className="div-expand" {..._more}>
        </div>
      )
    }  
    return (
      <div className="div-expand" {..._more}>
      {
        this.state.isShow && this.state.isLoading==false && 
        <div className="div-expand-row" style={{padding:'5px',backgroundColor:_bgColor}}>
          <div style={{
            paddingLeft:'5px',paddingRight:'5px',color:'#20a8d8',marginRight:'1px',fontSize:'30px'
            }}> <i className="fa fa-angle-double-right"/> </div>
          <div className="wRDetail">
            <Category 
              ref={r=> {this._cCategory=r;}}
              title="Details" screenCode={this._screenCode} controller="Details"
              config={{
                Page:{
                  staticOptions: this._options,
                  isNotChangePageTitle: true,
                  isNotShowLoading: true,
                },
                Header:{
                  show: true,
                  showAddNew: false,
                  showPrint: false,
                  isShadow: false,
                },
                Filter:{
                  show: false
                },
                Table:{
                  staticData: ()=>{
                    console.log('staticData:',this.state.data);
                    return this.state.data;
                  },
                  addExtraColumns: {                  
                    _STT :{ Type: { type: 'stt', }, Index: 0, CanShow: true, CanEdit: false, CanSort: false, Header: " ", Width: '30px', }
                  },
                  deleteWithData: true,
                  // localExtraData: this._extraDataLocal,                
                  extraData: this._extraDataLocal,
                  isMergeExtraData: true,
                  optionsTable:{
                    paginationShowsTotal: false,
                    hideSizePerPage: true,
                  },
                  tableProps:{
                    pagination: false,
                  },
                },
              //   Query:{
              //     WPGId: row.WPGId,
              //     ProjectId: row.ProjectId,
              //     ProjectWPGDefectId: row.Id
              //   },
              }}
              configController={this.props.configController}
            />
          </div>          
        </div>
      }
      </div>
    )
  }
}

export default CTable;
import React, { Component } from 'react';
import {
  Button, FormGroup, InputGroup, InputGroupAddon,
  Select,
  ColFilter,
  HOptions,HConstant,
} from './_import';

class CFSelect2Quality extends Component {
  constructor(props) {
    super(props);
    this.state = { 
      value: null,
    };
  }
  _renderItem=(option)=>{
    let _bg = 'transparent';
    if(option.ColorCode){
      _bg = option.ColorCode;
    }
    return <div className="s2color_value" style={{backgroundColor:_bg,color:'white'}}>{option.label}</div>;
  }
  _renderItemType2=(option)=>{
    let _bg = 'transparent';
    if(option.ColorCode){
      _bg = option.ColorCode;
    }
    let _content;
    if(option.value=="1"){
      _content = <i className="fa fa-times" style={{fontSize:'11px'}}></i>;
    }
    else if(option.value=="3"){
      _content = <i className="fa fa-minus" style={{fontSize:'11px'}}></i>;
    }
    else if(option.value=="5"){
      _content = <i className="fa fa-check" style={{fontSize:'11px'}}></i>;
    }
    return (
      <div className="dFfdRaiC">
        <div className="s2color_value" style={{backgroundColor:_bg,color:'white',width:'20px',height:'20px',marginRight:'5px'}}>
          {_content}
        </div>
        {option.label}
      </div>
    );
  }
  _renderContent=()=>{
    const {item,onClear,onChange,values,cFilter,options} = this.props;
    const {title,fieldName,cellProps} = item;
    
    let _moreProps = {};
    _moreProps = Object.assign(_moreProps,cellProps);
    let _value = this.state.value;
    if(_value==null){
      if(values && values[fieldName]){
        _value = values[fieldName];
      }
    }
    
    let _options = [
      {label:'Đỏ',value:'1',ColorCode:'#EC5463'},
      {label:'Vàng',value:'3',ColorCode:'#FDCD55'},
      {label:'Xanh',value:'5',ColorCode:'#9EC969'},
    ];
    if(item.optionKey){
      let _optionKey = item.optionKey;
      if(options && options[_optionKey]){
        _options = options[_optionKey];
      }
      if(item.needConvert==true){
        _options = HOptions.createOptionsForSelect2(_options);
      }
    }

    return (
      <FormGroup>
        <InputGroup>
          <InputGroupAddon addonType="prepend">{title}</InputGroupAddon>
          <div style={{flex:1}}>
            <Select
              id="select2"
              onBlurResetsInput={false}
              onSelectResetsInput={false}
              autoFocus={false}
              options={_options!=null?_options:[]}
              valueRenderer={this._renderItemType2}
              optionRenderer={this._renderItemType2}
              value={_value}
              simpleValue
              multi={true}
              closeOnSelect={false}
              clearable={false}
              name="selected-state"
              onChange={(newValue)=>{   
                //Trước khi sửa: let _value = ""
                //Sửa lỗi khi mảng rỗng lưu local value là "" => lưu là []            
                let _value = [];
                if(newValue){
                  _value = newValue.replace(HConstant.EmptyOption.value,'').split(',');
                }
                let _obj = { type: 'ArrayFilter', value: _value};   
                console.log('onChange:',newValue,_obj);    
                this.setState({
                  value: newValue
                });                           
                if(onChange){
                  onChange({
                    value: newValue,
                    filter: _obj
                  });
                }
              }}
              searchable={true}
              {..._moreProps}
            />
          </div>
          {
            _value!=null &&
            <div>
              <Button color="danger" onClick={()=>{
                this.setState({
                  value: null
                });   
                onClear && onClear(fieldName,item);
              }}><i className="fa fa-ban"></i></Button>
            </div>
          }          
        </InputGroup>
      </FormGroup>
    )
  }
  render(){
    const {item,index} = this.props;
    let _ui = this._renderContent();
    return (
      <ColFilter key={index} component={_ui} {...item.col}/>
    )
  }
}

export default CFSelect2Quality
import React from 'react'
import { MyCardHelpers } from '@macashipo/mlib';
import MyCardTypes from '../../../../components/MyCard/MyCardTypes';
import CSuppense from '../../../../components/MyCore/CSuspense'

const MyCard_001 = React.lazy(() => import('./Types/card_001'));
const MyCard_002 = React.lazy(() => import('./Types/card_002'));
const MyCard_003 = React.lazy(() => import('./Types/card_003'));
const MyCard_004 = React.lazy(() => import('./Types/card_004'));
const MyCard_005 = React.lazy(() => import('./Types/card_005'));
const MyCard_006 = React.lazy(() => import('./Types/card_006'));
const MyCard_007 = React.lazy(() => import('./Types/card_007'));
const MyCard_008 = React.lazy(() => import('./Types/card_008'));
const MyCard_009 = React.lazy(() => import('./Types/card_009'));

const MyCard_SolidGalleryItem = React.lazy(() => import('./Types/card_solid_galleryitem'));
const MyCard_solid_gallerydetail = React.lazy(() => import('./Types/card_solid_gallerydetail'));
const MyCard_solid_imgtag = React.lazy(() => import('./Types/card_solid_imgtag'));
const MyCard_solid_standard_knowledgebase = React.lazy(() => import('./Types/card_standard_knowledgebase'));
const MyCard_solid_standard_community = React.lazy(() => import('./Types/card_solid_standard_community'));
const MyCard_solid_standard_community_member = React.lazy(() => import('./Types/card_solid_standard_community_member'));
const MyCard_solid_standard_community_codel2s = React.lazy(() => import('./Types/card_solid_standard_community_codel2s'));
const MyCard_solid_package_item = React.lazy(() => import('./Types/card_solid_package_item'));
const MyCard_BidPipeLevelling = React.lazy(() => import('./Types/card_bidpipe_leveling'));

const MyCard_solid_standardgth_row = React.lazy(() => import('./Types/card_solid_standardgth_row'));
const MyCard_solid_standardgth_boundary = React.lazy(() => import('./Types/card_solid_standardgth_boundary'));
const MyCard_solid_standardgth_card = React.lazy(() => import('./Types/card_solid_standardgth_card'));

const MyCard_standardv4_simple = React.lazy(() => import('./Types/standardv4_simple'));

const AppMyCardTypes = {
  init(){    
    MyCardTypes.addType('card_001',(props)=>{ return CSuppense( props, MyCard_001 ) });
    MyCardTypes.addType('card_002',(props)=>{ return CSuppense( props, MyCard_002 ) });
    MyCardTypes.addType('card_003',(props)=>{ return CSuppense( props, MyCard_003 ) });
    MyCardTypes.addType('card_004',(props)=>{ return CSuppense( props, MyCard_004 ) });
    MyCardTypes.addType('card_005',(props)=>{ return CSuppense( props, MyCard_005 ) });
    MyCardTypes.addType('card_006',(props)=>{ return CSuppense( props, MyCard_006 ) });
    MyCardTypes.addType('card_007',(props)=>{ return CSuppense( props, MyCard_007 ) });
    MyCardTypes.addType('card_008',(props)=>{ return CSuppense( props, MyCard_008 ) });
    MyCardTypes.addType('card_009',(props)=>{ return CSuppense( props, MyCard_009 ) });
    MyCardTypes.addType('card_solid_galleryitem',(props)=>{ return CSuppense( props, MyCard_SolidGalleryItem ) });
    MyCardTypes.addType('card_solid_imgtag',(props)=>{ return CSuppense( props, MyCard_solid_imgtag ) });
    MyCardTypes.addType('card_solid_gallerydetail',(props)=>{ return CSuppense( props, MyCard_solid_gallerydetail ) });
    MyCardTypes.addType('card_standard_knowledgebase',(props)=>{ return CSuppense( props, MyCard_solid_standard_knowledgebase ) });
    MyCardTypes.addType('card_solid_standard_community',(props)=>{ return CSuppense( props, MyCard_solid_standard_community ) });
    MyCardTypes.addType('card_solid_standard_community_member',(props)=>{ return CSuppense( props, MyCard_solid_standard_community_member ) });
    MyCardTypes.addType('card_solid_standard_community_codel2s',(props)=>{ return CSuppense( props, MyCard_solid_standard_community_codel2s ) });
    MyCardTypes.addType('card_solid_package_item',(props)=>{ return CSuppense( props, MyCard_solid_package_item ) });
    MyCardTypes.addType('card_bidpipe_leveling',(props)=>{ return CSuppense( props, MyCard_BidPipeLevelling ) });
    MyCardTypes.addType('card_solid_standardgth_row',(props)=>{ return CSuppense( props, MyCard_solid_standardgth_row ) });
    MyCardTypes.addType('card_solid_standardgth_boundary',(props)=>{ return CSuppense( props, MyCard_solid_standardgth_boundary ) });
    MyCardTypes.addType('card_solid_standardgth_card',(props)=>{ return CSuppense( props, MyCard_solid_standardgth_card ) });
    MyCardTypes.addType('standardv4_simple',(props)=>{ return CSuppense( props, MyCard_standardv4_simple ) });
  }
}

const List = {
  standardv4_simple: MyCard_standardv4_simple
}

MyCardHelpers.addTypesFromListLazy(List)
export default AppMyCardTypes;
import ReactGA from 'react-ga';
const GA = {
  HConfig: null,
  getHConfig(){
    if(GA.HConfig && GA.HConfig.fnList){
      return GA.HConfig;
    }
  },
  initialize(Config){
    if(Config){
      GA.HConfig = Config;
      const HConfig = GA.getHConfig();
      if(HConfig){
        if(HConfig && HConfig.fnList.getIsDevMode()){
          let _gaId = HConfig.fnList.getConfig('GAId_Dev');
          let _user = HConfig.fnList.getAuthName()||'no_user';
          ReactGA.initialize(_gaId,{
            gaOptions:{
              userName: _user
            }
          });
          window._gaId = _gaId;
        }
        else{
          let _gaId = HConfig.fnList.getConfig('GAId');
          ReactGA.initialize(_gaId);
          window._gaId = _gaId;
        }
      }
    }        
  },
  trackingPageView({page,hideUser,noUser}={}){
    const HConfig = GA.getHConfig();
    if(HConfig){
      if(window._gaId){
        // console.warn("trackingPageView:",page);
        let _user = HConfig.fnList.getAuthName()||'no_user';
        if(hideUser===true && _user!='no_user'){
          _user = 'true';
        }
        if(page!=null && page.length>0){
          let _page = page;
          console.log('trackingPageView: ',_page);
          if(_user!=null && _user.length>0){
            // _page = _page + '?user=' + _user;
            if(noUser!==true){
              _page = _page + '?user=' + _user;
            }
          }
          ReactGA.pageview(_page);
        }
        else{
          let _hash = window.location.hash;
          console.log('trackingPageView: ',_hash);
          if(_user!=null && _user.length>0){
            // _hash = _hash + '?user=' + _user;
            if(noUser!==true){
              _hash = _hash + '?user=' + _user;
            }
          }
          ReactGA.pageview(_hash);
        }
      }
    }    
  },
  trackingEvent({category,action}){
    if(window._gaId){
      ReactGA.event({
        category: category,
        action: action,
      })
    }
  },
  trackingEventClick({action,label,value}={}){
    if(window._gaId){
      ReactGA.event({
        category: 'Click',
        action: action || 'click',//exp: item_abc
        label: label,//label item
        value: value//value is number
      })
    }
  },
  trackingEventErrorApi({apiUrl,label,value}={}){
    if(window._gaId){
      ReactGA.event({
        category: 'ErrorApi',
        action: 'ErrorApi:'+apiUrl,//exp: link api
        label: label,//label message
        value: value//value is number
      })
    }
  },
  trackingEventErrorUI({action,label,value}={}){
    if(window._gaId){
      ReactGA.event({
        category: 'ErrorUI',
        action: action,//exp: item_abc
        label: label,//label item
        value: value//value is number
      })
    }
  },
  trackingEventErrorImage({currentUrl,imgUrl,value}={}){
    if(window._gaId){
      ReactGA.event({
        category: 'ErrorImage',
        action: 'ErrorImage:'+currentUrl,//exp: link trang
        label: 'ErrorImage:'+imgUrl,//link hinh
        value: value//value is number
      })
    }
  }
}

export default GA;
import React from 'react';
function ComponentLoading(props) {
  return (
    <div></div>
  )
}

const Default = React.lazy(() => import('./Types/_default'));
const History = React.lazy(() => import('./Types/history'));
const JustHeader = React.lazy(() => import('./Types/justHeader'));

const BelowFilterTypes = {
  addTypes(key,fnUI){
    if(BelowFilterTypes.UI[key]==null){
      BelowFilterTypes.UI[key] = fnUI;
    }
    else{
      console.warn('addTypes exist:',key)
    }
  },
  UI:{
    _default:(props)=>{
      return(
        <React.Suspense fallback={<ComponentLoading />}>
          <Default {...props}/>
        </React.Suspense>
      )
    },
    history:(props)=>{
      return(
        <React.Suspense fallback={<ComponentLoading />}>
          <History {...props}/>
        </React.Suspense>
      )
    },
    justHeader:(props)=>{
      return(
        <React.Suspense fallback={<ComponentLoading />}>
          <JustHeader {...props}/>
        </React.Suspense>
      )
    },
  }
}

export default BelowFilterTypes;
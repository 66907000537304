import React, { Component } from 'react';
import { HConfig, HConstant } from '../../_import';
import Category from './Category';


class CTable extends Component {
  constructor(props) {
    super(props);
    this.state={
      isShow: false,
      isAddNew: false,
      extraData: null,
      cols:[],
      error: null,
      isLoading: true,
      data: null,
      moreKeyForReExpand: null,
    };
    // console.warn('TableDetailApi',this.props,this.props.row);
    const {row} = this.props;
    if(row.UI_DefaultShowExpand===true || row.UI_DefaultShowExpand==='show'){
      this.state.isShow = true;
      this.state.isLoading = false;
      row['_isExpand'] = this.state.isShow;
    }
  }

  componentDidMount(){
  }

  isShowed=({row,item})=>{
    return this.state.isShow;
  }

  reExpand=()=>{
    // this._onRequestList();
    const me = this;
    let _key = 0;
    if(this.state.moreKeyForReExpand!=null){
      _key = this.state.moreKeyForReExpand + 1;      
    }
    me.setState({
      moreKeyForReExpand: _key
    },()=>{
      me.setState({
        moreKeyForReExpand: null
      })      
    })
  }

  hide=(opts)=>{
    this.setState({
      isShow: false,      
    })
  }

  show=(opts)=>{
    const {row,options,extraData}=this.props;
    let _isShow = !this.state.isShow;
    if(opts.force==true){
      _isShow = true;
    }

    this.setState({
      isShow: _isShow,
      isLoading: false,
    },()=>{
      row['_isExpand'] = this.state.isShow;
      if(this.state.isShow){
        this.reload();
      }
      if(opts!=null && opts.cc!=null){
        opts.cc.forceUpdate();
      }
    })
  }

  reload(){
    // this._onRequestList();
  }

  _getQuery=()=>{
    const {row,detailConfig} = this.props;
    let _query = {};
    if(detailConfig.queryFromParent){
      let _keys = Object.keys(detailConfig.queryFromParent);
      for(let k of _keys){
        _query[k] = row[detailConfig.queryFromParent[k]];
      }
    }
    return _query;
  }

  _requestUpdateMaster=()=>{
    const {row,requestUpdateMaster} = this.props;
    console.log("_requestUpdateMaster");
    if(requestUpdateMaster){
      requestUpdateMaster(row);
    }
  }

  render() {    
    const {row,detailConfig,isWithProjectId,projectId,pageMatch,fnList} = this.props;
    const {moreKeyForReExpand} = this.state;
    let _more = {};
    let config = {};//add config empty 
    if(moreKeyForReExpand){
      _more.key = moreKeyForReExpand;
    }
    if(moreKeyForReExpand!=null){
      return (
        <div className="div-expand" {..._more}>
        </div>
      )
    } 
    // console.warn('TableDetailApi:',isWithProjectId,projectId);
    let _title='Details';
    let _bgColor = HConstant.gc(HConstant.AppConfig.expandBgColor);
    if(detailConfig.title){
      _title=detailConfig.title;
    }
    if(detailConfig.titleField){
      _title=row[detailConfig.titleField];
    }
    if(detailConfig.bgColor){
      _bgColor = detailConfig.bgColor;
    }
    return (
      <div className="div-expand" {..._more}>
      {
        this.state.isShow && this.state.isLoading==false && 
        <div className="div-expand-row" style={{padding:'5px',backgroundColor:_bgColor}}>
          <div style={{
            paddingLeft:'5px',paddingRight:'5px',color:'#20a8d8',marginRight:'1px',fontSize:'30px'
            }}> <i className="fa fa-angle-double-right"/> </div>
          <div className="wRDetail">
            <Category 
              ref={r=> {this._cCategory=r;}}
              parentData={row}
              projectId={projectId}
              pageMatch={pageMatch}
              isDetail={true}
              fnList={{
                ...fnList,
                fnRequestUpdateMaster: this._requestUpdateMaster,
                fnCBAddNew: ()=>{
                  if(detailConfig.needReloadMaster || config.needReloadMaster ||
                    detailConfig.needReloadMasterAfterAddNew || config.needReloadMasterAfterAddNew){
                    this._requestUpdateMaster();
                  } 
                },
                fnCBUpdateField: ()=>{
                  if(
                    detailConfig.needReloadMaster || config.needReloadMaster ||
                    detailConfig.needReloadMasterAfterUpdateField || config.needReloadMasterAfterUpdateField){
                    this._requestUpdateMaster();
                  }            
                },
                fnCBDeleteItem: ()=>{
                  if(
                    detailConfig.needReloadMaster || config.needReloadMaster ||
                    detailConfig.needReloadMasterAfterDeleteItem || config.needReloadMasterAfterDeleteItem){
                    this._requestUpdateMaster();
                  }                  
                },
                fnCBRequestList: (data,error,response,opts)=>{
                  if(
                    detailConfig.needReloadMaster || config.needReloadMaster ||
                    detailConfig.needReloadMasterAfterList || config.needReloadMasterAfterList){
                    if(opts==null || opts.firstLoad!=true){
                      this._requestUpdateMaster();
                    }              
                  }                  
                }
              }}
              title={_title} 
              screenCode={detailConfig.screenCode||"ScreenCodeDetail"} 
              controller={detailConfig.controller||"Details"}
              config={{
                Query: this._getQuery(),
                Page:{
                  isNotChangePageTitle: true,
                  isNotShowLoading: true,
                  isWithProjectId: isWithProjectId,                  
                },
                Header:{
                  show: true,
                  showAddNew: false,
                  showPrint: false,
                  showProjectTitle: false,
                  isShadow: false,
                  onlyButtonList: HConfig.DefaultValues.detailHeaderOnlyButtonList,
                },
                Filter:{
                  show: false
                },
                Table:{
                  optionsTable:{
                    // paginationShowsTotal: false,
                    // hideSizePerPage: true,
                  },
                  tableProps:{
                    pagination: false,// Khong an, vi se bi overide boi config options tu api
                  },
                },
              }}
              configController={this.props.configController}
            />
          </div>          
        </div>
      }
      </div>
    )
  }
}

export default CTable;
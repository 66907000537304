import React, { Component } from 'react';
import {
  Button, Dropdown, DropdownItem, DropdownMenu, DropdownToggle
} from '../../../_importComponent';
import { Global } from '../../../helpers';

class CDropdown extends Component {
  constructor(props) {
    super(props);
    this.state = { 
      dropdownOpen: false,
      options: [],
    };
  }

  _renderItems=()=>{
    const {options} = this.state;
    let _ui = [];
    if(options!=null){
      for(let i=0;i<options.length;i++){
        let _item = options[i];
        _ui.push(
          <DropdownItem key={i} onClick={()=>{
              console.log('Click item:',_item);
              if(_item.onClick!=null){
                _item.onClick(_item);
              }
            }}>
            {_item.iconName!=null && <i className={`fa ${_item.iconName}`}></i> }
            {_item.title}
          </DropdownItem>
        )
      }
    }
    return _ui;
  }

  _onClickDropMenu=()=>{
    const {options} = this.props;
    console.log('_onClickDropMenu:',options);
    this.setState({
      dropdownOpen: !this.state.dropdownOpen,
      options: options
    })
  }

  render() {
    const { componentItem } = this.props;
    return (
      <Dropdown nav isOpen={this.state.dropdownOpen} toggle={this._onClickDropMenu} style={{color:'red',listStyle:'none'}}>
        <DropdownToggle nav>
          {componentItem !=null ? componentItem: <Button>Menu</Button>}
        </DropdownToggle>
        
        <DropdownMenu right>
          { this._renderItems() }
        </DropdownMenu>
      </Dropdown>
    )
  }
}

CDropdown.Helper = {
  ConvertMenuDepartment(options){
    console.log('ConvertMenuDepartment:',options);
    let _options = [];
    if(options!=null){
      for(let i=0;i<options.length;i++){
        let _item = options[i];
        let _newItem = {
          title: _item.Text,
          onClick(){
            Global.History.push('/plan_week/' + _item.Value,true);
          },
        }
        _options.push(_newItem);
      }
    }
    return _options;
  },
  ConvertMenuDepartmentReportUser(options){
    console.log('ConvertMenuDepartment:',options);
    let _options = [];
    if(options!=null){
      for(let i=0;i<options.length;i++){
        let _item = options[i];
        let _newItem = {
          title: _item.Text,
          onClick(){
            Global.History.push('/plan_week_report_user/' + _item.Value,true);
          },
        }
        _options.push(_newItem);
      }
    }
    return _options;
  },
  ConvertMenuDepartmentReportProject(options){
    console.log('ConvertMenuDepartment:',options);
    let _options = [];
    if(options!=null){
      for(let i=0;i<options.length;i++){
        let _item = options[i];
        let _newItem = {
          title: _item.Text,
          onClick(){
            Global.History.push('/plan_week_report_project/' + _item.Value,true);
          },
        }
        _options.push(_newItem);
      }
    }
    return _options;
  },
}

export default CDropdown;
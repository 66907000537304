import HApiAxios from './apiServices'
export const apiPost = (configRequest)=>{
  return new Promise((resolve,reject)=>{
    let _request = {
      method: "POST",
    }
    if(configRequest.url){
      _request.url = configRequest.url
    }
    else{
      _request.path = configRequest.path
      _request.name = configRequest.name
    }
    HApiAxios.generic({
      request:_request,
      data: configRequest.data,
      successCallBack: (response) => {
        resolve(response)
      },
      errorCallBack: (error, response) => {
        reject(error, response)
      },    
    })
  })  
}
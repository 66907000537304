import classnames from 'classnames';
import React from 'react';
import {
  Card,
  CardBody
} from '../../_importComponent';
import { HLocalStorage } from '../../apps/Helpers';
import MyTableFilterHelpers from './MyTableFilterHelpers';
import MyTableFilterTypes from './MyTableFilterTypes';
import "./styles.css";

const Key = {
  PrefixConfigLocal: "mtfv3"
}
class Z extends React.Component {
  static propTypes = {
  }
  static defaultProps = {
  }
  constructor(props) {
    super(props);
    this.state={
      listFilterWithKey: {},
    };
    this._configItemByKey = {};
    this._countBuildItem = 0;//Dem so luong item build, check last item submit filter if has value
    this._countBuildItemTotal = -1;
    this._currentValues = {};//key: field, value: value    
    this._initValuesFilter = {};//key: field, value: value //from Saved or defaultValue and for Saved
    this._cComponentByFieldName = {};
    // this._parseConfigItemByKey();
    this._parseLastFilter();
  }

  getCurrentValues=()=>{
    return this._currentValues;
  }
  getInitValuesFilter=()=>{
    return this._initValuesFilter;
  }

  clearFilter=()=>{
    console.warn("Clear filter");
    if(this._cComponentByFieldName){
      let _fieldNames = Object.keys(this._cComponentByFieldName);
      for(let k of _fieldNames){
        let _c = this._cComponentByFieldName[k];
        if(_c && _c.onClear){
          _c.onClear();
        }
      }
    }
    this._saveLocalFilter({isClear:true});
  }

  _parseLastFilter=()=>{
    const {screenCode,options} = this.props;
    if(options && options.DefaultFilter){
      this._initValuesFilter = Object.assign(this._initValuesFilter,options.DefaultFilter);
    }
    if(screenCode && this._getHasSaveLocal()){
      let _filterSaved = HLocalStorage.ConfigScreen.getCustomField({
        screenCode: screenCode,
        key: Key.PrefixConfigLocal
      });
      if(_filterSaved){
        let _keys = Object.keys(_filterSaved);
        if(_keys && _keys.length>0){
          for(let k of _keys){
            this._initValuesFilter[k] =_filterSaved[k];
          }
        }
        console.log("_filterSaved:",_filterSaved,this._initValuesFilter);
      }            
    }    
  }

  _getHasSaveLocal=({isClear}={})=>{
    const {configFilter} = this.props;
    if(configFilter && configFilter.hasSaveLocal===true){//tam tat, chi bat khi co cau hinh
      return true
    }
    return false;
  }
  _saveLocalFilter=({isClear}={})=>{
    const {screenCode} = this.props;
    const hasSaveLocal = this._getHasSaveLocal();
    if(hasSaveLocal===false){ return ;}
    console.log("_saveLocalFilter:",screenCode,this._currentValues,this._initValuesFilter,this.state.listFilterWithKey);
    let _configSave = {};
    let _keys = Object.keys(this._initValuesFilter);
    for(let k of _keys){
      let _moreOfItem = MyTableFilterHelpers.getMoreOfItem(this._configItemByKey[k]);
      // console.warn("_more if item:",_moreOfItem,k,this._configItemByKey);
      if(_moreOfItem && _moreOfItem.isSaved!=false){
        _configSave[k] = this._initValuesFilter[k];
      }
    }
    if(screenCode){
      if(isClear===true){
        HLocalStorage.ConfigScreen.setCustomField({
          screenCode: screenCode,
          key: Key.PrefixConfigLocal,
          data: {}
        });
      }else{
        HLocalStorage.ConfigScreen.setCustomField({
          screenCode: screenCode,
          key: Key.PrefixConfigLocal,
          data: _configSave
        });
      }
      
    }
  }

  _loadLastFilterFromLocal=()=>{
    const {screenCode} = this.props;
  }

  _buildFilterForBootstrapTable=()=>{
    let _listFilterWithKey = this.state.listFilterWithKey;
    console.log('_buildFilterForBootstrapTable:',_listFilterWithKey);
    let _cKeys = Object.keys(_listFilterWithKey);
    let _obj = {};
    if(_cKeys && _cKeys.length>0){      
      for(let k of _cKeys){
        let _arrOfItem = _listFilterWithKey[k];
        if(Array.isArray(_arrOfItem)){
          if(_arrOfItem && _arrOfItem.length>0){
            for(let i in _arrOfItem){
              let _item = _arrOfItem[i];
              if(_item){
                _obj = Object.assign(_obj,_item);
              }
            }
          } 
        }
        else{
          _obj = Object.assign(_obj,_arrOfItem);
        }               
      }
    }
    return _obj;
  }
  _getOuterStyle=(item)=>{
    let _style = {};
    if(item.isFull){
      _style.width="100%";
      _style.paddingRight="0px";
    }
    return _style;
  }

  _onChangeValueOfItem=(item,value,opts={})=>{
    // const {onChangeSearch,onChangeFilter} = this.props;
    console.log('_onChangeValueOfItem:',item,value,opts);
    this._lastFilterChange = item; 
    // if(item.type=="search123"){
    //   this._currentValues[item.fieldName] = value;      
    //   if(onChangeSearch){
    //     onChangeSearch(value);
    //   }
    // }
    // else{
    let _key = MyTableFilterHelpers.getKeyOfItem(item);
    this._initValuesFilter[_key] = value;
    let _filterOfItem = opts.filter;
    if(_filterOfItem){
      this.state.listFilterWithKey[_key] = _filterOfItem;
      if(_filterOfItem[_key]){
        if(_filterOfItem[_key].valueFilterServer){
          this._currentValues[_key] = _filterOfItem[_key].valueFilterServer;
        }
        else if(_filterOfItem[_key].value){
          this._currentValues[_key] = _filterOfItem[_key].value;
        }          
      }    
    }    
    else{
      delete this.state.listFilterWithKey[_key];
      delete this._currentValues[_key];
      delete this._initValuesFilter[_key];
    } 
    if(opts.justUpdateFilter!==true){
      this._submitCurrentFilter();
    }      
    // }
    if(opts.fromInit!==true){
      this._saveLocalFilter();
    }  
  }
  _submitCurrentFilter=({fromDefaultValue,countInit}={})=>{
    const {onChangeSearch,onChangeFilter,onChangeFilterOnInit} = this.props;
    let _build = this._buildFilterForBootstrapTable();
    // console.log('_submitCurrentFilter _build:',_build,this._currentValues); 
    if(fromDefaultValue==true){
      console.log('_submitCurrentFilter fromDefaultValue:',this._countBuildItemTotal,countInit); 
      if(this._countBuildItemTotal===countInit){//finish
        if(_build && Object.keys(_build).length>0){
          if(onChangeFilterOnInit){
            onChangeFilterOnInit(_build)
          }
          else if(onChangeFilter){
            onChangeFilter(_build,{lastItemChange: this._lastFilterChange});
          }
        }
        else{
          if(onChangeFilterOnInit){
            onChangeFilterOnInit({})
          }
        }
      }      
    }
    else{
      if(onChangeFilter){
        onChangeFilter(_build,{lastItemChange: this._lastFilterChange});
      }
    }    
  }
  _getSourceList=()=>{
    const {options} = this.props;
    let _options = [];
    if(options){
      if(typeof options =="function"){
        _options = options();
      }
      else{
        _options = options;
      }
    }
    return _options;
  }
  _getDataList=()=>{
    const {dataList} = this.props;
    let _data = [];
    if(dataList){
      if(typeof dataList =="function"){
        _data = dataList();
      }
      else{
        _data = dataList;
      }
    }
    return _data;
  }
  _getDataList=()=>{
    const {dataList} = this.props;
    let _data = [];
    if(dataList){
      if(typeof dataList =="function"){
        _data = dataList();
      }
      else{
        _data = dataList;
      }
    }
    return _data;
  }
  _getContents=()=>{
    const {contents} = this.props;
    if(typeof contents == "function"){
      return contents();
    }
    return contents;
  }
  _renderContents=()=>{
    const {contents,configFilter,fnList, dataList} = this.props;
    let _ui = [];
    if(contents && contents.length>0){      
      for(let i in contents){
        let _item = contents[i];
        let _type = _item.type;
        if(_type=="search" && _item.fieldName==null){
          _item.fieldName = "_SearchText";
        }
        this._configItemByKey[MyTableFilterHelpers.getKeyOfItem(_item)] = _item;
        let _className = MyTableFilterHelpers.getClassNameOfItem(_item,configFilter);
        if(MyTableFilterTypes.Types[_type]){
          // console.warn("add filter",_type,_item);
          _ui.push(
            <div key={i} style={this._getOuterStyle(_item)} className={classnames("mtf-item",_className)}>
              {
                MyTableFilterTypes.Types[_type]({                  
                  ..._item,
                  configOne: _item,
                  innerRef: (r)=>{
                    if(r && this._cComponentByFieldName){
                      this._cComponentByFieldName[_item.fieldName] = r;
                    }
                    // console.warn("this._cComponentByFieldName:",this._cComponentByFieldName);
                  },
                  onChange:(value,opts)=>{
                    // console.warn("onChange opts:",value,opts)
                    this._onChangeValueOfItem(_item,value,opts);
                  },         
                  dataList: dataList,
                  _getSourceList:this._getSourceList,
                  _getDataList:this._getDataList,
                  fnList:{
                    getCountInit: ()=>{     
                      this._countBuildItem++; 
                      this._countBuildItemTotal = contents.length;                                  
                      return this._countBuildItem;
                    },
                    getCurrentValues: this.getCurrentValues,
                    getInitValuesFilter: this.getInitValuesFilter,
                    submitCurrentFilter: this._submitCurrentFilter,
                    ...fnList
                  }
                })
              }
            </div>
          );
        }
      }
    }
    if(fnList && fnList.fntfMoreComponentContents){
      let _moreUI = fnList.fntfMoreComponentContents();
      if(_moreUI && _moreUI.length>0){
        _ui.push(..._moreUI)
      }      
    }
    return _ui;
  }

  _getConfigFilter=()=>{
    let _configFilter = this.props.configFilter || {};
    return _configFilter;
  }
  _getConfigInConfigFilter=(key,df)=>{
    let _configFilter = this._getConfigFilter();
    if(_configFilter && _configFilter.hasOwnProperty(key)){
      return _configFilter[key];
    }
    return df;
  }
  _getStyleContainer=()=>{
    const {style,styleContainer,className,contents} = this.props;    
    let _styleContainer = Object.assign({},style,styleContainer,this._getConfigInConfigFilter('styleContainer',{}));
    return _styleContainer;
  }
  _getStyleWrapper=()=>{
    const {styleWrapper} = this.props;    
    let _styleContainer = Object.assign({padding:'5px'},styleWrapper,this._getConfigInConfigFilter('styleWrapper',{}));
    return _styleContainer;
  }
  render() {
    const {style,className,contents} = this.props;    
    return (
      <Card className={classnames(className,'no-print mf-container')} style={this._getStyleContainer()}>
        <CardBody className="" style={this._getStyleWrapper()}>
          {this._renderContents()}
        </CardBody>
      </Card>
    )
  }
}

export default Z;
import React, { Component } from 'react';
import {
  Button
} from '../../_importComponent';
import {ApiGeneric} from '../../_importService';
import {HUI,HText,HEE} from '../../_import';
import {HeaderWithExpandV1} from '../../_importV1';
import {MyForm,MyModal} from '../../_importMyComponent';
class CTable extends Component {
  constructor(props) {
    super(props);
    this.state={
      isSaving: false,
      msgSaving: HText.get("btn_save"),
    };
    this._countToResetForm = 0;
    this._checkCountToResetForm = 0;
  }

  componentDidMount(){
  }

  _onClickOK=()=>{
    if(this._cForm){
      let _values = this._cForm.getValues();
      // console.warn("values:",_values);
      this._onRequestSubmit(_values);
    }
  }

  _getRequestMethod=()=>{
    const {options,apiController} = this.props;
    // options.FormAddNewConfig = {};
    let _formAddNewConfig = options.FormAddNewConfig || {};
    let _config = _formAddNewConfig.Config || {};
    if(_config.APIName){
      if(_config.APIName.indexOf('/')>-1){
        return {
          method: 'POST',
          url: `/api/v1/${_config.APIName}`
        }
      }
      return {
        method: 'POST',
        path: apiController,
        name: _config.APIName
      }
    }
    return {
      method: 'POST',
      path: apiController,
      name: 'Add'
    }
  }
  _onRequestSubmit=(data={})=>{
    // console.warn(`_onRequestSubmit:`,data);
    const {getQuery} = this.props;    
    let _queryData = {};
    if(getQuery){
      _queryData = getQuery();
    }
    if(data){
      _queryData = Object.assign(_queryData,data);
    }
    this.setState({
      isSaving: true,
      msgSaving: HText.get("btn_saving"),
    },()=>{
      if(this._cForm){
        this._cForm.turnOnDisabled();
      }
      // return;
      ApiGeneric.generic({
        request:this._getRequestMethod(),
        data:_queryData,
        successCallBack:(response)=>{
          this._countToResetForm = 1;
          this._checkCountToResetForm = 0;
          this._msgSuccess = HText.get("msg_success_add");
          if(response.Msg){
            this._msgSuccess = response.Msg;
          }
          if(response.Data.Id){
            if(this._cForm){
              let _filesWithFieldName = this._cForm.getFilesNeedToUpload();
              if(_filesWithFieldName){
                let _allFieldName = Object.keys(_filesWithFieldName);
                for(let f of _allFieldName){
                  let _files = _filesWithFieldName[f];
                  if(_files && _files.length>0){
                    console.warn("_files:",_files);
                    if(_files && _files.length>0){
                      this._countToResetForm++;
                      this._onUploadFiles({
                        files: _files,
                        Id: response.Data.Id,
                        fieldName: f
                      })
                    }
                  }                
                }              
              }            
            }
          }
          if(this._countToResetForm>1){
            this.setState({
              isSaving: true,
              msgSaving: HText.get("btn_uploadingfile"),
            });
          }
          this._resetAfterSuccess(response);        
        },
        errorCallBack:(error,response)=>{
          this.setState({
            isSaving: false,
            msgSaving: HText.get("btn_save")
          },()=>{
            if(this._cForm){
              this._cForm.turnOnDisabled(false);
            }
          });
        }
      })
    })    
  }
  _onUploadFiles=({files,fieldName,Id}={})=>{
    const {projectId,screenCode,reloadTable} = this.props;
    let _customHeader = {
      ScreenCode: screenCode,
      Id: Id,
      FieldName: fieldName,
    };
    if(projectId){
      _customHeader.ProjectId = projectId;
    }
    ApiGeneric.generic({
      request:{
        method: 'UPLOAD',
        path: 'ProjectPhoto',
        name: 'Upload',
      },
      customHeader: _customHeader,
      files: files,
      successCallBack:(response)=>{        
        this._resetAfterSuccess(response);
      },
      errorCallBack:(error,response)=>{
      }
    })
  }

  _renderForm=()=>{
    const {options,getOptions} = this.props;
    const me = this;
    // options.FormAddNewConfig = {};
    let _formAddNewConfig = options.FormAddNewConfig || {};
    let _config = _formAddNewConfig.Config || {};
    let _moreExpand = _config.moreExpand || {};
    let _styleFooter = _config.styleFooter || {};
    let _formConfig = _formAddNewConfig.Form;
    let _defaultShowContent = false;
    let _hideFooter = false;
    let _title = _config.Title || HText.get('text_addnew');
    if(_config.DefaultShowContent!=null){
      _defaultShowContent = _config.DefaultShowContent;
    }
    if(_config.HideFooter!=null){
      _hideFooter = _config.HideFooter;
    }
    if(_formConfig){
      return (
        <HeaderWithExpandV1 ref={r=>{this._cExpand=r;}} title={_title} defaultShowContent={_defaultShowContent} {..._moreExpand}>
          <div style={{width:"100%"}}>
            <MyForm
              ref={r=>{this._cForm=r;}} 
              config={_formConfig} 
              onSubmit={this._onClickOK}
              isSubmiting={this.state.isSaving}
              getOptionOfKey={(key)=>{    
                if(getOptions){
                  let _options = getOptions();
                  if(_options[key]){
                    return _options[key];
                  }
                }                
                return [];
              }}
              renderFooter={()=>{
                if(_hideFooter==false){
                  return (
                    <div style={{padding:"15px",..._styleFooter}}>
                      <Button disabled={me.state.isSaving} color="success" onClick={()=>{
                        this._onClickOK();
                      }}>
                        {
                          me.state.isSaving==true && <i style={{marginRight:"5px"}} className={"fa fa-spinner fa-spin"}/> 
                        }
                        {me.state.msgSaving}
                      </Button>
                    </div>
                  )
                }                
              }}
            />
          </div>
        </HeaderWithExpandV1>
      )
    }    
  }

  _resetAfterSuccess=(response)=>{
    const {options,reloadTable} = this.props;
    this._checkCountToResetForm++;
    let _formAddNewConfig = options.FormAddNewConfig || {};
    let _config = _formAddNewConfig.Config || {};
    if(this._countToResetForm==this._checkCountToResetForm){      
      this._resetForm();
      if(reloadTable){
        reloadTable();
      }
      this.setState({
        isSaving: false,
        msgSaving: 'Lưu',
      },()=>{
        if(_config.needSendMsg){
          let _needSendMsg = _config.needSendMsg;
          if(typeof(_needSendMsg) == "string"){
            HEE.emit(_needSendMsg, {response})
          }
          else if(Array.isArray(_needSendMsg)){
            for(let i=0; i<_needSendMsg.length; i++){
              HEE.emit(_needSendMsg[i], {response})
            }
          }
        }
        if(_config.needCloseModal){
          MyModal.Helper.hide();
        }
      })
      HUI.Toast.showSuccess(this._msgSuccess);
    }
    else{
      this.setState({
        msgSaving: HText.get("btn_uploadingfile"),
      })
    }
  }
  _resetForm=()=>{
    if(this._cExpand){
      this._cExpand.reset();
    }
  }

  render() {
    const {options} = this.props;
    // console.warn('render chart:',options);
    return (
      <div className="header-formaddnew"> 
        {
          options && 
          // options.FormAddNewConfig &&
          this._renderForm()
        }     
      </div>
    )
  }
}

export default CTable;
import React from "react"
import { MyUIHelpers } from "@macashipo/mlib";
import * as MyHelpers from "../helpers"
const MUIselectmethod2fa = React.lazy(
  () => import("./Types/selectmethod2fa")
)
const MUIgeneration2FA = React.lazy(
  () => import("./Types/generation2FA")
)
const MUIdisable2FA = React.lazy(
  () => import("./Types/disable2FA")
)
const MUIsetting2FA = React.lazy(
  () => import("./Types/setting2FA")
)
const List = {
  setting2fa_selectmethod2fa: MUIselectmethod2fa,
  setting2fa_generation2fa: MUIgeneration2FA,
  setting2fa_setting2FA: MUIsetting2FA,
  setting2fa_disable2FA: MUIdisable2FA
};
const MyUIOne = {
  init() {
    // console.warn("init MyUIOne");
    MyUIHelpers.addTypesFromListLazy(List);
  },
};

export default MyUIOne;

import MyUIOne from "./MyUI"
const ModuleV1Page = {
    getMyKey: () => {
        return "v1_page"
    },
    runAfterInit: (HConfig) => {
        console.warn("runAfterInit v1_page",HConfig)
        MyUIOne.init();
    },
}

export default ModuleV1Page
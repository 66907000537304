import React, { Component } from 'react';
import SignatureItem from './SignatureItem';

class Signature extends Component {
  constructor(props) {
    super(props);
    this.state = { 
    };
  }

  _getDataSignature=()=>{
    const {data,options,projectId,screenCode} = this.props;
    if (data){
      return data;
    }else{
      if (options && options.Signature && options.Signature.Items && options.Signature.Items.length>0){
        return options.Signature;
      }
    }
  }

  render() {
    const {options,projectId,screenCode,getOptions,reloadTable,queryConfig,headerConfig,apiController,getFilterQuery} = this.props;
    let _ui = [];
    let _data = this._getDataSignature();
    if (_data && _data.Items && _data.Items.length>0){
      let _items = _data.Items;
      for (let i=0;i<_items.length;i++){
        if (_items[i]!=null){
          _ui.push(
            <SignatureItem key={i} title={_items[i].Name} 
              config = {_data.Config}
              item = {_items[i]}
              projectId={projectId}
              screenCode={screenCode}
              getOptions = {getOptions}
              reloadTable = {reloadTable}
              queryConfig = {queryConfig}
              // headerConfig = {headerConfig}
              apiController={apiController}
              getFilterQuery={getFilterQuery}
            />
          )
        }
       
      }
    }
    return (
      <div className="part-signature">{_ui}</div>
    );
  }
}

export default Signature;
import Moment from "moment";
import Numberal from '../libs/NumeralJs/numeral';
import HText from './Text'
const Format = {
  percent(number){
    let _v = '';
    if(number!=null){
      // let _n = Math.round(number * 100) / 100;
      let _n = Math.round(number * 10) / 10;
      _v = `${_n}%`;
    }
    return _v;
  },
  currency(s){
    let _value = '';
    if(s!=null){//support so am//if(s && s>0)
      let _isNegative = false;
      if(s<0){
        _isNegative = true;
        s  = s*-1;
      }
      _value = s.toFixed().replace(/./g, function(c, i, a) {
        // return i > 0 && c !== "." && (a.length - i) % 3 === 0 ? "," + c : c;
        return i > 0 && c !== "," && (a.length - i) % 3 === 0 ? "." + c : c;
      });

      if(_isNegative){
        _value = "-" + _value;
      }
    }
    return _value;
  },
  Date:{
    formatDate(value, format){
      let _value = '';
      let _format = format || 'DD/MM/YYYY hh:mm';
      
      if(value && value>0){
          _value = Moment(value).format(_format);
      }
      return _value;
    },
    dateStringWithFormat(time,format){
      if(time==null || time==0 || time == ''){
        return "";
      }
      let _d = new Date(time);
      if(format!=null && format.length>0){
        return Moment(time).format(format);
      }
      return _d.toISOString();
    },
    fromNow(time){
      if(time==null || time==0 || time == ''){
        return "";
      }
      return Moment(time).fromNow(); 
    },
    formatDateVietnamese(time){
      let _now = new Date();
      let _datetimeString = '';

      if(_now.getTime()>time ||_now.getTime()== time){
        let _months = Moment().diff(time, "months")
        let _days = Moment().diff(time, "days")
        let _hours = Moment().diff(time, "hours")
        let _minutes = Moment().diff(time, "minutes")

        if(_months > 0){
          _datetimeString =  `${_months} tháng trước`
        }
        else if(_days>0){
          _datetimeString =  `${_days} ngày trước`
        }
        else if(_hours>0){
          let _deltaMinutes = _minutes - 60*_hours;
          if(_deltaMinutes>0){
            _datetimeString = `${_hours} giờ ${_minutes - 60*_hours} phút trước`
          }
          else{
            _datetimeString = `${_hours} giờ trước`
          }
        }
        else if(_minutes>0){
          _datetimeString = `${_minutes} phút trước`
        }
        else{
          _datetimeString = "vài giây trước"
        }
      }
      return _datetimeString;
    },
    getObjectFromTime(time){
      let _dateObj = new Date(time);
      const monthNames = ["January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
      ];
      const monthShortNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
      return {
        month: _dateObj.getUTCMonth() + 1,
        monthName: monthNames[_dateObj.getUTCMonth()],
        monthShortName: monthShortNames[_dateObj.getUTCMonth()],
        day: _dateObj.getUTCDate(),
        hour: _dateObj.getUTCHours(),
        minute: _dateObj.getUTCMinutes()
      }
    },
    getNumberOfDay(fromDate, toDate){
      let _deltaDay = 0;
      if(toDate > fromDate){
        _deltaDay = Moment(toDate).diff(fromDate, "days");
      }
      return _deltaDay;
    },
    formatLanguageJQ(){
      const _currentLang = HText.language;
      let _vi = {
        closeText: "Đóng",
        prevText: "Trước",
        nextText: "Sau",
        currentText: "Hôm nay",
        monthNames: ["Tháng một", "Tháng hai", "Tháng ba", "Tháng tư", "Tháng năm", "Tháng sáu", "Tháng bảy", "Tháng tám", "Tháng chín", "Tháng mười", "Tháng mười một", "Tháng mười hai"],
        monthNamesShort: ["Một", "Hai", "Ba", "Tư", "Năm", "Sáu", "Bảy", "Tám", "Chín", "Mười", "Mười một", "Mười hai"],
        dayNames: ["Chủ nhật", "Thứ hai", "Thứ ba", "Thứ tư", "Thứ năm", "Thứ sáu", "Thứ bảy"],
        dayNamesShort: ["CN", "Hai", "Ba", "Tư", "Năm", "Sáu", "Bảy"],
        dayNamesMin: ["CN", "T2", "T3", "T4", "T5", "T6", "T7"],
        weekHeader: "Tuần",
        dateFormat: "dd/mm/yy",
        firstDay: 1,
        isRTL: false,
        showMonthAfterYear: false,
        yearSuffix: "",
      };

      let _en = {
        closeText: "Close",
        prevText: "Previous",
        nextText: "Next",
        currentText: "Today",
        monthNames: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
        monthNamesShort: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
        dayNames: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
        dayNamesShort: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
        dayNamesMin: ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"],
        weekHeader: "Week",
        dateFormat: "yy/mm/dd",
        firstDay: 1,
        isRTL: false,
        showMonthAfterYear: false,
        yearSuffix: "",
      }
      if(_currentLang == "vi"){
        return _vi
      }
      return _en
    }
  },
  Money:{
    formatMoney(value, currency){
      let _value = value;
      _value = Numberal(value).format('0,0.[0]')
      if(currency){
        _value = _value + currency;
      }
      return _value;
    },
    formatMoneyForAjuma(value){
      let _value = value;
      Numberal.localeData().delimiters.thousands = '.';
      Numberal.localeData().delimiters.decimal = ',';
      _value = Numberal(value).format('0,0[.][0]')
      _value = `${_value} ₫`;

      return _value;
    },
    makeShortenMoneyString(value){
      let _value = 0;
      if(value){
        let _divide = value/1000;
        if(_divide >= 1 && _divide<1000){
          _value = Numberal(value).format('0 a')
        }
        else if(_divide >=1000){
          _value = Numberal(value).format('0.0a')
        }
        else{
          _value = value
        }
      }
      return _value;
    },
  },
  expandFormatRepetitions(format) {
    return format.reduce(function __reducePatterns(patterns, nextItem) {
      if (nextItem.repeat > 1) {
        const expanded = [];
        const copy = { ...nextItem };
        delete copy.repeat;
        for (let i = 0; i < nextItem.repeat; i += 1) {
            expanded.push({ ...copy });
        }
        return [...patterns, ...expanded];
      }
      return [...patterns, nextItem];
    }, []);
  },
  formatValue(value, formatSpec = []) {
    const format = Format.expandFormatRepetitions(formatSpec);
    if (format.length > 0) {
        const characters = value.split("");
        let formattedValue = "",
            rawValue = "";
        while (format.length > 0 && characters.length > 0) {
            const pattern = format.shift();
            if (pattern.char) {
                let charRexp;
                if (typeof pattern.char === "object" && typeof pattern.char.test === "function") {
                    charRexp = pattern.char;
                } else if (Array.isArray(pattern.char) && pattern.char.length >= 1) {
                    const [rexp, mod = ""] = pattern.char;
                    charRexp = new RegExp(rexp, mod);
                } else if (typeof pattern.char === "string") {
                    charRexp = new RegExp(pattern.char);
                } else {
                    throw new Error(`Invalid pattern provided: ${pattern.char}`);
                }
                while (characters.length > 0 && charRexp.test(characters[0]) !== true) {
                    characters.shift();
                }
                if (characters.length > 0) {
                    formattedValue += characters[0];
                    rawValue += characters[0];
                    characters.shift();
                }
            } else if (typeof pattern.exactly === "string") {
                if (pattern.exactly.length !== 1) {
                    throw new Error(
                        `Unable to format value: 'exactly' value should be of length 1: ${pattern.exactly}`
                    );
                }
                formattedValue += pattern.exactly;
                if (pattern.exactly === characters[0]) {
                    characters.shift();
                }
            } else {
                throw new Error(
                    `Unable to format value: Invalid format specification: ${JSON.stringify(
                        pattern
                    )}`
                );
            }
        }
        return { formatted: formattedValue, raw: rawValue };
    }
    return { formatted: value, raw: value };
  }
}

window.HFormat = Format;
export default Format;
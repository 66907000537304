import React from 'react'
import CSuppense from '../MyCore/CSuspense'
import HUtils from '../../helpers/Utils'

const MIHambuger = React.lazy(() => import('./Types/hambuger'));
const MIUserchange = React.lazy(() => import('./Types/userchange'));
const MIDeselectall = React.lazy(() => import('./Types/deselectall'));
const MISelectall = React.lazy(() => import('./Types/selectall'));
const MI_poweroff = React.lazy(() => import('./Types/poweroff'));
const MyIconTypes = {
  addType(key,fnUI){
    if(MyIconTypes.Types[key]){
      console.warn("Type exist:",key);
    }
    else{
      MyIconTypes.Types[key]=fnUI;
    }
  },
  Types:{   
    hambuger: (props)=>{ return CSuppense(props,MIHambuger) },
    deselectall: (props)=>{ return CSuppense(props,MIDeselectall) },
    selectall: (props)=>{ return CSuppense(props,MISelectall) },
    userchange: (props)=>{ return CSuppense(props,MIUserchange) },
    poweroff: (props)=>{ return CSuppense(props,MI_poweroff) },
  }
}
if(window._M){
  window._M.MyIconTypes = MyIconTypes;
}
export default MyIconTypes;
import React, { Component } from 'react';
import { HConfig, HConstant } from '../../_import';
import PageGeneric from '../Generic/Page';
import Category from './Category';
import MyPageFromConfig from '../../components2/MyPage/MyPageFromConfig';

class CTableOne extends Component {
  constructor(props) {
    super(props);
    this.state={
      styleExpand:{},
    };
  }

  _getQuery=()=>{
    const {row,detailConfig,config} = this.props;
    let _query = {};
    if(detailConfig && detailConfig.queryFromParent){
      let _keys = Object.keys(detailConfig.queryFromParent);
      for(let k of _keys){
        _query[k] = row[detailConfig.queryFromParent[k]];
      }
    }
    if(config && config.queryFromParent){
      let _keys = Object.keys(config.queryFromParent);
      for(let k of _keys){
        _query[k] = row[config.queryFromParent[k]];
      }
    }
    // console.warn("_getQuery",_query,row);
    return _query;
  }

  _requestUpdateMaster=()=>{
    const {row,requestUpdateMaster} = this.props;
    console.log("_requestUpdateMaster");
    if(requestUpdateMaster){
      requestUpdateMaster(row);
    }
  }

  handleHideCol=(listCols,opts)=>{    
    if(this._cCategory && this._cCategory.handleHideCol){
      // console.warn("handleHideCol:",listCols,opts);
      this._cCategory.handleHideCol(listCols,opts)
    }
  }

  render(){
    const {row,detailConfig,isWithProjectId,projectId,config,pageMatch,getOptions,fnList} = this.props;
    const {title,titleField,screenCode,controller} = config;
    let _title='Details';
    if(title){
      _title=title;
    }
    if(titleField){
      _title=row[titleField];
    }
    // console.warn(`TableOne render:`,this.props,config);
    if(config.mypage){
      return (
        <MyPageFromConfig configPage={config.mypage} fnList={fnList}/>
      )
    }
    else if(config.uiType){
      return (
        <PageGeneric 
          projectId={projectId} 
          screenCode={screenCode||"ScreenCodeDetail"}  
          pageMatch={pageMatch}
          configPage={{//bo sung configPage gia
            ApiController: controller||"Details",
            RequestData: this._getQuery(),
            ConfigObj: config.configObj || config.configOnServer,//uu tien su dung ConfigObj
          }}                 
          configUI={{
            Title: 'Pages',
            Type: config.uiType,   
            Config:{
              ApiController: controller||"Details",
              ParentData:row,
              RequestData: this._getQuery(),
              ConfigOnServer: config.configOnServer
            }         
          }}
          match={{
            params:{
              id: projectId
            }
          }}
          />
      )
    }
    return (      
      <Category 
        ref={r=> {this._cCategory=r;}}
        projectId={projectId}
        parentData={row}
        pageMatch={pageMatch}
        isDetail={true}
        fnList={{
          ...fnList,
          fnRequestUpdateMaster: this._requestUpdateMaster,
          fnCBAddNew: ()=>{
            if(detailConfig.needReloadMaster || config.needReloadMaster ||
              detailConfig.needReloadMasterAfterAddNew || config.needReloadMasterAfterAddNew){
              this._requestUpdateMaster();
            } 
          },
          fnCBUpdateField: ()=>{
            if(
              detailConfig.needReloadMaster || config.needReloadMaster ||
              detailConfig.needReloadMasterAfterUpdateField || config.needReloadMasterAfterUpdateField){
              this._requestUpdateMaster();
            }            
          },
          fnCBDeleteItem: ()=>{
            if(
              detailConfig.needReloadMaster || config.needReloadMaster ||
              detailConfig.needReloadMasterAfterDeleteItem || config.needReloadMasterAfterDeleteItem){
              this._requestUpdateMaster();
            }                  
          },
          fnCBRequestList: (data,error,response,opts)=>{
            if(
              detailConfig.needReloadMaster || config.needReloadMaster ||
              detailConfig.needReloadMasterAfterList || config.needReloadMasterAfterList){
              if(opts==null || opts.firstLoad!=true){
                this._requestUpdateMaster();
              }              
            }                  
          },
          fnGetOptionsMaster: ()=>{
            if(getOptions){
              return getOptions();
            } 
          },
          fnGetOptionsExtraInMaster: ()=>{
            if(detailConfig.userOptionsInMaster && detailConfig.userOptionsInMaster.length>0 && getOptions){
              let _optionsMaster = getOptions();
              let _optionsExtra = {};
              if(_optionsMaster){
                for(let k of detailConfig.userOptionsInMaster){
                  if(_optionsMaster[k]){
                    _optionsExtra[k] = _optionsMaster[k];
                  }
                }
                return _optionsExtra;
              }
            }
          },
          fntdGetExpandLevel: ()=>{
            let _parentLevel = HConstant.rf(fnList,HConstant.UIFnTableDetail.fntdGetExpandLevel,[]);
            if(_parentLevel!=null){
              return _parentLevel + 1;
            }
            console.warn("fntdGetExpandLevel:",_parentLevel);
            return 1;//default la 1
          }
        }}
        title={_title} 
        screenCode={screenCode||"ScreenCodeDetail"}         
        controller={controller||"Details"}
        config={{
          Query: this._getQuery(),
          Page:{
            isNotChangePageTitle: true,
            isNotShowLoading: true,
            isWithProjectId: isWithProjectId,                  
          },
          Header:{
            show: true,
            showAddNew: false,
            showPrint: false,
            showProjectTitle: false,
            isShadow: false,
            onlyButtonList: HConfig.DefaultValues.detailHeaderOnlyButtonList,
          },
          Filter:{
            show: false
          },
          Table:{
            optionsTable:{
              // paginationShowsTotal: false,
              // hideSizePerPage: true,
            },
            tableProps:{
              pagination: false,// Khong an, vi se bi overide boi config options tu api
            },
          },
        }}
        configController={this.props.configController}
      />
    )    
  }
}
class CTable extends Component {
  constructor(props) {
    super(props);
    this.state={
      isShow: false,
      isAddNew: false,
      extraData: null,
      cols:[],
      error: null,
      isLoading: true,
      data: null,
      // isShowMulti: {},
      // configMulti: {},
      listUI: {},
      listRef: {},
      moreKeyForReExpand: null,
    };
    // console.warn('TableDetailApi',this.props,this.props.row);
    const {row} = this.props;
    if(row['_isExpandMulti'] == null){
      row['_isExpandMulti'] = {};
    }
    if(row['_configMulti'] == null){
      row['_configMulti'] = {};
    }
    if(row.UI_DefaultShowExpand && typeof row.UI_DefaultShowExpand =='string'){
      let _keys = row.UI_DefaultShowExpand.split(';');
      if(_keys && _keys.length>0){
        for(let k of _keys){
          // this.state.isShowMulti[k] = true;
          row['_isExpandMulti'][k] = true;
        }
      }
    }
  }

  componentDidMount(){
  }

  isShowed=({row,item})=>{
    // const {isShowMulti}=this.state;
    if(item){
      if (item.list && item.list.length>0){//showAll
        let _rs = true;
        for(let i=0;i<item.list.length;i++){
          let _key = item.list[i].key || item.list[i].screenCode || 'nokey';
          // if(isShowMulti && isShowMulti[_key]!=true)
          if(row['_isExpandMulti'] && row['_isExpandMulti'][_key]!=true)
          {
            return false; //chỉ cần 1 cái sai là return sai
          }
        }
        return _rs;
      }else{
        let _key = item.key || item.screenCode || 'nokey';
        // if(isShowMulti && isShowMulti[_key]==true)
        if(row['_isExpandMulti'] && row['_isExpandMulti'][_key]==true)
        {
          // console.warn('isShowed:',item,isShowMulti);
          return true;
        }
      }
    }     
    return false;
  }

  show=(opts)=>{
    // console.warn('opts: ', opts);
    const {row,options,extraData}=this.props;
    const {cc,item} = opts;    
    if(opts.force==true){
      _isShow = true;
    }
    // let _configMulti = this.state.configMulti;
    // let _isShowMulti = this.state.isShowMulti;  
    if(row['_isExpandMulti'] == null){
      row['_isExpandMulti'] = {};
    }
    if(row['_configMulti'] == null){
      row['_configMulti'] = {};
    }
    if(item){
      if (item && item.list && item.list.length>0){
        if(item.isRow==true){
          //Trong đây không setState được vì đang thực hiện, ở cuối hàm đã có setState
          this.state.styleExpand = {display:'flex', flexDirection:'row'};
          // this.setState({
          //   styleExpand:{display:'flex', flexDirection:'row'}
          // })
        }  
        else{
          this.state.styleExpand = {display:'flex', flexDirection:'column'};
        }      
        for (let i=0;i<item.list.length;i++){
          let _item = item.list[i];
          let _key = _item.key || _item.screenCode || 'nokey';
          // _configMulti[_key] = _item;
          row['_configMulti'][_key] = _item;

          // if(_isShowMulti[_key]==null){
          //   _isShowMulti[_key] = true;
          // }
          // else{
          //   _isShowMulti[_key] = !_isShowMulti[_key];
          // }
          if(row['_isExpandMulti'][_key]==null){
            row['_isExpandMulti'][_key] = true;
          }
          else{
            row['_isExpandMulti'][_key] = !row['_isExpandMulti'][_key];
          }
        }
      }else{
        let _key = item.key || item.screenCode || 'nokey';
        // _configMulti[_key] = item;
        row['_configMulti'][_key] = item;

        // if(_isShowMulti[_key]==null){
        //   _isShowMulti[_key] = true;
        // }
        // else{
        //   _isShowMulti[_key] = !_isShowMulti[_key];
        // }
        if(row['_isExpandMulti'][_key]==null){
          row['_isExpandMulti'][_key] = true;
        }
        else{
          row['_isExpandMulti'][_key] = !row['_isExpandMulti'][_key];
        }
      } 
    }
    let _isShow = false;
    // if(_isShowMulti && Object.keys(_isShowMulti).length>0){
    //   for(let k of Object.keys(_isShowMulti)){
    //     if(_isShowMulti[k]==true){
    //       _isShow = true;
    //       break;
    //     }
    //   }
    // }
    if(row['_isExpandMulti'] && Object.keys(row['_isExpandMulti']).length>0){
      for(let k of Object.keys(row['_isExpandMulti'])){
        if(row['_isExpandMulti'][k]==true){
          _isShow = true;
          break;
        }
      }
    }
    if (row['_configMulti']){
      if (item.isRow != null){
        row['_configMulti']['_isRow'] = item.isRow;
      }
    }
    this.setState({
      isShow: _isShow,
      isLoading: false,
      // configMulti: _configMulti,
      // isShowMulti: _isShowMulti,
    },()=>{
      // row['_isExpand'] = this.state.isShow;
      if(this.state.isShow){
        this.reload();
      }
      if(opts!=null && opts.cc!=null){
        opts.cc.forceUpdate();
      }
    })
  }

  reload(){
    // this._onRequestList();    
  }

  reExpand=()=>{
    // this._onRequestList();
    const me = this;
    let _key = 0;
    if(this.state.moreKeyForReExpand!=null){
      _key = this.state.moreKeyForReExpand + 1;      
    }
    me.setState({
      moreKeyForReExpand: _key
    },()=>{
      me.setState({
        moreKeyForReExpand: null
      })      
    })
  }

  _getQuery=()=>{
    const {row,detailConfig} = this.props;
    let _query = {};
    if(detailConfig.queryFromParent){
      let _keys = Object.keys(detailConfig.queryFromParent);
      for(let k of _keys){
        _query[k] = row[detailConfig.queryFromParent[k]];
      }
    }
    return _query;
  }

  _renderOne=()=>{

  }

  _getCanShow=(keyScreen)=>{
    const {row,detailConfig,isWithProjectId,projectId} = this.props;
    let _canShow = true;
    if(_canShow===true){
      if(row.UI_ConfigExpand && row.UI_ConfigExpand.Disable){
        if(row.UI_ConfigExpand.Disable.indexOf(keyScreen)>-1){
          _canShow = false;
        }
      }
    }
    return _canShow;
  }
  _renderMulti=()=>{    
    const {row,detailConfig,isWithProjectId,projectId,requestUpdateMaster,getOptions,fnList} = this.props;
    const {isShowMulti,configMulti,listRef} = this.state;
    let listUI = this.state;
    listUI = [];
    let _ui = [];
    let _keys = [];
    let _configMulti = row['_configMulti'];
    if(row['_configMulti']){
      _keys = Object.keys(row['_configMulti']);    
    }
    // console.warn('_renderMulti key, row: ', _keys, row);    
    if(_keys && _keys.length>0){
      for(let k of _keys)
      {
        if(this._getCanShow(k)==false){
          continue;
        }
        // if(isShowMulti[k]==true){
        if(row['_isExpandMulti'] && row['_isExpandMulti'][k]==true){
          // console.warn("render TableOne",k,row);
          let _config = _configMulti[k];
          let _index = _config.index!=null?_config.index:0;
          if(listUI[_index] == null){
            listUI[_index] = {};
          }
          if(listUI[_index][k]==null){
            let _bgColor = HConstant.gc(HConstant.AppConfig.expandBgColor);          
            let _hideArrow = false;
            let _iconArrow = "fa-angle-double-right";
            let _padding = '5px';
            if(detailConfig){
              if(detailConfig.bgColor){
                _bgColor = detailConfig.bgColor;
              }
              else if(detailConfig.bgF){
                _bgColor = row[detailConfig.bgF];
              }              
            }
            if(_config.bgColor){
              _bgColor = _config.bgColor;
            }
            else if(_config.bgF){
              _bgColor = row[_config.bgF];
            }
            if(_config.hideArrow==true){
              _hideArrow=true;
            }
            if(_config.iconArrow){
              _iconArrow=_config.iconArrow;
            }
            if(_config.padding) {
              _padding =_config.padding;
            }
            // console.warn('_bgColor:',_bgColor,_config);
            let _styleItem = {
              padding:_padding,
              backgroundColor:_bgColor,
              position:"relative",
            };
            let _styleArrow = {
              paddingLeft:'5px',paddingRight:'5px',color:'#20a8d8',marginRight:'1px',fontSize:'30px'
            }
            if(_config.styleExpand){
              _styleItem = Object.assign(_styleItem,_config.styleExpand);
            }
            else if(_config.style){//style se bi nham lan voi style cell
              _styleItem = Object.assign(_styleItem,_config.style);
            }
            if(_config.styleArrow){
              _styleArrow = Object.assign(_styleArrow,_config.styleArrow);
            }
            if(row && row['_configMulti'] && row['_configMulti']['_isRow']==true){
              if(_styleItem.width==null){
                _styleItem.flex = 1;
              }              
            }
            let _keyUI = k;
            if(detailConfig.prefixFieldKeyUI!=null){
              let _arr = detailConfig.prefixFieldKeyUI.split(",");
              let _newKeyUI = "";
              for(let f of _arr){
                if(row[f]!=null){
                  _newKeyUI += `${row[f].toString()}_`;
                }
              } 
              _newKeyUI += k;        
              _keyUI = _newKeyUI;
              // console.warn("newKeyUI",_keyUI);     
            }
            listUI[_index][k] = (
              <div key={_keyUI} className="div-expand-row" style={_styleItem}>
                {
                  //hideArrow=flase thì hiển thị mũi tên bên trái table
                  //Bỏ mũi tên bên trái table khi show all detail trên 1 hàng: thêm trong more hideArrow=true
                  _hideArrow == false &&
                  <div style={_styleArrow} className="div-expand-arrow"> <i className={`fa ${_iconArrow}`}/> </div>
                }                
                 <CTableOne 
                  ref={r=>{listRef[k]=r;}}
                  row={row} 
                  parentData={row}
                  detailConfig={detailConfig} 
                  getOptions={getOptions}
                  pageMatch={this.props.pageMatch}
                  isWithProjectId={isWithProjectId} projectId={projectId}
                  requestUpdateMaster={requestUpdateMaster}
                  config={_configMulti[k]}
                  configController={this.props.configController}
                  fnList={fnList}
                />
              </div>             
            )
          }
          // _ui.push(
          //   listUI[k]
          // );
        }
      }
    }
    if(listUI && listUI.length>0){
      for(let i =0;i<listUI.length;i++){
        let _item = listUI[i];
        if(_item && typeof _item=='object'){
          let _keys = Object.keys(_item);
          for(let k of _keys){
            _ui.push(
              _item[k]
            );
          }
        }
      }
    }
    return _ui;
  }
  handleHideCol=(listCols,opts)=>{
    const {listRef} = this.state;
    if(listRef){
      let _keys = Object.keys(listRef);
      if(_keys && _keys.length>0){
        for(let k of _keys){
          if(listRef[k] && listRef[k].handleHideCol){
            listRef[k].handleHideCol(listCols,opts)
          }
        }
      }
    }
  }
  render() {    
    const {row,detailConfig,isWithProjectId,projectId,} = this.props;
    const {moreKeyForReExpand} = this.state;
    // console.log('render Multi:',row);
    let _styleExpand = {display:'flex', flexDirection:'column'};
    if(detailConfig && detailConfig.isRow==true && row){//Bo sung isRow trong detailConfig (Dung cho nut expandAll)
      if(row['_configMulti']){
        row['_configMulti']['_isRow']=true;
      }
      else{
        row['_configMulti'] = {
          _isRow: true
        }
      }
    }
    if(row && row['_configMulti'] && row['_configMulti']['_isRow']==true){
      _styleExpand = {display:'flex', flexDirection:'row', flexWrap:'wrap'};
    }
    let _more = {};
    if(moreKeyForReExpand){
      _more.key = moreKeyForReExpand;
    }
    if(moreKeyForReExpand!=null){
      return (
        <div className="div-expand" style={_styleExpand} {..._more}>
        </div>
      )
    }    
    return (
      <div className="div-expand" style={_styleExpand} {..._more}>        
      {
        this._renderMulti()
      }
      </div>
    )
  }
}

export default CTable;
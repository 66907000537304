import React from "react"
import { MyFormControlHelpers, MyUIHelpers } from "@macashipo/mlib";
const MFC_number_percent = React.lazy(() => import("./Types/number_percent"));
const MFC_number_decimal = React.lazy(() => import("./Types/number_decimal"));
const List = {
  number_percent: MFC_number_percent,
  number_decimal: MFC_number_decimal
};
const ListMyUIMapCell = {
  fc_number_percent: MFC_number_percent,
  fc_number_decimal: MFC_number_decimal,
}

const MyTableCellOne = {
  init() {
    MyFormControlHelpers.addTypesFromListLazy(List);
    MyUIHelpers.addTypesFromListLazy(ListMyUIMapCell)
  },
};

export default MyTableCellOne;

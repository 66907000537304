import React from "react"
import { MyTableCellHelpers, MyUIHelpers } from "@macashipo/mlib";
const MTC_select_row_action = React.lazy(() => import("./Types/select_row_action"));
const MTC_select_score = React.lazy(() => import("./Types/select_score"));
const MTC_number_percent = React.lazy(() => import("./Types/number_percent"));
const MTC_number_numeral = React.lazy(() => import("./Types/number_numeral"));
const MTC_div_editable = React.lazy(() => import("./Types/div_editable"));
const MTC_html_editor = React.lazy(() => import("./Types/html_editor"));

//shared
const MTC_cell_loading = React.lazy(() => import("./Types/_cell_loading"));
const List = {
  select_row_action: MTC_select_row_action,
  select_score: MTC_select_score,
  number_percent: MTC_number_percent,
  number_numeral: MTC_number_numeral,
  div_editable: MTC_div_editable,
  html_editor: MTC_html_editor,
};
const ListMyUIMapCell = {
  cell_select_score: MTC_select_score,
  cell_number_percent: MTC_number_percent,
  cell_number_numeral: MTC_number_numeral,
  cell_div_editable: MTC_div_editable,
  cell_html_editor: MTC_html_editor
}
const ListMyUISharedCell = {
  _cell_loading: MTC_cell_loading
}
const MyTableCellOne = {
  init() {
    MyTableCellHelpers.addTypesFromListLazy(List);
    MyUIHelpers.addTypesFromListLazy(ListMyUIMapCell)
    MyUIHelpers.addTypesFromListWithoutLazy(ListMyUISharedCell)
  },
};

export default MyTableCellOne;

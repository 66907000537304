import React from 'react';
import * as H from './Helpers';
import PageExt from './PageExt';

/**
 * PageGeneric.
 *
 * @class PageGeneric
 * @static
 * @namespace PageGeneric
 * 
 */
class PageGeneric extends PageExt {
  constructor(props) {
    super(props);
    this.state = {};

    this._projectId = this.getProjectId();
    this._screenCode = this.getScreenCode();
    this._listRefs = {};
  }

  // _sampleConfigUI=()=>{
  //   this._configUI = {
  //     Title: 'Page',
  //     Config:{
  //     },
  //     Contents: [
  //       {
  //         Type: 'tab',
  //         Config:{
  //         },
  //         Contents: [

  //         ],
  //       }
  //     ]
  //   };
  // }

  componentDidMount(){
    // console.warn('Page componentDidMount',this);
  }

  _renderContentWithType=(configOfContent,index)=>{
    
  }

  _renderContents=()=>{
    let _configUI = this.getConfigUI();
    let _ui= [];
    if(_configUI && _configUI.Contents){
      for(let i in _configUI.Contents){
        let _content = _configUI.Contents[i];
        if(H.PageGenericType.Types[_content.Type]!=null){
          _ui.push(
            H.PageGenericType.Types[_content.Type](_content,
              this.getMorePropsShared(),
              {
                //opts
                index: i,
                listRefs: this._listRefs,   
                parent: this,   
              })
          );
        }
        else{
          console.warn('Page type not exist:',_content.Type);
        }
      }
    }
    return _ui;
  }

  render() {    
    let _configUI = this.getConfigUI();
    if(_configUI.Type && _configUI.Type!="page"){
      if(H.PageGenericType.Types[_configUI.Type]){
        return (
          H.PageGenericType.Types[_configUI.Type](_configUI,
            this.getMorePropsShared(),
            {
              listRefs: this._listRefs,   
              parent: this,           
            })
        )
      }
      else{
        return (
          <div className="animated"> 
            {`Page UIType: ${_configUI.Type} not config!`}
          </div>
        )
      }      
    }
    return (
      <div className="animated"> 
      {
        this._renderContents()
      }
      </div>
    )
  }
}

export default PageGeneric;

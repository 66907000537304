import React, { Component } from 'react';
import {
  FormGroup,InputGroup, InputGroupAddon, InputGroupText,
  ColFilter,
  MyModal, MyUI,
  HUtils, Button
} from './_import';

class CFSwitch extends Component {
  constructor(props) {
    super(props);
    this.state = { 
      value: null,
    };
  }
  static getDerivedStateFromProps(props, state) {
    // console.warn("getDerivedStateFromProps",props,state); 
    if(props && props.values){
      let _count = Object.keys(props.values).length;
      if(state.needUpdate==true){
        return {
          needUpdate: false
        }
      }
      if(_count==0 && state.value){
        // console.warn("hello ihere",_count,props.values);
        return {
          value: null
        }
      }      
    }
    return null;
  }
  _updateValue=(value)=>{
    const {onChange} = this.props;
    // console.warn("_updateValue:",value);
    let _value = [];
    if(value!=null && typeof value=="string"){
      let _valueString = value.toString();
      _value = _valueString.split(',');
    }
    else if(Array.isArray(value)){
      _value = value;
    }
    let _obj = { type: 'ArrayFilter', value: _value};   
    this.setState({
      value,
      needUpdate: true,
    },()=>{
      if(onChange){
        onChange({
          value: _value,
          filter: _obj
        });
      }
    })
  }
  _getConfigPanigation=()=>{
    const {item} = this.props;
    let _configPagination = HUtils.Obj.get(item,"moreObj.configPagination");
    if(_configPagination!=null){
      return _configPagination;
    }
    return null
  }
  _getConfigApi=()=>{
    return null
  }
  _getIsMulti=()=>{
    const {item} = this.props;
    let _multi = HUtils.Obj.get(item,"moreObj.multi");
    // console.warn("multi:",_multi);
    if(_multi===true){
      return _multi;
    }
    return false
  }
  _getCurrentValue=()=>{
    return this.state.value
  }
  _getCurrentArrayText=()=>{
    let _values = this._getCurrentValue();
    let _options = this._getOptions();
    if(_values && _values.length>0){
      let _arr = [];
      if(HUtils.isArray(_values)){
        _arr = _values;
      }else if(HUtils.isString(_values)){
        _arr = _values.split(',');
      }
      let _arrDisplay = [];
      for(let k of _arr){
        for(let i=0;i<_options.length;i++){
          let _option = _options[i];
          if(_option.Value==k){
            _arrDisplay.push(_option.Text);
            continue;
          }          
        }        
      }
      return _arrDisplay;
    }
    return [];
  }
  _getOptions=()=>{
    const {options,item} = this.props;
    let _optionKey = HUtils.Obj.get(item,'optionKey');
    if(options && _optionKey && options[_optionKey]){
      return options[_optionKey]
    }
    return [];
  }
  _getTitle=()=>{
    const {options,item} = this.props;
    // console.warn("title:",item);
    return item.title || "Select"
  }
  _getTextDisplay=()=>{
    let _arrDisplay = this._getCurrentArrayText();
    let _ui = [];
    let _class = "sp-btn-blue";
    if(_arrDisplay && _arrDisplay.length>0){        
      for (let i in _arrDisplay){
        _ui.push(
          <span className={_class} key={i}>
            {_arrDisplay[i]}
          </span>
        )
      }
    }    
    return _ui;
  }
  _focusFilterSearch=()=>{
    // let _input = document.querySelector("th[data-field='Text'] input");
    let _input = document.querySelector("input.mct-select2_modal");
    if (_input!=null){
      _input.focus();
    }
  }
  _renderModalContent=(optionsSelect)=>{
    let _configPagination = this._getConfigPanigation();
    let _configApi = this._getConfigApi();
    let _multi = this._getIsMulti();
    setTimeout(this._focusFilterSearch,100);
    
    return(
      <MyUI type="select2box" 
        showFilter ={true}
        valueSelect={this._getCurrentValue()}
        options={optionsSelect} 
        showClearSelect={true}
        multi={_multi}
        configPagination={_configPagination}
        configApi={_configApi}
        clickClearSelect={()=>{
          MyModal.Helper.hide();
          this._updateValue("");
        }}
        showButtonsOK={_multi===true}
        // value={_valueSelect}
        onOK={(newValue)=>{
          this.setState({
            value: newValue,
          },()=>{            
            MyModal.Helper.hide();    
            this._updateValue(newValue);                 
          })
        }}
        onChange={(newValue)=>{
          if(_multi!==true){
            this.setState({
              value: newValue,
            },()=>{            
              MyModal.Helper.hide();    
              this._updateValue(newValue);                     
              console.warn("udpate value:",newValue);            
            })
          }
      }}/>
    )
  }

  _renderContent=()=>{
    const {item,onClear,onChange,values,cFilter,options} = this.props;
    const {title,fieldName,cellProps} = item;
    
    let _moreProps = {};
    _moreProps = Object.assign(_moreProps,cellProps);
    let _value = this.state.value;
    if(_value==null){
      // if(values && values[fieldName]){
      //   _value = values[fieldName];
      // }
      _value = false;
    }

    return (
      <FormGroup>
        <InputGroup>
          <InputGroupAddon addonType="prepend">
            <InputGroupText style={{padding:'3px'}}>
              {title}              
            </InputGroupText>            
          </InputGroupAddon>
          <div style={{flex:1,minHeight:'35px',cursor:'pointer',alignItems:'center',paddingLeft:'5px',paddingRight:'5px',border:'1px solid #c2cfd6',display:'flex',flexDirection:'row',flexWrap:'wrap'}} onClick={()=>{
            console.log('on Click');   
            MyModal.Helper.show(this._renderModalContent(this._getOptions()),{
              key: 'filter_select2_modal',
              title: this._getTitle(),
              className: 'modal-lg',
              // ..._configModal
            })             
          }}>
            {this._getTextDisplay()}
          </div>     
          {
            _value!=null && _value!="" &&
            <div>
              <Button color="danger" onClick={()=>{
                this.setState({
                  value: "",
                },()=>{
                  this._updateValue("");
                })
              }}><i className="fa fa-ban"></i></Button>
            </div>
          } 
        </InputGroup>
      </FormGroup>      
    )
  }
  render(){
    const {item,index} = this.props;
    let _ui = this._renderContent();
    // console.warn("render:",this.props);
    return (
      <ColFilter key={index} component={_ui} {...item.col}/>
    )
  }
}

export default CFSwitch
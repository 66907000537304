import React from 'react';

const ColFilter = (props) =>{
  let _classNameItem = "col-12 col-sm-6 col-md-4";
  // console.warn("col filter:",props);
  let _configItem = props.configItem || {};
  if(props.isFull ==true || _configItem.isFull ==true || (_configItem.moreObj && _configItem.moreObj.isFull===true) ){
    _classNameItem = "col-12"
    return (
      <div className={_classNameItem}>{props.component}</div>
    )
  }
  // console.warn("configFilter:",props,props.configFilter);
  if(props.configFilter && props.configFilter.classNameItem){
    _classNameItem = props.configFilter.classNameItem;
  }
  return (
    <div className={_classNameItem}>{props.component}</div>
  )
}

export default ColFilter;
//khac voi source mobile
import React from 'react';
import HUI from '../../helpers/UI';
class MyLoadingApp extends React.Component {    
  constructor(props){
    super(props);
    this.state = {
      show: false,
      config: null,
    }
  }
  render(){
    return (<div></div>)
  }
}
MyLoadingApp.Helpers = {
  component: null,
  hide(){
    HUI.LoadingScreen.hide();
  },
  show(opts={}){    
    if(opts.notShowLoadingApp==true){return;}
    HUI.LoadingScreen.show();
  },
}
export default MyLoadingApp
import React from "react";
import {
  HC
} from "../_import";
class AvatarV3 extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    if(HC.rc(HC.AppFnList.getAuthIsLogged,[])){
      let _imgAvatar = HC.rc(HC.AppFnList.getCurrentUserAvatar,[]);
      if(_imgAvatar){
        return (
          <img src={_imgAvatar} style={{width:"100%",height:"100%"}}/>
        )
      }
    }
    //if nothing
    return (
      <img src="img/avatars/0.jpg" style={{width:"100%",height:"100%"}}/>
    )
  }
}
export default AvatarV3;

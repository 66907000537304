import {Switch, Route, Redirect} from 'react-router-dom';
// import createHistory from "history/createBrowserHistory"

// import Global from './Global';
import HGlobalSetting from './GlobalSetting';
import HLink from './Link';
import HUI from './UI';
import HUtils from './Utils';
import Api from '../services/account';
// import {FHelper} from '../libs/Firebase';

const AUTH_ACCESS_TOKEN_KEY = 'AUTH_ACCESS_TOKEN_KEY';
const AUTH_TYPE = 'AUTH_TYPE';
const AUTH_INFO = 'AUTH_INFO';
const AUTH_AVATAR = 'AUTH_AVATAR';
const CONFIGS = 'CONFIGS';
const PROJECT_SCREENS = 'PROJECT_SCREENS';
const AUTH_TYPE_LIST = {
  normal: 'normal',
  google: 'google',
}

const GOOGLE_ACCESS_TOKEN_KEY = 'GOOGLE_ACCESS_TOKEN_KEY';
const GOOGLE_ACCESS_EMAIL = 'GOOGLE_ACCESS_EMAIL';
const GOOGLE_IMG_URL = 'GOOGLE_IMG_URL';
const GOOGLE_ACCESS_LAST_TIME = 'GOOGLE_ACCESS_LAST_TIME';

// const history = createHistory();
export const Auth = {
  HConfig: null,
  access_token: null,
  auth_type: null,
  auth_info: null,
  afterLogout: {},
  initAuth({HConfig}={}){
    Auth.HConfig = HConfig;
  },
  getName(){
    if(this.auth_info!=null){
      return this.auth_info.name;
    }
  },
  getUserId(){
    if(this.auth_info!=null){
      return this.auth_info.user_id;
    }
  },
  getIsLogged(){
    if(Auth.access_token){
      return true;
    }
    return false;
  },
  getImgUrl(){
    let _authAvatar = localStorage.getItem(AUTH_AVATAR);
    if(_authAvatar && _authAvatar.startsWith('http')){
      return _authAvatar;
    }
    let _googleImg = localStorage.getItem(GOOGLE_IMG_URL);
    if(_googleImg!=null){
      return _googleImg;
    }
    return 'img/avatars/0.jpg';
  },
  getAppLogo(){
    return HUtils.Obj.get(Auth.auth_info,"appLogo_url");
    // if(Auth.authInfo.appLogo_url){ return Auth.authInfo.appLogo_url; }
  },
  registerAfterLogout(key,fn){
    if(key && fn){
      Auth.afterLogout[key] = fn
    }
  },
  checkLastLogin(isGoHome){
    // console.log('checkLastLogin:',isGoHome);
    let _token = localStorage.getItem(AUTH_ACCESS_TOKEN_KEY);
    let _type = localStorage.getItem(AUTH_TYPE);
    let _info = localStorage.getItem(AUTH_INFO);
    if(_token!=null) this.access_token = _token;
    if(_type!=null) this.auth_type = _type;
    if(_info!=null) this.auth_info = JSON.parse(_info);
    if(_token!=null && isGoHome==true){
      HLink.push('/');
      return true;
    }
    return false;
  },
  loginSuccess(info,access_token,token_type,auth_type){
    this.access_token = token_type + ' '+ access_token;
    this.auth_type = auth_type;
    this.auth_info = info;

    localStorage.setItem(AUTH_ACCESS_TOKEN_KEY, this.access_token);
    localStorage.setItem(AUTH_TYPE, this.auth_type);
    localStorage.setItem(AUTH_INFO,JSON.stringify(info));
    localStorage.setItem(CONFIGS, info['configs']);
    localStorage.setItem(PROJECT_SCREENS, info['project_screens']);
    if(info.avatar_url){
      localStorage.setItem(AUTH_AVATAR, info.avatar_url);
    }
    // FHelper.logToken(info.user_id,access_token);
    console.log('loginSuccess:',this);
    HGlobalSetting.afterLogin();
    HLink.push('/');
  },
  loginWithUP(user,password,callbackFinish){
    Api.login(user,password,{
      successCallBack:(response)=>{
        // console.log('successCallBack:',response);
        if(response.access_token!=null){
          Auth.loginSuccess(response,response.access_token,response.token_type,AUTH_TYPE_LIST.normal);
        }
        // else{
        //   HUI.Toast.showError('Login Fail!');
        // }
        if(callbackFinish!=null){callbackFinish(response)}
      },
      errorCallBack:(error,response)=>{
        console.log('errorCallBack:',error,response);
        if(callbackFinish!=null){callbackFinish()}
      }
    })
  },
  loginWithGoogle(access_token,profile,callbackFinish){
    console.log('loginWithGoogle:',access_token,profile);
    AuthGoogle.saveNewGoogleToken(access_token,profile);
    Api.login_google(access_token,profile,{
      successCallBack:(response)=>{
        // console.log('successCallBack:',response);
        let _data = response.Data;
        if(_data.Token!=null){
          Auth.loginSuccess(_data.Token,_data.Token.access_token,_data.Token.token_type,AUTH_TYPE_LIST.google);
        }
        else{
          console.log('Login fail!');
          // Global.Toast.showError('Login Fail!');
          HUI.Toast.showError('Login Fail!');
        }
        if(callbackFinish!=null){callbackFinish()}
      },
      errorCallBack:(error,response)=>{
        console.log('errorCallBack:',error,response);
        if(callbackFinish!=null){callbackFinish()}
      }
    });
  },
  logout(isForce,pathRedirect){
    console.log('logout:',this);
    if(this.auth_type==AUTH_TYPE_LIST.google){
      AuthGoogle.logout();
    }

    this.access_token = null;
    this.auth_type = null;
    this.auth_info = null;

    let _keep = {};
    if(localStorage){
      let _allKeys = Object.keys(localStorage);
      for(let k of _allKeys){
        if(k && k.startsWith("_")==true){
          _keep[k] = localStorage.getItem(k);
        }
      }
    }
    localStorage.clear();

    if(_keep){
      let _allKeys = Object.keys(_keep);
      for(let k of _allKeys){
        localStorage.setItem(k,_keep[k]);
      }
    }
    // localStorage.removeItem(AUTH_ACCESS_TOKEN_KEY);
    // localStorage.removeItem(AUTH_TYPE);
    // localStorage.removeItem(AUTH_INFO);

    HGlobalSetting.beforeLogout();
    if(Auth.afterLogout){
      for(let k of Object.keys(Auth.afterLogout)){
        let fn = Auth.afterLogout[k]
        if(fn && typeof fn =="function"){
          console.log("run afterlogout:",k)
          fn()
        }
      }
    }
    Api.logout();
    HLink.push(pathRedirect!=null?pathRedirect:'/');
  },
}

export const AuthGoogle = {
  clientId: '786359577708-65mnb6iqfg4fev8mgrlvu9hb4o22um8f.apps.googleusercontent.com',
  saveNewGoogleToken(accessToken,profile){
    localStorage.setItem(GOOGLE_ACCESS_TOKEN_KEY, accessToken);
    localStorage.setItem(GOOGLE_ACCESS_EMAIL, profile.email);
    localStorage.setItem(GOOGLE_IMG_URL, profile.imageUrl);
    localStorage.setItem(GOOGLE_ACCESS_LAST_TIME, new Date().getTime());
  },
  checkLastAccessToken(isAutoLogin,callback){
    console.log('checkLastAccessToken:',isAutoLogin);
    let _token = localStorage.getItem(GOOGLE_ACCESS_TOKEN_KEY);
    let _email = localStorage.getItem(GOOGLE_ACCESS_EMAIL);
    if(_token!=null && _token.length>0){
      if(isAutoLogin==true){
        if(callback!=null) callback(_token,_email);
      }
      return _token;
    }
  },
  logout(){
    console.log('logout:',this);
    // localStorage.clear();
    // localStorage.removeItem(GOOGLE_ACCESS_TOKEN_KEY);
    // localStorage.removeItem(GOOGLE_ACCESS_EMAIL);
    // localStorage.removeItem(GOOGLE_ACCESS_LAST_TIME);
    // localStorage.removeItem(GOOGLE_IMG_URL);
  },
}

export function requireAuth(component) {
  console.log('requireAuth:',isLoggedIn());
  if (isLoggedIn()) {
    return component;
  }
  else{
    return <Redirect to="/login"/>
  }
}

export function getAccessToken() {
  return localStorage.getItem(AUTH_ACCESS_TOKEN_KEY);
}

export function isLoggedIn() {
  const access_token = getAccessToken();
  return !!access_token;
}
import MyUIOne from "./MyUI";
import {HModules} from "@macashipo/mlib"
const ModuleDEBUI = {
  //========== init ==========
  _key: "deb_ui",
  _fnList: {},
  _config: {},
  _routes: [],
  getMyKey: () => {
    return "deb_ui";
  },
  runAfterInit: (HConfig) => {
    HModules.initDefault(ModuleDEBUI, { MyUIOne });
  },
  //---------- end default init ----------//
};

export default ModuleDEBUI;

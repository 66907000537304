import MyTableV1 from './views/Components/Table/index2';
import TableHelperV1 from './helpers/App/TableHelper';
import PageHelperV1 from './helpers/App/PageHelper'
import InputControlV1 from './views/Components/InputEditor/CustomControl'
import TableFilterV1 from './views/Components/TableFilter/index'
import PageHeaderV1 from './views/Components/PageHeader'
import CellHelperV1 from './helpers/App/CellHelper'//null
import AppHelperV1 from './helpers/App/App'
import DataHelperV1 from './helpers/App/DataHelper'
import LoadingV1 from './components/Loading/Loading'
import ProjectMenuV1 from './views/Components/ProjectMenu/index'
import HeaderWithExpandV1 from './components/Header/HeaderWithExpand'
import HeaderCurrentV1 from './components/Header/HeaderCurrent'

import {PermissionHelper as PermissionHelperV1} from './helpers/App/PermissionHelper'
import {PermissionArea as PermissionAreaV1} from './helpers/App/PermissionHelper'
const _test = {
  MyTableV1,
  TableHelperV1,
  PageHelperV1,
  InputControlV1,
  TableFilterV1,
  PageHeaderV1,
  CellHelperV1,
  AppHelperV1,
  DataHelperV1,
  LoadingV1,
  ProjectMenuV1,
  HeaderWithExpandV1,
  HeaderCurrentV1,
  PermissionHelperV1,
  PermissionAreaV1,
}
let _keys = Object.keys(_test);
for(let k of _keys){
  if(_test[k]==null){
    console.warn("import V1 null:",k);
  }
}
export {
    MyTableV1,
    TableHelperV1,
    PageHelperV1,
    InputControlV1,
    TableFilterV1,
    PageHeaderV1,
    CellHelperV1,
    AppHelperV1,
    DataHelperV1,
    LoadingV1,
    ProjectMenuV1,
    HeaderWithExpandV1,
    HeaderCurrentV1,
    PermissionHelperV1,
    PermissionAreaV1,
}
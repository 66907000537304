import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Config from '../Config';

const PermissionHelper = {
  key: {
    ms_addstage: 'ms_addstage',
    ms_sync: 'ms_sync',
    debug_rightmenu: 'debug_rightmenu',
    // admin_menu: 'admin_menu',
  },
  isSettedFromServer: false,
  alwayApprove: [],
  alwayDeny: [],
  onlyDebug: ['debug_rightmenu'],
  dataPermission: {},
  listComponentNeedReload: {},
  listFnCallbackNeedReload: {},
  setPermissionFromServer(FunctionSettings){
    if(FunctionSettings!=null){
      for(let _item of FunctionSettings){
        PermissionHelper.dataPermission[_item.Key] = _item.CanShow;
      }
      //test
      // PermissionHelper.dataPermission['ms_sync'] = false;
    }
    this.isSettedFromServer = true;
    // console.log('setPermissionFromServer:',FunctionSettings,PermissionHelper.dataPermission);
    if(Object.keys(this.listComponentNeedReload).length>0){
      for(let _k of Object.keys(this.listComponentNeedReload)){
        let _item = this.listComponentNeedReload[_k];
        if(_item!=null){
          if(_item.component!=null){
            // console.log('forceUpdate component:',this.listComponentNeedReload);
            _item.component.forceUpdate();
          }
        }
      }
      this.listComponentNeedReload = {};
      for(let _k of Object.keys(this.listFnCallbackNeedReload)){
        let _item = this.listFnCallbackNeedReload[_k];
        if(_item!=null){
          if(_item.fnCallback!=null){
            console.log('forceUpdate component:',this.listFnCallbackNeedReload);
            _item.fnCallback(this.getPermission(_item.keyName,null));
          }
        }
      }
      this.listFnCallbackNeedReload = {};
    }
  },
  getFnKeys(){
    let _arr = Object.keys(PermissionHelper.key);
    return _arr;
  },
  unMountComponent({key,component}){
    if(PermissionHelper.listComponentNeedReload[key]){
      delete PermissionHelper.listComponentNeedReload[key];
    }
  },
  getPermissionWithCallback({key,fnCallback,component}){
    let _p = PermissionHelper.getPermission(key,component);
    if(this.isSettedFromServer==false){
      if(this.listFnCallbackNeedReload!=null && this.listFnCallbackNeedReload[key]==null && fnCallback!=null){
        this.listFnCallbackNeedReload[key] = {
          keyName: key,
          fnCallback: fnCallback,
        };
      }
    }
    else{
      if(fnCallback!=null){
        fnCallback(_p);
      }
    }
  },
  getPermission(key,component){
    // console.log('getPermission: ',key);
    if(this.isSettedFromServer==false){
      if(this.listComponentNeedReload!=null && this.listComponentNeedReload[key]==null && component!=null){
        this.listComponentNeedReload[key] = {
          keyName: key,
          component: component,
        };
      }
    }
    if(PermissionHelper.onlyDebug.indexOf(key)>-1){
      if(Config.fnList.getIsDevMode()==true){
        return true;
      }
      else{
        return false;
      }
    }
    if(PermissionHelper.alwayApprove.indexOf(key)>-1){
      return true;
    }
    else if(PermissionHelper.alwayDeny.indexOf(key)>-1){
      return false;
    }
    return PermissionHelper.dataPermission[key];
  },
}

class PermissionArea extends Component {
  componentWillUnmount(){
    const {keyName,alwayApprove} = this.props;
    // console.warn("unmount permission");
    PermissionHelper.unMountComponent({key:keyName,component:this});
  }
  _checkPermission=()=>{
    const {keyName,alwayApprove} = this.props;
    if(alwayApprove==true){
      return true;
    }
    return PermissionHelper.getPermission(keyName,this);
  }
  render(){
    // console.warn("render permission");
    if(this.props.wrapByDiv==false){
      if(this._checkPermission()==true){
        return this.props.children;
      }
    }
    return (
      <div style={this.props.style}>{ this._checkPermission() && this.props.children}</div>
    )
  }
}
PermissionArea.propTypes = {
  keyName: PropTypes.string.isRequired,
  alwayApprove: PropTypes.bool,
  wrapByDiv: PropTypes.bool,
};
PermissionArea.defaultProps = {
  keyName: null,
  alwayApprove: false,
  wrapByDiv: true,
};

export {
  PermissionHelper,
  PermissionArea,
};

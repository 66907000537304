import React from 'react'
import MyLayoutTypes from '../../../../v1/components2/MyLayout/MyLayoutTypes';
import CSuppense from '../../../../components/MyCore/CSuspense'

const Admin = React.lazy(() => import('./Types/admin'));
const SlideItem = React.lazy(() => import('./Types/slide_item'));

const AppMyLayoutTypes = {
  init(){    
    MyLayoutTypes.addType('admin',(props)=>{ return CSuppense( props, Admin ) });
    MyLayoutTypes.addType('slide_item',(props)=>{ return CSuppense( props, SlideItem ) });
  }
}
export default AppMyLayoutTypes;
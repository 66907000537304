import React, { Component } from 'react';
import {
  FormGroup,InputGroup, InputGroupAddon, InputGroupText,
  ColFilter
} from './_import';

class CFSwitch extends Component {
  constructor(props) {
    super(props);
    this.state = { 
      value: null,
    };
  }
  _renderContent=()=>{
    const {item,onClear,onChange,values,cFilter,options} = this.props;
    const {title,fieldName,cellProps} = item;
    
    let _moreProps = {};
    _moreProps = Object.assign(_moreProps,cellProps);
    let _value = this.state.value;
    if(_value==null){
      // if(values && values[fieldName]){
      //   _value = values[fieldName];
      // }
      _value = false;
    }

    return (
      <FormGroup>
        <InputGroup>
          <InputGroupAddon addonType="prepend">
            <InputGroupText style={{padding:'3px'}}>
              {title}
              <div className="dFfdRjcSB" style={{alignItems:'center',marginLeft:'10px',paddingLeft:'10px',paddingRight:'10px',borderLeft: '1px solid #c2cfd5'}} onClick={()=>{
                console.log('on Click');
                this.setState({
                  value: !this.state.value
                },()=>{
                  if(onChange){
                    let _newValue = this.state.value;
                    let _obj = { type: 'CustomFilter',value: 
                      {
                        callbackParameters: {
                          selectValue: _newValue
                        },
                        callback:(targetVal,callbackParameters)=>{
                          // console.warn('callback:',targetVal,callbackParameters,_newValue);
                          if(_newValue==true){
                            return _newValue == targetVal;
                          }
                          else{
                            return true;
                          }
                        }
                      }
                    }; 
                    onChange({
                      value: _newValue,
                      filter: _obj
                    });
                  }
                });
              }}>
                <label className="cui-sw cui-sw-green">
                  <input type="checkbox" className="cui-sw-input" checked={_value} disabled/>
                  <span className="cui-sw-label" data-on="On" data-off="Off"></span>
                  <span className="cui-sw-handle"></span>
                </label>
              </div>
            </InputGroupText>            
          </InputGroupAddon>          
        </InputGroup>
      </FormGroup>      
    )
  }
  render(){
    const {item,index} = this.props;
    let _ui = this._renderContent();
    return (
      <ColFilter key={index} component={_ui} {...item.col}/>
    )
  }
}

export default CFSwitch
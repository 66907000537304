import React from "react"
import { MyBaseHelpers } from "@macashipo/mlib";
const MB_text = React.lazy(() => import("./Types/text"));
const MB_numeral = React.lazy(() => import("./Types/numeral"));
const MB_input = React.lazy(() => import("./Types/input"));
const MB_select = React.lazy(() => import("./Types/select"));
const MB_select2 = React.lazy(() => import("./Types/select2"));
const MB_switch = React.lazy(() => import("./Types/switch"));
const MB_checkbox = React.lazy(() => import("./Types/checkbox"));
const MB_div_editable = React.lazy(() => import("./Types/div_editable"));
const List = {
  text: MB_text,
  numeral: MB_numeral,
  input: MB_input,
  select: MB_select,
  select2: MB_select2,
  switch: MB_switch,
  checkbox: MB_checkbox,
  div_editable: MB_div_editable,
};
const MyBaseOne = {
  init() {
    MyBaseHelpers.addTypesFromListLazy(List);
  },
};

export default MyBaseOne;

import React, { Component } from 'react';
import { Global, Loading } from '../../../helpers';
import InputControl from './CustomControl';


class InputEditor extends Component {
  constructor(props) {
    super(props);
  }

  setValue=(value)=>{
    if(this._cControl!=null){
      this._cControl.setValue(value);
    }
  }

  render() {
    const {row, fnRequestUpdate, extraData, canEdit } = this.props;
    const {fieldName,options,type,placeholder,onUpdateField, className, styleControl}=this.props;
    const {id,dateFormat} = this.props;
    let _style = {};
    if(type=='checkbox'){
      _style={justifyContent:'center'};
    }

    let _disabled = !Global.TableHelper.canEdit(fieldName,extraData,canEdit);
    var moreProps = {
    };
    if(type=='date'){
      if(id!=null){
        moreProps.id = id;
      }
      else{
        moreProps.id = 'df_'+fieldName+'_'+row.Id;
      }
      if(dateFormat!=null){
        moreProps.dateFormat = dateFormat;
      }
    }
    else if(type=='select'){
      // console.log('input select:',row,fieldName,row[fieldName]);
    }
    // console.log('render InputEditor version 1:',fieldName,row);

    return (
      <span className={"s_full s_full_center " + `${className!=null?className:' '}`} style={_style}>
        <InputControl
          ref={r=>{this._cControl=r;}} 
          spellCheck={false}
          className={"select_wordwrap s_input"}
          style={Object.assign({height:'100%'},styleControl)}
          type={type}
          value={ row[fieldName] } 
          options={options}
          disabled={_disabled}
          placeholder = { placeholder }
          onBlur={(ev)=> { 
            if(type=='text'||type=='textarea'){
              InputEditor.Helper.onBlur(fnRequestUpdate,row,ev,fieldName,this); 
            }
          }}
          onChange={ (ev,newValue) => { 
            if(type=='select' || type=='checkbox' || type=='date'){
              InputEditor.Helper.onChange(fnRequestUpdate,row,newValue,fieldName,this);
              // if(onUpdateField!=null){
              //   onUpdateField(row,fieldName,newValue,row[fieldName],null,this);
              // }
            }
          } }
          {...moreProps} >
        </InputControl>
        {
          Global.TableHelper.Updating.isUpdating(row,[fieldName])==true &&
          <Loading style={{}} styleItem={{backgroundColor:'white'}} type={Loading.Type.threeBounce}/>
        }
      </span>
    );
  }
}

InputEditor.Helper = {
  isChanging:(onChangingField,row,changingField)=>{
    // return true;//test
    if(onChangingField!=null && onChangingField.length>0){
      let _isChanging = false;
      // console.log('isChanging:',onChangingField,row.Id,changingField);
      if(changingField!=null){
        if(Array.isArray(changingField)==true){
          for(let i=0;i<changingField.length;i++){
            if(Array.isArray(onChangingField)==true){
              if(onChangingField.indexOf(row.Id + "_" + changingField[i])>-1){
                _isChanging = true;
                break;
              }
            }
            else{
              if(onChangingField == row.Id + "_" + changingField[i]){
                _isChanging = true;
                break;
              }
            }
          }
        }
        else{
          if(onChangingField == row.Id + "_" + changingField){
            _isChanging = true;
          }
        }
      }
      return _isChanging;
    }
    return false;
  },
  onBlur:(fnRequestUpdate,row,ev,fieldName,component)=>{
    if(ev!=null){
      Global.TableHelper.onUpdateField(row,fieldName,ev.currentTarget.value,row[fieldName],null,fnRequestUpdate,component);
    }
  },
  onBlurWithNewValue:(fnRequestUpdate,row,newValue,fieldName,component)=>{
    Global.TableHelper.onUpdateField(row,fieldName,newValue,row[fieldName],null,fnRequestUpdate,component);
  },
  onBlurWithOldValue:(fnRequestUpdate,row,ev,fieldName,oldValue,component)=>{
    if(ev!=null){
      Global.TableHelper.onUpdateField(row,fieldName,ev.currentTarget.value,oldValue,null,fnRequestUpdate,component);
    }
  },
  onChange:(fnRequestUpdate,row,newValue,fieldName,component)=>{
    Global.TableHelper.onUpdateField(row,fieldName,newValue,row[fieldName],null,fnRequestUpdate,component);
  },
  onChangeCheckbox:(fnRequestUpdate,row,newValue,fieldName,component)=>{
    Global.TableHelper.onUpdateField(row,fieldName,newValue,row[fieldName],null,fnRequestUpdate,component);
  },
  onChangeSelect:(fnRequestUpdate,row,newValue,fieldName,component)=>{
    Global.TableHelper.onUpdateField(row,fieldName,newValue,row[fieldName],null,fnRequestUpdate,component);
  },
  onChangeDate:(fnRequestUpdate,row,newValue,fieldName,component)=>{
    Global.TableHelper.onUpdateField(row,fieldName,newValue,row[fieldName],null,fnRequestUpdate,component);
  },
}

export default InputEditor;
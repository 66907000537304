import React, { Component } from 'react';
import {
  Button
} from '../../_importComponent';
import MyDialog from '../../components/MyDialog';
import * as H from '../../helpers';
import ApiGeneric from '../../services/generic';
import {LaddaButton, EXPAND_RIGHT} from '../../_importLibs';

class MyButton extends Component {
  constructor(props) {
    super(props);
    this.state = { 
    };
  }

  componentDidMount(){
  }

  render() {
    return (
      <Button>{this.props.title}</Button>
    )
  }
}


class MyButtonCallApi extends Component {
  constructor(props) {
    super(props);
    this.state = { 
      loading: false
    };
  }

  componentDidMount(){
  }

  _onRequest=(cb)=>{
    const {queryData,config,reloadTable} = this.props;
    this.setState({
      loading:true
    },()=>{
      ApiGeneric.generic({
        request:{
          method: 'POST',
          url: `/api/v1/${this.props.apiPathAndName}`
        },
        data:queryData,
        successCallBack:(response)=>{
          let _msg = response.Msg;
          let _data = response.Data;
          if(_msg){
            H.Global.Toast.showSuccess(_msg);
          }
          if(cb){
            cb();
          }
          if(config && config.NeedReload==true){
            window.location.reload();
          }
          else if(config && config.NeedReloadList==true && reloadTable!=null){
            reloadTable();
          }        
          if(_data){
            if(_data.FullPath){
              MyDialog.Helper.show({
                title: 'Link',
                msg: (<div style={{wordWrap:'break-word'}}><a href={_data.FullPath} target="_blank">{_data.FullPath}</a></div>)});
            }
          }
          this.setState({loading:false});
        },
        errorCallBack:(error,response)=>{
          this.setState({loading:false});
        }
      })
    })
  }
  _onClickOK=()=>{
    const {queryData,config,reloadTable} = this.props;
    if(config && config.ConfirmMsg){
      H.Global.Alert.showMsgQuestion(H.Text.get('btn_confirm'),config.ConfirmMsg,({hide,disableAll})=>{
        disableAll();
        this._onRequest(()=>{
          hide();
        });
      },()=>{},false);
    }   
    else{
      this._onRequest();
    } 
  }

  render() {
    return (
      <LaddaButton
        className="btn btn-success btn-ladda"
        loading={this.state.loading}
        onClick={this._onClickOK}
        data-color="green"
        style={{marginRight:'2px'}}
        data-style={EXPAND_RIGHT}>
        {this.props.children}
      </LaddaButton>
    )
  }
}

MyButton.Api = MyButtonCallApi;

export default MyButton;
import React from "react"
import { MyUIHelpers, CSuppense } from "@macashipo/mlib";
const MUI_filterhub_v3 = React.lazy(() => import("./Types/filterhub_v3"));
const List = {
  filterhub_v3: MUI_filterhub_v3
};
const MyUIOne = {
  init() {
    MyUIHelpers.addTypesFromListLazy(List);
  },
};

export default MyUIOne;

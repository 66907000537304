import MyPageOne from "./MyPage"
import MyLayoutOne from "./MyLayout"
const ModuleQuiz = {
  getMyKey: () => {
    return "quiz"
  },
  runAfterInit: (HConfig) => {
    MyPageOne.init();
    MyLayoutOne.init();
  },
}

export default ModuleQuiz
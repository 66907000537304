import React, { Component } from 'react';

class CTable extends Component {
  constructor(props) {
    super(props);
    this.state={
    };
  }

  componentDidMount(){
  }

  render() {
    const {pageConfig,options} = this.props;
    // console.warn('render chart:',options);
    return (
      <div className="header-below">  
      {
        pageConfig && pageConfig.componentBelowHeader &&
        <pageConfig.componentBelowHeader />
      }
      {
        options && options.Configs && options.Configs.InstructionWidget && options.Configs.InstructionWidget.ImageURL &&
        <img src={options.Configs.InstructionWidget.ImageURL} width="100%"/>
      }
      </div>
    )
  }
}

export default CTable;
import React, { Component } from 'react';
import {
  Button,
  Modal, ModalBody, ModalFooter, ModalHeader
} from '../../../_importComponent';
import * as H from '../../../helpers';
import ApiGeneric from '../../../services/generic';
import MyForm from './index';
import {LaddaButton, EXPAND_RIGHT} from '../../../_importLibs';

class ModalAddNew extends Component {
  constructor(props) {
    super(props);

    this.state = { 
      isShow: false,
      isSubmiting: false,      
      options: [],
      error: null,
    };
    // console.log('ModalAddNew constructor: ',props);
  }

  componentDidMount(){
  }

  _onClickOK=()=>{
    // console.warn("click OK");
    if(this._cForm){
      let _values = this._cForm._getCurrentValues();
      console.warn("values:",_values);
      this._onRequestSubmit(_values);
    }
  }

  _onRequestSubmit=(data={})=>{
    const {queryData,config,reloadTable} = this.props;
    let _queryData = Object.assign({},queryData,data);
    // console.warn('_onRequestSubmit:',_queryData);
    // return;
    this.setState({
      isSubmiting:true
    },()=>{
      ApiGeneric.generic({
        request:{
          method: 'POST',
          url: `/api/v1/${config.APIName}`
        },
        data:_queryData,
        successCallBack:(response)=>{
          let _msg = response.Msg;
          let _data = response.Data;
          if(_msg){
            H.Global.Toast.showSuccess(_msg);
          }
          if(config && config.NeedReload==true){
            window.location.reload();
          }
          else if(config && config.NeedReloadList==true && reloadTable!=null){
            reloadTable();
          }
          if(_data){
            if(_data.FullPath){
              H.MyDialog.Helper.show({
                title: 'Link',
                msg: (<div style={{wordWrap:'break-word'}}><a href={_data.FullPath} target="_blank">{_data.FullPath}</a></div>)});
            }
          }
          this.setState({isSubmiting:false,isShow:false});
        },
        errorCallBack:(error,response)=>{
          this.setState({isSubmiting:false});
        }
      })
    })
  }

  getOptions=()=>{
    // console.log('getOptions:',this.props);
    if(this.props.options){
      return this.props.options;
    }
    else if(this.props.getOptions){
      let _options = this.props.getOptions();
      // console.log('getOptions2 :',_options);
      return _options;
    }
    return {};
  }

  render() {
    // console.warn('render FormModal');
    const {config} = this.props;
    let _title = config.Name;     
    let _formConfig = config.Form;
    let _titleForm = _formConfig.Title;      
    return (
      <div style={{display:"inline-block"}}>
        <Modal fade={false} className="modal-lg" isOpen={this.state.isShow} toggle={()=>{this.setState({isShow:!this.state.isShow})}} backdrop="static">
          <ModalHeader toggle={()=>{this.setState({isShow:!this.state.isShow})}}>{_titleForm}</ModalHeader>
          <ModalBody>        
            {
              this.state.isShow==true && _formConfig !=null && 
              <MyForm 
                ref={r=>{this._cForm=r;}} 
                config={_formConfig} 
                onSubmit={this._onClickOK}
                getOptionOfKey={(key)=>{
                  let _options = this.getOptions();
                  if(_options[key]){
                    return _options[key];
                  }
                  return [];
                }}
              />
            }
            {
              this.state.isSubmiting ==true &&
              <div className="fm-loading">
                <i className="fa fa-spin fa-refresh"/> Loading...
              </div>
            }
          </ModalBody>          
          <ModalFooter>
            <LaddaButton
              className="btn btn-success btn-ladda"
              loading={this.state.isSubmiting}
              onClick={this._onClickOK}
              data-color="green"
              data-style={EXPAND_RIGHT}
            >
              Submit
            </LaddaButton>
            {/* <Button color="primary" onClick={this._onClickOK}>Đồng ý</Button>{' '} */}
            <Button color="secondary" onClick={()=>{this.setState({isShow:!this.state.isShow})}}>Đóng</Button>
          </ModalFooter>
        </Modal>
        <LaddaButton
          className="btn btn-success btn-ladda"
          loading={this.state.loading}
          onClick={()=>{
            this.setState({
              isShow: true
            });
          }}
          data-color="green"
          style={{marginRight:'2px'}}
          data-style={EXPAND_RIGHT}>
          {_title}          
        </LaddaButton>
      </div>
    )
  }
}

export default ModalAddNew;
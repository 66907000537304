import React from 'react';
/**
 * Form Type: default.
 *
 * @class default
 * @static
 * @namespace default
 * @memberof FormType
 * 
 * @example
 * 
 */
class FormDefault extends React.Component {
  static propTypes = {
  }
  static defaultProps = {
  }
  constructor(props) {
    super(props);
    this.state={
    };
    
  }

  /**
   * Thuộc tính sử dụng trong more:
   * 
   * @method more
   * @memberof FormType.default

   * 
  */
  more() {
  }

  render() {
    const {item, opts} = this.props
    return (
      <div className="mui-form-text">
        default
      </div>
    )
  }
}

export default FormDefault;
import React from "react"
import { MyLayoutHelpers, CSuppense } from "@macashipo/mlib";
const MPEmpty = React.lazy(() => import("./Types/empty"));
const MPDefault = React.lazy(() => import("./Types/v3"));
const MPv3setting = React.lazy(() => import("./Types/v3setting"));
const List = {
  v3empty: MPEmpty,
  v3default: MPDefault,
  v3setting: MPv3setting,
};
const MyPageOne = {
  init() {
    console.warn("init MyLayoutOne");
    for(let k of Object.keys(List)){
      MyLayoutHelpers.addTypes(k,(props)=>{
        return CSuppense(props, List[k])
      })
    }
    // MyLayoutHelpers.addTypesFromListLazy(List);
  },
};

export default MyPageOne;

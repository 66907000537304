import MyUIOne from "./MyUI";
import MyTableCellOne from "./MyTableCell"
import MyFormControlOne from "./MyFormControl"
import MyPageOne from "./MyPage"
import MyTableFilterOne from "./MyTableFilter"
import {HModules} from "@macashipo/mlib"
const ModuleV1Page = {
  //========== init ==========
  _key: "v3_ui",
  _fnList: {},
  _config: {},
  _routes: [],
  getMyKey: () => {
    return "v3_ui";
  },
  runAfterInit: (HConfig) => {
    HModules.initDefault(ModuleV1Page, { MyTableCellOne, MyUIOne, MyPageOne, MyFormControlOne, MyTableFilterOne });
  },
  //---------- end default init ----------//
};

export default ModuleV1Page;

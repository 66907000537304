import ApiGeneric from './services/generic';
import ApiProjectHeader from './services/project_header';
import ApiProjectPhoto from './services/project_photo';
import ApiProjectMaterials from './services/project_materials';
import ApiProjectSitePhoto from './services/project_sitephoto';
import ApiSitePhoto3 from './services/project_sitephoto_3';
import ApiScopeRisk from './services/scope_risk';
import ApiProjectBriefReport from './services/project_briefreport';
import ApiScopeCostPlan from './services/scope_costplan';
import ApiScopeQB from './services/scope_qb';

const SApiGeneric = ApiGeneric
export {
    ApiGeneric,SApiGeneric,
    ApiProjectHeader,
    ApiProjectPhoto,ApiProjectMaterials,ApiProjectSitePhoto,ApiSitePhoto3,ApiScopeRisk,
    ApiProjectBriefReport,ApiScopeCostPlan,ApiScopeQB
}
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {
  Button,
  Popover, PopoverBody, PopoverHeader
} from '../../../_importComponent';
import {HConstant} from '../../../_import';
import {ApiGeneric} from '../../../_importService';

class ProjectMenu extends Component {
  constructor(props) {
    super(props);
    this.state = { 
      popoverOpen: false,
      popoverTarget: 'Popover1',
      popoverId: 0,
      dicFiles: {},
      data: [],
    };
  }

  componentDidMount(){
    // console.warn('ProjectMenu componentDidMount',ProjectMenu.Data.MenuData);
    let _project_screens= localStorage.getItem(HConstant.LocalKey.PROJECT_SCREENS);
    ProjectMenu.Data.MenuData.parseFromServer(_project_screens);
  }

  _onRequestHeader=()=>{
    const {projectId} = this.props;
    if(projectId!=null){
      ApiGeneric.generic({
        request:{
          method: "POST",
          path: "ProjectScreenHeader",
          name: "GetHeader"
        },
        data:{
          ProjectId: projectId,
        },
        isHideMsgError: true,
        successCallBack:(response)=>{
          if(response.Data!=null && response.Data.Dic_Files!=null){
            ProjectMenu.Data.MenuData.updateCurrentDicFiles(response.Data.Dic_Files);
            this.setState({
              dicFiles: response.Data.Dic_Files
            })
          }        
        },
        errorCallBack:(error,response)=>{
          console.log('_onRequestHeader error:',error,response);
        }
      });
    }
  }

  show=(target,projectId,dicFiles)=>{
    console.log('show:',target,projectId,this.state);
    if(ProjectMenu.Data.MenuData && ProjectMenu.Data.MenuData.currentDicFilesOfProject==null ){
      // console.warn("need request project header");
      this._onRequestHeader();
    }

    if(target==this.state.popoverTarget && this.state.popoverOpen==true){
      this.setState({
        popoverOpen: false,
        popoverTarget: target,
        popoverId: projectId,
        dicFiles: dicFiles,
        data: ProjectMenu.Data.MenuData.data,
      })
    }
    else{
      this.setState({
        popoverOpen: true,
        popoverTarget: target,
        popoverId: projectId,
        dicFiles: dicFiles,
        data: ProjectMenu.Data.MenuData.data,
      })
    }
  }
  showInactive=(target,projectId,dicFiles)=>{
    // console.log('showInactive:',target,projectId,this.state);
    if(target==this.state.popoverTarget && this.state.popoverOpen==true){
      this.setState({
        popoverOpen: false,
        popoverTarget: target,
        popoverId: projectId,
        dicFiles: dicFiles,
        data: ProjectMenu.Data.MenuData.dataInactive,
      })
    }
    else{
      this.setState({
        popoverOpen: true,
        popoverTarget: target,
        popoverId: projectId,
        dicFiles: dicFiles,
        data: ProjectMenu.Data.MenuData.dataInactive,
      })
    }
  }

  _renderList=()=>{
    let _data = this.state.data;
    // console.warn('_renderList data:',_data);
    let _ui = [];
    for(let i=0;i<_data.length;i++){
      let _sub = _data[i];
      let _uiSub = [];
      for(let j=0;j<_sub.length;j++){
        let _c = _sub[j].ColorCode;
        let _t = _sub[j].Name;
        let _code = _sub[j].Code;
        let _l = '';
        if(_sub[j].Url!=null){
          _l = _sub[j].Url.replace('[ID]',this.state.popoverId);
          if(this.state.dicFiles!=null && this.state.dicFiles[_code]!=null){
            _l = _l.replace('[FILEID]',this.state.dicFiles[_code]);
          }
        }
        if(_l.indexOf('http')==0){
          _uiSub.push(
            <a key={j} className="s_bt_menu" target="_blank" href={_l} style={{backgroundColor:_c,border:'1px dotted grey',}}>{_t}</a>
          )
        }
        else{
          _uiSub.push(
            <Link key={j} className="s_bt_menu" target="_blank" style={{backgroundColor:_c,border:'1px dotted grey',}} to={_l}>{_t}</Link>
          )
        }
      }
      _ui.push(
        <div key={i} className="s_bt_menu_c">
          { _uiSub }
        </div>
      )
    }
    return (
      <div style={{display:'flex',flexDirection:'row',flexWrap:'wrap'}}>
        { _ui }
      </div>
    )
  }
  render() {
    return (
      <div>
        <Button style={{display:'none'}}  onClick={()=>{
            this.setState({popoverOpen:!this.state.popoverOpen});
          }}
          id="Popover1">Show Menu</Button> 
        <Popover className={"s_dropshadow"} style={{}} placement={'bottom'} delay={0} isOpen={this.state.popoverOpen} target={this.state.popoverTarget} toggle={()=>{
          this.setState({popoverOpen:!this.state.popoverOpen});
        }}>
          <PopoverHeader>[{this.state.popoverId}] Bấm chọn vào ô bên dưới để hiện thị bảng dữ liệu</PopoverHeader>
          <PopoverBody style={{padding:'0px'}}>
            {
              this._renderList()
            }
          </PopoverBody>
        </Popover>
      </div>
    )
  }
}

ProjectMenu.Data = {
  Menu: {
    hr:{
      code: 'WI_GM03',
    },
    scope_management:{
      code: 'WI_SCOPE_MANAGEMENT',
    },
    quality_management:{
      code: 'WI_QUALITY_MANAGEMENT',
    },
    charter:{
      code: 'WI_GM_CHARTER',
    },
    site_photos:{
      code: 'WI_SITE_PHOTOS',
    },
    time_control:{
      code: 'WI_TIME_CONTROL',
    },
    risk:{
      code: 'WI_RISK',
    },
    doitc:{
      code: 'DOITC',
    },
    milestone:{
      code: 'MILESTONE',
    },
    milestone_report:{
      code: 'MILESTONE_REPORT',
    },
    planweek:{
      code: 'PLANWEEK',
    },
    planweek_department:{
      code: 'PLANWEEK_DEPARMTENT',
    },
    planweek_report:{
      code: 'PLANWEEK_REPORT',
    },
    planweek_project:{
      code: 'PLANWEEK_PROJECT',
    },
    site_photos3:{
      code: 'WI_SITE_PHOTOS3',
    },
    project:{
      code: 'PROJECT',
    },
    project_search:{
      code: 'PROJECT_SEARCH',
    },
    admin:{
      code: 'ADMIN',
    },
    crmtask:{
      code: 'CRM_TASK',
    },
    crmtaskreport:{
      code: 'CRM_TASK_REPORT',
    },
    crmcontact:{
      code: 'CRM_CONTACT',
    },
    crmopty:{
      code: 'CRM_OPTY',
    },
    staffleadstaff:{
      code: 'STAFF_LEAD_STAFF',
    },
    staffdetail:{
      code: 'STAFF_DETAIL',
    },
    config_staff:{
      code: 'CONFIG_STAFF',
    },
    category:{
      code: 'CATEGORY',
    },
  },
  MenuData: {
    data: [],
    dataInactive: [],
    currentDicFilesOfProject: null,
    update(data){
      this.data = data;
    },
    updateInactive(data){
      this.dataInactive = data;
    },
    parseFromServer(jsonString){
      if(jsonString!=null){
        try {
          let _psa = JSON.parse(jsonString);
          if(_psa!=null){
            ProjectMenu.Data.MenuData.data = _psa;
            // ProjectMenu.Data.MenuData.update(_psa);
            // console.log('Update Menu:',_psa);
          }
        } catch (error) {
          console.warn('Parse JSON MENU Error');
        }
      }
    },
    parseInactiveFromServer(jsonString){
      if(jsonString!=null){
        try {
          let _psa = JSON.parse(jsonString);
          if(_psa!=null){
            ProjectMenu.Data.MenuData.dataInactive = _psa;
            // ProjectMenu.Data.MenuData.updateInactive(_psa);
            // console.log('Update Menu:',_psa);
          }
        } catch (error) {
          console.warn('Parse JSON MENU Error');
        }
      }
    },
    updateCurrentDicFiles(dicFiles){
      this.currentDicFilesOfProject = dicFiles;
    }
  }
}

ProjectMenu.Helper = {
  buildLeftComponent(projectId){
    return (
      <div style={{display:'flex',flexDirection:'row',}}>
        <ProjectMenu ref={r=> {this._cProjectMenu=r;}} projectId={projectId}/>
        <Button style={{color:'#44cfae',padding:'0px'}} id={"btnActionMenu"} color="link" onClick={()=>{
          if(this._cProjectMenu!=null){ this._cProjectMenu.show('btnActionMenu',projectId,ProjectMenu.Data.MenuData.currentDicFilesOfProject)}
        }}>
          <i style={{fontSize:'25px'}} className="fa fa-caret-square-o-down"></i>
        </Button>
      </div>
    )
  },
  //Con su dung o cac man hinh: scope, qualityManagement, risk, qualityManagementbaseline
  buildHeader(title,projectId,fromComponent){
    let _this = fromComponent || this;
    if(projectId!=null){
      return (
        <div style={{display:'flex',flexDirection:'row',}}>
          {/* <ProjectMenu ref={r=> {_this._cProjectMenu=r;}}/>
          <Button style={{color:'#44cfae',padding:'0px'}} id={"btnActionMenu"} color="link" onClick={()=>{
            if(_this._cProjectMenu!=null){ _this._cProjectMenu.show('btnActionMenu',projectId,ProjectMenu.Data.MenuData.currentDicFilesOfProject)}
          }}>
            <i style={{fontSize:'25px'}} className="fa fa-caret-square-o-down"></i>
          </Button> */}
          <div style={{display:'flex',alignItems:'center'}} className="s_title_page">
          {title} - {projectId}
          </div>
        </div>
      )
    }
    else{
      return (
        <div style={{display:'flex',flexDirection:'row',}}>
          {/* <ProjectMenu ref={r=> {_this._cProjectMenu=r;}}/> */}
          <div style={{display:'flex',alignItems:'center'}} className="s_title_page">
          {title}
          </div>
        </div>
      )
    }
  },
}

export default ProjectMenu;
// if(window){
//     window.simulateClick = function (elem) {
//         // Create our event (with options)
//         var evt = new MouseEvent('click', {
//             bubbles: true,
//             cancelable: true,
//             view: window
//         });
//         // If cancelled, don't dispatch our event
//         var canceled = !elem.dispatchEvent(evt);
//     };
// }

const V = {
    simulateClick : function (elem) {
        // Create our event (with options)
        var evt = new MouseEvent('click', {
            bubbles: true,
            cancelable: true,
            view: window
        });
        // If cancelled, don't dispatch our event
        var canceled = !elem.dispatchEvent(evt);
    }
}

if(window){
  window.simulateClick = V.simulateClick;
}
export default V;
import React from 'react';
import FormHelper from '../FormHelper';

/**
 * Form Type: select_kanban_working.
 *
 * @class select_kanban_working
 * @static
 * @namespace select_kanban_working
 * @memberof FormType
 * 
 * @example
 * 
 */
class Z extends React.Component {
  static propTypes = {
  }
  static defaultProps = {
  }
  constructor(props) {
    super(props);
    this.state={
      value: null,
    };
    
  }

  /**
   * Thuộc tính sử dụng trong more:
   * 
   * @method more
   * @memberof FormType.select_kanban_working

   * 
  */
  more() {
  }

  _getSelected=(value)=>{
    const {item,opts} = this.props;
    let _isMultiple = FormHelper.getMultiple(item,opts);
    let _value = value;
    if(_isMultiple){
      let _valueOfControl = this.state.value || [];
      let _index = _valueOfControl.indexOf(_value);
      if(_index==-1){
        return false;
      }
      else{
        return true;
      }
    }
    else{
      if(this.state.value!=null && this.state.value==_value ){
        return true;
      }
      else{
        return false;
      }
    }
  }

  render() {
    const {item, opts} = this.props
    // console.warn('item in type: ', item);
    // console.warn('opts in type: ', opts);
    let _options = [];
    let _isMultiple = FormHelper.getMultiple(item,opts);
    // let _sourceList = FormHelper.getOptionsSource(item,opts);
    let _sourceList = [
      {value:'0.1',text:'0.1'},
      {value:'0.5',text:'0.5'},
      {value:'1',text:'1'},
      {value:'2',text:'2'},
      {value:'3',text:'3'},
      {value:'4',text:'4'},
      {value:'5',text:'5'},
      {value:'6',text:'6'},
      {value:'7',text:'7'},
      {value:'8',text:'8'},
      {value:'9',text:'9'},

    ];
    // console.warn('_sourceList', _sourceList);
    // let _flexDirection = FormHelper.getDirection(item,opts)
    let _li = [];
    if(_sourceList && _sourceList.length>0){
      for(let i in _sourceList){
        let _o = _sourceList[i];
        let _value = _o.value || _o.Value;
        let _text = _o.text || _o.Text || _o.label || _o.title;
        // _options.push(
        //   {value:_value,label:_text}
        // )
        let _classname = "";
        if(this._getSelected(_value)){
          _classname += " selected";
        }
        _li.push(
          <li key={i} className={_classname} 
            style ={{width:'30px', textAlign:'center',backgroundColor:'skyblue', margin:'5px', 
            borderRadius:'5px'}}
            onClick={()=>{
            if(_isMultiple){
              let _valueOfControl = this.state.value || [];
              let _index = _valueOfControl.indexOf(_value);
              if(_index==-1){
                _valueOfControl.push(_value);
              }
              else{
                _valueOfControl = _valueOfControl.split(_index,1);
              }
              this.setState({
                value: _valueOfControl
              },()=>{
                if(opts && opts.onUpdateValue){
                  opts.onUpdateValue({newValue:this.state.value});
                }
              });
            }
            else{
              this.setState({
                value: _value
              },()=>{
                if(opts && opts.onUpdateValue){
                  opts.onUpdateValue({newValue:this.state.value});
                }
              });
            }            
          }}>{_text}</li>
        )
      }
    }
    let _disabled = FormHelper.getDisabled(item,opts);
    return (
      <ul className="mui-form-c-selectlist" style={{display:'flex', flexDirection:'row',borderColor:'white'}}>
        {_li}
      </ul>
    );
  }
}

export default Z;
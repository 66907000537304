
const listSetting = [
  // {title:"Hi 1", icon:null, path:null, subTitle:null, onClick:null},
  // {title:"Hi 2", icon:null, path:null, subTitle:null, onClick:null}
]

const configSetting = {
  
}
const registerMenuSetting = ()=>{

}

export const getListSetting = () =>{
  return listSetting
}
export const addOneMenuSetting = ({title, icon, path, subTitle, onClick, configPage}={}) =>{
  listSetting.push(
    {title, icon, path, subTitle, onClick, configPage}
  )
}

export const FnList = {
  registerMenuSetting: registerMenuSetting,
  addOneMenuSetting: addOneMenuSetting
}
import React, { Component } from 'react';
import {
  Alert,
  Button,
  FormGroup, Label, Modal, ModalBody, ModalFooter, ModalHeader
} from '../../_importComponent';
import {LaddaButton,EXPAND_RIGHT} from '../../_importLibs';
import {HUI,HText} from '../../_import';
import {InputControlV1} from '../../_importV1'



class ModalAddNew extends Component {
  constructor(props) {
    super(props);

    this.state = { 
      isShow: false,
      isLoading: false,
      isSaving: false,
      isShowAlert: false,
      msgAlert: '',
      options: [],
      error: null,
    };
    this._cInput={};
    console.log('ModalAddNew constructor: ',props);
  }

  componentDidMount(){
  }

  loadWithOptions(options){
    if(options!=null){
      this.setState({
        options: options,
        error:null,
        isLoading:false
      });
    }
  }

  show(){
    this.setState({
      isShow:true,
    })
  }

  _onDismissAlert=()=>{
    this.setState({ isShowAlert: false, msgAlert: '' });
  }

  _showAlert=(msg)=>{
    this.setState({ isShowAlert: true, msgAlert: msg });
  }

  _checkValid=()=>{
    let _isValid = false;
    if(this.state.currentSelectedTask!=null){
      if(this.state.selectedStaff!=null && this.state.selectedStaff.value!=null){
        _isValid=true;
      }
    }
    return _isValid;
  }

  _onClickOK=()=>{
    const {onRequestAddNew,addNewConfig} = this.props;
    if(this._cInput!=null){
      let _data = {};

      if(addNewConfig!=null && addNewConfig.data!=null){
        for(let i in addNewConfig.data){
          let _item = addNewConfig.data[i];
          let _key = _item.name;
          if(this._cInput[_key]!=null){
            _data[_key] = this._cInput[_key].getValue();
            //check valid
            if(_data[_key].length==0){
              HUI.Toast.showWarn(HText.get('msg_input_please'));
              return;
            }
          }
        }

        this.setState({
          isSaving: true,
        },()=>{
          if(onRequestAddNew!=null){
            onRequestAddNew(_data,({success})=>{
              this.setState({isSaving: false,isShow:!success})
            })
          }
        })
      }      
    }
  }

  _renderForm=()=>{
    const {addNewConfig} = this.props;
    let _ui = [];
    if(addNewConfig!=null && addNewConfig.data!=null){
      for(let i in addNewConfig.data){
        let _item = addNewConfig.data[i];
        let _key = _item.name;
        let _type = _item.type;
        let _optionKey = _item.optionKey;
        _ui.push(
          <FormGroup key={i}>
            <Label><strong><i style={{color:'#555'}} className="fa fa-briefcase"/> {_item.label}</strong></Label>
            <InputControlV1
              ref={r=>{this._cInput[_key]=r;}}
              type={_type} 
              options={this.state.options[_optionKey]}
            />
          </FormGroup>
        )
      }
    }
    return (
      <div>
        {_ui}
      </div>
    )
  }

  render() {
    return (
      <Modal fade={false} className="modal-lg" isOpen={this.state.isShow} toggle={()=>{this.setState({isShow:!this.state.isShow})}} backdrop="static">
        <ModalHeader toggle={()=>{this.setState({isShow:!this.state.isShow})}}>{
          "Thêm nhanh"
        }</ModalHeader>
        <ModalBody>
          {
            this._renderForm()
          }
          <Alert color="warning" isOpen={this.state.isShowAlert} toggle={this._onDismissAlert}>
            {this.state.msgAlert}
          </Alert>
        </ModalBody>
        <ModalFooter>
          <LaddaButton
            className="btn btn-success btn-ladda"
            loading={this.state.isSaving}
            onClick={this._onClickOK}
            data-color="green"
            data-style={EXPAND_RIGHT}
          >
            Lưu
          </LaddaButton>
          {/* <Button color="primary" onClick={this._onClickOK}>Đồng ý</Button>{' '} */}
          <Button color="secondary" onClick={()=>{this.setState({isShow:!this.state.isShow})}}>Đóng</Button>
        </ModalFooter>
      </Modal>
    )
  }
}

export default ModalAddNew;

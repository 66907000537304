import { createStore, combineReducers} from 'redux';
import HLocalStorage from '../../helpers/LocalStorage'
const initStateFromLocal = ()=>{
  let _obj = {
    // userSwitchIndex: HLocalStorage.getString("V3_CurrUserSwitch",-1),
  }
  return _obj;
}

const initialState = {
  projectList: [],
  currentProject: {
    Id: "", ProjectId: ""
  },
  openLeftMenu: false,
  parsedParam: false,//can phai parse param xong moi load ui
  switchPortfolioIndex: -1,
  projectActiveIndex: 0,
  waitingOptionsTab1: true,
  //for departmnet
  departmentList: [],
  departmentSwitchIndex: -1,
  departmentActiveIndex: 0,
  departmentCurrent: { Id: "" },
  //for user
  userList: [],
  userSwitchIndex: -1,
  userActiveIndex: 0,
  userCurrent: { Id: "" },
  footerList:  [],
  //init from local
  ...initStateFromLocal()
}



export const solidv3 = (state = initialState, { type, ...rest }) => {
  switch (type) {
    case 'setObjInSolidV3':
      return {...state, ...rest }
    default:
      return state
  }
}

window._TEST = initialState;
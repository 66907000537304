import React from "react"
import { MyPageHelpers } from "@macashipo/mlib";
const MTC_select_to_compare = React.lazy(() => import("./Types/select_to_compare"));
const List = {
  select_to_compare: MTC_select_to_compare
};
const MyPageOne = {
  init() {
    MyPageHelpers.addTypesFromListLazy(List);
  },
};

export default MyPageOne;

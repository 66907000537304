import React, { Component } from 'react';
import MyTableFilterHubTypes from './MyTableFilterHubTypes';
class MyTableFilterDataHub extends Component {
  static defaultProps = {
    configFilter: {},
    fnList: {},
  }
  constructor(props) {
    super(props);
  }
  componentDidMount(){
  }
  render() {
    const {configFilter,fnList} = this.props;
    let _type = configFilter.type || '_default';
    // console.warn('MyTableFilterDataHub:',configFilter,fnList,_type,MyTableFilterHubTypes.Types)
    if(MyTableFilterHubTypes.Types[_type]){
      return MyTableFilterHubTypes.Types[_type]({
        configFilter,fnList
      });
    }
    // console.warn("MyTableFilterHub missing:",_type);
    return (
      <div className="filter-hub-empty"></div>
    )
  }
}

export default MyTableFilterDataHub;

import Constant from './Constant';
import Global from './Global';
import Color from './Color';
import {Auth} from './Auth';
import Config from './Config';
// import {F,FHelper} from '../libs/Firebase';
import GA from '../libs/GA';
import Store from './Store';
import M from '../libs/M';
import V from "../libs/Vanilla";
import {App, PageHelper, PermissionHelper, PermissionArea, TableHelper, CellHelper, OptionsHelper, DataHelper} from './App';
//Components
import Loading from '../components/Loading/Loading';
import InputEditor from '../views/Components/InputEditor';
import InputControl from '../views/Components/InputEditor/CustomControl';
import ProjectMenu from '../views/Components/ProjectMenu';
import PageHeader from '../views/Components/PageHeader';
import ProjectHeader from '../views/Components/ProjectHeader';
import MyTable from '../views/Components/Table/index2';//null
import TableFilter from '../views/Components/TableFilter';
import { Popover, Tooltip } from '../mreact/core';
import SelectColor from '../views/Components/Select/SelectColor';
import DropdownMenu from '../views/Components/DropdownMenu/DropdownMenu';
//Libs
import {Link} from 'react-router-dom';
import MyDialog from '../components/MyDialog';
import MyButton from '../components/MyButton';
import MyTab from '../components/MyTab';
import Text from './Text';
import GlobalSetting from './GlobalSetting';
import SampleData from './SampleData';
import Utils from './Utils';
import Paste from  './Paste';

const _test = {
  Constant,
  Config,
  Global,
  Auth,
  Color,
  GA,
  Store,
  M,
  V,
  App,
  PageHelper,
  PermissionHelper,
  PermissionArea,
  TableHelper,
  CellHelper,
  OptionsHelper,
  DataHelper,
  /**Components */
  Loading,
  InputEditor,
  InputControl,
  ProjectMenu,
  PageHeader,
  ProjectHeader,
  MyTable,
  TableFilter,
  Popover, Tooltip,
  SelectColor,
  DropdownMenu,
  /**Libs */
  Link,
  MyDialog,
  MyButton,
  MyTab,
  Text,
  GlobalSetting,
  SampleData,
  Utils,
  Paste
}
let _keys = Object.keys(_test);
for(let k of _keys){
  if(_test[k]==null){
    console.warn("import helpers null:",k);
  }
}
export {
  Constant,
  Config,
  Global,
  Auth,
  Color,
  GA,
  Store,
  M,
  V,
  App,
  PageHelper,
  PermissionHelper,
  PermissionArea,
  TableHelper,
  CellHelper,
  OptionsHelper,
  DataHelper,
  /**Components */
  Loading,
  InputEditor,
  InputControl,
  ProjectMenu,
  PageHeader,
  ProjectHeader,
  MyTable,
  TableFilter,
  Popover, Tooltip,
  SelectColor,
  DropdownMenu,
  /**Libs */
  Link,
  MyDialog,
  MyButton,
  MyTab,
  Text,
  GlobalSetting,
  SampleData,
  Utils,
  Paste,
}
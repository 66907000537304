import React, { Component } from 'react';
import {
  Alert, Button, Card, CardBody
} from '../../_importComponent';
import { classnames } from '../../_importLibs';
import { HConstant, HUI, HUtils, HText, HEE} from '../../_import'
import { MyUIHelpers, MyTableFilterHub, MyUIButton} from '../../_importMyComponent'
import {ApiGeneric} from '../../_importService';
import { PageHelperV1, PageHeaderV1, AppHelperV1, TableFilterV1, ProjectMenuV1} from '../../_importV1'
import { ProjectHeaderV1} from '../../_importV1b'
import {MyUI,HOptions} from "@macashipo/mlib"
import ModalControl from '../Components/ModalControl';
import ModalPhoto from '../Components/ModalPhoto/ModalPhoto';
import Signature from '../Components/Signature/Signature';
import BelowFilter from './BelowFilter';
import BelowHeader from './BelowHeader';
import CChart from './Chart';
import FormAddNew from './FormAddNew';
import ModalAddNew from './ModalAddNew';
import CTable from './Table';
import A from '../../helpers/A'

class Page extends Component {
  constructor(props) {
    super(props);
    const {match} = props;
    this.state = { 
      isAddNew: false,
      options: {},
      error: null,
      defaultExpand: true,
    };

    console.log('constructor Category:',this.props,match);
    this._title = this.props.title||'Category';
    this._screenCode = this.props.screenCode||'CATEGORY';
    if(this._screenCode=="CATEGORY"){
      console.warn("Page not set screenCode",this.props.screenCode);
    }
    this._apiPath = this.props.controller;
    this._config = this.props.config||{};
    this._fnList = this.props.fnList||{};
    
    this._headerConfig = this._config.Header || {
      show: true,
      showAddNew: true,
    };
    this._tableConfig = this._config.Table || {};
    this._filterConfig = this._config.Filter||{
      require4ShowData: false,
      show: true,
      listInList: [],
      data: []
    };
    this._pageConfig = this._config.Page||{};
    this._addNewConfig = this._config.AddNew ||{};
    this._expanderConfig = this._config.Expander || {};
    this._detailConfig = this._config.Detail ||{};
    this._belowFilterConfig = this._config.BelowFilter || null;
    // console.log('this._headerConfig:',this._headerConfig);
    // console.log('this._tableConfig:',this._tableConfig);
    // console.log('this._filterConfig:',this._filterConfig);
    // console.log('this._pageConfig:',this._pageConfig);
    // console.log('this._addNewConfig:',this._addNewConfig);
    // console.warn('this._belowFilterConfig:',this._belowFilterConfig);

    if(props.isWithProjectId==true){
      this._pageConfig.isWithProjectId=true;      
      if(props.projectId){
        this._projectId = props.projectId;
        // console.warn('this._projectId 1:',this._projectId);
      }
    }
    if(this._pageConfig.isWithProjectId==true){
      if(match && match.params!=null && match.params.projectId!=null){
        this._projectId = match.params.projectId;
        // console.warn('this._projectId 2:',this._projectId);
      }
      else if(props.projectId){
        this._projectId = props.projectId;
      }      
    }
    if(this._projectId && this._headerConfig.showProjectMenu==null){
      this._headerConfig.showProjectMenu = false;//mac dinh false het
    }
    if(this._projectId && this._headerConfig.showProjectHeader==null){
      this._headerConfig.showProjectHeader = true;
    }
    if(props.isDetail===true){
      this._headerConfig.showProjectMenu = false;
      this._headerConfig.showProjectHeader = false;
    }
    this._allConfig = {
      Page: this._pageConfig,
      Header: this._headerConfig,
      AddNew: this._addNewConfig,
      Expander: this._expanderConfig,
    }

    this._sharedFnList = {
      fnGetProjectId: this.getProjectId,
      fnSearch: this.fnSearch,
      fnReloadList: this.reloadTable,
      // fnGetParamsURL: this.getParamsURL,
      // fnGetScreenCode: this.getScreenCode,
      // fnGetOptions: this.getOptions,
      // fnGetApiController: this.getApiController,
      // fnGetRequestData: this.getRequestData,
      // fnGetListObj: this.getListObject,
      // fnGetListData: this.getListData,
      // fnGetListExtraData: this.getListExtraData,
      // fnGetConfigFilter: this.getConfigFilter,
      // fnRequestList: this.requestList,
      // fnGetConfigFromScreenConfig: this.getConfigFromScreenConfig,
      // fnRequestUpdate: (row, fieldName, cellValue, opts)=>{//fnUpdateCell tu version solid
      //   console.warn("fnRequestUpdate:",row,fieldName,cellValue);
      //   this.requestUpdateField({
      //     row: row,
      //     requestData:{
      //       Id: row.Id,
      //       Values: [
      //         {FieldName: fieldName, NewValue: cellValue}
      //       ]
      //     },
      //     opts: opts
      //   })                              
      // },
    }
  }

  componentDidMount(){
    PageHelperV1.componentDidMount(this._title,this._onRequestOptions,{
      isNotChangePageTitle: !this.isNeedChangePageTitle(),//bo sung ! cho dung
      isNotShowLoading: this._pageConfig.isNotChangePageTitle || this._pageConfig.isNotShowLoading,
    });
    this._initEventMsg() // WEBAPI-6160
    //update component
    if(this._fnList && this._fnList.onComponentDidMount){
      this._fnList.onComponentDidMount(this)
    }
  }

  getProjectId=()=>{
    return this._projectId;
  }

  isRootPage=()=>{
    const {fnList} = this.props;
    if(fnList && fnList.isRootPage){
      return fnList.isRootPage()
    }
    return true;
  }
  isNeedChangePageTitle=()=>{
    if(this._pageConfig.isNotChangePageTitle===true){
      return false
    }
    if(this.isRootPage()===false){
      return false
    }
    return true
  }

  getQueryConfig=()=>{
    this._config = this.props.config||{};
    this._queryConfig = this._config.Query ||{};
    if (this.props.queryConfig){
      this._queryConfig = Object.assign(this._queryConfig, this.props.queryConfig);
    }
    // console.warn("this._queryConfig:",this._queryConfig,this.props.config)
    return this._queryConfig
  }

  getConfigPage=()=>{
    const {configController} = this.props;
    // console.warn("getConfigPage:",this.props)
    return configController;
  }
  getListComponentExtraTable=()=>{
    if(this._cTable){
      return this._cTable.getListComponentExtraTable();
    }
  }

  reloadTable=()=>{
    if(this._cTable!=null){
      this._cTable.reload();
    }
  }

  reloadOptionsAndList=()=>{
    this._onRequestOptions({
      optsLoadOptionsInList: {
        notNeedRequestList: false,
        firstLoad: false,
      }
    })
  }

  fnSearch=(text)=>{
    if(this._cTable!=null){this._cTable.handleSearch(text)}
  }

  reloadPage=()=>{
    window.location.reload();
  }

  getRefTable=()=>{
    return this._cTable;
  }

  getOptions=()=>{
    return this.state.options;
  }

  //hàm lấy data của table
  getDataTable=()=>{
    if(this._cTable!=null){
      return this._cTable.getData();
    }
  }

  getParentData=()=>{
    const {parentData} = this.props;
    return parentData;
  }

  getExtraDataTable=()=>{
    if(this._cTable!=null){
      return this._cTable.getExtraData();
    }
  }

  getDataTableFiltered=()=>{
    if(this._cTable!=null){
      return this._cTable.getDataFiltered();
    }
  }

  //lấy cols
  getColsTable=()=>{
    if(this._cTable!=null){
      return this._cTable.getCols();
    }
  }
  
  _parseConfigFromOptions=({options}={})=>{
    HUtils.parseOptionsLogoConfig(options);
    if(options && options.Configs && options.Configs.ScreenConfig){
      try {
        let _config = JSON.parse(options.Configs.ScreenConfig);
        if(_config){
          if(_config.Filter){
            this._filterConfig = Object.assign(this._filterConfig,_config.Filter);
          }
          // console.warn("Test config Filter");
          // this._filterConfig.type = "soezy_report";
          // this._filterConfig.isUpdateAfterLoadList = true;
          if(_config.Header){
            this._headerConfig = Object.assign(this._headerConfig,_config.Header);
          }
          if(_config.Table){
            this._tableConfig = Object.assign(this._tableConfig,_config.Table);
          }
          if(_config.Page){
            this._pageConfig = Object.assign(this._pageConfig,_config.Page);
          }
          if(_config.AddNew){
            this._addNewConfig = Object.assign(this._addNewConfig,_config.AddNew);
          }
          if(_config.Detail){
            this._detailConfig = Object.assign(this._detailConfig,_config.Detail);
          }
          if(_config.BelowFilter){
            this._belowFilterConfig = Object.assign(this._belowFilterConfig||{},_config.BelowFilter);
          }
          if(_config.Expander){
            this._expanderConfig = Object.assign(this._expanderConfig,_config.Expander);
          }
        }
        console.log('_config:',_config);
      } catch (error) {
        console.warn('Cant parse options config:',error);
      }
    }
    let _UITypeContents = HUtils.Obj.get(options,"Configs.UITypeContents");
    if(_UITypeContents){
      MyUIHelpers.addListUI(_UITypeContents);
    }
  }

  _getConfigFromConfigPage=(key)=>{
    const {getConfigFromPageConfig,configController} = this.props
    if(getConfigFromPageConfig){
      return getConfigFromPageConfig(key,null);
    }
    else if(configController){
      return HUtils.Obj.get(configController,`ConfigObj.${key}`);
    }
  }
  _initEventMsg=()=>{
    const _eventMsg = this._getConfigFromConfigPage(HConstant.ConfigPage.needReceiveMsg);
    this._fnFromMsg = {
      updateRowInList: this._updateRowInList,
      reloadList: this.reloadTable //WEBAPI-6227
    }    
    if(_eventMsg){
      console.warn("_eventMsg", _eventMsg)
      if(typeof(_eventMsg) == "string"){
        HEE.on(_eventMsg, (obj)=>{
          if(this._fnFromMsg.hasOwnProperty(_eventMsg)){
            this._fnFromMsg[_eventMsg](obj)
          }
        })
      }
      else if(Array.isArray(_eventMsg)){
        for(let i =0; i<_eventMsg.length; i++){
          HEE.on(_eventMsg[i], (obj)=>{
            if(this._fnFromMsg.hasOwnProperty(_eventMsg[i])){
              this._fnFromMsg[_eventMsg[i]](obj)
            }
          })
        }
      }
    }
  }
  _updateRowInList=(obj)=>{
    const dataList = this.getDataTable();
    const newData = HUtils.Obj.get(obj,"response.Data")
    // console.warn("_updateRowInList:", obj)

    if(newData && dataList && dataList.length>0){
      let _itemWillUpdate = dataList.find((v)=> v.Id === newData.Id)
      if(_itemWillUpdate){
        HUtils.updateDataWithNewData(_itemWillUpdate, newData);
        this.forceUpdate()
      }
    }
  }
  _getSharedQueryData=()=>{
    const {configController,pageMatch, fnList} = this.props;
    let _data = {};
    if(this.props.match && this.props.match.params){
      _data = Object.assign({},this.props.match.params);
    }
    if(pageMatch && pageMatch.params){
      _data = Object.assign({},pageMatch.params);
    }
    if(this._pageConfig.isWithProjectId==true && this._projectId){
      _data.ProjectId = this._projectId;
    }    
    if(configController && configController.Id){
      _data.ScreenGUID = configController.Id;
    }
    _data = Object.assign(_data,this.getQueryConfig()); 
    if(this._fnList && this._fnList.getQuery){
      // console.warn("aaaa", this._fnList.getQuery())
      _data = Object.assign(_data, this._fnList.getQuery());
    }
    _data = Object.assign(_data, HConstant.runMyPageFnList(fnList,this,HConstant.MyPage.FnList.getSharedQueryData));
    // console.warn(`_getSharedQueryData:`,_data,this.getQueryConfig(),this.props.match,pageMatch);
    return _data;
  }

  _onRequestOptions=({optsLoadOptionsInList}={})=>{
    if(this._pageConfig.isGetOptions===false || this._pageConfig.staticOptions!=null){
      let _options = this.state.options || {};
      if(this._pageConfig.staticOptions!=null){
        _options = Object.assign(_options,this._pageConfig.staticOptions);
        this._parseConfigFromOptions({options:_options});
        // this._parseButtonListFromOptions({options:_options});
      }
      this.setState({options:_options,defaultExpand:this._expanderConfig.defaultExpand,error:null,},()=>{
        console.warn('options:',_options,this.state,this._expanderConfig);
      });
      this._loadTable({
        options: _options,
        optsLoadOptionsInList
      });
      // if(this._cTable!=null){
      //   this._cTable.loadWithOptions(_options);
      // }
      // if(this._cModalAddNew!=null){
      //   this._cModalAddNew.loadWithOptions(_options);
      // }
      if(this._cFilter!=null){
        this._cFilter.loadWithOptions(_options);
      }
      return;
    }
    let _data = this._getSharedQueryData();
    console.log('_onRequestOptions :',this.props,this._pageConfig,this._projectId,this.getQueryConfig());
    ApiGeneric.generic({
      request:{
        method: 'POST',
        path: this._apiPath,
        name: ApiGeneric.NAME.Options
      },
      data:_data,
      successCallBack:(response)=>{
        let _options = response.Data;
        this._parseConfigFromOptions({options:_options});
        // this._parseButtonListFromOptions({options:_options});
        this.setState({options:_options,defaultExpand:this._expanderConfig.defaultExpand,error:null,},()=>{
          if(this._fnList && this._fnList.oneLoadFinishOptions){
            this._fnList.onLoadFinishOptions(_options)
          }
          if(this.state.defaultExpand===false){
            HUI.LoadingScreen.hide();
          }
          else{
            this._loadTable({
              options: _options,
              optsLoadOptionsInList
            });
          }          
        });        
      },
      errorCallBack:(error,response)=>{
        HUI.LoadingScreen.showError(response!=null?response.Msg:HText.get('msg_error_get_options'),()=>{
          HUI.LoadingScreen.show();
          this._onRequestOptions();
        })
        this.setState({error:error!=null?error:response});
      }
    })
  }

  _loadTable=({options,optsLoadOptionsInList}={})=>{
    if(this._cTable!=null){
      this._cTable.loadWithOptions(options,optsLoadOptionsInList);
    }
    if(this._cModalAddNew!=null){
      this._cModalAddNew.loadWithOptions(options);
    }
  }

  getAllConfig=()=>{
    return this._allConfig;
  }

  _onRequestAddNew=(data,cb)=>{
    let _query = this._getSharedQueryData();
    // let _query = {};
    // if(this._pageConfig.isWithProjectId && this._projectId){
    //   _query.ProjectId = this._projectId;
    // }
    if(data){
      _query = Object.assign(_query,data);
    }
    ApiGeneric.generic({
      request:{
        method: 'POST',
        path: this._apiPath,
        name: ApiGeneric.NAME.Add
      },
      data: _query,
      successCallBack:(response)=>{
        //needClearFilter
        if(this._headerConfig && this._headerConfig.clearFilterAfterAddNew==true){
          this._clearFilter();
        }

        if(this._cTable!=null){
          this._cTable.reload();
        }
        if(cb!=null){
          cb({success:true});
        }
      },
      errorCallBack:(error,response)=>{
        if(cb!=null){
          cb({success:false});
        }
      }
    })
  }

  _clearFilter=()=>{
    if(this._cFilter && this._cFilter.clearCurrentFilter){
      this._cFilter.clearCurrentFilter();
    }
  }

  _renderFooter=()=>{
    const options = this.state.options;
    if(options && options.FooterConfig && options.FooterConfig.IsShow==true){
      let _align = options.FooterConfig.ButtonListAlignment;
      if(_align){ _align = _align.toLowerCase() }
      if(_align=='center'){_align = 'center';}
      else if(_align=='left'){_align = 'flex-start';}
      else if(_align=='right'){_align = 'flex-end';}
      else{_align = 'flex-end';}
      return (
        <Card>
          <CardBody>
            <MyUI type="solid_pageheaderbutton"
              styleContainer={{justifyContent:_align}}
              inBox = {false}
              listData = {options.FooterConfig.ButtonList}
              projectId = {this._projectId}
              screenCode= {this._screenCode}
              getOptions = {this.getOptions}
              getDataTable = {this.getDataTable}
              getExtraDataTable={this.getExtraDataTable}
              getRefTable = {this.getRefTable}
              reloadTable = {this.reloadTable}
              reloadOptionsAndList ={this.reloadOptionsAndList}
              queryConfig = {this.getQueryConfig()}
              headerConfig = {this._headerConfig}
              onClearFilter={this._clearFilter}
              apiController={this.props.controller}
              fnList={this._fnList}
              getAllConfig={this.getAllConfig}
              getQuery={()=>{
                // console.warn('getQuery',getFilterQuery);
                let _query = {};
                if(this._cTable){
                  let _filterQuery = this._cTable.fnGetFilterQuery();
                  _query = Object.assign(_query,{
                    FilterQuery: _filterQuery || {}
                  })
                  let _requestQuery = this._cTable.fnGetRequestQuery();
                  _query = Object.assign(_query,_requestQuery);
                }
                return _query;
              }}
            />
          </CardBody>
        </Card>
      )
    }
  }
  _renderHeader=()=>{
    //console.warn('Category this: ', this._headerConfig);
    let _headerConfig = this._headerConfig;
    let _options = this.state.options || {};    
    if(_headerConfig && _headerConfig.show==true){    
      // console.warn(`_headerConfig`,_headerConfig,this._expanderConfig,this.state);  
      let _lefComponent = (<div style={{display:'flex',alignItems:'center',justifyContent:'center',color:'#7d7d7d',width:'30px'}}><i className="fa fa-database"/></div>);
      let _rightComponent = (<div></div>);
      
      if(_headerConfig.showProjectMenu==true && this._projectId){
        _lefComponent = ProjectMenuV1.Helper.buildLeftComponent(this._projectId);
      }
      else if(this._expanderConfig.hasPageExpand){
        _lefComponent = (
          <div style={{display:'flex',alignItems:'center',justifyContent:'center',color:'#7d7d7d',width:'30px'}}>
            <Button style={{color:'#44cfae',padding:'0px'}} color="link" onClick={()=>{
              this.setState({defaultExpand:!this.state.defaultExpand},()=>{    
                this._loadTable({options:this.state.options});          
              })
            }}>
              <i style={{fontSize:'25px'}} className={`fa ${this.state.defaultExpand?'fa-chevron-circle-up':'fa-chevron-circle-down'}`}></i>
            </Button>
          </div>
        );
      }
      let _titleAddNew = HText.get('btn_new');
      if(_headerConfig.iconAddNew){
        _titleAddNew = <i className={_headerConfig.iconAddNew} />;
      }
      else if(_headerConfig.titleAddNew){
        _titleAddNew = _headerConfig.titleAddNew;
      }

      _rightComponent = (
        <div style={{display:'flex',flexDirection:'row',alignItems:"center"}}>
          {/* {
            this._cListBtnFromSVOptions !=null && this._cListBtnFromSVOptions
          } */}
          {
            this.props.renderTabHeader!=null&&this.props.showTabOnHeaderContent == true &&
            <div className="">
              {
                this.props.renderTabHeader({
                })
              }
            </div>
            
          }
          <MyUI type="solid_pageheaderbutton"
            inBox = {false}
            listData = {_options.ButtonList}
            projectId = {this._projectId}
            screenCode= {this._screenCode}
            getOptions = {this.getOptions}
            getDataTable = {this.getDataTable}
            getExtraDataTable={this.getExtraDataTable}
            getRefTable = {this.getRefTable}
            reloadTable = {this.reloadTable}
            reloadOptionsAndList ={this.reloadOptionsAndList}
            queryConfig = {this.getQueryConfig()}            
            headerConfig = {this._headerConfig}
            onClearFilter={this._clearFilter}
            apiController={this.props.controller}
            fnList={this._fnList}
            getAllConfig={this.getAllConfig}
            getQuery={()=>{
              // console.warn('getQuery',getFilterQuery);
              let _query = {};
              if(this._cTable){
                let _filterQuery = this._cTable.fnGetFilterQuery();
                let _sortQuery = this._cTable.fnGetSortQuery();
                _query = Object.assign(_query,{
                  FilterQuery: _filterQuery || {},
                  SortQuery: _sortQuery || [],//sortQuery la array
                })
                let _requestQuery = this._cTable.fnGetRequestQuery();
                _query = Object.assign(_query,_requestQuery);
              }
              return _query;
            }}
          />
          {/* {
            _headerConfig.listComponent !=null &&
            _headerConfig.listComponent
          } */}
          {

            _headerConfig.listComponent !=null &&
            (
              typeof _headerConfig.listComponent == 'function' ?
              _headerConfig.listComponent({
                props: this.props,
                component: this,
                options: this.state.options,
                //truyền hàm k có dấu (), nếu có () là truyền kq của hàm
                getDataTable:this.getDataTable,
                getComponentTable: ()=>{
                  return this._cTable;
                },
                getColsTable:this.getColsTable,
              })
              :
              _headerConfig.listComponent

            )
          }
          {
            _headerConfig.componentAddNew !=null &&
            <_headerConfig.componentAddNew ref={r=> {this._cAddNew=r;}} projectId={this._projectId} 
              onRequestAddNew={this._onRequestAddNew}
              options={this.state.options} getOptions={this.getOptions}/>
          }
          {
            _headerConfig.showAddNew && 
            <MyUIButton.loading 
              ref={r=>{this._cBtnAddNew=r;}}
              disabled={this.state.isAddNew} 
              style={{marginRight:'5px'}} 
              color="primary"
              className="mbt-addnew"
              onClick={()=>{
                if(this._addNewConfig!=null && this._addNewConfig.data!=null){
                  if(this._cModalAddNew!=null){
                    this._cModalAddNew.show();
                  }
                }
                else{
                  if(this._cBtnAddNew && this._cBtnAddNew.showLoading){
                    this._cBtnAddNew.showLoading();
                  }
                  this._onRequestAddNew(null,()=>{
                    if(this._cBtnAddNew && this._cBtnAddNew.hideLoading){
                      this._cBtnAddNew.hideLoading();
                    }
                  });
                }
              }}
              >{this.state.isAddNew==true&&<i className="fa fa-spinner fa-spin"/>} 
              {
                this._headerConfig.onlyButtonList==true?
                <i className={"fa fa-plus"} />
                :
                _titleAddNew
              }                            
            </MyUIButton.loading>
          }
          
          </div>
      );

      if(this._headerConfig.onlyButtonList===true){
        return (
          <div className={`onlyBtnListHeader no-print ${this._headerConfig.onlyButtonListClassName||""}`}>
            {_rightComponent}
          </div>
        )
      }

      return (
        <div className="header-container page-header">
          {
            this._headerConfig.showProjectHeader && this._projectId &&
            <ProjectHeaderV1 projectId={this._projectId} screenCode={this._screenCode} />
          }
          <PageHeaderV1 
            ref={r=> {this._cHeader=r;}}
            headerConfigs={this._headerConfig}
            expanderConfig = {this._expanderConfig}
            title={this._headerConfig.title || this._title} 
            projectId={this._projectId}
            leftComponent={_lefComponent}
            rightComponent={_rightComponent}
            isShadow={this._headerConfig.isShadow!=null?this._headerConfig.isShadow:true}
            showPrint={this._headerConfig.showPrint!=null?this._headerConfig.showPrint:true}
            showProjectTitle={this._headerConfig.showProjectTitle!=null?this._headerConfig.showProjectTitle:true}
            style={this._headerConfig.style!=null?this._headerConfig.style:{}}
            fnList={this._sharedFnList}
            />
        </div>
      )
    }
  }

  _updateFilterConfigWithExtraDataColumn=(extraData)=>{
    let _dataFilter = this._filterConfig.data||[];
    let _listInList = this._filterConfig.listInList||[];
    //Add filter search if config show
    //if showSearch = false: hide filter search
    if (this._filterConfig && this._filterConfig.showSearch!=false){
      let _isExist = false;
      let _noSymbol = false;
      let _instant = false;
      if(this._filterConfig.searchNoSymbol){
        _noSymbol = true;
      }
      if(this._filterConfig.searchInstant){
        _instant = true;
      }

      for(let i =0;i<_dataFilter.length;i++){
        let _item = _dataFilter[i];
        if(_item.type==TableFilterV1.Type.search){
          _isExist = true;
          if(_noSymbol){
            _item.nosymbol = _noSymbol;
          }          
          break;
        }
      }
      console.log("_updateFilterConfigWithExtraDataColumn",_isExist,this._filterConfig);
      if(_isExist==false){        
        _dataFilter.push({
          type: TableFilterV1.Type.search,
          nosymbol: _noSymbol,
          instant: _instant,
          more: this._filterConfig.moreSearch
        })
      }      
    } 

    if(extraData && extraData.Columns){
      let _fields = Object.keys(extraData.Columns);
      let _isFilterServer = false;
      let _listFieldQuery = {};
      let _listFieldQueryObj = {};
      let _moreOfField = {};
      for(let _k of _fields){
        let _col = extraData.Columns[_k];
        // console.warn('col in filter:',_col,_k);
        if(_col && _col.Filter && _col.Filter.type){
          let _moreProps = {};
          let _needConvert = true;
          if(_col.Filter.optionKey && _col.Filter.optionKey.indexOf('_InList')>-1){
            _needConvert = false;
            _listInList.push(_col.Filter.optionKey.replace('_InList',''));            
          }
          else if(_col.Filter.optionKey && (_col.Filter.optionKey.toLowerCase() == 'inlist' || _col.Filter.optionKey.toLowerCase().endsWith('_inlist')==true)){
            // console.warn(`filter:`,_col.Filter.optionKey,_k)
            _needConvert = false;
            _col.Filter.optionKey = `${_k}_InList`;
            _listInList.push(_k);  
          }

          let _type = _col.Filter.type;
          let _title = _col.Filter.title || _col.Header || _k;
          let _optionKey = _col.Filter.optionKey;
          let _fieldName = _k;
          let _fieldQuery = null;

          let _more = {};
          if(_col.Filter.more){
            try {
              _more = JSON.parse(_col.Filter.more);
              _moreOfField[_fieldName] = _more;
              if(_more.col){
                _moreProps.col = _more.col;
                console.warn("col:",_moreProps);
              }
              if(_more.isServer==true){
                _isFilterServer = true;
                _fieldName = `${_k}_OnServer`;
                _fieldQuery = _more.fieldQuery || _more.fQuery || _k;//bo sung fQuery cho dong bo voi fQueryStart,fQueryEnd
                _listFieldQuery[_fieldName] = _fieldQuery;
                _moreProps['selectProps'] = {
                  multi: false,
                  closeOnSelect: true,
                };
                _listFieldQueryObj[_fieldName] = {};
                if(_more.fQueryStart){
                  _listFieldQueryObj[_fieldName]["fQueryStart"] = _more.fQueryStart;
                }
                if(_more.fQueryEnd){
                  _listFieldQueryObj[_fieldName]["fQueryEnd"] = _more.fQueryEnd;
                }
                
                //test
                // if(_type=="checkbox"){
                //   _moreProps.defaultValue = true;
                // }
              }
              if(_more && _moreProps){
                _moreProps = Object.assign(_more,_moreProps);
              }
            } catch (error) {
              console.warn('Parse Json error:',error,_col.Filter.more);
            }
          }
          _dataFilter.push({
            type: _type,
            title: _title,
            optionKey: _optionKey,
            fieldName: _fieldName,
            needConvert: _needConvert,
            fieldQuery: _fieldQuery,
            more: _more,
            ..._moreProps
          })          
        }
      }

      if(_isFilterServer==true || this._filterConfig.hasFilterServer){
        this._filterConfig.customQueryFromSever = function({obj}){
          var _query = {};
          if(obj){
            for(let _k in obj){
              let _item = obj[_k];
              // console.warn('customQueryFromSever custom sever:',obj,_k,_item,_listFieldQuery,_moreOfField);
              if(_k && _k.startsWith("{")){
                try {
                  let _customObjFromField = JSON.parse(_k);
                  if(_customObjFromField.fieldName && _customObjFromField.fieldQuery){
                    _k = _customObjFromField.fieldName;
                    
                    if(typeof _customObjFromField.fieldQuery=="string"){
                      _listFieldQuery[_k] = _customObjFromField.fieldQuery;
                    }
                    else if(typeof _customObjFromField.fieldQuery=="object"){
                      _listFieldQueryObj[_k] = _customObjFromField.fieldQuery;
                    } 
                    // console.warn("_listFieldQuery:",_listFieldQuery,_k,_listFieldQuery[_k]);
                  }
                  
                } catch (error) {
                  console.warn("parse custom field",error);
                }
              }
              if(_item!=null){
                if(_k && _listFieldQuery[_k] && _k.indexOf('_OnServer')>-1){
                  let _fieldName = _k.replace('_OnServer','');
                  if(typeof _item == 'boolean'){//type checkbox
                    _query[_listFieldQuery[_k]] = _item;
                  }
                  else if(_item.value!=null && _item.value.length>0){
                    if(typeof _item.value == 'string'){
                      _query[_listFieldQuery[_k]] = _item.value;
                    }
                    else if(Array.isArray(_item.value)){
                      if(_moreOfField[_fieldName] && _moreOfField[_fieldName].dataType=="array"){
                        _query[_listFieldQuery[_k]] = _item.value;
                      }
                      else{
                        _query[_listFieldQuery[_k]] = _item.value.join(',');
                      }
                      // if(_item.value.length==1){
                      //   _query[_listFieldQuery[_k]] = _item.value[0];
                      // }
                      // else{
                      //   _query[_listFieldQuery[_k]] = _item.value;
                      // }                      
                    }                    
                  }
                }
                
                if(_item.start!=null){
                  let _fFrom = "FromDate";
                  if(_listFieldQueryObj[_k] && _listFieldQueryObj[_k]["fQueryStart"]!=null){
                    _fFrom = _listFieldQueryObj[_k]["fQueryStart"];
                  }
                  _query[_fFrom] = _item.start;                
                }
                if(_item.end!=null){
                  let _fTo = "ToDate";
                  // console.warn("fTo:",_fTo,_listFieldQueryObj,_k,_listFieldQueryObj[_k]);
                  if(_listFieldQueryObj[_k] && _listFieldQueryObj[_k]["fQueryEnd"]!=null){
                    _fTo = _listFieldQueryObj[_k]["fQueryEnd"];
                  }
                  _query[_fTo] = _item.end;                
                }
              }              
            }
          }
          // console.warn('customQueryFromSever query:',_query);
          return _query;
        };
      }
    }
    // console.warn('_updateFilterConfigWithExtraDataColumn data:',this._filterConfig);
    this._filterConfig.data=_dataFilter;
    this._filterConfig.listInList =_listInList;

    //Nếu data filter ko có phần tử nào thì không hiển thị vùng filter (show = false)
    if(this._filterConfig.data!=null && this._filterConfig.data.length==0){
      this._filterConfig.show = false;
    }
    if(this._filterConfig.alwayShow==true){
      this._filterConfig.show = true;
    }
    // console.log('_updateFilterConfigWithExtraDataColumn:',extraData,this._filterConfig);
  }

  _renderTopHeader=()=>{
    let _pageConfig = this._pageConfig;
    let _show = HUtils.Obj.get(_pageConfig,"topHeader.show");
    let _typeTopHeader = HUtils.Obj.get(_pageConfig,"topHeader.type");
    let _styleTopHeader = HUtils.Obj.get(_pageConfig,"topHeader.style");
    if(_typeTopHeader && _show!==false){
      return (
        <MyUI type={_typeTopHeader} style={_styleTopHeader} fnList={this._sharedFnList}/>
      )
    }
  }
  _renderTopTable=()=>{
    let _pageConfig = this._pageConfig;
    let _show = HUtils.Obj.get(_pageConfig,"topTable.show");
    let _typeTopTable = HUtils.Obj.get(_pageConfig,"topTable.type");
    let _styleTopTable = HUtils.Obj.get(_pageConfig,"topTable.style");
    console.warn("_renderTopTable:",_typeTopTable)
    if(_typeTopTable && _show!==false){
      return (
        <MyUI type={_typeTopTable} style={_styleTopTable} fnList={this._sharedFnList}/>
      )
    }
  }
  handleHideCol=(listFields,opts)=>{
    if(this._cTable!=null){this._cTable.handleHideCol(listFields,opts)}
  }
  _renderChart=()=>{
    if(this._fnList && this._fnList.customRenderChart){
      this._fnList.customRenderChart((moreProps={})=>{
        return (
          <CChart 
            ref={r=> {this._cChart=r;}}
            apiController={this.props.controller}
            prefixId={this.props.prefixId!=null?`${this.props.prefixId}`:""}
            options={this.state.options} 
            projectId={this._projectId} 
            screenCode={this._screenCode}
            getOptions = {this.getOptions}
            getDataTable = {this.getDataTable}
            getExtraDataTable = {this.getExtraDataTable}
            getDataTableFilterd = {this.getDataTableFiltered}
            reloadTable = {this.reloadTable}
            queryConfig = {this.getQueryConfig()}
            headerConfig = {this._headerConfig}
            getFilterQuery={()=>{
              if(this._cTable){
                return this._cTable.fnGetFilterQuery();
              }
              return {};
            }}
            {...moreProps}
          />
        )
      },{
        fnList:{
          getDataTable: this.getDataTable,
          getOptions: this.getOptions
        }
      }) 
    }
    else{
      return (
        <CChart 
          ref={r=> {this._cChart=r;}}
          apiController={this.props.controller}
          prefixId={this.props.prefixId!=null?`${this.props.prefixId}`:""}
          options={this.state.options} 
          projectId={this._projectId} 
          screenCode={this._screenCode}
          getOptions = {this.getOptions}
          getDataTable = {this.getDataTable}
          getExtraDataTable = {this.getExtraDataTable}
          getDataTableFilterd = {this.getDataTableFiltered}
          reloadTable = {this.reloadTable}
          queryConfig = {this.getQueryConfig()}
          headerConfig = {this._headerConfig}
          getFilterQuery={()=>{
            if(this._cTable){
              return this._cTable.fnGetFilterQuery();
            }
            return {};
          }}
        />
      )
    }

  
  }
  render() {
    const {configSharedOnServer} = this.props;
    let _options = this.state.options || {};
    let _listButton = null;
    if ( _options.HeaderConfig){
      _listButton = _options.HeaderConfig.ButtonList;
      if(_options.HeaderConfig.Title){
        this._title = _options.HeaderConfig.Title;
        AppHelperV1.changePageTitle(this._title);
      }
    }
    // console.warn("category:",this.state,this._fnList);
    if(this._expanderConfig.hasPageExpand===true && this.state.defaultExpand===false){
      return (
        <div className={classnames("animated",configSharedOnServer!=null?configSharedOnServer.className:"")} style={configSharedOnServer!=null?configSharedOnServer.style:{}}>
          {
            this._renderHeader()
          }
        </div>
      )
    }
    return (
      <div className={classnames("animated page-category",configSharedOnServer!=null?configSharedOnServer.className:"")} style={configSharedOnServer!=null?configSharedOnServer.style:{}}>
        <MyUI type="solid_pageheaderbutton"
          inBox = {true}
          listData = {_listButton}
          projectId = {this._projectId}
          screenCode= {this._screenCode}
          getOptions = {this.getOptions}
          getDataTable = {this.getDataTable}
          getExtraDataTable={this.getExtraDataTable}
          getRefTable={this.getRefTable}
          reloadTable = {this.reloadTable}
          queryConfig = {this.getQueryConfig()}
          apiController={this.props.controller}
          getQuery={()=>{
            // console.warn('getQuery',getFilterQuery);
            let _query = {};
            if(this._cTable){
              let _filterQuery = this._cTable.fnGetFilterQuery();
              _query = Object.assign(_query,{
                FilterQuery: _filterQuery || {}
              })
            }
            return _query;
          }}
        />
        {
          this._renderTopHeader()
        }
        {
          this._renderHeader()
        }
        <BelowHeader pageConfig={this._pageConfig} options={this.state.options}/>
        {/* {
          this._pageConfig && this._pageConfig.componentBelowHeader && 
            <this._pageConfig.componentBelowHeader/>
        }         */}
        <FormAddNew 
          ref={r=> {this._cFormAddNew=r;}}
          apiController={this.props.controller}
          options={this.state.options} 
          projectId={this._projectId} 
          screenCode={this._screenCode}
          getOptions = {this.getOptions}
          getDataTable = {this.getDataTable}
          getExtraDataTable = {this.getExtraDataTable}
          getDataTableFilterd = {this.getDataTableFiltered}
          reloadTable = {this.reloadTable}
          queryConfig = {this.getQueryConfig()}
          headerConfig = {this._headerConfig}
          addNewConfig = {this._addNewConfig}
          getQuery={()=>{
            // console.warn('getQuery',getFilterQuery);
            let _query = this._getSharedQueryData();
            if(this._cTable){
              let _filterQuery = this._cTable.fnGetFilterQuery();
              _query = Object.assign(_query,{
                FilterQuery: _filterQuery || {}
              })
            }
            return _query;
          }}
        />
        {
          this._filterConfig.show!==false &&
          <MyTableFilterHub configFilter={this._filterConfig} fnList={{
            innerRef:(r)=>{  
              if(this._cFilter==null && r){
                this._cFilter=r;
                // console.warn("this._cFilter:",this._cFilter);
                if(this._isLoadedOptionsForFilter!=true && this._waitingLoadFilter===true){//load options neu do load sau api list, luc do chua co this._cFilter
                  this._cFilter.loadWithOptions(this.state.options);
                  this._isLoadedOptionsForFilter = true;
                  this._waitingLoadFilter = false;
                }
              }                                            
            },
            manualLoadOptionsForFilter:()=>{
              let _options = this.state.options;
              console.warn("manualLoadOptionsForFilter:",_options);
              if(Object.keys(_options).length>0 && this._cFilter){
                this._cFilter.loadWithOptions(_options);   
                this._isLoadedOptionsForFilter = true;             
              }              
            },
            getOptions:()=>{
              return this.state.options;
            },
            getScreenCode:()=>{
              return this._screenCode;
            },
            onChangeFilter:(filter)=>{
              // console.warn("onChangeFilter:",filter);
              if(this._cTable!=null){this._cTable.handleFilterData(filter)}
            },
            onChangeSearch:(text)=>{
              if(this._cTable!=null){this._cTable.handleSearch(text)}
            },
            onHideCol:(listFields,opts)=>{
              if(this._cTable!=null){this._cTable.handleHideCol(listFields,opts)}
            },
          }}/>
        }        
        {
          this.state.isShowMsg != null &&
          <Alert className="no-print" color="warning" isOpen={this.state.isShowMsg} style={{marginBottom:'5px'}}>
            {this.state.msgShow}
          </Alert>
        }        
        {
          this._pageConfig && this._pageConfig.componentBelowFilter!=null ?
          // this._pageConfig.componentBelowFilter({projectId: this._projectId})
          <this._pageConfig.componentBelowFilter ref={r=> {this._cBelowFilter=r;}} projectId={this._projectId}/>
          :
          (
            this._belowFilterConfig !=null &&
            <BelowFilter 
              projectId={this._projectId}
              config={this._belowFilterConfig}
              type={this._belowFilterConfig.type}
            />
          )
        }
        {
          this._renderChart()
        }
        
        {
          this._renderTopTable()
        }
        <CTable 
          ref={r=> {this._cTable=r;}}
          title={this._title}
          onAddNew={this._onClickAddNew}
          onLoadListFinish={(data,error,response,opts)=>PageHelperV1.onLoadListFinish(data,error,response,(listData,error,response,opts)=>{
            // console.warn('onLoadListFinish:',listData,this.state,this._cFilter);
            if(response){
              if(this._pageConfig && this._pageConfig.updateTotalDataToTab===true){
                A.V1.updateTotalDataToTab(HUtils.Obj.get(response,"Data.Total"),this.getConfigPage())
              }
              
              if(response.Data && response.Data.ExtraData && (this._isUpdateFilterConfigFromExtraData!=true || this._filterConfig.isUpdateAfterLoadList===true)){//call one time, solve duplicate build
                if(this._filterConfig.isUpdateAfterLoadList===true && this._filterConfig){
                  this._filterConfig.data = [];
                }
                this._updateFilterConfigWithExtraDataColumn(response.Data.ExtraData);
                this._isUpdateFilterConfigFromExtraData = true;
              }
              // if(response.MsgShowInUI!=null && response.MsgShowInUI.length>0){//check MsgShowInUI inside function
              //   if(this._cBelowFilter && this._cBelowFilter.showMsg){
              //     this._cBelowFilter.showMsg(response.MsgShowInUI);
              //   }
              // }

              if(response.MsgShowInUI!=null){
                this.setState({
                  isShowMsg: true,
                  msgShow: response.MsgShowInUI,
                })
              }
              else{
                this.setState({
                  isShowMsg: false,
                  msgShow: "",
                })
              }
            }
            let _options = this.state.options;
            if(this._filterConfig.listInList){
              //do ham updateListInList ko return option ma chi update options, bo _options = ra
              HOptions.updateListInList(_options,this._filterConfig.listInList,listData,{
                listFieldTextByFieldOptionsInList: this._filterConfig.listFieldTextByFieldOptionsInList
              });
            }
            // console.warn("aaaa filteR:",this._cFilter,this._filterConfig,this._isLoadedOptionsForFilter);
            if(this._cFilter!=null){
              if(this._isLoadedOptionsForFilter!=true){//avoid call filter again after load options
                // console.warn("aaaa 2:",this._cFilter,this._filterConfig);
                this._cFilter.loadWithOptions(_options);
                this._isLoadedOptionsForFilter = true;;
              }
              else{
                this._cFilter.updateOptions(_options);
              }                
            }
            else{
              //truong hop filter chua duoc load component
              this._waitingLoadFilter = true;
            }
            if(this._cBelowFilter!=null){
              this._cBelowFilter.loadWithOptions({options:_options,data:listData,response:response});
            }
            if(this._cBelowTable!=null){
              this._cBelowTable.loadWithOptions({options:_options,data:listData,response:response});
            }
            if(this._fnList && this._fnList.fnCBRequestList){
              this._fnList.fnCBRequestList(listData,error,response,opts);
            }
          },this._cTable,opts)}
          onAfterFilter={(res) => {
            // console.warn('onAfterFilter:',res);
            if (this._cBelowFilter) this._cBelowFilter.loadWithOptions(res);
            if (this._cBelowTable) this._cBelowTable.loadWithOptions(res);            
            if (this._cChart) { this._cChart.onChangeData(res) }
          }}
          onAfterSearch={(res) => {         
            if (this._cChart) { this._cChart.onChangeData(res) }
          }}
          onAfterUpdateField={(res)=>{
            if (this._cBelowFilter) this._cBelowFilter.loadWithOptions(res);
            if (this._cBelowTable) this._cBelowTable.loadWithOptions(res);
            if(this._fnList && this._fnList.fnCBUpdateField){
              this._fnList.fnCBUpdateField();
            }
          }}
          onAfterDeleteItem={()=>{
            if(this._fnList && this._fnList.fnCBDeleteItem){
              this._fnList.fnCBDeleteItem();
            }
          }}
          onClickPhotoSite={(row,fieldName,screenCode,opts)=>{
            console.log('onClickPhotoSite:',row,fieldName,screenCode,this._pageConfig,this._cModalPhoto);
            if(this._cModalPhoto!=null){
              this._cModalPhoto.show(row,fieldName,screenCode||this._screenCode,opts);
            }
          }}
          onClickShowModalControl={(opts)=>{
            if(this._cModalControl!=null){
              this._cModalControl.show(opts);
            }
          }}
          onClearFilter={this._clearFilter}
          screenCode={this._screenCode}
          apiPath={this._apiPath}
          tableConfig={this._tableConfig}
          filterConfig={this._filterConfig}
          queryConfig={this.getQueryConfig()}
          detailConfig={this._detailConfig}
          pageConfig={this._pageConfig}
          pageMatch={this.props.pageMatch}
          projectId={this._projectId}
          getOptions = {this.getOptions}
          getParentData={this.getParentData}
          getSharedQueryData={this._getSharedQueryData}
          configController={this.props.configController}          
          onRequestOptions={this._onRequestOptions}
          fnList={{...this._sharedFnList,...this._fnList}}
        />
        {
          this._pageConfig && this._pageConfig.componentBelowTable &&
          <this._pageConfig.componentBelowTable ref={r=> {this._cBelowTable=r;}} projectId={this._projectId}/>
        } 
        {
          this._renderFooter()
        }
        {/* {this._renderSignature()}  */}
        <Signature 
          apiController={this.props.controller}
          options={this.state.options} 
          projectId={this._projectId} 
          screenCode={this._screenCode}
          getOptions = {this.getOptions}
          reloadTable = {this.reloadTable}
          queryConfig = {this.getQueryConfig()}
          headerConfig = {this._headerConfig}
          getFilterQuery={()=>{
            if(this._cTable){
              return this._cTable.fnGetFilterQuery();
            }
            return {};
          }}
        />
        <ModalAddNew 
          ref={(r)=>{this._cModalAddNew=r;}}
          options={this.state.options}
          onRequestAddNew={this._onRequestAddNew}
          onReloadList={()=>{
            if(this._cTable!=null){
              this._cTable.reload();
            }
          }}
          screenCode={this._screenCode}
          apiPath={this._apiPath}
          addNewConfig={this._addNewConfig}
        />
        {
          this._pageConfig.isWithModalControl == true &&
          <ModalControl ref={r=>{this._cModalControl=r;}} projectId={this._projectId}/>
        }
        {/* {
          this._pageConfig.isWithPhotoSite == true &&
          <ModalPhoto 
            ref={(r)=>{this._cModalPhoto=r;}}
            projectId={this._projectId||""}
            onReloadTable={this._onReloadTable}
          />
        } */}
        <ModalPhoto 
          ref={(r)=>{this._cModalPhoto=r;}}
          projectId={this._projectId||""}
          onReloadTable={this.reloadTable}
        />
        {
          this._pageConfig && this._pageConfig.componentFooter &&
          <this._pageConfig.componentFooter ref={r=> {this._cFooter=r;}} projectId={this._projectId}/>
        }
      </div>
    )
  }
}

export default Page;

import React from "react";
class MyUISearch extends React.Component {  
  load=({configPage,configSearchBar})=>{
    // console.warn("MyUISearch load:",this._uiSearch,configPage,configSearchBar);
    if(configSearchBar){
      this._configSearchBar = configSearchBar;
      if(configSearchBar.componentSearchBar){
        this._uiSearch = configSearchBar.componentSearchBar();
      }
      else{
        this._uiSearch = (
          <div>No thing</div>
        )
      }      
      this.forceUpdate();      
    }
    else{
      this._uiSearch = null;
      this.forceUpdate();
    }
  }
  render() {   
    // console.warn("render UI Search Bar",this._uiSearch) 
    return (
      <div>
        {this._uiSearch}
      </div>
    )
  }
}
export default MyUISearch;

import React from "react";
import ConfigTabV3 from "../../../model/ConfigTabV3";
import {
  connect, HApiAxios, HUtils, MyModal
} from "../_import";
import Tabv3_Lev3 from "./tabv3_lev3";
class PageTab3L3_Project extends Tabv3_Lev3 {
  constructor(props) {
    super(props);
    this.state = {
    };
    this.initTab();
    this._parseParams();
    // function share dung de chuyen tab, dung cho viec click từ cell co thể đổi project ở tab lev3
    this._sharedFnList.fnCustomSwitchProjectOnTab = (projectId)=>{
      console.warn("fnCustomSwitchProjectOnTab:",this.props,projectId)
      const projectList = this.props.projectList;
      if(projectList && projectId && projectList.length>0){
        for(let i=0;i<projectList.length;i++){
          if(projectList[i].Id===projectId){
            this.onChangeProject(projectList[i])
            break;
          }
        }
      }
    }
  }
  _parseParams=()=>{
  }
  getHasProject=()=>{
    const {switchPortfolioIndex} = this.props;
    if(switchPortfolioIndex==-1){
      return false;
    }
    return true;
  }
  componentDidMount() {
    if (this._isNeedRequestOptions()) {
      this.requestOptions({
        cbSuccess: () => {},
      });
    }
    this.getProjectList();
  }
  getSharedQueryDataMore=()=>{
    const { currentProject, switchPortfolioIndex } = this.props;
    if (switchPortfolioIndex !== -1 && currentProject) {    
      return {
        ProjectId: currentProject.Id
      }  
    }
  }
  _getProjectAll=()=>{
    if(this._projectAll){
      return this._projectAll;
    }
    this._projectAll = {
      Id: "", Name: ""
    }
    return this._projectAll;
  }  
  getProjectList = () => {
    const {switchPortfolioIndex, setObjInSolidV3} = this.props;
    HApiAxios.generic({
      request: {
        method: "POST",
        path: "ProjectOfStaff",
        name: "List",
      },
      data: {},
      successCallBack: (response) => {
        let _projectList = HUtils.Obj.get(response, "Data.Data") || [];
        let _infoProject = ConfigTabV3.parseProjectList(_projectList);
        console.warn("_infoProject",_infoProject,_projectList)
        setObjInSolidV3({
          projectList: _projectList,currentProject: _infoProject.Data,
          projectActiveIndex: _infoProject.Index,
          switchPortfolioIndex: switchPortfolioIndex!=-1?_infoProject.Index:switchPortfolioIndex,//update switchPortfolioIndex lai cho dung voi project
        })
        // setProjectList(_projectList,_currentProject);    
        this.setState({
          loadedTab: [this.getTabId({index:this.state.activeTabIndex,currentProject:_infoProject.Data})]
        });
      },
      errorCallBack: (error, response) => {
        console.warn("error: ", error, response);
      },
    });
  };
  getCurrentProjectName = ()=>{
    const {currentProject} = this.props;
    if(currentProject){
      return currentProject.Name;
    }
  }
  onSwitch = (index) => {
    const { fnList, setSwitchPortfolio, currentProject } = this.props;
    if(index==-1){
      ConfigTabV3.switchOffProject()
    }
    else if(currentProject){
      ConfigTabV3.switchProject(currentProject.Id,currentProject,index,{
        needClearTab: true
      });
    }
    setSwitchPortfolio(index,currentProject);
    if(fnList && fnList.fntv1OnReloadUI){
      fnList.fntv1OnReloadUI();
    }      
  };
  onChangeProject = (item) => {
    const { projectList, fnList, setObjInSolidV3 } = this.props;
    console.warn("onChangeProject:",item);
    let _index = 0;
    let _item = projectList.find((v, i) => {
      if (v.Id === item.Id) {
        _index = i;
      }
      return v.Id === item.Id;
    });
    if (_item) {
      if(fnList && fnList.fntv1OnReloadUI){
        fnList.fntv1OnReloadUI();
      }    

      setObjInSolidV3({
        currentProject: _item,
        projectActiveIndex: _index,
        switchPortfolioIndex: _index,
      })

      //set url
      ConfigTabV3.switchProject(_item.Id,_item,_index,{
        needClearTab: true
      });

      MyModal.Helper.hide();
    }
  };

  renderPreLeftMenu = () => {
    const { projectList, switchPortfolioIndex, projectActiveIndex} = this.props;
    // console.warn("renderPreLeftMenu:",projectActiveIndex,this.props);
    return (
      <div
        className="switch-header"
        style={{
          marginRight: "50px",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginRight: "8px",
            fontWeight: 500,
          }}
        >
          <label className="label-switch" style={{ padding: "0px 5px" }}>
            <div
              className="toggle-switch"
              style={{
                boxShadow: "none",
                backgroundColor: "rgb(111 119 130 / 52%)",
              }}
            >
              <input
                className="toggle-state"
                type="checkbox"
                name="check"
                checked={switchPortfolioIndex === -1 ? false : true}
                onChange={() =>
                  this.onSwitch(switchPortfolioIndex === -1 ? projectActiveIndex : -1)
                }
              />
              <div className="indicator" style={{ boxShadow: "none" }}></div>
            </div>
          </label>
          <div
            style={{
              display: "flex",
              flexWrap: "wrap"
            }}
          >
            <div
              style={{
                color: switchPortfolioIndex === -1 ? "#c74634" : "var(--grey-primary)",
                cursor: "pointer",
              }}
              onClick={() => {
                if (projectActiveIndex === switchPortfolioIndex) {
                  this.onSwitch(-1)
                }
              }}
            >
              Portfolio
            </div>
            <div
              style={{
                margin: "0px 2px",
                color: "var(--grey-primary)",
              }}
            >
              /
            </div>
            <div
              style={{
                color:
                switchPortfolioIndex === projectActiveIndex
                    ? "#c74634"
                    : "var(--grey-primary)",
                cursor: "pointer",
              }}
              onClick={() => {
                console.warn("onClick:",projectActiveIndex,switchPortfolioIndex)
                if (projectActiveIndex === switchPortfolioIndex) {
                  MyModal.Helpers.showMyUI(
                    "list_card",
                    {
                      dataList: projectList,
                      cardType: "card_project_select",
                      configPagination: {
                        using: true,
                        pageSize: 10,
                      },
                      fnList: {
                        fnSelectProject: (pj) => {
                          this.onChangeProject(pj);
                        },
                      },
                    },
                    {
                      title: "Choose Project",
                      closeButton: true,
                      size: "lg",
                    }
                  );
                }
                else{
                  this.onSwitch(switchPortfolioIndex === -1 ? projectActiveIndex : -1)
                }
              }}
            >
              {this.getCurrentProjectName()}
            </div>
          </div>
        </div>
      </div>
    );
  };
  render() {
    const {currentProject,waitingOptionsTab1,switchPortfolioIndex} = this.props;
    if(waitingOptionsTab1){
      return (
        <div className="app">
          {this.renderTab()}        
        </div>
      )
    }
    // console.warn("currentProject",switchPortfolioIndex,currentProject);
    return (
      <div className="app">
        {this.renderTab()}        
        {
          // for print
          switchPortfolioIndex>=0 && currentProject &&
          <div className="only-print" style={{padding:"0px 15px",fontWeight:"bold"}}>{this.getCurrentProjectName()}</div>
        }
        {currentProject && this.renderContent()}
      </div>
    );
  }
}
// export default PageTab3L3_Project;
const mapStateToProps = state =>{
  return{
    currentProject: state.solidv3.currentProject,
    projectList: state.solidv3.projectList,
    switchPortfolioIndex: state.solidv3.switchPortfolioIndex,
    projectActiveIndex: state.solidv3.projectActiveIndex,
    waitingOptionsTab1: state.solidv3.waitingOptionsTab1,
  }
}
const mapDispatchToProps=dispatch=>{
  return{
    setObjInSolidV3: (obj)=>{
      dispatch({type: 'setObjInSolidV3',...obj});
    },
    setSwitchPortfolio: (index,currentProject)=>{
      dispatch({type: 'setObjInSolidV3',switchPortfolioIndex:index,currentProject: currentProject})
    },
  }
}
export default connect(mapStateToProps,mapDispatchToProps)(PageTab3L3_Project)
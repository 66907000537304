import MyBaseOne from "./MyBase";
import MyUIOne from "./MyUI";
import MyCardOne from "./MyCard";
import MyLayoutOne from "./MyLayout";
import {HModules,HFormat, HIs} from "@macashipo/mlib"
import moment from "moment";
const ModuleCoreUI = {
  //========== init ==========
  _key: "core_ui",
  _fnList: {},
  _config: {},
  _routes: [],
  getMyKey: () => {
    return "core_ui";
  },
  runAfterInit: (HConfig) => {
    HModules.initDefault(ModuleCoreUI, { MyBaseOne, MyUIOne, MyCardOne, MyLayoutOne });
    ModuleCoreUI.initFormat()
  },
  //---------- end default init ----------//
  initFormat: ()=>{
    HFormat.Types["date"] = {
      format: (value, configFormat) => {
        // console.warn("format date:", value, configFormat)
        if (value != null && HIs.isDateValue(value)) {
          return moment(value).format(
            configFormat.format
          )
        }
        return ""
      },
    }
    HFormat.Types["number_decimal"] = {
      format: (value, configFormat) => {
        let _num = Number(value)
        if (!isNaN(_num)) {
          let _s = _num.toLocaleString("it-IT", {
            style: "decimal",
          })
          return _s
        }
        return ""
      },
    }
    HFormat.Types["number_percent"] = {
      format: (value, configFormat) => {
        let _num = Number(value)
        if (!isNaN(_num)) {
          //can nhan 100 cho TH: 0.5 => 50%
          if(configFormat.needMultip){
            _num = _num * 100;
          }
          //so le hien thi
          if(configFormat.numDecimal){
            let _x = 10;
            if(configFormat.numDecimal>1 && configFormat.numDecimal<=5){
              _x = Math.pow(10,configFormat.numDecimal);
            }
            _num = Math.round(_num * _x) / _x;
          }
          let _s = _num.toLocaleString("it-IT", {
            style: "decimal",
          })
          return _s
        }
        return ""
      },
    }
    HFormat.Types["configByValue"] = {
      format: (value, configFormat) => {
        let _configByValue = configFormat.configByValue;
        if(_configByValue){
          if(value!=null && _configByValue[value]){
            return _configByValue[value]
          }
          else if(value===null && _configByValue["_null"]){
            return _configByValue["_null"]
          }
          else if((value==="" || value===null) && _configByValue["_empty"]){
            return _configByValue["_empty"]
          }
        }
      },
    }
  }
};

export default ModuleCoreUI;

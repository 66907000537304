import React from "react"
import { MyPageHelpers } from "@macashipo/mlib";
// const MPLogin = React.lazy(() => import("./Types/login"));
const MPLogin2FA = React.lazy(() => import("./Types/login_with2fa"));
const MPWelcome = React.lazy(() => import("./Types/welcome"));
const MPMyProfile = React.lazy(() => import("./Types/myprofile"));
const List = {
  // login: MPLogin,
  login_with2fa: MPLogin2FA,
  welcome: MPWelcome,
  myprofile: MPMyProfile,
};
const MyPageOne = {
  init() {
    console.warn("init MyPageOne");
    MyPageHelpers.addTypesFromListLazy(List);
  },
};

export default MyPageOne;

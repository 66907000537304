import React from 'react';
import {CellHelper} from '../Helpers';
class CellType_ComponentCheckIcon extends React.Component {
  static propTypes = {
  }
  static defaultProps = {
  }
  constructor(props) {
    super(props);
    this.state={
    };
  }

  render() {
    const {row,extra,fnList} = this.props
    const configCheckIcon = this.props.configCheckIcon || {}
    let _fieldName = configCheckIcon.fieldName   
    if(_fieldName && row){
      let _valueCheck = row[_fieldName]
      return (
        <div className="mct-status-check-icon" style={{padding:"0px 5px",...configCheckIcon.style}} title={configCheckIcon.title}>
          <input type={"checkbox"} checked={_valueCheck} onChange={()=>{
            CellHelper.callRequestUpdate({extra:extra,row:row,fieldName:_fieldName,newValue:!_valueCheck,component:this});
          }}/>
        </div>
      )
    }
    return (
      <div></div>
    )
  }
}

export default CellType_ComponentCheckIcon;
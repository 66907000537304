import {HModules} from '@macashipo/mlib'
import HRoutes from './Route'
// const HModule = {
//   //short init
//   initDefault(module = {},listComponent = {}){
//     if(module && module._key){
//       HModule.addUI(module, listComponent)
//       HModule.addRoutes(module)
//       HModule.registerFnList(module);
//     } 
//     else{
//       console.warn("missing module key",module)
//     }
//   },
//   //add MyComponent
//   addUI(module = {},listComponent = {}){
//     if(module && module._key){
//       let _keys = Object.keys(listComponent);
//       for(let k of _keys){
//         if(listComponent[k] && listComponent[k].init){
//           listComponent[k].init()
//         }
//       }
//     } 
//   },
//   //instance
//   ModuleInstances: {},  
//   registerInstance(key,obj){
//     HModule.ModuleInstances[key] = obj
//   },
//   getInstance(key){
//     if(HModule.ModuleInstances[key]){
//       return HModule.ModuleInstances[key]
//     }
//     console.warn("[Module] No instance:",key)
//     return null
//   },
//   //========== fnList
//   ModuleFnList: {},
//   registerFnList(module = {}){
//     if(module && module._key && module._fnList){
//       HModule.ModuleFnList[module._key] = module._fnList
//     }    
//   },
//   runFunctionInModuleFnList(moduleKey= "",fnName = "",args=[]){
//     if(HModule.ModuleFnList[moduleKey] && HModule.ModuleFnList[moduleKey][fnName]){
//       HModule.ModuleFnList[moduleKey][fnName].apply(null,args)
//     }
//     else{
//       console.warn("Missing fn module",moduleKey,fnName,HModule.ModuleFnList[moduleKey])
//     }
//   },
//   //========== routes
//   addRoutes(module = {},opts={}){
//     if(module && module._key && module._routes){
//       HRoutes.addPublicRoutes(module._routes)
//     }
//   }
// }


/**
 * Template
 */

//========== init ==========
// _key: "mykey",
// _fnList: {},
// _config: {},
// _routes: MyRoutes.routes,//for public routes
// getMyKey: () => {
//   return "mykey";
// },
// runAfterInit: (HConfig) => {
//   HModule.initDefault(ModuleV1Page, { MyPageOne, MyUIOne, MyLayoutOne });
// },
//---------- end default init ----------//

export const addRoutesPublic=(module)=>{
  if(module && module._key && module._routes){
    HRoutes.addPublicRoutes(module._routes)
  }
}
export default HModules
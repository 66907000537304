import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { Input } from '../../../_importComponent';
import { Select, Mv1 } from '../../../_importLibs';
import { HConfig, HOptions } from '../../../_import';

const Select2Color=(option)=>{
  let _bg = option.value||'transparent';
  return (
    <div style={{display:'flex',alignItems:'center'}}><i className="fa fa-square" style={{color:_bg,fontSize:'20px'}}></i> &nbsp; {option.label}</div>
  )
}
class InputControl extends Component {
  constructor(props) {
    super(props);
    let _value = props.value||'';
    let _maskValue = props.value||'';
    if(props.type=='checkbox'){
      _value = props.value!=null?props.value:false;
    }
    else if(props.type=='text'&&props.mask!=null){
      _maskValue = this.getMaskValue(_value);
    }
    this.state = { 
      value: _value,
      maskValue: _maskValue,
    };
  }
  componentDidMount(){
    const {type,id}=this.props;
    if(type=='date'){
      // console.warn('componentDidMount: ',id,this);
      this._initDatePicker(this.props.value);
    }
  }
  componentWillReceiveProps(nextProps){
    const {type}=this.props;
    if(nextProps.debug==true){
      console.log('InputControl componentWillReceiveProps: ',this.props,this.props.value,' -> ',nextProps.value,);
    }
    if(type=='date'){
      // console.log('componentWillReceiveProps date input',nextProps,this.props);
      let _newValue = nextProps.value || "";
      if(nextProps.id!=this.props.id){
        this.setState({value:_newValue},()=>{
          this._initDatePicker(_newValue,true);
        })
      }
      else if(nextProps.value!=this.props.value){
        this.setState({value:_newValue},()=>{
          this._initDatePicker(_newValue);
        })
      }
    }
    else if(type=='checkbox'){
      // console.log('InputControl componentWillReceiveProps: ',this.props,this.props.value,' -> ',nextProps.value,);
      // Da tung ko xu ly gi het, ko biet tai sao      
      if(nextProps.value!=null && this.props.value !=null && nextProps.value!=this.props.value){
        let _value = nextProps.value!=null?nextProps.value:false;
        // console.log('set value:',_value);
        this.setState({value:_value})
      }
    }
    else{
      if(nextProps.value!=this.props.value){
        if(this.props.mask!=null){
          this.setState({
            value:nextProps.value||'',
            maskValue: this.getMaskValue(nextProps.value||''),
          });
        }
        else{
          this.setState({value:nextProps.value||''});
        }
      }
    }
  }
  getValue=()=>{
    return this.state.value;
  }
  getMaskValue=(value)=>{
    let mask = new Mv1.InputMask({pattern: this.props.mask});
    let _ok = mask.input(value);
    if(_ok){
      return mask.getValue(); 
    }
    else{
      return value;
    }
  }
  getIdTrim=()=>{
    const {id} = this.props;
    let _id = id;
    if(_id){
      _id = _id + "";
      _id = _id.replace(/[^a-zA-Z0-9]/g, '_');
    }
    return _id;
  }
  getNewValueWithRegexDelete=(value)=>{
    const {regexDelete}=this.props;
    if(regexDelete!=null && value!=null){
      let regex = new RegExp(regexDelete,'ig');
      console.log('getNewValueWithRegexDelete:',regex,value);
      let _newValue = value.replace(regex, '');
      console.log('getNewValueWithRegexDelete:',_newValue);
      return _newValue;
    }
    return value;
  }
  setValue=(value)=>{
    const {type}=this.props;
    console.log('setValue:',value);
    if(type=='date'){
      this._initDatePicker(value);
    }
    else if(this.props.mask!=null){
      this.setState({
        value: value,
        maskValue: this.getMaskValue(value)
      })
    }
    else{
      this.setState({
        value: value,
      })
    }
  }
  focus=()=>{
    // console.log('focus:',this._cInput);
    if(this._cInput){
      // this._cInput.focus();
      let _e = ReactDOM.findDOMNode(this._cInput);
      // console.log(_e);
      if(_e){
        _e.focus();
      }
    }
    else if(this._cInputSelect2){
      this._cInputSelect2.focus();
      // console.log("focus",this._cInputSelect2);
    }
  }

  _initDatePicker=(milisecond,forceNew)=>{
    const {type,id,dateFormat}=this.props;
    let _dateFormat = dateFormat || HConfig.dateFormatJQ;
    // console.warn('_initDatePicker:',milisecond,id);
    if(this._datePicker==null || forceNew === true){
      // console.warn('_initDatePicker:',milisecond,id);
      if(forceNew==true && this._datePicker){
        // console.warn('forec',this._datePicker);
        this._datePicker.datepicker('destroy');
      }
      this._datePicker = window.jQuery('#'+this.getIdTrim()).datepicker({
        dateFormat: _dateFormat,
        changeMonth: true,
        changeYear: true,
        yearRange: '1950:2030',
        onSelect: (dateText) => {
          console.log("Selected date: " + dateText,id);
          // console.log(this);
          this.setState({value:dateText},()=>{
            this._onChangeDate();
          });
        }
      });
    }
    
    // this._datePicker.datepicker('setDate',new Date());
    // console.log('init date1:',id,milisecond);
    if(milisecond!=null && milisecond!=''){
      // console.log('init date:',id,milisecond);
      this._datePicker.datepicker('setDate',new Date(milisecond));
      let _value = this._datePicker.val();
      // console.log(_value);
      if(_value!=null){
        this.setState({value:_value});
      }
    }
    else{
      this.setState({value:''});
    }
  }
  _onChangeDate=()=>{
    const {onChange,id}=this.props;
    console.log('_onChangeDate:',this.state.value,id,this._datePicker);
    // console.log('time: ',this._datePicker);
    // console.log(this._datePicker.val());
    // console.log(this._datePicker.datepicker( 'getDate' ));
    let _date = this._datePicker.datepicker( 'getDate' );
    if(_date!=null){
      // console.log(_date.getTime());
      let _milisecond = _date.getTime();
      // let _milisecond = _date.getTime() - (_date.getTimezoneOffset() * 60000);
      if(onChange!=null){
        onChange(null,_milisecond);
      }
    }
  }
  _renderText=()=>{
    const {className,style,type,onBlur,onChange,placeholder,disabled,autoFocus,isSetDataValue,morePropsInput}=this.props;
    return (
      <Input
        ref={r=>{this._cInput=r;}} 
        // className={className}
        className={`${className} c-itext`}
        style={style}
        type={type}
        autoFocus={autoFocus}
        value={this.state.value} 
        placeholder = { placeholder }
        disabled={disabled}
        onBlur={onBlur}      
        onChange={ (ev) => { this.setState({ value: ev.currentTarget.value },()=>{
          if(onChange!=null){
            onChange(ev,this.state.value,this._cInput);
          }
          if(isSetDataValue){
            // console.log("isSetDataValue",ev,ev.target, Object.keys(ev.target||{}))
            if(ev && ev.target && ev.target.setAttribute){
              ev.target.setAttribute("data-value",this.state.value)
            }
          }
        });}} 
        {...morePropsInput}
        >
      </Input>
    )
  }
  _renderTextWithMask=()=>{
    const {className,style,type,onBlur,onChange,placeholder,disabled}=this.props;
    // console.log('_renderTextWithMask:',this.state.maskValue);
    return (
      <Input 
        className={className}
        style={style}
        mask={this.props.mask}
        type={type}
        value={this.state.maskValue} 
        placeholder = { placeholder }
        disabled={disabled}
        onBlur={(ev)=>{
          console.log('ev:',ev,ev.currentTarget.value);
          this.setState({
            maskValue: this.getMaskValue(ev.currentTarget.value)
          },()=>{
            if(onBlur!=null){
              onBlur(ev,this.state.value);
            }
          })
        }}
        onMouseDown={(ev)=>{
          // console.log('onMouseDown:',ev,ev.currentTarget.value);
          this.setState({
            maskValue: this.state.value
          })
        }}
        onChange={ (ev) => { 
          // console.log('ev:',ev,ev.currentTarget.value,this.props.regexDelete);
          let _newValue = ev.currentTarget.value;
          if(this.props.regexDelete!=null){
            _newValue = this.getNewValueWithRegexDelete(_newValue);
          }
          this.setState({ value: _newValue, maskValue: _newValue },()=>{
            if(onChange!=null){
              onChange(ev,this.state.value);
            }
          });
        }} 
        />
    )
  }
  _renderNumber=()=>{
    const {className,style,type,onBlur,onChange,placeholder,disabled}=this.props;
    let _patern = /[^(\d-)]/ig;//mac dinh support so am
    if(this.props.supportNegative==true){
      _patern = /[^(\d-)]/ig;
    }
    // if()
    return (
      <Input 
        className={className}
        style={style}
        type={'text'}
        value={this.state.value} 
        placeholder = { placeholder }
        disabled={disabled}
        onBlur={onBlur}
        onChange={ (ev) => { 
          let _newValue = ev.currentTarget.value.replace(_patern, '');
          this.setState({ value: _newValue},()=>{
          if(onChange!=null){
            onChange(ev,this.state.value);
          }
        });}} >
      </Input>
    )
  }
  _renderPassword=()=>{
    const {className,style,type,onBlur,onChange,placeholder,disabled}=this.props;
    return (
      <Input 
        className={className}
        style={style}
        type={'password'}
        value={this.state.value} 
        placeholder = { placeholder }
        disabled={disabled}
        onBlur={onBlur}
        onChange={ (ev) => { 
          let _newValue = ev.currentTarget.value;
          this.setState({ value: _newValue},()=>{
          if(onChange!=null){
            onChange(ev,this.state.value);
          }
        });}} >
      </Input>
    )
  }
  _renderTextArea=()=>{
    const {className,style,type,onBlur,onChange,placeholder,disabled,rows,multipleLine}=this.props;
    let _moreClassName = 'celltype-textarea';
    if(multipleLine==true){
      _moreClassName = 'celltype-textareafull';
    }
    return (
      <Input 
        ref={r=>{this._cInput=r;}} 
        // className={className}
        className={`${className} c-itextarea ${_moreClassName}`}
        style={style}
        type={type}
        rows={rows}
        value={this.state.value} 
        placeholder = { placeholder }
        disabled= {disabled}
        onBlur={onBlur}
        onChange={ (ev) => { this.setState({ value: ev.currentTarget.value },()=>{
          if(onChange!=null){
            onChange(ev,this.state.value);
          }
        });}} >
      </Input>
    )
  }
  _renderSelect=()=>{
    const {className,style,type,onBlur,onChange,options,placeholder,disabled,dir}=this.props;
    let _style = style;
    let _uiOptions = HOptions.createListForSelect({
      sourceList: options, itemEmpty: '', currentValue: this.state.value, currentValueText: this.props.optionText,
      isNotAutoAddWhenNotExist: this.props.isNotAutoAddWhenNotExist,
    });
    // if(options!=null && _uiOptions.length>(options.length+1)){
    //   // console.log('_style:',_style);
    //   // if(_style!=null){
    //   //   _style=Object.assign(_style,{border:'1px red dashed'});
    //   // }
    // }
    return (
      <Input 
        className={className}
        style={_style}
        type={type}
        dir={dir}
        value={this.state.value} 
        placeholder = { placeholder }
        disabled={disabled}
        onBlur={onBlur}
        onChange={ (ev) => { this.setState({ value: ev.currentTarget.value },()=>{
          if(onChange!=null){
            onChange(ev,this.state.value);
          }
        });}} >
        {
          _uiOptions!=null && _uiOptions
        }
      </Input>
    )
  }
  _renderDate=()=>{
    const {className,id,style,type,onBlur,onChange,options,placeholder,disabled}=this.props;
    return (
      <Input 
        id={this.getIdTrim()}  
        className={className + ' datepicker'}
        style={style}
        type="text"
        value={this.state.value}
        placeholder = { placeholder }
        disabled={disabled}
        onBlur={(ev)=>{
          console.log('onBlur:',ev.currentTarget.value,this,id);          
        }} 
        onChange={ (ev)=>{
          console.log('onChange:',ev.currentTarget.value);
          this.setState({value:ev.currentTarget.value});
        }}
        />
    )
  }
  _renderCheckBox=()=>{
    const {className,style,type,onBlur,onChange,placeholder,disabled}=this.props;
    return (
      <Input 
        className={`${className} s_sm_xncb`}
        style={style}
        type={"checkbox"}
        // value={this.state.value} 
        checked={ this.state.value } 
        // placeholder = { placeholder }
        disabled={disabled}
        // onBlur={onBlur}
        onChange={ (ev) => { 
          console.log('onChange:',this.state.value,ev.currentTarget.checked);
          this.setState({ value: ev.currentTarget.checked },()=>{
          if(onChange!=null){
            onChange(ev,this.state.value);
          }
        });}} >
      </Input>
    )
  }
  _renderSelect2Color=()=>{
    const {className,style,type,onBlur,onChange,options,placeholder,disabled,dir,moreProps,innerRef}=this.props;
    return (
      <Select
        // id="select2"
        ref={r=>{
          this._cInputSelect2=r;
          if(moreProps && moreProps.menuIsOpen && this._cInputSelect2 && this._cInputSelect2.setState){
            this._cInputSelect2.setState({
              isOpen: true
            })
          }
          if(innerRef){
            innerRef(r);
          }
          // console.warn("reff",this._cInputSelect2);
        }}
        onBlurResetsInput={false}
        onSelectResetsInput={false}        
        autoFocus={false}
        options={options!=null?options:[]}
        menuPortalTarget
        valueRenderer={Select2Color}
        optionRenderer={Select2Color}
        value={this.state.value}
        simpleValue        
        multi={false}
        closeOnSelect={false}
        clearable={false}
        name="selected-state"
        // value={this.state.WPGGroupId}
        onChange={(newValue)=>{
          this.setState({
            value: newValue,
          },()=>{
            if(onChange!=null){
              onChange(newValue);
            }
          });
        }}
        searchable={true}
        {...moreProps}
      />
    )
  }
  _renderSelect2=()=>{
    const {className,style,type,onBlur,onChange,options,placeholder,disabled,dir,moreProps}=this.props;
    let _options = [];
    if(options!=null && options.length>0){
      _options = options;
      if(options[0].value==null && options[0].Value!=null){
        _options = HOptions.convertOptionsApiToSelect2({sourceList:options});
      }
    }
    return (
      <Select
        // id="select2"
        ref={r=>{this._cInputSelect2=r;}}
        onBlurResetsInput={false}
        onSelectResetsInput={false}
        autoFocus={false}
        options={_options}
        value={this.state.value}
        simpleValue
        disabled={disabled}
        multi={false}
        closeOnSelect={true}
        clearable={false}
        name="selected-state"
        // value={this.state.WPGGroupId}
        onChange={(newValue)=>{
          this.setState({
            value: newValue,
          },()=>{
            if(onChange!=null){
              onChange(null,newValue);
            }
          });
        }}
        searchable={true}
        {...moreProps}
      />
    )
  }
  
  render() {
    const {type}=this.props;
    if(type=='text'){
      if(this.props.mask!=null){
        return this._renderTextWithMask();
      }
      return this._renderText();
    }
    else if(type=='number'){
      return this._renderNumber();
    }
    else if(type=='password'){
      return this._renderPassword();
    }
    else if(type=='textarea'){
      return this._renderTextArea();
    }
    else if(type=='select'){
      return this._renderSelect();
    }
    else if(type=='date'){
      return this._renderDate();
    }
    else if(type=='checkbox'){
      return this._renderCheckBox();
    }
    else if(type=='select2color'){
      return this._renderSelect2Color();
    }
    else if(type=='select2'){
      return this._renderSelect2();
    }
    return (
      <div>
        {
          this.state.value
        }
      </div>
    )
  }
}

export default InputControl;
import HRouter from '../../helpers/Route'
import HUtils from '../../helpers/Utils'
import HConstant from '../../helpers/Constant'
import HLocalStorage from '../../helpers/LocalStorage'
const HelperV3 = {
    needReloadPage: false,
    initLocalRoute(){
        let _currentLocal = HLocalStorage.getObject(HConstant.LocalKey.CATEGORY_LIST);
        if(_currentLocal && HUtils.isArray(_currentLocal)){
            HRouter.listRoutesServer = _currentLocal;
        }
        else{
            HelperV3.needReloadPage = true;
        }
        console.warn("initLocalRoute:",HRouter.listRoutesServer)
    },
    convertConfigPage(configPage){        
        if(configPage.UIType==null){
            configPage.UIType = "solidv3_table"
        }
        else if(configPage.UIType=="Table"){
            configPage.UIType = "solidv3_table"
        }else if(configPage.UIType=="Tab"){
            configPage.UIType = "solidv3_tab"
        }
        return configPage;
    },
    parseRouteFromServer(dataList,{fnForceUpdate}={}){
        if(dataList && HUtils.isArray(dataList)){
            let _configList= dataList.map((v,i)=>{
                if(v){
                    HelperV3.convertConfigPage(v);
                    if(v.UIUrl && v.UIUrl.indexOf("[")){
                        v.UIUrl=v.UIUrl.replace(/\[ID\]/g,":projectId");
                        v.UIUrl=v.UIUrl.replace(/\[([\w^\]]+)\]/g,":$1");
                    }
                }                
                return v;
            });
            try {
                HLocalStorage.setObject(HConstant.LocalKey.CATEGORY_LIST,_configList);
            } catch (error) {
                HUtils.logErrorParseJson(_configList);
            }
            HRouter.listRoutesServer = _configList;
        }
        let _currentLocal = HLocalStorage.getObject(HConstant.LocalKey.CATEGORY_LIST);
        if(_currentLocal && HUtils.isArray(_currentLocal)){
            if(HelperV3.needReloadPage == true){
                if(fnForceUpdate){
                    fnForceUpdate();
                }
            }
        } 
    }
}
export default HelperV3
const Routes = {
  routes: [
    {
      Id: "setting",
      UIUrl: "/setting",
      UIType: "setting",
    },
  ]
}

export default Routes
import React from 'react';
import {
  Button
} from '../../_importComponent';
import HOptions from '../../helpers/Options';
import HText from '../../helpers/Text';

/**
 * more list
 * className 
 * ui
 * all -checkbox
 * positive -checkbox
 * negative -checkbox
 * search
 * select
 * select2
 * 
 */
const MyTableFilterHelpers = {
  getSourceListInItem(props,opts={}){
    const {isUsingOptionInList,optionKey,fieldName} = props;//in config
    const {_getSourceList,_getDataList} = props;
    if(isUsingOptionInList==true){
      let _data = _getDataList();
      if(_data){
        return HOptions.createListDataFromFieldNameOfDataList(fieldName,_data,opts);
      }
    }
    let _sourceList = _getSourceList();
    // console.warn('_sourceList',_sourceList,optionKey);
    if(_sourceList && optionKey){
      if(opts && opts.isSelect2==true && opts.needConvert==true){
        return HOptions.convertOptionsApiToSelect2({sourceList:_sourceList[optionKey]})
      }
      return _sourceList[optionKey];
    }
    return [];
  },
  // //Chuyen sang EXT
  // getFieldNameFromItem(props){
  //   return props.fieldName;
  // },
  getKeyOfItem(item){
    return item.key || item.fieldName || "nokey";
  },
  // //Khong xai nua
  // getFilterBootstrapTableInOpts(opts){
  //   if(opts && opts.filterBootstrapTable){
  //     return opts.filterBootstrapTable;
  //   }
  // },
  // buildOptsFilterBootstrapTableInOpts(opts,value){
  //   opts.filterBootstrapTable = value;
  //   return opts;
  // },
  getClassNameOfItem(item,configFilter){
    let _className = "col-12 col-sm-6 col-md-4 col-lg-3";
    if(configFilter && configFilter.classNameItem){
      _className = configFilter.classNameItem;
    }
    let _more = MyTableFilterHelpers.getMoreOfItem(item);
    if(_more && _more.className){
      _className = _more.className;
    }
    return _className;
  },
  getMoreOfItem(item){
    let _more = {};
    if(item){
      if(item._moreObj){
        return item._moreObj;
      }
      if(typeof item.more=="object"){
        return item.more;
      }
      else if(typeof item.more=="string"){
        try {
          let _obj = JSON.parse(item.more);        
          item._moreObj = _obj;
          return _obj;
        } catch (error) {
          console.warn("parse json more error",item);
        }
      }
    }    
    return _more;
  },
  renderClear(fnClear){
    return (
      <div>
        <Button color="danger" style={{border:"0px"}} outline onClick={()=>{
          if(fnClear){fnClear()}
        }}><i className="fa fa-ban"></i></Button>
      </div>
    )
  },
}

export {
  HText,
  HOptions
};
export default MyTableFilterHelpers;
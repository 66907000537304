import React from "react"
import { MyUIHelpers, CSuppense } from "@macashipo/mlib";
const MUI_html = React.lazy(() => import("./Types/html"));

const List = {
  html: MUI_html,
};
const MyUIOne = {
  init() {
    console.warn("init MyUIOne");
    MyUIHelpers.addTypesFromListLazy(List);
  },
};

export default MyUIOne;

import React, { Component } from 'react';
import Types from './BelowFilterTypes';
class BelowFilter extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount(){
  }

  _renderContent=()=>{
    const {type} = this.props;
    if(type && Types.UI[type]){
      return Types.UI[type](this.props);
    }
    return Types.UI._default(this.props);
  }
  _renderContents=(type,config)=>{
    if(type && Types.UI[type]){
      return Types.UI[type]({...this.props,type:type,config:config});
    }
    return Types.UI._default({...this.props,type:type,config:config});
  }
  render() {
    const {style,config} = this.props;
    if(Array.isArray(config)){
      return (
        <div style={style}>
          {
            config.map((v,i)=>{
              return (
                <div key={i}>
                  {
                    this._renderContents(v.type,v)
                  }
                </div>
              )
            })
          }
        </div>
      )
    }
    return (
      <div style={style}>
        {this._renderContent()}
      </div>
    )
  }
}

export default BelowFilter;
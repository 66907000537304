import React, { Component } from 'react';
const Helper = {
  getDirection: function(item,opts){
    let _direction = "row";
    let _style = Helper.getStyle(item,opts);
    if(_style.Direction){
      return _style.Direction;
    }
    if(opts.configForm && opts.configForm.Direction){
      _direction = opts.configForm.Direction;
    }
    return _direction;
  },
  getStyleRow: function(item,opts){
    let _style = Helper.getStyle(item,opts);
    if(_style.Row){
      return _style.Row;
    }
    return {};
  },
  getStyleLeft: function(item,opts){
    let _style = Helper.getStyle(item,opts);
    if(_style.Left){
      return _style.Left;
    }
    return {};
  },
  getStyleRight: function(item,opts){
    let _style = Helper.getStyle(item,opts);
    if(_style.Right){
      return _style.Right;
    }
    return {};
  },
  getStyleLabel: function(item,opts){
    let _style = Helper.getStyle(item,opts);
    if(_style.Label){
      return _style.Label;
    }
    return {};
  },
  getStyleControl: function(item,opts){
    let _style = Helper.getStyle(item,opts);
    if(_style.Control){
      return _style.Control;
    }
    return {};
  },
  getStyle: function(item,opts){
    if(item.Style){
      return item.Style;
    }
    return {};
  },
  getDisabled: function(item,opts){
    if(item && item.Disabled!=null){
      return item.Disabled;
    }
    return false;
  },
  getMultiple: function(item,opts){
    if(item && item.Props && item.Props.multiple==true){
      return true;
    }
    return false;
  },
  getDefaultValue: function(item,opts){
    let _v;
    let _fieldName = item.FieldName;
    if(opts && opts.defaultValues && opts.defaultValues[_fieldName]!=null){
      _v = opts.defaultValues[_fieldName];
    }
    // console.warn('getDefaultValue:',item,opts,_v);
    return _v;
  },
  getOptionsSource: function(item,opts){
    // console.warn('getOptionsSource',item,opts);
    let _options = [];
    if(item.SourceList){
      _options = item.SourceList;
    }
    else if(item && item.Source!=null && opts && opts.getOptionOfKey!=null){
      // console.warn('x',item,opts);
      _options = opts.getOptionOfKey(item.Source);
    }
    else if(opts && opts.sourceLists){
      _options = opts.sourceLists[item.FieldName];
    }
    
    // console.warn('getOptionsSource result',_options);
    return _options;
  },
  getPropsControl: function(item,opts){
    let _props = {};
    if(item.Props){
      if(typeof item.Props == "object"){
        _props = Object.assign(_props,item.Props);
      }
      else if(typeof item.Props == "string"){
        try {
          _props = Object.assign(_props,JSON.parse(item.Props));
        } catch (error) {
          console.warn('Parse Json Props Control error',item,opts,error);
        }
      }
    }
    return _props;
  },
}
export default Helper;
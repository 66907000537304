import classnames from 'classnames';
import React, { Component } from 'react';
import {
  Nav, NavItem, NavLink,
  TabContent, TabPane
} from '../../_importComponent';

class MyTab extends Component {
  constructor(props) {
    super(props);

    this.state = { 
      activeTab: 'tab_0',
      loadedTab: ['tab_0'],
    };

    //tabConfig
    //data []
    //isLazyLoading false
  }

  componentDidMount(){
  }

  _onClickTab=(tabId)=>{
    let _loadedTab = this.state.loadedTab;
    if(_loadedTab.indexOf(tabId)==-1){
      _loadedTab.push(tabId);
    }
    this.setState({
      activeTab: tabId,
      loadedTab: _loadedTab,
    })
  }

  _renderTabHeader=()=>{
    let _tabConfig = this.props.tabConfig;
    if(_tabConfig && _tabConfig.data &&_tabConfig.data.length>0){
      let _ui =[];
      for(let i in _tabConfig.data){
        let _item = _tabConfig.data[i];
        _ui.push(
          <NavItem key={i}>
            <NavLink
              className={classnames({ active: this.state.activeTab === `tab_${i}` })}
              onClick={() => { this._onClickTab(`tab_${i}`) }}>
              {_item.title}
            </NavLink>
          </NavItem>
        );
      }
      return _ui; 
    }
  }

  _renderTabContent=()=>{
    let _tabConfig = this.props.tabConfig;
    let _isLazyLoading = this.props.tabConfig.isLazyLoading!=null?this.props.tabConfig.isLazyLoading:false;
    if(_tabConfig && _tabConfig.data &&_tabConfig.data.length>0){
      let _ui =[];
      for(let i in _tabConfig.data){
        let _item = _tabConfig.data[i];
        _ui.push(
          <TabPane key={i} tabId={`tab_${i}`}>
            {
              _isLazyLoading == true && this.state.loadedTab.indexOf(`tab_${i}`)>-1 ?
              _item.component : _isLazyLoading == false ? _item.component : <div></div>
            }
          </TabPane>
        );
      }
      return _ui; 
    }
  }

  render() {
    return (
      <div>
        <Nav tabs>
          {this._renderTabHeader()}
        </Nav>
        <TabContent activeTab={this.state.activeTab}>
          {this._renderTabContent()}
        </TabContent>
      </div>
    )
  }
}

MyTab.Helper = {
}

export default MyTab;

import React from 'react';
import MyLoading from '../MyLoading/MyLoading';

const Default = React.lazy(() => import('./TypesHub/_default'));
const FilterV2 = React.lazy(() => import('./TypesHub/filter_v2'));

//Custom Soezy
const SoezyReport = React.lazy(() => import('./TypesHub/soezy_report'));
const SoezyReportV2 = React.lazy(() => import('./TypesHub/soezy_report_v2'));

const MyTableFilterHubTypes = {
  addType(key,fnComponent){
    if(MyTableFilterHubTypes.Types[key]==null){
      MyTableFilterHubTypes.Types[key] = fnComponent;
    }
  },
  Types:{    
    _default(props){ return( <React.Suspense fallback={<MyLoading />}> <Default {...props}/> </React.Suspense> ) },
    filter_v2(props){ return( <React.Suspense fallback={<MyLoading />}> <FilterV2 {...props}/> </React.Suspense> ) },
    soezy_report(props){ return( <React.Suspense fallback={<MyLoading />}> <SoezyReport {...props}/> </React.Suspense> ) },
    soezy_report_v2(props){ return( <React.Suspense fallback={<MyLoading />}> <SoezyReportV2 {...props}/> </React.Suspense> ) },
  }
}

export default MyTableFilterHubTypes;
import React from "react";
import { connect } from "react-redux";
import ConfigTabV3 from "../../../model/ConfigTabV3";
import {
  HC,
  HUtils, MyPageFromConfig, MyUI, TabContent,
  TabPane
} from "../_import";
import "./styles/common.css";
import Tabv3_ext from "./tabv3_ext";
class PageTab3L7 extends Tabv3_ext {
  constructor(props) {
    super(props);
    this.keyRightPositon = "";
    this._cMenuv7 = null;
    this.wrapperRef = React.createRef();
  }
  componentDidMount() {
    if (this._isNeedRequestOptions()) {
      this.requestOptions({
        cbSuccess: () => {},
      });
    }
  }
  getHasProject=()=>{
    const {fnList} = this.props;
    if(fnList && fnList.getIsHasProject){
      return fnList.getIsHasProject();
    }
    return false;
  }
  _isNeedRequestOptions = () => {
    const { configPage } = this.props;
    if (configPage && configPage.Contents) {
      return false;
    }
    return true;
  };
  getWidthMenu = () => {
    const menu_wrap = document.getElementById("tab_lv7_wrapper");
    return Math.ceil(menu_wrap?.offsetWidth) || window.outerWidth - 30;
  };
  renderPreLeftMenu = () => {
    const { configPage } = this.props;
    const title = configPage.Title;
    return (
      <div className="title">
        {title} <i className="fa fa-chevron-right" />
      </div>
    );
  };
  renderTab = () => {
    const { fnList, configPage } = this.props;
    const { activeTabIndex } = this.state;
    const contents = this.getContents();
    const buttonsOnTab = HUtils.Obj.get(this.getConfigPage(), `ConfigObj.${HC.ConfigPage.buttonsOnTab}`);
    const title = configPage.Title
    const titleMenuActive = contents && contents[activeTabIndex]?.Title

    // console.warn("buttonsOnTab:",buttonsOnTab);
    return (
      <div id="tab_lv7_wrapper" className="tab_lv7_wrapper no-print">
        <MyUI
          type="responsive_menu"
          id="tab_lv7"
          width={this.getWidthMenu()}
          leftMenu={contents}
          rightMenu={[]}
          buttonsOnTab={buttonsOnTab}
          renderPreLeftMenu={this.renderPreLeftMenu}
          classNameMenu="btn-tab-lv5"
          config={{
            isFullWidthInMobile: true,
            isShortMenuInMobile: false,
            hidePreLeftMenuInMobile: true,
          }}
          fnList={{
            ...this._sharedFnList,
            onChangeMenu: (index, item) => {
              this.changeTab({
                index,
                item,
              });
            },
            getIsActive: (index) => {
              return this.getTabIsActive({ index });
            },
            getWidth: () => {
              return this.getWidthMenu();
            },
            setRefMenu: (c) => {
              this._cMenuv7 = c
            }
          }}
        />
        {/* <div className="tab_lv5">
          <div className="title">
            {title} <i className="fa fa-chevron-right" />
          </div>
          {contents.map((v, i) => {
            return (
              <Button
                key={i}
                onClick={() => {
                  this.changeTab({ index: i, item: v });
                }}
                className={`btn-tab-lv5 ${
                  this.getTabIsActive({ index: i }) ? "active" : ""
                }`}
              >
                {v.Title}
              </Button>
            );
          })}
          {
            buttonsOnTab!=null &&
            <div className="btns-ontab" style={{display:'flex',flexDirection:'row'}}>
            {
              buttonsOnTab.map((v,i)=>{
                return (
                  <MyButton type={v.Type} config={v} key={i} fnList={this._sharedFnList}/>
                )                
              })
            }
            </div>
          }
          
        </div>
        <div className="tab_lv5_mobile">
          <div className="btn-mn" onClick={() => {
            MyModal.Helper.showFromFunction(() => { 
              return (
                <ul className="menu-list">
                  {contents.map((v, i) => {
                    return (
                      <li
                        key={i}
                        onClick={() => {
                          this.changeTab({ index: i, item: v });
                          MyModal.Helper.hide()
                        }}
                        className={`itm ${
                          this.getTabIsActive({ index: i }) ? "active" : ""
                        }`}
                      >
                        {v.Title}
                      </li>
                    );
                  })}
                </ul>
              );
            }, {
              className: "tab_lv5_modal_menu",
              title: title
            })
          }}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 23 19"
            >
              <g fill="var(--blue-primary)">
                <rect y="16" width="23" height="2" rx="1.5"></rect>
                <rect width="23" height="2" rx="1.5"></rect>
                <rect y="8" width="23" height="2" rx="1.5"></rect>
              </g>
            </svg>
          </div>
          <div className="breakcumb">
            <span className="parent">{title}</span>
            {titleMenuActive && (
              <span className="child">
                <i className="fa fa-chevron-right" /> {titleMenuActive}
              </span>
            )}
          </div>
        </div>
        <div className="tab_lv5-right">               
        </div> */}
      </div>
    );
  };
  renderContent = () => {
    const { fnList, currentProject, customTabLev } = this.props;
    const contents = this.getContents();
    let _ui = [];
    // console.warn("renderContent tab lv7")
    return (
      <div className="content_lv7">
        <TabContent
          activeTab={this.getTabActive({ currentProject })}
          style={{ border: "0px", background: "transparent" }}
        >
          {contents.map((v, i) => {
            let _tabId = this.getTabId({
              index: i,
              currentProject: currentProject,
            });
            let _isLoaded = this.getTabIsLoaded({ index: i, currentProject });
            // console.warn("render tablev7:", _tabId, _isLoaded, this.state);
            if (_isLoaded) {
              if(_tabId==this.getTabActive({})){
                ConfigTabV3.setTabByLev(customTabLev||7,v.Id)
              }
              return (
                <TabPane key={i} tabId={_tabId} style={{ padding: "0px" }}>
                  <MyPageFromConfig
                    key={_tabId}
                    configPage={v}
                    fnList={{
                      ...fnList,           
                      ...this._sharedFnList,
                      getParentLevelTabIndex: this.getCurrentLevelTabIndex,           
                      updateConfigSearchBar:(configPage,configSearchBar)=>{
                        if(configPage && configPage.Id){  
                          if(i==this.state.activeTabIndex){//chi load tab active
                            if(fnList && fnList.updateConfigSearchBar){
                              fnList.updateConfigSearchBar(configPage,configSearchBar)
                            }
                          }                                                
                        }
                      }
                    }}
                  />
                </TabPane>
              );
            } else {
              return (
                <TabPane key={i} tabId={_tabId} style={{ padding: "0px" }}>
                  <div>........</div>
                </TabPane>
              );
            }
          })}
        </TabContent>
      </div>
    );
  };
  render() {
    return (
      <div className="app">
        {this.renderTab()}
        {this.renderContent()}
      </div>
    );
  }
}
// export default PageTab3L5
const mapStateToProps = (state) => {
  return {
    currentProject: state.solidv3.currentProject,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setObjInSolidV3: (obj) => dispatch({ type: "setObjInSolidV3", ...obj }),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(PageTab3L7);

const BuildVersion = {
  buildCode: 20210001,
  buildDate: '31/1/2021',
  historyBuild: {
    20210001:{
      note: 'Init project 2021'
    }
  }
}

window._BuildVersion_ = BuildVersion;
export default BuildVersion;
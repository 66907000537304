import MyUIOne from "./MyUI";
import MyPageOne from "./MyPage"
import {HModules} from "@macashipo/mlib"
import V from "src/helpers/V"
const ModuleDev = {
  //========== init ==========
  _key: "dev",
  _fnList: {},
  _config: {},
  _routes: [],
  getMyKey: () => {
    return "dev";
  },
  runAfterInit: (HConfig) => {
    HModules.initDefault(ModuleDev, { MyPageOne, MyUIOne });
    //add test page
    V.Routes_AddTest([
      {UIUrl:'/testmodal',ScreenCode:'Test',Title:'Test',APIName:'Test',UIType:'test', Config: '{"type":"test"}'},
    ])
  },
  //---------- end default init ----------//
};
export default ModuleDev;


const DataSample = {
  buildArrayObject(num){
    let _arr = [];
    for(let i=0;i<num;i++){
      _arr.push(
        {
          Id: i+1+"",
          FirstName: DataSample.getFirstName(),
          LastName: DataSample.getLastName(),
          PlanDate: DataSample.getDate(),
          Color: DataSample.getColor(),
        }
      )
    }
    return _arr;
  },
  getColor(){
    let _letters = '0123456789ABCDEF';
    let _color = '#';
    for (var i = 0; i < 6; i++) {
      _color += _letters[Math.floor(Math.random() * 16)];
    }
    return _color;
  },
  getDate(distant=10){
    let _milisecond = new Date().getTime();
    let _min = -10;
    let _max = 10;
    let _random = Math.floor(Math.random() * (_max - _min + 1)) + _min;
    return _milisecond + (_random * 24 * 60 * 60 * 1000);
  },
  getFirstName(){
    let _arr = DataSample.first_name;
    let _randomIndex = Math.floor(Math.random() * _arr.length); 
    return _arr[_randomIndex];
  },
  getLastName(){
    let _arr = DataSample.last_name;
    let _randomIndex = Math.floor(Math.random() * _arr.length); 
    return _arr[_randomIndex];
  },
  first_name: ["Yalonda", "Yasmeen", "Yasmin", "Yelena", "Yetta", "Yettie", "Yetty", "Yevette", "Ynes", "Ynez", "Yoko", "Yolanda", "Yolande", "Yolane", "Yolanthe", "Yoshi", "Yoshiko", "Yovonnda", "Ysabel", "Yvette", "Yvonne"],
  last_name: ["Zabrina", "Zahara", "Zandra", "Zaneta", "Zara", "Zarah", "Zaria", "Zarla", "Zea", "Zelda", "Zelma", "Zena", "Zenia", "Zia", "Zilvia", "Zita", "Zitella", "Zoe", "Zola", "Zonda", "Zondra", "Zonnya", "Zora", "Zorah", "Zorana", "Zorina", "Zorine", "Zsa", "Zsa", "Zsazsa", "Zulema", "Zuzana"],
  Data:{
    string_name: ["Olala", "Ololo", "Ohihi", "Ohaha", "Ohoho", "Opapa", "Okaka", "Osasa", "Obaba", "Ovava", "Ohuhu", "Ocaca", "Oiji", "Ohehe", "Okeke", "Odede", "Obebe", "Ozaza", "Olulu", "Omimi", "Okiki", "Ogigi", "Owiwi"],
    objStatus:{
      deleted: 'Deleted',
      new: 'New', 
      pending: 'Pending',
    }, 
    objCity:{
      vn_hcm: {name:'Hồ Chí Minh'},
      vn_hn: {name:'Hà Nội'},
      us_ny: {name:'New York'}
    },
    objExtraData: {
      CanEdit: true, CanShow: true, Color_BG: null, Color_Text: null, ExtData: null, Filter: null, 
      Header: "Header",
      Width: "100%",
      // Type:{
      //   bgF: null, link: null, more: null, source: null, sourceField: null, type: "readonly"
      // }
    },
    objTypeExtra: {
      bgF: null, link: null, more: null, source: null, sourceField: null, type: "readonly"
    },
  },
  getString(minLength=10,maxLength=100){
    let _str = '';
    let _str1 = DataSample.Data.string_name[Math.floor(Math.random() * DataSample.Data.string_name.length)];
    // console.warn('_str1: ', _str1);
    _str = _str + _str1;
    while (_str.length < minLength){
      _str1 = DataSample.Data.string_name[Math.floor(Math.random() * DataSample.Data.string_name.length)];
      // console.warn('_str1 trong while: ', _str1);
      _str = _str +' '+ _str1;
      // console.warn('str trong while: ', _str);
      if (_str.length >= minLength && _str.length <= maxLength){
        return _str;
      } 
    }
    

  },
  getNumber(min=0,max=100){
    return Math.floor(Math.random() * (max - min + 1)) + 0;
  },
  buildColsForBootstrapTableFromData(data,opts={}){
    if (data != null && data.length > 0){
      let _item = data[0];
      let _keys = Object.keys(_item);
      let _arr = [];
      for (let i in _keys){
        let _itemKeys = _keys[i];
        let _obj = {dataField:_itemKeys, header:_itemKeys}
        _obj = Object.assign(_obj, opts[_itemKeys])
        _arr.push(_obj);
      }
      return _arr;
    }
  },
  
  buildArrayOptionsFromObject(obj,opts={}){
    let _keys = Object.keys(obj);
    let _arr=[];
    for (let i in _keys){
      let _item = _keys[i];
      let _childKey = opts.childKey;
      let _text = '';
      if (Object.keys(opts).length != 0){
        _text = obj[_item][_childKey];
        // console.warn('text khi co opts: ', _text);
      } else{
        _text = obj[_item];
        // console.warn('text khi ko co opts: ', _text);
      }
      _arr.push({
        Value:_item,
        Text: _text
      })
    }
    return _arr;
  },
  
  buildExtraData(obj, configType={}){
    let _keys = Object.keys(obj);
    let _extraData = {};
    _extraData.Columns = {};
    for (let i in _keys){
      let _k = _keys[i];
      // console.warn('_k: ', _k, i, _keys);
      let _columns = DataSample.Data.objExtraData;
      let _type = DataSample.Data.objTypeExtra;
      _columns.Header = _k;
      _columns = Object.assign({},_columns, obj[_k]);
      _type = Object.assign({},_type, configType[_k]);
      _extraData.Columns[_k] = _columns;
      _extraData.Columns[_k].Type = _type;
    }
    return _extraData;
  }
}

// let _testExtraData = DataSample.buildExtraData({
//   Id:{
//     CanEdit: false   
//   },Name:{},Age:{}
// },{
//   Id:{
//     type: 'text',
//     more: '',
//   }
// });
// console.warn('_testExtraData: ', _testExtraData);
// let _testbuildArray1 = DataSample.buildArrayOptionsFromObject(DataSample.Data.objStatus);
// console.warn('_testbuildArray khi không có opts: ', _testbuildArray1);
// let _testbuildArray2 = DataSample.buildArrayOptionsFromObject(DataSample.Data.objCity, {childKey:'name'});
// console.warn('_testbuildArray khi có opts: ', _testbuildArray2);

export default DataSample;
import PropTypes from 'prop-types';
import React, { Component } from 'react';

class Loading extends Component {
  constructor(props) {
    super(props);
  }


  _renderChild=()=>{
    const {type,styleItem} = this.props;

    switch(type){
      case Loading.Type.circle:
        return (
          <div className="sk-circle">
            <div style={styleItem} className="sk-circle1 sk-child"></div>
            <div style={styleItem} className="sk-circle2 sk-child"></div>
            <div style={styleItem} className="sk-circle3 sk-child"></div>
            <div style={styleItem} className="sk-circle4 sk-child"></div>
            <div style={styleItem} className="sk-circle5 sk-child"></div>
            <div style={styleItem} className="sk-circle6 sk-child"></div>
            <div style={styleItem} className="sk-circle7 sk-child"></div>
            <div style={styleItem} className="sk-circle8 sk-child"></div>
            <div style={styleItem} className="sk-circle9 sk-child"></div>
            <div style={styleItem} className="sk-circle10 sk-child"></div>
            <div style={styleItem} className="sk-circle11 sk-child"></div>
            <div style={styleItem} className="sk-circle12 sk-child"></div>
          </div>
        )
      case Loading.Type.threeBounce:
        return (
          <div className="sk-three-bounce">
            <div style={styleItem} className="sk-child sk-bounce1"></div>
            <div style={styleItem} className="sk-child sk-bounce2"></div>
            <div style={styleItem} className="sk-child sk-bounce3"></div>
          </div>
        )
    }
  }
  render() {
    return (
      <div className={"s_full s_full_center"} style={Object.assign({backgroundColor:'rgba(0, 0, 0, 0.3)',zIndex:10,justifyContent:'center'},this.props.style)}>
      {
        <i className={"fa fa-spinner fa-spin"} style={{color:'white',fontSize:'30px'}}/>
        // this._renderChild()
      }
      </div>
    );
  }
}

Loading.Type = {
  threeBounce: 'threeBounce',
  circle: 'circle',
}

Loading.propTypes = {
  color: PropTypes.string,
  type: PropTypes.string,
  style: PropTypes.object,
  styleItem: PropTypes.object,
}

Loading.defaultProps = {
  type: Loading.Type.threeBounce,
  color: '#333',
}

export default Loading;
import React, { Component } from 'react';
import {
  Button, FormGroup, InputGroup, InputGroupAddon,
  Select,
  ColFilter,
  HOptions,  
} from './_import';

class CFSelect2Content extends Component {
  constructor(props) {
    super(props);
    this.state = { 
      value: null,
    };
  }
  _renderContent=()=>{
    const {item,onClear,onChange,values,cFilter,options} = this.props;
    const {title,fieldName,cellProps} = item;
    
    let _moreProps = {};
    _moreProps = Object.assign(_moreProps,cellProps);
    let _value = this.state.value;
    if(_value==null){
      if(values && values[fieldName]){
        _value = values[fieldName];
      }
    }
    

    let _options = [];
    let _optionKey = item.optionKey;
    if(options && options[_optionKey]){
      _options = options[_optionKey];
    }
    if(item.needConvert==true){
      _options = HOptions.createOptionsForSelect2(_options);
    }
    return (
      <FormGroup>
        <InputGroup>
          <InputGroupAddon addonType="prepend">{title}</InputGroupAddon>
          <div style={{flex:1}}>
            <Select
              id="select2"
              onBlurResetsInput={false}
              onSelectResetsInput={false}
              autoFocus={false}
              options={_options!=null?_options:[]}
              value={_value}
              simpleValue
              multi={true}
              closeOnSelect={false}
              clearable={false}
              name="selected-state"
              onChange={(newValue)=>{                
                let _value = "";
                if(newValue){
                  _value = newValue.replace(/(\d+)/g,"(;$1;)");
                  _value = _value.replace(/,/g,'|');
                }
                let _obj = { type: 'RegexFilter', value: _value};   
                console.log('onChange:',newValue,_obj);    
                this.setState({
                  value: newValue
                });                           
                if(onChange){
                  onChange({
                    value: newValue,
                    filter: _obj
                  });
                }
              }}
              searchable={true}
              {..._moreProps}
            />
          </div>
          {
            _value!=null &&
            <div>
              <Button color="danger" onClick={()=>{
                this.setState({
                  value: null
                });   
                onClear(fieldName,item);
              }}><i className="fa fa-ban"></i></Button>
            </div>
          }          
        </InputGroup>
      </FormGroup>
    )
  }
  render(){
    const {item,index} = this.props;
    let _ui = this._renderContent();
    return (
      <ColFilter key={index} component={_ui} {...item.col}/>
    )
  }
}

export default CFSelect2Content
import React from "react";
import AuthorizationSecond from '../../../class/AuthorizationSecond';
import ConfigTabV3 from "../../../model/ConfigTabV3";
import {
  Button, connect, HApiAxios, HUI, HUtils, MyIcon, MyModal, Tooltip
} from "../_import";
import Tabv3_Lev3 from "./tabv3_lev3";
class PageTab3L3_User extends Tabv3_Lev3 {
  constructor(props) {
    super(props);
    this.state = {
    };
    this.initTab();
    this._parseParams();
  }
  _parseParams=()=>{
  }
  getSharedQueryDataMore=()=>{
    const { userCurrent, userSwitchIndex } = this.props;
    if (userSwitchIndex !== -1 && userCurrent) {    
      return {
        StaffGUIId: userCurrent.Id
      }  
    }
  }
  componentDidMount() {
    if (this._isNeedRequestOptions()) {
      this.requestOptions({
        cbSuccess: () => {},
      });
    }
    this.getuserList();
  }
  getuserList = () => {
    const {userSwitchIndex, setObjInSolidV3} = this.props;
    HApiAxios.generic({
      request: {
        method: "POST",
        path: "StaffView4SolidTeam",
        name: "List",
      },
      data: {},
      successCallBack: (response) => {
        let _userList = HUtils.Obj.get(response, "Data.Data") || [];
        let _info = ConfigTabV3.parseStaffList(_userList);
        console.warn("_infoStaff",_info,_userList)
        setObjInSolidV3({
          userList: _userList,
          userCurrent: _info.Data,
          userActiveIndex: _info.Index,
          userSwitchIndex: userSwitchIndex!=-1?_info.Index:userSwitchIndex,//update userSwitchIndex lai cho dung voi project
        })  
        this.setState({
          loadedTab: [this.getTabId({index:this.state.activeTabIndex})]
        });
      },
      errorCallBack: (error, response) => {
        console.warn("error: ", error, response);
      },
    });
  };
  getCurrentItemName = ()=>{
    const {userCurrent} = this.props;
    if(userCurrent){
      return userCurrent.DisplayName;
    }
  }
  _getTokenUserSecond=(user)=>{
    // StaffScreenDiagram/GenerateSecondLoginToken
    HApiAxios.generic({
      request: {
        method: "POST",
        path: "StaffScreenDiagram",
        name: "GenerateSecondLoginToken",
      },
      data: {
        StaffGUIId: user.ID_GUID
      },
      successCallBack: (response) => {
        let _token = HUtils.Obj.get(response,"Data.Token");
        if(_token){
          AuthorizationSecond.saveLocalStorage(user,_token);
          window.location.reload();
        }
      },
      errorCallBack: (error, response) => {
        console.warn("error: ", error, response);
      },
    });
  }
  onSwitch = (index) => {
    const { fnList, setSwitchUser, userCurrent } = this.props;
    if(index==-1){
      ConfigTabV3.switchOffSolidStaff()
    }
    else if(userCurrent){
      ConfigTabV3.switchSolidStaff(userCurrent.Id,userCurrent,index,{
        needClearTab: true
      });
    }
    setSwitchUser(index,userCurrent);
    if(fnList && fnList.fntv1OnReloadUI){
      fnList.fntv1OnReloadUI();
    }        
  };
  onSwitchUserModeView=()=>{
    const { fnList, setSwitchUser, userCurrent } = this.props;
    console.warn("onSwitchUserModeView:",userCurrent);
    if(userCurrent && userCurrent.Id){
      HUI.Alert.showMsgQuestion("Xác nhận",`Bạn có chắn muốn chuyển sang chế độ xem của ${userCurrent.DisplayName}`,()=>{
        this._getTokenUserSecond(userCurrent);
      })
    }    
  }
  onChangeUser = (item) => {
    const { userList, fnList, setObjInSolidV3 } = this.props;
    console.warn("onChangeUser:",item);    
    let _index = 0;
    let _item = userList.find((v, i) => {
      if (v.Id === item.Id) {
        _index = i;
      }
      return v.Id === item.Id;
    });
    if (_item) {
      if(fnList && fnList.fntv1OnReloadUI){
        fnList.fntv1OnReloadUI();
      }    
      
      setObjInSolidV3({
        userCurrent: _item,
        userActiveIndex: _index,
        userSwitchIndex: _index,
      })      

      //set url
      ConfigTabV3.switchSolidStaff(_item.Id,_item,_index,{
        needClearTab: true
      });
      MyModal.Helper.hide();
    }
  };

  renderPreLeftMenu = () => {
    const { userList, userSwitchIndex, userActiveIndex, userCurrent} = this.props;
    return (
      <div
        className="switch-header"
        style={{
          marginRight: "50px",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginRight: "8px",
            fontWeight: 500,
          }}
        >
          <label className="label-switch" style={{ padding: "0px 5px" }}>
            <div
              className="toggle-switch"
              style={{
                boxShadow: "none",
                backgroundColor: "rgb(111 119 130 / 52%)",
              }}
            >
              <input
                className="toggle-state"
                type="checkbox"
                name="check"
                checked={userSwitchIndex === -1 ? false : true}
                onChange={() =>
                  this.onSwitch(userSwitchIndex === -1 ? userActiveIndex : -1)
                }
              />
              <div className="indicator" style={{ boxShadow: "none" }}></div>
            </div>
          </label>
          <div
            style={{
              display: "flex",
              flexWrap: "wrap"
            }}
          >
            <div
              style={{
                color: userSwitchIndex === -1 ? "#c74634" : "var(--grey-primary)",
                cursor: "pointer",
              }}
              onClick={() => {
                if (userActiveIndex === userSwitchIndex) {
                  this.onSwitch(-1)
                }
              }}
            >
              Solid
            </div>
            <div
              style={{
                margin: "0px 2px",
                color: "var(--grey-primary)",
              }}
            >
              /
            </div>
            <div
              style={{
                color:
                userSwitchIndex === userActiveIndex
                    ? "#c74634"
                    : "var(--grey-primary)",
                cursor: "pointer",
              }}
              onClick={() => {
                console.warn("onClick:",userActiveIndex,userSwitchIndex)
                // if (userActiveIndex === userSwitchIndex) {
                  MyModal.Helpers.showMyUI(
                    "list_card",
                    {
                      dataList: userList,
                      cardType: "card_project_select",
                      cardProps:{
                        fieldName: "DisplayName"
                      },
                      configPagination: {
                        using: true,
                        pageSize: 10,
                      },
                      fnList: {
                        fnSelectProject: (pj) => {
                          this.onChangeUser(pj);
                        },
                      },
                    },
                    {
                      title: "Choose User",
                      closeButton: true,
                      size: "lg",
                    }
                  );
                // }
                // else{
                //   this.onSwitch(userSwitchIndex === -1 ? userActiveIndex : -1)
                // }
              }}
            >
              {this.getCurrentItemName()}
            </div>
          </div>
          {
            userSwitchIndex>-1 && AuthorizationSecond.getConfigByKey(AuthorizationSecond.KCanRunAsOtherUser,false) && userCurrent &&
            <div>
              <Tooltip placement="top" overlay={`Chuyển chế độ xem qua tài khoản ${userCurrent.DisplayName}`}>
                <Button style={{padding:"2px 5px",background:"#c74634",marginLeft:"5px"}} onClick={()=>{this.onSwitchUserModeView()}}>
                  <MyIcon data="type:userchange" width={"20px"} height={"20px"} fill={"white"}/>
                </Button>
              </Tooltip>
            </div>
          }          
        </div>
      </div>
    );
  };
  render() {
    const {userCurrent,waitingOptionsTab1,userSwitchIndex} = this.props;
    if(waitingOptionsTab1){
      return (
        <div className="app">
          {this.renderTab()}        
        </div>
      )
    }
    return (
      <div className="app">
        {this.renderTab()}
        {
          // for print
          userSwitchIndex>=0 && userCurrent &&
          <div className="only-print" style={{padding:"0px 15px",fontWeight:"bold"}}>{this.getCurrentItemName()}</div>
        }   
        {userCurrent && this.renderContent()}
      </div>
    );
  }
}
// export default PageTab3L3_Project;
const mapStateToProps = state =>{
  return{
    userCurrent: state.solidv3.userCurrent,
    userList: state.solidv3.userList,
    userSwitchIndex: state.solidv3.userSwitchIndex,
    userActiveIndex: state.solidv3.userActiveIndex,
    waitingOptionsTab1: state.solidv3.waitingOptionsTab1,
  }
}
const mapDispatchToProps=dispatch=>{
  return{
    setObjInSolidV3: (obj)=>{
      dispatch({type: 'setObjInSolidV3',...obj});      
    },
    setSwitchUser: (index,current)=>{
      dispatch({type: 'setObjInSolidV3',userSwitchIndex:index,userCurrent: current})
    },
  }
}
export default connect(mapStateToProps,mapDispatchToProps)(PageTab3L3_User)
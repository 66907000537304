import React from 'react';
import MyLoading from '../MyLoading/MyLoading';

const Circle = React.lazy(() => import('./Types/circle'));
const Square = React.lazy(() => import('./Types/square'));
const Triangle = React.lazy(() => import('./Types/triangle'));
const Star = React.lazy(() => import('./Types/star'));
// const Star2 = React.lazy(() => import('./Types/star2'));
const Line = React.lazy(() => import('./Types/line'));
const Rect = React.lazy(() => import('./Types/rect'));
const Text = React.lazy(() => import('./Types/text'));
const Icon = React.lazy(() => import('./Types/icon'));

const MySVGTypes = {
  addType(key,fnComponent){
    if(MySVGTypes.Types[key]==null){
      MySVGTypes.Types[key] = fnComponent;
    }
  },
  Types:{    
    square(props){
      return(
        <React.Suspense fallback={<MyLoading />}>
          <Square {...props}/>
        </React.Suspense>
      )
    },
    triangle(props){
      return(
        <React.Suspense fallback={<MyLoading />}>
          <Triangle {...props}/>
        </React.Suspense>
      )
    },
    star(props){
      return(
        <React.Suspense fallback={<MyLoading />}>
          <Star {...props}/>
        </React.Suspense>
      )
    },
    line(props){
      return(
        <React.Suspense fallback={<MyLoading />}>
          <Line {...props}/>
        </React.Suspense>
      )
    },
    rect(props){
      return(
        <React.Suspense fallback={<MyLoading />}>
          <Rect {...props}/>
        </React.Suspense>
      )
    },
    circle(props){
      return(
        <React.Suspense fallback={<MyLoading />}>
          <Circle {...props}/>
        </React.Suspense>
      )
    },
    text(props){
      return(
        <React.Suspense fallback={<MyLoading />}>
          <Text {...props}/>
        </React.Suspense>
      )
    },
    icon(props){
      return(
        <React.Suspense fallback={<MyLoading />}>
          <Icon {...props}/>
        </React.Suspense>
      )
    },
    // star2(props){
    //   return(
    //     <React.Suspense fallback={<MyLoading />}>
    //       <Star2 {...props}/>
    //     </React.Suspense>
    //   )
    // },
  }
}

export default MySVGTypes;
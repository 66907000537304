import * as Routers from './_routes';
import HGA from '../../helpers/GA';

/** Custom Component */
// import MyPage from './components/MyPage'//tam an
// import MyUI from './components/MyUI'//tam an

/** Custom store */
// import * as CustomStore from './_store';

/** Css */
import './styles.css'
import './styles_overide.css'
import './styles_custom.css'
import './styles_common.css'
import './styles_common_v2.css'
import '../../libs/ReactSelectV1.1.0/scss/react-select.css'

/** Helpers */
// import {HGlobalSetting,HConfig,HStoreRedux,HLocalStorage} from '../Helpers';
import HLink from '../../helpers/Link';
import HUtils from '../../helpers/Utils';
import HAuth from '../../helpers/Auth';
import HGlobalSetting from '../../helpers/GlobalSetting';

/** Service */
import SApiAxios from '../../helpers/ApiAxios';

/** Version History */
import BuildVersion from './_version'

const ConfigBuild = {
  HConfig: null,
  Routers: Routers,
  // CustomStore,
  ConfigApp:{
    defaultHomePage: '',
    BaseUrl: 'http://soliddevapi2.allianceitsc.com',
    isUseHash: true,//Su dung router Hash,
    listDomainDebug: ['ajumashopdev.allianceitsc.com'],//Check debug    
    googleClientId: "786359577708-65mnb6iqfg4fev8mgrlvu9hb4o22um8f.apps.googleusercontent.com",
    appName: 'solid',
    // GAId: 'UA-64446070-6',
    // GAId_Dev: 'UA-156318542-10',
    theme: "theme-solid",
    metaName: 'Solid',
    metaUrl: 'https://solid.vn',
    //from solid
    hasRequestFirst: true,
    showLoginWithUserPassword: false,
    pathLogo: 'img/logo.png',
    home_url: '/welcome',
    footer: '<span><a href="http://app.solidapp.vn">SolidApp</a> &copy; 2018 DEV </span>',
  },
  fnList:{
    afterInit(HConfig){
      ConfigBuild.HConfig = HConfig;
      console.warn("RunAfterInit:",Routers);            
      /** GA */
      // HGA.initialize();

      //init facebook
      // initFacebookSdk();
      if(HConfig.configApp && HConfig.configApp.hasRequestFirst!==false){
        HGlobalSetting.requestFirst();
      }
    },
    afterLostSession(){
      console.warn("afterLostSession");
      HAuth.logOut();
    },
    afterLogout(){
      console.warn("afterLogout");
      SApiAxios.updateToken(null);
      HLink.push('/');
      //Khi mat section tu dong logout roi tro ve trang home
    },
    afterLogin(){
      console.warn("afterLogin");
      SApiAxios.updateToken(HAuth.getAuthToken());  
      HLink.push(ConfigBuild.ConfigApp.home_url);    
      HUtils.runFnConfig(ConfigBuild.HConfig,"afterLogged",[]);
    },
    afterLogged(){
      console.warn("afterLogged");
      HGlobalSetting.requestConfigMenu();
      HGlobalSetting.requestProjectScreenHeader();
    },
    afterErrorApi(error,opts){
      // console.warn("afterErrorApi:",error,opts);
      let _url = HUtils.Obj.get(opts,"options.options.url") || 'no_url';
      let _errorStatus = HUtils.Obj.get(error,"response.status") || 'no_status';
      let _data = HUtils.Obj.get(opts,"options.data") || {};
      if(_data && typeof _data=='object'){
        let _query = Object.keys(_data).map(key => `${encodeURIComponent(key)}=${encodeURIComponent(_data[key])}`).join('&');
        if(_query && typeof _query=="string"){
          _url += '?' + _query;
        }
      }
      let _error_message = HUtils.Obj.get(opts,"error_message") || 'no_message';
      console.warn("LogError:",_url,_error_message,_errorStatus);
      HGA.trackingEventErrorApi({
        apiUrl: _url,
        label: _error_message
      })
    },
  },
}

export default ConfigBuild;
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import {
  Card,
  CardBody, CardHeader,
  Input
} from '../../../_importComponent';
import { Color, Global, Loading, Text } from '../../../helpers';
import {ApiProjectHeader} from '../../../_importService';
import {Dropzone} from '../../../_importLibs';
import InputControl from '../InputEditor/CustomControl';
import ProjectMenu from '../ProjectMenu';
import classNames from 'classnames';
// class InputEditor extends Component {
//   constructor(props) {
//     super(props);
//     this.state = { 
//       value: props.value||'',
//     };
//   }

//   componentWillReceiveProps(nextProps){
//     if(nextProps.value!=this.state.value && this.state.value=='' && nextProps.value!=null){
//       this.setState({
//         value:nextProps.value
//       })
//     }
//   }

//   _onBlur=(ev,fieldName)=>{
//     const {onUpdateField,type}=this.props;
//     // console.log('_onBlur:',ev.currentTarget,fieldName,ev.currentTarget.value);
//     if(type!="select"){
//       if(onUpdateField!=null){
//         onUpdateField(fieldName,ev.currentTarget.value);
//       }
//     }
//   }
//   _renderInput=()=>{
//     const {onChangingField,fieldName,options,type, placeholder,onUpdateField,canEdit }=this.props;
//     return (
//       <Input 
//         spellCheck={false}
//         className={"select_wordwrap s_input"}
//         style={{}}
//         type={type}
//         value={ this.state.value } 
//         disabled={!canEdit}
//         placeholder = { placeholder }
//         onBlur={(ev)=> {this._onBlur(ev,fieldName)}}
//         onChange={ (ev) => { this.setState({ value: ev.currentTarget.value })}}
//         >
//       </Input>
//     )
//   }
//   _renderSelect=()=>{
//     const {onChangingField,fieldName,options,type, placeholder,onUpdateField,canEdit }=this.props;
//     let _uiOptions = Global.Helper.createOptions(options,' ');

//     return (
//       <Input 
//         spellCheck={false}
//         className={"select_wordwrap s_input"}
//         style={{}}
//         type={type}
//         value={ this.state.value } 
//         disabled={!canEdit}
//         placeholder = { placeholder }
//         onChange={ (ev) => { this.setState({ value: ev.currentTarget.value },()=>{
//           if(onUpdateField!=null){
//             console.log('update select:',fieldName,this.state.value);
//             onUpdateField(fieldName,this.state.value);
//           }
//         }); } } >
//         {
//           _uiOptions!=null && _uiOptions
//         }
//       </Input>
//     )
//   }
//   render() {
//     const {onChangingField,fieldName,options,type, placeholder,onUpdateField,canEdit }=this.props;
//     let _isChanging = false;

//     // console.log('InputEditor:',fieldName,this.state,type);
//     let _uiInput;
//     if(type=='select'){
//       _uiInput = this._renderSelect();
//     }
//     else{
//       _uiInput = this._renderInput();
//     }
//     return (
//       <span className={""} style={{flex:1}}>
//         {
//           _uiInput
//         }
//         {
//           _isChanging==true && 
//           <Loading style={{}} styleItem={{backgroundColor:'white'}} type={Loading.Type.threeBounce}/>
//         }
//       </span>
//     );
//   }
// }

class ProjectHeader extends Component {
  constructor(props) {
    super(props);
    this.state = { 
      projectHeader: null,
      isUploadingLogo: false,
      minimize: false,
    };
  }

  componentDidMount(){
    this._onRequestHeader();
  }

  _onRequestHeader=()=>{
    const {projectId,screenCode} = this.props;
    if(projectId!=null){
      ApiProjectHeader.get_header(projectId,screenCode,{
        successCallBack:(response)=>{
          // console.log('_onRequestHeader:',response);
          this.setState({
            projectHeader: response.Data
          })

          if(response.Data!=null && response.Data.Dic_Files!=null){
            ProjectMenu.Data.MenuData.updateCurrentDicFiles(response.Data.Dic_Files);
          }
        },
        errorCallBack:(error,response)=>{
          console.log('_onRequestHeader error:',error,response);
        }
      })
    }
  }

  _onRequestUpdate=(fieldName,value)=>{
    const {projectId,screenCode} = this.props;
    if(projectId!=null){
      let _data = [{
        FieldName: fieldName,
        OldValue: '',
        NewValue: value,
      }];
      ApiProjectHeader.update_field(projectId,screenCode,_data,{
        successCallBack:(response)=>{
          Global.Toast.showSuccess(response.Msg);
          this.setState({
            projectHeader: response.Data
          })
        },
        errorCallBack:(error,response)=>{
          console.log('_onRequestUpdate error:',error,response);
        }
      })
    }
  }
  
  _onDrop=(files,rejected)=>{
    const {projectId,screenCode} = this.props;
    console.log(files);
    console.log(rejected);
    if(rejected!=null && rejected.length>0){
      if(files!=null && files.length==0){        
        Global.Alert.showMsg(Text.get('msg_error'),Text.get('msg_error_upload_onlyimage'));
      }
    }
    if(files!=null && files.length>0){
      this.setState({
        isUploadingLogo: true
      },()=>{
        let _f = files[0];
        console.log("File name: " + _f.name);
        console.log("File size: " + _f.size);
        console.log("File type: " + _f.type);
        // console.log("Binary content: " + _f.getAsBinary());
        // console.log("Text content: " + _f.getAsText(""));
        ApiProjectHeader.update_logo(projectId,_f,{
          successCallBack:(response)=>{
            console.log('_onRequestUpdate:',response);
            let _projectHeader = this.state.projectHeader;
            if(response.Data!=null && response.Data.length>0){
              if(_projectHeader!=null){
                _projectHeader.LogoUrl = response.Data;
              }
            }
            this.setState({
              projectHeader: _projectHeader,
              isUploadingLogo: false,
            })
          },
          errorCallBack:(error,response)=>{
            console.log('_onRequestUpdate error:',error,response);
            this.setState({
              isUploadingLogo: false,
            })
          }
        })
      })
    }
  }

  _onUpdateField=(fieldName,value)=>{
    console.log('_onUpdateField:',fieldName,value);
    const {projectHeader} = this.state;
    if(projectHeader!=null && projectHeader[fieldName]!=value && !(value=="" && projectHeader[fieldName]==null)){
      this._onRequestUpdate(fieldName,value);
    }
  }
  render() {
    const {projectHeader} = this.state;
    let _projectName = projectHeader!=null?projectHeader.DisplayName || '':'';
    let _place_Area = projectHeader!=null?projectHeader.Place_Area || '': '';
    let _widthInputPlaceArea = String(_place_Area).length;
    if(_widthInputPlaceArea>0){
      _widthInputPlaceArea+=2
    }
    else{
      _widthInputPlaceArea = 5
    }
    // console.log("_place_Area:",_place_Area,_widthInputPlaceArea)
    let _placeName = projectHeader!=null?projectHeader.Place_Location || '':'';
    let _reportNo = projectHeader!=null?projectHeader.ReportNo || '':'';
    let _reportingFrom = projectHeader!=null && projectHeader.ReportingFrom !=null ? projectHeader.ReportingFrom || '':'';
    let _reportingTo = projectHeader!=null && projectHeader.ReportingTo !=null ? projectHeader.ReportingTo || '':'';
    let _createdDate = projectHeader!=null && projectHeader.CreatedDate !=null ? projectHeader.CreatedDate || '':'';
    let _optionsPM = projectHeader!=null?projectHeader.PMList || []:[];
    let _pmId = projectHeader!=null?projectHeader.PMId || '':'';
    let _optionsCM = projectHeader!=null?projectHeader.ConstructionManagerList || []:[];
    let _cmId = projectHeader!=null?projectHeader.ConstructionManagerId || '':'';
    let _optionsTK = projectHeader!=null?projectHeader.TKLeadList || []:[];
    let _tKLeadId = projectHeader!=null?projectHeader.TKLeadId || '':'';
    let _optionsBD = projectHeader!=null?projectHeader.BDLeadList || []:[];
    let _bdId = projectHeader!=null?projectHeader.BDLeadId || '':'';
    let _optionsQC = projectHeader!=null?projectHeader.QCLeadList || []:[];
    let _qcLeadId = projectHeader!=null?projectHeader.QCLeadId || '':'';
    let _optionsTM = projectHeader!=null?projectHeader.TMLeadList || []:[];
    let _tmLeadId = projectHeader!=null?projectHeader.TMLeadId || '':'';
    
    let _logoURL = projectHeader!=null?projectHeader.LogoUrl || '':'';
    let _styleDropzone = {cursor: 'pointer',position: "relative",width:'95%',height:'95%',padding:'5px',border:'2px dashed #d6d6d6',borderRadius:'5px',};
    if(_logoURL!=null && _logoURL.length>0){
      _styleDropzone = {cursor: 'pointer',position: "relative",width:'95%',height:'95%',padding:'5px',color:'transparent',background:'transparent'};
    }
    // console.log('_projectName:',_projectName);
    let _borderStyle = '1px dashed ' + Color.borderHeaderProject;
    let _bgColorLogo = 'white';
    let _canEdit = false;
    if(projectHeader){
      if(projectHeader.CanEditHeader!=null){
        _canEdit = projectHeader.CanEditHeader;
      }
      if(projectHeader.Logo_Background){
        _bgColorLogo = projectHeader.Logo_Background;
      }
    }
    return (
        <Card style={{border:'0px',backgroundColor:'transparent'}} className={classNames("s_boxshadow",{
          "no-print": this.state.minimize
        })}>
          <CardHeader style={{backgroundColor:'#6088b3',paddingTop:'5px',paddingBottom:'5px',color:'white',border:'0px',display:"flex",flexDirection:"row",alignItems:"center",justifyContent:"space-between"}}>
            <strong>PROJECT WEEKLY STATUS REPORT</strong>
            <div className="no-print" title={this.state.minimize?"Hiện thông tin":"Ẩn thông tin"} style={{cursor:"pointer"}} onClick={()=>{
              this.setState({
                minimize: !this.state.minimize
              })
            }}><i className={this.state.minimize?"fa fa-window-maximize":"fa fa-window-minimize"}/></div>
          </CardHeader>
          <CardBody style={{backgroundColor:Color.bgPjHeader,marginTop:'2px',padding:'0px', display: this.state.minimize?"none":"block"}}>
            <div className="s_projectheader">
              <div className="pjh_col1">
                <div className="s_projectheader_logo">
                  <div className="dropzone" style={{backgroundColor:_bgColorLogo}}>
                    {
                      _logoURL.length>0 && 
                      <img src={_logoURL} style={{
                        // width:'auto',height:'auto',
                        // maxWidth:'138px',maxHeight:'138px',
                        width: "100%", height:"100%",
                        position:'absolute',
                        // borderLeft:'2px #e4e5e6 solid',borderRight:'2px #e4e5e6 solid'
                      }} />
                    }
                    {
                      _canEdit &&
                      <Dropzone accept="image/jpeg" onDrop={this._onDrop}>
                        {({getRootProps, getInputProps, isDragActive}) => {
                          return (
                            <div
                              {...getRootProps()}
                              className={`dropzone ${isDragActive?'dropzone--isActive':''}`}
                              style={_styleDropzone}
                            >
                              <input {...getInputProps()} />
                              {
                                isDragActive ?
                                  <p>Drop files here...</p> :
                                  <p>Try dropping some files here, or click to select files to upload.</p>
                              }
                            </div>
                          )
                        }}
                      </Dropzone>
                    }
                    
                    {/* <Dropzone accept="image/jpeg, image/png" onDrop={this._onDrop} style={_styleDropzone}>
                      {({getRootProps, getInputProps, isDragActive}) => {
                        return (
                          <p>Dropping logo here, or click to select image to upload.</p>
                        )
                      }}
                    </Dropzone> */}
                  </div>
                  {
                    this.state.isUploadingLogo == true &&
                    <Loading style={{}} styleItem={{backgroundColor:'white'}} type={Loading.Type.threeBounce}/>
                  }
                </div>
                <div style={{flex:1,display:'flex',flexDirection:'column'}}>
                  <div className="pjh_row" style={{borderBottom:_borderStyle,}}>
                    <div style={{width:'30%',borderRight:_borderStyle}} className="s_projectheader_label">
                      Project's Name + Area (sqm)/<br/>Tên dự án + diện tích (m²)
                    </div>
                    <div style={{flex:1,display:'flex',alignItems:'flex-end',flexDirection:'row'}}>
                      <InputControl 
                        className="s_input select_wordwrap"
                        disabled={!_canEdit} 
                        type="text" 
                        placeholder={""} 
                        value={_projectName}
                        onBlur={(ev)=> { 
                          this._onUpdateField('DisplayName',ev.currentTarget.value);
                        }}
                        />
                        <div style={{display:"flex",flexDirection:"row",alignItems:"center"}}>
                          <span style={{width:"10px",textAlign:"center"}}>/</span>
                          <div className='a-input-wbycontent' data-value={String(_place_Area)}>
                            <InputControl 
                              className="s_input select_wordwrap"
                              disabled={!_canEdit} 
                              type="text" 
                              placeholder={""} 
                              value={_place_Area}
                              // style={{width: `${_widthInputPlaceArea}ch`}}
                              onBlur={(ev)=> { 
                                this._onUpdateField('Place_Area',ev.currentTarget.value);
                                this.forceUpdate()
                              }}
                              onChange={(ev,value,rInput)=>{
                                console.log("rInput:",ev,rInput)
                                if(ev && ev.target && ev.target.parentNode && ev.target.parentNode.setAttribute){
                                  ev.target.parentNode.setAttribute("data-value",value)
                                }
                              }}
                              morePropsInput={{
                                size: 1
                              }}
                              />
                          </div>                          
                          {/* <span>m2</span> */}
                          <span>m²</span>
                        </div>
                    </div>
                  </div>
                  <div className="pjh_row">
                    <div style={{width:'30%',borderRight:_borderStyle}} className="s_projectheader_label">
                      Location /<br/>Địa điểm
                    </div>
                    <div style={{flex:1,display:'flex',alignItems:'flex-end',flexDirection:'row'}}>
                      {/* <InputEditor type="textarea" placeholder={""} canEdit={true} fieldName={"Place_Location"} onUpdateField={this._onUpdateField} value={_placeName}/> */}
                      <InputControl 
                        className="s_input select_wordwrap"
                        style={{height:'48px'}}
                        disabled={!_canEdit}
                        type="textarea" 
                        value={_placeName}
                        rows={2}
                        onBlur={(ev)=> { 
                          this._onUpdateField('Place_Location',ev.currentTarget.value);
                         }}
                        />
                    </div>
                  </div>
                </div>
              </div>
              
              <div style={{flex:1}}>
                <div className="pjh_row" style={{borderBottom:_borderStyle,}}>
                  <div style={{width:'33%',borderRight:_borderStyle}} className="s_projectheader_label">
                    <div>
                      Project Manager /<br/>Quản lý dự án<br/>
                    </div>
                    <InputControl 
                      className="s_input select_wordwrap"
                      disabled={!_canEdit}
                      type="select" 
                      options={_optionsPM} 
                      value={_pmId}
                      onChange={(ev,newValue)=>{
                        this._onUpdateField('PMId',newValue);
                      }}
                      />
                  </div>
                  <div style={{width:'33%',borderRight:_borderStyle}} className="s_projectheader_label">
                    <div>
                      Bussiness Development /<br/>Phát triển kinh doanh<br/>
                    </div>
                    <InputControl 
                      className="s_input select_wordwrap"
                      disabled={!_canEdit}
                      type="select" 
                      options={_optionsBD} 
                      value={_bdId}
                      onChange={(ev,newValue)=>{
                        this._onUpdateField('BDLeadId',newValue);
                      }}
                      />
                  </div>
                  <div style={{width:'33%'}} className="s_projectheader_label">
                    <div>
                      Designer /<br/>Thiết kế<br/>
                    </div>
                    <InputControl 
                      className="s_input select_wordwrap"
                      disabled={!_canEdit}
                      type="select" 
                      options={_optionsTK} 
                      value={_tKLeadId}
                      onChange={(ev,newValue)=>{
                        this._onUpdateField('TKLeadId',newValue);
                      }}
                      />
                  </div>
                </div>
                <div className="pjh_row">
                  <div style={{width:'33%',borderRight:_borderStyle}} className="s_projectheader_label">
                    <div>
                    Construction Manager /<br/>Quản lý thi công<br/>
                    </div>
                    <InputControl 
                      className="s_input select_wordwrap"
                      disabled={!_canEdit}
                      type="select" 
                      options={_optionsCM} 
                      value={_cmId}
                      onChange={(ev,newValue)=>{
                        this._onUpdateField('ConstructionManagerId',newValue);
                      }}
                      />
                  </div>
                  <div style={{width:'33%',borderRight:_borderStyle}} className="s_projectheader_label">
                    <div>
                      Quality Management /<br/>Quản lý chất lượng<br/>
                    </div>
                    <InputControl 
                      className="s_input select_wordwrap"
                      disabled={!_canEdit}
                      type="select" 
                      options={_optionsQC} 
                      value={_qcLeadId}
                      onChange={(ev,newValue)=>{
                        this._onUpdateField('QCLeadId',newValue);
                      }}
                      />
                  </div>
                  <div style={{width:'33%',}} className="s_projectheader_label">
                    <div>
                      Cost &amp; Purchasing/<br/>Thu mua<br/>
                    </div>
                    <InputControl 
                      className="s_input select_wordwrap"
                      disabled={!_canEdit}
                      type="select" 
                      options={_optionsTM} 
                      value={_tmLeadId}
                      onChange={(ev,newValue)=>{
                        this._onUpdateField('TMLeadId',newValue);
                      }}
                      />
                  </div>
                  {/* <div style={{width:'50%',borderRight:_borderStyle,display:'flex'}}>
                    <div style={{width:'50%'}} className="s_projectheader_label">
                      Report No. /<br/>Lần báo cáo thứ
                      <br/>
                      <InputControl 
                        className="s_input select_wordwrap"
                        disabled={!_canEdit} 
                        type="number" 
                        placeholder={""} 
                        value={_reportNo}
                        onBlur={(ev)=> { 
                          this._onUpdateField('ReportNo',ev.currentTarget.value);
                        }}
                      />
                    </div>
                    <div style={{width:'50%',borderLeft:_borderStyle}} className="s_projectheader_label">
                      Date /<br/>Ngày thực hiện
                      <br/>
                      <InputControl 
                        style={{border:'0px',backgroundColor:'transparent'}}
                        disabled={!_canEdit}
                        className="s_input"
                        onChange={(ev,newValue)=>{
                          this._onUpdateField('CreatedDate',newValue);
                        }}
                        id="ph_CreatedDate" 
                        type="date" 
                        placeholder={""} 
                        value={_createdDate}/>
                    </div>
                  </div> */}
                  {/* <div style={{width:'50%',display:'flex',flexDirection:'column'}}>
                    <div style={{borderBottom:_borderStyle}} className="s_projectheader_label">
                      Report Period / Giai đoạn báo cáo
                    </div>
                    <div style={{display:'flex'}} className="">
                      <div style={{width:'50%',}} className="s_projectheader_label">
                        From
                        <br/>
                        <InputControl 
                          style={{border:'0px',backgroundColor:'transparent'}}
                          disabled={!_canEdit}
                          className="s_input"
                          onChange={(ev,newValue)=>{
                            this._onUpdateField('ReportingFrom',newValue);
                          }}
                          id="ph_ReportingFrom" 
                          type="date" 
                          placeholder={""} 
                          value={_reportingFrom}/>
                      </div>
                      <div style={{width:'50%',}} className="s_projectheader_label">
                        To
                        <br/>
                        <InputControl 
                          style={{border:'0px',backgroundColor:'transparent'}}
                          disabled={!_canEdit}
                          className="s_input"
                          onChange={(ev,newValue)=>{
                            this._onUpdateField('ReportingTo',newValue);
                          }}
                          id="ph_ReportingTo" 
                          type="date" 
                          placeholder={""} 
                          value={_reportingTo}/>
                      </div>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </CardBody>
        </Card>
    )
  }
}

ProjectHeader.propTypes = {
  projectId: PropTypes.string,
  screenCode: PropTypes.string,
}

ProjectHeader.defaultProps = {
}

export default ProjectHeader;
import React, { PureComponent } from 'react';
import AbsFormRow from "./AbsFormRow";
import "./style.css";
class Page extends PureComponent {
  constructor(props) {
    super(props);
    this.state={
      values: {}
    }
    this._cRow = {};
    this._currentValues = {};        
  }

  componentDidMount(){
  }

  // componentWillUpdate(newProps,newState){
  //   console.warn('Form componentWillUpdate',this.props,newProps);
  //   console.warn('Form componentWillUpdate',this.state,newState);
  // }

  _renderType=(item,opts)=>{

  }

  _renderRow=(item,opts)=>{
    const {onSubmit,getOptionOfKey,defaultValues} = this.props;
    const config = this._getConfig();
    return (
      <AbsFormRow 
        ref={r=>{this._cRow[item.FieldName]=r;}} 
        key={opts.index} 
        item={item} 
        opts={opts}         
        values={this.state.values}
        getOptionOfKey={getOptionOfKey}
        onSubmit={onSubmit}
        defaultValues={config.DefaultValues}
        onUpdateValueOfField={this._onUpdateValueOfField}
        configForm={config}
      />
    )
  }
  _renderForm=()=>{

  }

  _getConfig=()=>{
    const {config} = this.props;
    return config;
  }
  _getConfigData=()=>{
    const config = this._getConfig();
    if(config && config.Data!=null){
      return config.Data;
    }
    return [];
  }

  _getCurrentValues=()=>{
    let _values = {};
    if(this._cRow){
      for(let k of Object.keys(this._cRow)){
        let _c = this._cRow[k];
        if(_c){
          _values[k] = _c.getValue();
        }        
      }
    }
    return _values;
  }

  getValues=()=>{
    // console.warn('getValues:',this.props);
    return this._getCurrentValues();
  }

  isValid=()=>{
    return true;
  }

  _onUpdateValueOfField=({fieldName,newValue})=>{
    this._currentValues[fieldName] = newValue;
  }

  _getItemTrim=(item)=>{
    return item;
  }

  render() {
    // console.warn('render Form');
    let _uiRow = [];
    const configsData = this._getConfigData();
    if(configsData && configsData.length>0){
      for(let i in configsData){
        let _item = this._getItemTrim(configsData[i]);
        _uiRow.push(
          this._renderRow(_item,{index:i})
        );
      }
    }    
    return (
      <div className="mui-form">    
        {_uiRow}                   
      </div>   
    )
  }
}

export default Page;

import React from "react"
import { MyUI, MyLayout } from "@macashipo/mlib"
class MC_with_myui extends React.Component {
  render() {
    const config = this.props.config || {}
    const configMyUI = config.myui || {}
    // console.warn("render with myui:", this.props)
    return (
      <MyUI
        {...this.props}
        type={configMyUI.type}
        config={configMyUI}
        {...configMyUI.moreProps}
      />
    )
  }
}

export default MC_with_myui

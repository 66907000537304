import MyUIOne from "./MyUI";
import MyPageOne from "./MyPage";
import MyLayoutOne from "./MyLayout";
import MyRoutes from "./routes";
import HRoutes from "src/helpers/Route";
import HModule from "src/helpers/Module"
const ModuleV1Page = {
  //========== init ==========
  _key: "v3_page",
  _fnList: {},
  _config: {},
  _routes: MyRoutes.routes,
  getMyKey: () => {
    return "v3_page";
  },
  runAfterInit: (HConfig) => {
    HModule.initDefault(ModuleV1Page, { MyPageOne, MyUIOne, MyLayoutOne });
  },
  //---------- end default init ----------//
};

export default ModuleV1Page;

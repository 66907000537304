import React from "react"
import { MyUIHelpers, CSuppense } from "@macashipo/mlib";
const MUI_filterhub_deb = React.lazy(() => import("./Types/filterhub_deb"));
const List = {
  filterhub_deb: MUI_filterhub_deb
};
const MyUIOne = {
  init() {
    MyUIHelpers.addTypesFromListLazy(List);
  },
};

export default MyUIOne;

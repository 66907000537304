import React from "react"
import { MyPageHelpers } from "@macashipo/mlib";
const MP_dev_test = React.lazy(() => import("./Types/dev_test"));
const List = {
    test: MP_dev_test
};
const MyPageOne = {
  init() {
    MyPageHelpers.addTypesFromListLazy(List);
  },
};

export default MyPageOne;

export const onKeyDownEnter = (ev, fn) => {
  if (ev.key === "Enter") {
    fn()
  }
}

export const isFunction = (fn) => {
  return typeof fn === "function"
}

export const runFuntion = (fnList, name, args = []) => {
  if (fnList && isFunction(fnList[name])) {
    return fnList[name].apply(null, args)
  } else {
    console.warn("runFuntion failed:", fnList, name)
  }
}

export const runFuntionWithProps = (props = {}, name, args = []) => {
  let _fnList = props.fnList || {}
  return runFuntion(_fnList,name,args)
}

export const mergeObjIfExist = (srcObj, mergeObj) => {
  if (srcObj && mergeObj) {
    let _keys = Object.keys(mergeObj)
    if (_keys && _keys.length > 0) {
      for (let k of _keys) {
        srcObj[k] = mergeObj[k]
      }
    }
  }
}
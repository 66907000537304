import React from 'react';
import HHub from '../../../helpers/Hub';
import HUtils from '../../../helpers/Utils';
class CellType_ComponentFileIcon extends React.Component {
  static propTypes = {
  }
  static defaultProps = {
  }
  constructor(props) {
    super(props);
    this.state={
    };
  }

  render() {
    const me = this;
    const {row,fnList} = this.props
    const configFileIcon = this.props.configFileIcon || {}
    let _fieldName = configFileIcon.fieldName
    let _configFile = configFileIcon.file || {}    
    let _showBadge = false;
    let _countFiles = 0
    if(configFileIcon.showBadge){
      _showBadge = true;
      if(row[_fieldName] && row[_fieldName].Photos){
        _countFiles = row[_fieldName].Photos.length;        
      }
    }
    if(_fieldName && row){
      return (
        <div className="mct-status-file-icon" style={{color:"#20a8d8",padding:"0px 5px",...configFileIcon.style}} title={configFileIcon.title} onClick={()=>{
          let _screenCode = HUtils.runFnList(fnList,"fnGetScreenCode",this,[],"") || "";
          let _projectId = HUtils.runFnList(fnList,"fnGetProjectId",this,[],"") || ""
          console.log("show file",this.props,_screenCode,_projectId)
          HHub.showFilesToUploadV2({
            opts:{
              row: row,
              cell: row[_fieldName],
              data: row[_fieldName],
              fieldName: _fieldName,
              screenCode: _screenCode,
              projectId: _projectId,
              fID: configFileIcon.fID,
              fFN: configFileIcon.fFN, 
              ..._configFile                   
            },                  
            canEdit: configFileIcon.canEdit!=null?configFileIcon.canEdit:true,                  
            fnList:{
              ...fnList,
              onChangeData: (data)=>{
                HUtils.updateDataWithNewData(row[_fieldName],data,{fieldId:null});
                this.forceUpdate();
              }
            }
          })
        }}>
          <i className="fa fa-file-o" />
          {
            _showBadge && _countFiles> 0&& <span class="badge-files" style={{"fontSize":"8px","width":"10px","height":"12px","minWidth":"12px","padding":"0px",...configFileIcon.styleBadge}}>{_countFiles}</span>
          }
          
        </div>
      )
    }
    return (
      <div></div>
    )
  }
}

export default CellType_ComponentFileIcon;
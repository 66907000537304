import Api from './api';

const name_prefix = 'project_header_';
export default {
  request:{
    get_header:{
      type: name_prefix + 'get_header',
      url: '/api/v1/ProjectScreenHeader/GetHeader',
    },
    update_field:{
      type: name_prefix + 'update_field',
      url: '/api/v1/ProjectScreenHeader/UpdateFields',
    },
    project_screens:{
      type: name_prefix + 'project_screens',
      url: '/api/v1/ProjectScreenHeader/ProjectScreens',
    },
    update_logo(id){
      return {
        type: name_prefix + 'update_logo',
        url: '/api/v1/Upload/Project/Logo/'+id
      }
    },
  },
  get_header(ProjectId,ScreenCode,options={}){
    options.data = {
      ProjectId: ProjectId,
      ScreenCode: ScreenCode,
    };
    Api.post(this.request.get_header,options)
  },
  project_screens(options={}){
    Api.post(this.request.project_screens,options)
  },
  update_field(ProjectId,ScreenCode,data,options={}){
    options.data = {
      ProjectId: ProjectId,
      ScreenCode: ScreenCode,
      Values: data,
      /*Values: [{
        "FieldName": "string",
        "OldValue": {},
        "NewValue": {}
      ]} */
    };
    Api.post(this.request.update_field,options)
  },
  update_logo(ProjectId,file,options={}){
    options.file = file;
    Api.uploadFile(this.request.update_logo(ProjectId),options)
  },
}
import React, { Component } from 'react';
import CFSwitch from './TypesV1/switch_inbox'
import CFSelect2Quality from './TypesV1/select2quality'
import CFSelect2Content from './TypesV1/select2content'
import CFSelect2Modal from './TypesV1/select2_modal'

export const CellCustom = {  
  select2content: (props)=>{ return ( <CFSelect2Content key={props.index} {...props}/> ) },
  select2quality: (props)=>{ return ( <CFSelect2Quality key={props.index} {...props}/> ) },
  switch_inbox: (props)=>{ return ( <CFSwitch key={props.index} {...props}/> ) },
  select2_modal: (props)=>{ return ( <CFSelect2Modal key={props.index} {...props}/> ) },
}
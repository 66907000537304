const Routes = {
  routes: [
    {
      Id: "setting2fa",
      UIUrl: "/setting/2fa",
      UIType: "setting2fa",
    },
  ]
}

export default Routes
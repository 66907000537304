import React from "react"
import { MyUIHelpers, CSuppense } from "@macashipo/mlib";
const List = {
};
const MyUIOne = {
  init() {
    console.warn("init MyUIOne");
    MyUIHelpers.addTypesFromListLazy(List);
  },
};

export default MyUIOne;

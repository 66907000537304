import React from 'react';
import {
  Input
} from '../../../../_importComponent';
/**
 * Form Type: password.
 *
 * @class password
 * @static
 * @namespace password
 * @memberof FormType
 * 
 * @example
 * 
 */
class Z extends React.Component {
  static propTypes = {
  }
  static defaultProps = {
  }
  constructor(props) {
    super(props);
    this.state={
      value:''
    };
    
  }

  /**
   * Thuộc tính sử dụng trong more:
   * 
   * @method more
   * @memberof FormType.password

   * 
  */
  more() {
  }

  _onHandleBlur=(ev,opts)=>{
    // console.warn('_onHandleBlur:',e,e.currentTarget.value);
    if(opts && opts.onUpdateValue){
      opts.onUpdateValue({newValue:ev.currentTarget.value});
    }
  }

  render() {
    const {item, opts} = this.props
    // console.warn('item in type: ', item);
    // console.warn('opts in type: ', opts);
    return (
      <div className="mui-form-text">
        <Input
          type = 'password'
          onChange={(ev)=>{
            console.warn('value: ', ev.currentTarget.value);
            this.setState({
              value: ev.currentTarget.value
            })
          }}
          onBlur={(ev)=>{
            this._onHandleBlur(ev, opts);
          }}
        />
      </div>
    )
  }
}

export default Z;
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Button, Card, CardBody } from '../../../_importComponent';
import {MyUI} from "@macashipo/mlib"

class PageHeader extends Component {
  constructor(props) {
    super(props);
    this.state = { 
    };
  }

  reload=()=>{
    console.log('reload:',this.props);
    this.forceUpdate();
  }
  changeTitle=(title)=>{
    this.props.title = title;
    this.forceUpdate();
  }
  render() {
    const {title,showPrint,leftComponent,centerComponent,rightComponent,isShadow,style,showProjectTitle,fnList,headerConfigs}=this.props;
    return (
      <Card className={isShadow==true?"s_boxshadow":""} style={style}>
        <CardBody style={{padding:'2px'}}>
          <div style={{display:'flex',flexDirection:'row',flexWrap:'wrap'}}>
            <div style={{display:'flex',flexDirection:'row',}}>
              { leftComponent }
              <div style={{display:'flex',alignItems:'center'}} className="s_title_page">
                {title} 
                {showProjectTitle!=false && this.props.projectId!=null?` - ${this.props.projectId}`:""}
              </div>
              {
                headerConfigs && headerConfigs.showSearch===true && //WEBAPI-6101
                <MyUI type="page_header_search" fnList={fnList}/>
              }
            </div>
            <div style={{marginLeft:'auto'}}>
            { centerComponent }
            </div>
            <div style={{marginLeft:'auto',display:'flex',flexDirection:'row'}} className="no-print">
              { rightComponent }
              {
                showPrint==true &&
                <Button color="primary" onClick={()=>{window.print();}}><i className="fa fa-print"/></Button>
              }
            </div>
          </div>
        </CardBody>
      </Card>
    )
  }
}

PageHeader.propTypes = {
  projectId: PropTypes.string,
  screenCode: PropTypes.string,
  title: PropTypes.string,
  showPrint: PropTypes.bool,
  leftComponent: PropTypes.element,
  centerComponent: PropTypes.element,
  rightComponent: PropTypes.element,
}

PageHeader.defaultProps = {
  showPrint: true,
}

export default PageHeader;
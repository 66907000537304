import { Component } from 'react';
import Types from './MyChartTypes';
class Page extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount(){
  }

  _renderChart=()=>{
    const {type} = this.props;
    if(type && Types.UI[type]){
      return Types.UI[type](this.props);
    }
    return Types.UI._default(this.props);
  }
  render() {
    return this._renderChart();
  }
}

export default Page;
import React from 'react';
import {
  checkbox, currency, datepicker,
  listcheckbox, quality, rate, select2, select2color, selectlist
} from "./CustomType";
import FormHelper from "./FormHelper";
import Html from './Types/html';
// const Password = React.lazy(() => import('./Types/password'));
import Password from './Types/password';
import SelectKanbanWorking from './Types/select_kanban_working';
import Default from './Types/_default';

const FormType = {
  type:{
    input: (item,opts,type)=>{
      let _style = FormHelper.getStyle(item);
      let _onHandleChange=(e)=>{
        // console.warn('_onHandleChange:',e,e.currentTarget.value);
        if(type=="select"){
          if(opts && opts.onUpdateValue){
            opts.onUpdateValue({newValue:e.currentTarget.value});
          }
        }
      }
      let _onHandleBlur=(e)=>{
        // console.warn('_onHandleBlur:',e,e.currentTarget.value);
        if(type=="text" || type=="date" || type=="textarea"){
          if(opts && opts.onUpdateValue){
            opts.onUpdateValue({newValue:e.currentTarget.value});
          }
        }
      }
      let _input;
      let _defaultValue = FormHelper.getDefaultValue(item,opts);
      let _disabled = FormHelper.getDisabled(item,opts);
      // console.warn('_defaultValue: ', _defaultValue);
      if(type=="text" || type=="date"){
        _input = (
          <input disabled={_disabled} className="mui-form-control" defaultValue={_defaultValue} type={type} onChange={_onHandleChange} onBlur={_onHandleBlur}/>
        )
      }
      else if(type=="textarea"){
        _input = (
          <textarea disabled={_disabled} className="mui-form-control" defaultValue={_defaultValue} type={type} onChange={_onHandleChange} onBlur={_onHandleBlur}/>
        )
      }
      else if(type=="select"){
        let _options = [];
        let _sourceList = FormHelper.getOptionsSource(item,opts);
        if(_sourceList && _sourceList.length>0){
          for(let i in _sourceList){
            let _o = _sourceList[i];
            let _value = _o.value || _o.Value;
            let _text = _o.text || _o.Text || _o.label || _o.title;
            _options.push(
              <option key={i} value={_value}>{_text}</option>
            )
          }
        }
        _input = (
          <select disabled={_disabled} defaultValue={_defaultValue} className="mui-form-control" onChange={_onHandleChange}>
            <option value=""></option>
            {_options}
          </select>
        )
      }
      return (
        <div className={`mui-form-${type}`}>
          {_input}
        </div>
      )
    },
    readonly: (item,opts)=>{
      let _style = FormHelper.getStyle(item);
      return <div className="mui-form-readonly" style={_style}>ReadOnly</div> 
    },
    text: (item,opts)=>{    
      // console.warn('item: ', item);
      // console.warn('opts: ', opts);  
      return FormType.type.input(item,opts,"text");
    },
    number: (item,opts)=>{    
      return FormType.type.input(item,opts,"text");
    },
    date: (item,opts)=>{      
      return FormType.type.input(item,opts,"date");
    },
    textarea: (item,opts)=>{      
      return FormType.type.input(item,opts,'textarea'); 
    },
    // checkbox: (item,opts)=>{      
    //   return FormType.type.input(item,opts,'checkbox'); 
    // },
    select: (item,opts)=>{      
      return FormType.type.input(item,opts,'select'); 
    },
    select2: select2,
    selectlist: selectlist,
    datepicker: datepicker,
    checkbox: checkbox,
    select2color:select2color,
    rate: rate,
    currency: currency,
    quality: quality,
    listcheckbox: listcheckbox,
    password: (item,opts)=>{   
      // console.warn('item: ', item);
      // console.warn('opts: ', opts);     
      return (<Password item={item} opts={opts}/>)
    },
    select_kanban_working: (item,opts)=>{   
      // console.warn('item: ', item);
      // console.warn('opts: ', opts);     
      return (<SelectKanbanWorking item={item} opts={opts}/>)
    },
    _default: (item,opts)=>{   
      return (<Default item={item} opts={opts}/>)
    },
    html: (item,opts)=>{   
      return (<Html item={item} opts={opts}/>)
    },
  }
}

export default FormType;
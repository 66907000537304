const Routes = {
  routes: [
    {
      Id: "myprofile",
      UIUrl: "/my-profile",
      UIType: "myprofile",
    }
  ]
}

export default Routes
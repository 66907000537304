import React from 'react'
import { MyLibHelpers } from '@macashipo/mlib';
const MLganttchart = React.lazy(() => import('../../../../v1/components2/MyLib/Types/ganttchart'));

const ListLazy = {
  ganttchart: MLganttchart
}
const AppMyLibTypes = {
  init(){    
    MyLibHelpers.addTypesFromListLazy(ListLazy)
  }
}
export default AppMyLibTypes;
import React from 'react'
import MyIconTypes from "./MyIconTypes"
class MyIcon extends React.Component{
    render(){
        const {data, className, style} = this.props;
        if(data && typeof data == "string"){
            if(data.startsWith("fa")){
                return <i className={data} style={style}/>
            }
            else if(data.startsWith("type:")){
                let _type = data.replace("type:","");
                if(MyIconTypes.Types[_type]){
                    return MyIconTypes.Types[_type](this.props);
                }
            }
            else if(data.startsWith("data:")){
                return <img className={className} src={data} style={style}/>
            }
            else if(data.startsWith("http")){
                return <img className={className} src={data} style={style}/>
            }
        }
        return <div></div>
    }
}
export default MyIcon;
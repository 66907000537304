import React from "react"
import { MyPageHelpers } from "@macashipo/mlib";
const MPSetting = React.lazy(() => import("./Types/setting"));
const MPsetting_general = React.lazy(() => import("./Types/setting_general"));
const MPsetting_myprofile = React.lazy(() => import("./Types/setting_myprofile"));
const List = {
  setting: MPSetting,
  setting_general: MPsetting_general,
  setting_myprofile: MPsetting_myprofile
};
const MyPageOne = {
  init() {
    MyPageHelpers.addTypesFromListLazy(List);
  },
};

export default MyPageOne;

import React from 'react'
import {MyPageHelpers} from "@macashipo/mlib"
import MyPageTypes from '../../../../components/MyPage/MyPageTypes';
import CSuppense from '../../../../components/MyCore/CSuspense'

import PageTabv3_lev1 from './Types/tabv3_lev1'
import PageTabv3_lev3 from './Types/tabv3_lev3'
import PageTabv3_lev5 from './Types/tabv3_lev5'
import PageTabv3_lev7 from './Types/tabv3_lev7'
import PagePanelv3 from './Types/solidv3_panel'
import PageTablev3 from './Types/tablev3'
import PageTabv3_lev3_Project from './Types/tabv3_lev3_project'
import PageTabv3_lev3_Department from './Types/tabv3_lev3_department'
import PageTabv3_lev3_User from './Types/tabv3_lev3_user'

const PageSolidv3_bookingcalendar  = React.lazy(() => import('./Types/solidv3_bookingcalendar'));
const PageSolidv3_dashboard  = React.lazy(() => import('./Types/solidv3_dashboard'));
const PageSolidv3_documnent  = React.lazy(() => import('./Types/solidv3_document'));
const PageSolidv3_table  = React.lazy(() => import('./Types/solidv3_table'));
const PageSolidv3_tab  = React.lazy(() => import('./Types/solidv3_tab'));
const PageSolidv3_with_mylayout  = React.lazy(() => import('./Types/solidv3_with_mylayout'));
const PageSolidv3_html  = React.lazy(() => import('./Types/solidv3_html'));

const PageSolidv3_timecontrol = React.lazy(() => import('./Types/solidv3_timecontrol'));
const PageSolidv3_requirementdoctimecontrol = React.lazy(() => import('./Types/solid_requirementdoctimecontrol'));
const PageSolidv3_evm = React.lazy(() => import('./Types/solidv3_evm'));
const PageSolidv3_crmcontact = React.lazy(() => import('./Types/solidv3_crmcontact'));
const PageSolidv3_crmpipe = React.lazy(() => import('./Types/solidv3_crmpipe'));
const PageSolidv3_crmopty = React.lazy(() => import('./Types/solidv3_crmopty'));
const PageSolidv3_ninebox = React.lazy(() => import('./Types/solidv3_ninebox'));

const PageTable_listv3 = React.lazy(() => import('./Types/table_listv3'));
const PageTable_blockv3 = React.lazy(() => import('./Types/table_blockv3'));
const PageStandardv3 = React.lazy(() => import('./Types/standardv3'));
const PageSolidv3_gallery = React.lazy(() => import('./Types/solidv3_gallery'));
const PageSolidv3_gallery_list = React.lazy(() => import('./Types/solidv3_gallery_list'));
const PageSolidv3_gallery_detail = React.lazy(() => import('./Types/solidv3_gallery_detail'));
const PageSolidv3_bidlevel = React.lazy(() => import('./Types/solidv3_bidlevel'));

const PageSolidv3_standard_community = React.lazy(() => import('./Types/solidv3_standard_community'));
const PageSolidv3_standard_introducebar = React.lazy(() => import('./Types/solidv3_standard_introducebar'));
const PageSolidv3_standard_knowledgebase = React.lazy(() => import('./Types/solidv3_standard_knowledgebase'));
const PagesSolidv3_standard_package_detail = React.lazy(() => import('./Types/solidv3_standard_package_detail'));
const PageSolidv3_standard_package = React.lazy(() => import('./Types/solidv3_standard_package'));
const PageSolidv3_standard_search = React.lazy(() => import('./Types/solidv3_standard_search'));
const PageSolidv3_standard_statsbar = React.lazy(() => import('./Types/solidv3_standard_statsbar'));
const PageSolidv3_standard_mylayout = React.lazy(() => import('./Types/solidv3_standard_mylayout'));
const PageSolidv3_gantt= React.lazy(() => import('./Types/solidv3_gantt'));
const PageSolidv3_chart= React.lazy(() => import('./Types/solidv3_chart'));
const PageSolidv3_orgchart= React.lazy(() => import('./Types/solidv3_orgchart'));
const PageSolidv3_screenstaff= React.lazy(() => import('./Types/solidv3_screenstaff'));

const PageSolidv3_site_camera = React.lazy(() => import('./Types/site_camera/site_camera.js'));

const PageUnderconstructionv3  = React.lazy(() => import('./Types/underconstructionv3'));
const PageAccessdeniedv3 = React.lazy(() => import('./Types/accessdeniedv3'));
const PageNotFound = React.lazy(() => import('./Types/pagenotfound'));
// Page from mockup
const PageProjectRequirementBrief  = React.lazy(() => import('./Types/v3_project_requirement_brief'));
const PageProjectRequirementDesignBrief  = React.lazy(() => import('./Types/v3_project_requirement_design_brief'));
const PageProjectReportDashboard = React.lazy(() => import('./Types/v3_project_report_dashboard'));
const PageProjectDocumentFunction = React.lazy(() => import('./Types/v3_project_document_function'));
const PageProjectDocumentMilestone = React.lazy(() => import('./Types/v3_project_document_milestone'));
const PageCustomerPipePotential = React.lazy(() => import('./Types/v3_customer_pipe_potential'));
const PageSuppliersHome = React.lazy(() => import('./Types/v3_suppliers_home'));
const PageSuppliersQualification = React.lazy(() => import('./Types/v3_suppliers_qualification'));
const PageSuppliersBidOpty = React.lazy(() => import('./Types/v3_suppliers_bid_opty'));
const PageSuppliersBidSubmitted  = React.lazy(() => import('./Types/v3_suppliers_bid_submitted'));
const PageSuppliersBidLevelling  = React.lazy(() => import('./Types/v3_suppliers_bid_levelling'));

// Page update 2022
const PageSolidv3_solidv3_planweek_v2  = React.lazy(() => import('./Types/solidv3_planweek_v2'));
const PageSolidv3_solidv3_planweek_pm  = React.lazy(() => import('./Types/solidv3_planweek_pm'));
const PageSolidv3_solidv3_sitephotov2insitedaily  = React.lazy(() => import('./Types/solidv3_sitephotov2insitedaily'));
const PageSolidv3_solidv3_milestone  = React.lazy(() => import('./Types/solidv3_milestone'));

//Page new
const PageSolidv3_site_manager  = React.lazy(() => import('./Types/solidv3_site_manager'));
const AppMyPage = {
  init(){
    MyPageTypes.addTypes('tabv3_lev1',(props)=>{ return ( <PageTabv3_lev1 {...props}/> ) });
    MyPageTypes.addTypes('tabv3_lev3',(props)=>{ return ( <PageTabv3_lev3 {...props}/> ) });
    MyPageTypes.addTypes('tabv3_lev5',(props)=>{ return ( <PageTabv3_lev5 {...props}/> ) });
    MyPageTypes.addTypes('tabv3_lev7',(props)=>{ return ( <PageTabv3_lev7 {...props}/> ) });
    MyPageTypes.addTypes('tabv3_lev9',(props)=>{ return ( <PageTabv3_lev7 {...props} customTabLev={9}/> ) });
    MyPageTypes.addTypes('tabv3_lev11',(props)=>{ return ( <PageTabv3_lev7 {...props} customTabLev={11}/> ) });
    MyPageTypes.addTypes('solidv3_panel',(props)=>{ return ( <PagePanelv3 {...props}/> ) });
    MyPageTypes.addTypes('tablev3',(props)=>{ return ( <PageTablev3 {...props}/> ) });
    MyPageTypes.addTypes('tabv3_lev3_project',(props)=>{ return ( <PageTabv3_lev3_Project {...props}/> ) });
    MyPageTypes.addTypes('tabv3_lev3_department',(props)=>{ return ( <PageTabv3_lev3_Department {...props}/> ) });
    MyPageTypes.addTypes('tabv3_lev3_user',(props)=>{ return ( <PageTabv3_lev3_User {...props}/> ) });        
    MyPageTypes.addTypes('underconstructionv3',(props)=>{ return CSuppense(props,PageUnderconstructionv3) });
    MyPageTypes.addTypes('pagenotfound',(props)=>{ return CSuppense(props,PageNotFound) });    
    MyPageTypes.addTypes('solidv3_bookingcalendar',(props)=>{ return CSuppense(props,PageSolidv3_bookingcalendar) });
    MyPageTypes.addTypes('solidv3_dashboard',(props)=>{ return CSuppense(props,PageSolidv3_dashboard) });
    MyPageTypes.addTypes('solidv3_document',(props)=>{ return CSuppense(props,PageSolidv3_documnent) });
    MyPageTypes.addTypes('solidv3_table',(props)=>{ return CSuppense(props,PageSolidv3_table) });
    MyPageTypes.addTypes('solidv3_tab',(props)=>{ return CSuppense(props,PageSolidv3_tab) });
    MyPageTypes.addTypes('solidv3_with_mylayout',(props)=>{ return CSuppense(props,PageSolidv3_with_mylayout) });
    MyPageTypes.addTypes('solidv3_html',(props)=>{ return CSuppense(props,PageSolidv3_html) });

    MyPageTypes.addTypes('table_list',(props)=>{ return CSuppense(props,PageTable_listv3) });
    MyPageTypes.addTypes('table_listv3',(props)=>{ return CSuppense(props,PageTable_listv3) });
    MyPageTypes.addTypes('table_blockv3',(props)=>{ return CSuppense(props,PageTable_blockv3) });
    MyPageTypes.addTypes('table_category',(props)=>{ return CSuppense(props,PageSolidv3_table) });//for v1: co man hinh su dung
    MyPageTypes.addTypes('standardv3',(props)=>{ return ( <PageStandardv3 {...props}/> ) });    
    MyPageTypes.addTypes('solidv3_timecontrol',(props)=>{ return CSuppense(props,PageSolidv3_timecontrol) });
    MyPageTypes.addTypes('solidv3_evm',(props)=>{ return CSuppense(props,PageSolidv3_evm) });
    MyPageTypes.addTypes('solidv3_crmcontact',(props)=>{ return CSuppense(props,PageSolidv3_crmcontact) });
    MyPageTypes.addTypes('solidv3_crmpipe',(props)=>{ return CSuppense(props,PageSolidv3_crmpipe) });
    MyPageTypes.addTypes('solidv3_crmopty',(props)=>{ return CSuppense(props,PageSolidv3_crmopty) });
    MyPageTypes.addTypes('solidv3_ninebox',(props)=>{ return CSuppense(props,PageSolidv3_ninebox) });
    MyPageTypes.addTypes('solidv3_gallery',(props)=>{ return CSuppense(props,PageSolidv3_gallery) });
    MyPageTypes.addTypes('solidv3_gallery_list',(props)=>{ return CSuppense(props,PageSolidv3_gallery_list) });
    MyPageTypes.addTypes('solidv3_gallery_detail',(props)=>{ return CSuppense(props,PageSolidv3_gallery_detail) });
    MyPageTypes.addTypes('solidv3_bidlevel',(props)=>{ return CSuppense(props,PageSolidv3_bidlevel) });
    MyPageTypes.addTypes('solidv3_gantt',(props)=>{ return CSuppense(props,PageSolidv3_gantt) });
    MyPageTypes.addTypes('solidv3_chart',(props)=>{ return CSuppense(props,PageSolidv3_chart) });
    MyPageTypes.addTypes('solidv3_orgchart',(props)=>{ return CSuppense(props,PageSolidv3_orgchart) });
    MyPageTypes.addTypes('solidv3_screenstaff',(props)=>{ return CSuppense(props,PageSolidv3_screenstaff) });
    MyPageTypes.addTypes('solid_requirementdoctimecontrol',(props)=>{ return CSuppense(props,PageSolidv3_requirementdoctimecontrol) });

    //standard
    MyPageTypes.addTypes('solidv3_standard_community',(props)=>{ return CSuppense(props,PageSolidv3_standard_community) });
    MyPageTypes.addTypes('solidv3_standard_introducebar',(props)=>{ return CSuppense(props,PageSolidv3_standard_introducebar) });
    MyPageTypes.addTypes('solidv3_standard_knowledgebase',(props)=>{ return CSuppense(props,PageSolidv3_standard_knowledgebase) });
    MyPageTypes.addTypes('solidv3_standard_package_detail',(props)=>{ return CSuppense(props,PagesSolidv3_standard_package_detail) });
    MyPageTypes.addTypes('solidv3_standard_package',(props)=>{ return CSuppense(props,PageSolidv3_standard_package) });
    MyPageTypes.addTypes('solidv3_standard_search',(props)=>{ return CSuppense(props,PageSolidv3_standard_search) });
    MyPageTypes.addTypes('solidv3_standard_statsbar',(props)=>{ return CSuppense(props,PageSolidv3_standard_statsbar) });
    MyPageTypes.addTypes('solidv3_standard_mylayout',(props)=>{ return CSuppense(props,PageSolidv3_standard_mylayout) });
    
    //site camera
    MyPageTypes.addTypes('solidv3_site_camera',(props)=>{ return CSuppense(props,PageSolidv3_site_camera) });

    // Page from mockup
    MyPageTypes.addTypes('v3_project_requirement_brief',(props)=>{ return CSuppense(props,PageProjectRequirementBrief) });
    MyPageTypes.addTypes('v3_project_requirement_design_brief',(props)=>{ return CSuppense(props,PageProjectRequirementDesignBrief) });
    MyPageTypes.addTypes('v3_project_report_dashboard',(props)=>{ return CSuppense(props,PageProjectReportDashboard) });
    MyPageTypes.addTypes('v3_project_document_function',(props)=>{ return CSuppense(props,PageProjectDocumentFunction) });
    MyPageTypes.addTypes('v3_project_document_milestone',(props)=>{ return CSuppense(props,PageProjectDocumentMilestone) });
    MyPageTypes.addTypes('v3_customer_pipe_potential',(props)=>{ return CSuppense(props,PageCustomerPipePotential) });
    MyPageTypes.addTypes('v3_suppliers_home',(props)=>{ return CSuppense(props,PageSuppliersHome) });
    MyPageTypes.addTypes('v3_suppliers_qualification',(props)=>{ return CSuppense(props,PageSuppliersQualification) });
    MyPageTypes.addTypes('v3_suppliers_bid_opty',(props)=>{ return CSuppense(props,PageSuppliersBidOpty) });
    MyPageTypes.addTypes('v3_suppliers_bid_submitted',(props)=>{ return CSuppense(props,PageSuppliersBidSubmitted) });
    MyPageTypes.addTypes('v3_suppliers_bid_levelling',(props)=>{ return CSuppense(props,PageSuppliersBidLevelling) });
  }
}
const List = {
  accessdeniedv3: PageAccessdeniedv3,
  solidv3_planweek: PageSolidv3_solidv3_planweek_v2,
  solidv3_planweekpm: PageSolidv3_solidv3_planweek_pm,
  solidv3_sitephotov2insitedaily: PageSolidv3_solidv3_sitephotov2insitedaily,
  solidv3_milestone: PageSolidv3_solidv3_milestone,
  solidv3_site_manager: PageSolidv3_site_manager,
}
MyPageHelpers.addTypesFromListLazy(List)
// AppMyPage.init();
console.warn("AAAA",MyPageHelpers.Types)
export default AppMyPage;
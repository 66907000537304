import PropTypes from 'prop-types';
import React, { Component } from 'react';
import {
  Button, Card, CardBody
} from '../../_importComponent';

/**
 * Component HeaderCurrent, hiển thị header Current ở giữa History và DataTable
 * @class HeaderCurrent
 * @property {string} title title của current
 */
class HeaderCurrent extends Component {
  constructor(props) {
    super(props);
    this.state = { 
    };
  }
  componentDidMount(){      
  }
  _renderHeaderCurrent(){
    const {title} = this.props;
    return(
      <Card className="s_boxshadow">
        <CardBody style={{padding:'2px'}}>
        <div style={{display:'flex',flexDirection:'row',flexWrap:'wrap'}}>
          <div style={{display:'flex',flexDirection:'row',}}>
            <Button style={{color:'#44cfae',padding:'0px'}} color="link" onClick={()=>{
            }}>
                <i style={{fontSize:'25px'}} className={`fa fa-chevron-circle-right`}></i>
            </Button>
            <div style={{display:'flex',alignItems:'center'}} className="s_title_page">
              {title}
            </div>
          </div>
        </div>
        </CardBody>        
      </Card>
    )
  }

  render(){
    return (
      this._renderHeaderCurrent()
    );
  }
}
HeaderCurrent.propTypes = {
  title: PropTypes.string
}
HeaderCurrent.defaultProps = {
  title: 'Current'
};
export default HeaderCurrent;
import React from "react"
import { MyUIHelpers } from "@macashipo/mlib";
const List = {
};
const MyUIOne = {
  init() {
    MyUIHelpers.addTypesFromListLazy(List);
  },
};

export default MyUIOne;

import Config from '../Config';

const App = {
  name: 'Solid',
  version: 1,
  changePageTitle(title){
    document.title = title + ` - ${Config.title}`;
  },
  setState(component,state,fnCallback){
    component.setState.call(component,state,fnCallback);
  },
  checkLocalVersion(versionCheck){
    let _v = localStorage.getItem('version');
    let _vd = localStorage.getItem('version_debug');
    return _vd==versionCheck;
  },
}

export default App;
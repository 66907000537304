import React, { Component } from 'react';
import {
  Button,
  Modal, ModalBody, ModalFooter, ModalHeader
} from '../../../_importComponent';
import {
  LaddaButton, EXPAND_RIGHT
} from '../../../_importLibs';
import {
  TableHelperV1
} from '../../../_importV1';
import InputControl from '../InputEditor/CustomControl';

class ModalControl extends Component {
  constructor(props) {
    super(props);
    this.state = { 
      isShow:false,
      fieldName: '',
      row: {},
      value: '',
      type: '',
      options: {},
      placeholder: '',
      component: null,
      inputProps: {},
      fnRequestUpdate: null,
      onUpdateField: null,
    };
  }

  componentDidMount(){

  }

  show=({row,component,fieldName,value,type,options,placeholder,inputProps,fnRequestUpdate,onUpdateField})=>{
    this.setState({
      isShow: true,
      row: row||{},
      fieldName: fieldName,
      value: value||"",
      type: type,
      options: options,
      placeholder: placeholder||"",
      component:component||null,
      inputProps: inputProps||{},
      fnRequestUpdate: fnRequestUpdate||null,
      onUpdateField: onUpdateField||null,
    })
  }

  render() {
    const {fnRequestUpdate,component,onUpdateField}=this.state;
    const {inputProps,title}=this.state;
    return (
      <Modal fade={false} className="modal-lg" isOpen={this.state.isShow} toggle={()=>{this.setState({isShow:!this.state.isShow})}}>
        <ModalHeader toggle={()=>{this.setState({isShow:!this.state.isShow})}}>{title!=null?title:this.state.row.Id}</ModalHeader>
        <ModalBody>
          <div>
            {this.state.placeholder}
            <InputControl 
              ref={r=>{this._cInput=r;}}
              className={"s_input"}
              style={{flex:1}}
              type={this.state.type}
              options={this.state.options}
              value={this.state.value} 
              placeholder={this.state.placeholder}
              {...inputProps}
              />
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="link" onClick={()=>{this.setState({isShow:!this.state.isShow})}}>Đóng</Button>
          <LaddaButton
            className="btn btn-success btn-ladda"
            // loading={this.state.isSaving}
            onClick={()=>{
              if(this._cInput!=null){
                let _value = this._cInput.getValue();
                console.log('new value:',_value);
                this.setState({
                  isShow: false,
                },()=>{
                  if(_value!=this.state.row[this.state.fieldName]){
                    if(onUpdateField!=null){
                      onUpdateField(this.state.row,this.state.fieldName,_value,this.state.row[this.state.fieldName]);
                    }
                    else if(fnRequestUpdate!=null && component!=null){
                      TableHelperV1.onUpdateFieldOld(
                        this.state.row,this.state.fieldName,_value,this.state.row[this.state.fieldName],null,fnRequestUpdate,component
                      );
                    }
                  }
                })
              }
            }}
            data-color="green"
            data-style={EXPAND_RIGHT}>Lưu</LaddaButton>
        </ModalFooter>
      </Modal>
    )
  }
}

export default ModalControl;

import React from "react";
import { connect } from "react-redux";
import ConfigTabV3 from "../../../model/ConfigTabV3";
import Tabv3_SearchBar from "../../MyUI/Types/tabv3_searchbar";
import {
  MyPageFromConfig, MyUI, TabContent,
  TabPane
} from "../_import";
import "./styles/common.css";
import "./styles/responsive.css";
import Tabv3_ext from "./tabv3_ext";
class PageTab3L5 extends Tabv3_ext {
  constructor(props) {
    super(props);
    this.keyRightPositon = "";
    this._cMenuv5 = null;
    this.wrapperRef = React.createRef();
  }
  componentDidMount() {
    if (this._isNeedRequestOptions()) {
      this.requestOptions({
        cbSuccess: () => {},
      });
    }
  }
  getHasProject = () => {
    const { fnList } = this.props;
    if (fnList && fnList.getIsHasProject) {
      return fnList.getIsHasProject();
    }
    return false;
  };
  _isNeedRequestOptions = () => {
    const { configPage } = this.props;
    if (configPage && configPage.Contents) {
      return false;
    }
    return true;
  };
  getWidthMenu = () => {
    const menu_wrap = document.getElementById("tab_lv5_wrapper");
    const right_menu = document.getElementById("tab_lv5_suff");
    const menu_width = Math.ceil(menu_wrap?.offsetWidth) || 0;
    const right_width = Math.ceil(right_menu?.offsetWidth + 10) || 0;
    console.warn("vv menu_width, right_width", menu_width, right_width)
    return menu_width - right_width - 30;
  };
  renderPreLeftMenu = () => {
    const { configPage } = this.props;
    const title = configPage.Title;
    return (
      <div className="title">
        {title} <i className="fa fa-chevron-right" />
      </div>
    );
  };
  renderTab = () => {
    const contents = this.getContents();
    return (
      <div id="tab_lv5_wrapper" className="tab_lv5_wrapper no-print">
        <MyUI
          type="responsive_menu"
          id="tab_lv5"
          width={this.getWidthMenu()}
          leftMenu={contents}
          rightMenu={[]}
          renderPreLeftMenu={this.renderPreLeftMenu}
          classNameMenu="btn-tab-lv5"
          config={{
            isFullWidthInMobile: true,
            isShortMenuInMobile: false,
            hidePreLeftMenuInMobile: true,
          }}
          fnList={{
            onChangeMenu: (index, item) => {
              this.changeTab({
                index,
                item,
              });
            },
            getIsActive: (index) => {
              return this.getTabIsActive({ index });
            },
            getWidth: () => {
              return this.getWidthMenu();
            },
            setRefMenu: (c) => {
              this._cMenuv5 = c
            }
          }}
        />
        {/* <div className="tab_lv5">
          <div className="title">
            {title} <i className="fa fa-chevron-right" />
          </div>
          {contents.map((v, i) => {
            return (
              <Button
                key={i}
                onClick={() => {
                  this.changeTab({ index: i, item: v });
                }}
                className={`btn-tab-lv5 ${
                  this.getTabIsActive({ index: i }) ? "active" : ""
                }`}
              >
                {v.Title}
              </Button>
            );
          })}
        </div> */}
        {/* <div className="tab_lv5_mobile">
          <div className="btn-mn" onClick={() => {
            MyModal.Helper.showFromFunction(() => { 
              return (
                <ul className="menu-list">
                  {contents.map((v, i) => {
                    return (
                      <li
                        key={i}
                        onClick={() => {
                          this.changeTab({ index: i, item: v });
                          MyModal.Helper.hide()
                        }}
                        className={`itm ${
                          this.getTabIsActive({ index: i }) ? "active" : ""
                        }`}
                      >
                        {v.Title}
                      </li>
                    );
                  })}
                </ul>
              );
            }, {
              className: "tab_lv5_modal_menu",
              title: title
            })
          }}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 23 19"
            >
              <g fill="var(--blue-primary)">
                <rect y="16" width="23" height="2" rx="1.5"></rect>
                <rect width="23" height="2" rx="1.5"></rect>
                <rect y="8" width="23" height="2" rx="1.5"></rect>
              </g>
            </svg>
          </div>
          <div className="breakcumb">
            <span className="parent">{titleMenuActive}</span>
            <span className="parent">{title}</span>
            {titleMenuActive && (
              <span className="child">
                <i className="fa fa-chevron-right" /> {titleMenuActive}
              </span>
            )}
          </div>
        </div> */}
        {/* {this._componentSearchBar && (
          <div id="tab_lv5_suff" className="tab_lv5-right">
            <Tabv3_SearchBar
              ref={(r) => {
                this._cSearchBar = r;
              }}
              componentSearchBar={this._componentSearchBar}
            ></Tabv3_SearchBar>
          </div>
        )} */}
        <div id="tab_lv5_suff" className="tab_lv5-right">
          <Tabv3_SearchBar
            ref={(r) => {
              this._cSearchBar = r;
            }}
            componentSearchBar={this._componentSearchBar}
          ></Tabv3_SearchBar>
        </div>
      </div>
    );
  };
  renderContent = () => {
    const { fnList, currentProject } = this.props;
    const contents = this.getContents();
    let _levelTabIndex = this.getCurrentLevelTabIndex();
    if (
      ConfigTabV3.checkUrlWithContent(`t${_levelTabIndex}`, contents) == false
    ) {
      return (
        <MyPageFromConfig
          key="pagenotfound"
          configPage={{
            Id: "notfound",
            UIType: "pagenotfound",
          }}
        />
      );
    }
    let _ui = [];
    return (
      <div className="content_lv5">
        <TabContent
          activeTab={this.getTabActive({ currentProject })}
          style={{ border: "0px", background: "transparent" }}
        >
          {contents.map((v, i) => {
            let _tabId = this.getTabId({
              index: i,
              currentProject: currentProject,
            });
            //tam thoi de im de ra man hinh ko co quyen
            // if(v.ConfigsExt && v.ConfigsExt.IsDisable===true){
            //   return (
            //     <TabPane key={i} tabId={_tabId} style={{ padding: "0px" }}>
            //       <div>Not permission</div>
            //     </TabPane>
            //   )
            // }
            let _isLoaded = this.getTabIsLoaded({ index: i, currentProject });
            // console.warn("render tablev5:", _tabId, _isLoaded, this.state);
            if (_isLoaded) {
              if (_tabId == this.getTabActive({})) {
                ConfigTabV3.setTabByLev(5, v.Id);
              }
              return (
                <TabPane key={i} tabId={_tabId} style={{ padding: "0px" }}>
                  <MyPageFromConfig
                    key={_tabId}
                    configPage={v}
                    fnList={{
                      ...fnList,
                      ...this._sharedFnList,
                      getParentLevelTabIndex: this.getCurrentLevelTabIndex,
                      updateConfigSearchBar: (configPage, configSearchBar) => {
                        if (configPage && configPage.Id) {
                          if (i == this.state.activeTabIndex) {
                            //chi load tab active
                            if (this._cSearchBar) {
                              this._cSearchBar.load({
                                configPage,
                                configSearchBar,
                              });
                            }
                          }
                        }
                      },
                    }}
                  />
                </TabPane>
              );
            } else {
              return (
                <TabPane key={i} tabId={_tabId} style={{ padding: "0px" }}>
                  <div>........</div>
                </TabPane>
              );
            }
          })}
        </TabContent>
      </div>
    );
  };
  render() {
    return (
      <div className="app">
        {this.renderTab()}
        {this.renderContent()}
      </div>
    );
  }
}
// export default PageTab3L5
const mapStateToProps = (state) => {
  return {
    currentProject: state.solidv3.currentProject,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setObjInSolidV3: (obj) => dispatch({ type: "setObjInSolidV3", ...obj }),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(PageTab3L5);

import React from "react";
import { fnGetConfigTable, fnListConfigApp, fnListMyPage, KConfigPageFilter, KConfigPageTable, KConfigPagePage, KConfigPageHeader } from "src/helpers/fnList";
import MyTableHelperV1 from "../../../../../v1/helpers/App/TableHelper";
import {
  Alert, HApiAxios, HConstant, HEE, HUI, HUtils, MyTableFilterData, MyUI, PageExtV2
} from "../_import";
import {HIs } from "@macashipo/mlib"
import { KSolid_EE } from "src/helpers/KSolid"
class PageTablev3 extends PageExtV2 {
  constructor(props) {
    super(props);
    this.state = {
      textSearch: "",
      sort: null,
    };
    // this._cTableExpand = {};
    this._keyInitTableV3 = `${new Date().toISOString()}`;
    HUtils.initInnerRef(this);
    this._showLoadingRequestFilter = false;
    this._lastFilterNeedRequestServer = true;
    //chuyen vao ext
    // this._onePage= new OnePage({})
    this._fnAfterGetList = (resList)=>{
      //chuyen vao ext sau khi get list
      // this._onePage.parseListAndExtraData(resList.Data);      
      let _needRebuildColAfterGetList = fnListMyPage.fnGetConfigPageTable(this._sharedFnList,[KConfigPageTable.needRebuildColAfterGetList])
      console.warn("_needRebuildColAfterGetList:",_needRebuildColAfterGetList,this._onePage._dataOptions)
      if(_needRebuildColAfterGetList===true && this._cTable && this._cTable.rebuildColsWithExtraData){
        this._cTable.rebuildColsWithExtraData()
      }
    }
    //
    this._onePage._oneFilters.afterChangeSort =()=>{
      if(this._onePage._oneScreenConfig._table.hasPagingRemote){
        this.requestList({
          notShowLoadingApp: true,
          customQuery: this._onePage.getQueryShared(),
          cbSuccess: (resList) => {
            this._fnAfterGetList(resList)
          },
        });
      }
    }
    this._onePage._configPagination.afterChange = ()=>{
      if(this._onePage._oneScreenConfig._table.hasPagingRemote){
        this.requestList({
          notShowLoadingApp: true,
          customQuery: this._onePage.getQueryShared(),
          cbSuccess: (resList) => {
            this._fnAfterGetList(resList)           
          },
        });
      }      
    }
    this._onePage._oneFilters.afterChangeFilter = ()=>{
      console.warn("afterChangeFilter:",this._lastFilterNeedRequestServer)
      if(this._onePage._oneFilters.needRequestServerAfterFilter && this._lastFilterNeedRequestServer){
        this._onePage._configPagination._pageCurrent = this._onePage._configPagination._pageStart
        this.requestList({
          notShowLoadingApp: !this._showLoadingRequestFilter,
          customQuery: this._onePage.getQueryShared(),
          cbSuccess: (resList) => {
            this._fnAfterGetList(resList)
          },
        });
      }      
    }
  }
  componentDidMount() {
    // console.warn("request:",this.props);
    this.requestOptions({
      notShowLoadingApp: true,
      cbSuccess: (resOptions) => {
        // this.initScreenWidth();
        //chuyen vao ext
        // this._onePage.parseOptions(resOptions.Data);
        this._parseOptions(this.state.options);
        this.requestList({
          notShowLoadingApp: true,
          customQuery: this._onePage.getQueryPagination(),
          cbSuccess: (resList) => {
            //chuyen vào ext
            // this._onePage.parseListAndExtraData(resList.Data);
            // this._buildCols({
            //   extraData: this.getListExtraData(),
            // });
          },
        });
      },
    });
    this._initEventMsg();
  }
  _initEventMsg = () => {
    const _eventMsg = this.getConfigFromPageConfig(
      HConstant.ConfigPage.needReceiveMsg
    );
    this._fnFromMsg = {
      updateRowInList: this._updateRowInList,
      reloadList: this.reload, //WEBAPI-6227
    };
    // console.warn("_eventMsg", _eventMsg);
    if (_eventMsg) {
      if (typeof _eventMsg == "string") {
        HEE.on(_eventMsg, (obj) => {
          if (this._fnFromMsg.hasOwnProperty(_eventMsg)) {
            this._fnFromMsg[_eventMsg](obj);
          }
        });
      } else if (Array.isArray(_eventMsg)) {
        for (let i = 0; i < _eventMsg.length; i++) {
          HEE.on(_eventMsg[i], (obj) => {
            if (this._fnFromMsg.hasOwnProperty(_eventMsg[i])) {
              this._fnFromMsg[_eventMsg[i]](obj);
            }
          });
        }
      }
    }
  };
  _parseOptions=(options = {})=>{
    const { configPage } = this.props;
    let _searchHolder = HUtils.Obj.get(options,"Configs.ConfigSearch.Placeholder")  
    console.warn("_searchHolder:",_searchHolder, options , configPage)
    HEE.emit(KSolid_EE.ev_fullsearch_updateholder,{
      searchPlaceHolder: _searchHolder || "",
      textSearch: "",
      configPage: configPage
    })
  }
  reload = () => {
    let _staticReload = this._getTableConfig(
      HConstant.TableConfig.staticDataReload
    );
    if (_staticReload != null) {
      _staticReload({
        cb: () => {
          this.requestList({cbSuccess: ()=>{
            this._cTable?._rebuildCols && this._cTable._rebuildCols()
          }});
        },
      });
    } else {
      this.requestList({cbSuccess: ()=>{
        this._cTable?._rebuildCols && this._cTable._rebuildCols()
      }});
    }
  };
  fnReloadOptionsAndList = ({ row, opts } = {}) => {
    const { onRequestOptions } = this.props;
    if (onRequestOptions) {
      onRequestOptions({
        optsLoadOptionsInList: {
          notNeedRequestList: false,
          firstLoad: false,
        },
      });
    }
  };
  fnReloadOptions = ({ row, opts } = {}) => {
    const { onRequestOptions } = this.props;
    if (onRequestOptions) {
      onRequestOptions({
        optsLoadOptionsInList: {
          notNeedRequestList: true,
          firstLoad: false,
        },
      });
    }
  };
  fnRequestDelete = ({ row, extra }) => {
    this.requestDelete(row);
  };
  fnForceUpdateTable = () => {
    this.forceUpdate();
  };
  fnClickShowModalControl = ({ row, opts }) => {
    if (this.props.onClickShowModalControl) {
      this.props.onClickShowModalControl(opts);
    }
  };
  fnGetProjectId = ({ row, opts } = {}) => {
    if (row && row.ProjectId) {
      return row.ProjectId;
    }
    return this.getProjectId();
  };
  fnGetFilterSharedDetail=()=>{
    let _filterShared = {};
    if(this._currentFilter){
      let _keys = Object.keys(this._currentFilter);
      if(_keys && _keys.length>0){
        for(let k of _keys){
          let _item = this._currentFilter[k];
          if(_item.isSharedDetail===true){
            _filterShared[k] = _item;
          }
        }
      }
    }
    return _filterShared;
  }
  fnGetFilterQuery = () => {
    return this._filterQuery;
  };
  fnOnRequestUpdateFields = (row, values, opts = {}) => {
    const { onAfterUpdateField } = this.props;
    const { componentCell, updateSuccess, updateFailed } = opts;
    // console.log('_onRequestUpdateFields:',values,this.props);
    HApiAxios.generic({
      request: {
        method: "POST",
        path: this.getApiController(),
        name: HApiAxios.NAME.UpdateFields,
      },
      data: {
        ProjectId: row.ProjectId,
        Id: row.Id,
        Url: window.location.href,
        RequestData: this.getRequestData(),
        Values: values,
      },
      successCallBack: (response) => {
        if (response.Data != null && response.Data.Id == row.Id) {
          let _data = this.state.data;
          // console.log('_data:',_data);
          for (let i = 0; i < _data.length; i++) {
            if (_data[i].Id == response.Data.Id) {
              for (let _key of Object.keys(_data[i])) {
                if (_key.startsWith("_") == false) {
                  _data[i][_key] = response.Data[_key];
                }
              }
              break;
            }
          }
          if (onAfterUpdateField) {
            onAfterUpdateField({ data: _data });
          }
        }
        HUI.Toast.showSuccess(response.Msg);
        if (updateSuccess) {
          updateSuccess();
        }
        this.forceUpdate();
      },
      errorCallBack: (error, response) => {
        if (updateFailed) {
          updateFailed();
        }
      },
    });
  };
  fnOnRequestUpdate = (row, cellName, cellValue, opts) => {
    const { onAfterUpdateField } = this.props;
    console.warn("_onRequestUpdate:", row, this.props);
    HApiAxios.generic({
      request: {
        method: "POST",
        path: this.getApiController(),
        name: HApiAxios.NAME.UpdateFields,
      },
      data: {
        ProjectId: row.ProjectId,
        Id: row.Id,
        Url: window.location.href,
        RequestData: this.getRequestData(),
        Values: [
          {
            FieldName: cellName,
            NewValue: cellValue,
          },
        ],
      },
      successCallBack: (response) => {
        if (response.Data != null && response.Data.Id == row.Id) {
          let _data = this.state.data;
          HUtils.updateDataWithNewData(row, response.Data);
          let _isAlwaysUpdateCellAfterUpdate = this._getTableConfig(
            HConstant.TableConfig.isAlwaysUpdateCellAfterUpdate
          );
          if (_isAlwaysUpdateCellAfterUpdate != false) {
            if (_isAlwaysUpdateCellAfterUpdate == "all") {
              row._UI_NeedUpdate = { ...response.Data };
            } else {
              let _obj = {};
              _obj[cellName] = response.Data[cellName];
              row._UI_NeedUpdate = _obj;
            }
          }

          if (opts) {
            if (opts.fnUpdateUILoading) {
              opts.fnUpdateUILoading(false, { ...opts });
            }
            if (opts.fnUpdateUIError) {
              opts.fnUpdateUIError(null, { ...opts });
            }
            if (opts.fnSuccessCallBack) {
              opts.fnSuccessCallBack(response, { ...opts });
            }
          }

          if (onAfterUpdateField) {
            onAfterUpdateField({ data: _data });
          }
        }
        let _isHideMsgUpdateConfig = this._getTableConfig(
          HConstant.TableConfig.hideMsgUpdate
        );
        if (
          _isHideMsgUpdateConfig == true ||
          (opts && opts.hideMsgUpdate == true)
        ) {
          console.log("Update success");
        } else {
          HUI.Toast.showSuccess(response.Msg);
        }
        MyTableHelperV1.Updating.remove(row, cellName);
        this.forceUpdate();

        if (
          this._getTableConfig(
            HConstant.TableConfig.needReloadAfterUpdate,
            false
          )
        ) {
          this.reload();
        }
        let _needReloadAfterUpdateFields = this._getTableConfig(
          HConstant.TableConfig.needReloadAfterUpdateFields,
          null
        );
        if (_needReloadAfterUpdateFields != null) {
          if (Array.isArray(_needReloadAfterUpdateFields)) {
            if (_needReloadAfterUpdateFields.indexOf(cellName) > -1) {
              this.reload();
            }
          } else if (typeof _needReloadAfterUpdateFields == "string") {
            if (cellName == _needReloadAfterUpdateFields) {
              this.reload();
            }
          }
        }
      },
      errorCallBack: (error, response) => {
        // this.forceUpdate();
        // console.warn("errorCallBack:",error,response);
        if (response && response.Msg) {
          if (opts) {
            if (opts.fnUpdateUILoading) {
              opts.fnUpdateUILoading(false, { ...opts });
            }
            if (opts.fnUpdateUIError) {
              opts.fnUpdateUIError(response.Msg, { ...opts });
            }
          }
        }
      },
    });
  };
  _getTableConfig = (key, df) => {
    const tableConfig = this.getConfigFromScreenConfig("Table");
    return HConstant.getConfig(tableConfig, "TableConfig", key, df);
  };
  _getTableConfigV2 = (key, df) => {
    const tableConfig = this.getConfigFromScreenConfig("Table");
    return HConstant.getConfig(tableConfig, "TableConfig", key, df);
  };  
  requestSearch = () => {
    const { textSearch } = this.state;
    // console.warn("requestSearch:", textSearch);
    const configPagefilter = fnListMyPage.fnGetConfigPageFilter(this._sharedFnList,[])
    // console.warn("configPagefilter:",configPagefilter)
    if (configPagefilter[KConfigPageFilter.searchServer] === true) {
      this.requestList({
        customQuery: {
          TextSearch: textSearch,
        },
      });
    } else {
      this.handleSearch(textSearch);
    }
  };
  handleFilterData = (obj) => {
    const { onAfterFilter } = this.props;
    const configPagefilter = fnListMyPage.fnGetConfigPageFilter(this._sharedFnList,[])
    // console.log('handleFilterData:',obj,filterConfig,this.state.data,this._customQuery);
    if (obj) {
      this._filterQuery = {};
      let _keys = Object.keys(obj);
      if (_keys) {
        for (let k of _keys) {
          if (typeof obj[k] == "boolean") {
            this._filterQuery[k] = obj[k];
          } else {
            if (
              obj[k].start != null &&
              obj[k].end != null &&
              obj[k].start > 0
            ) {
              //Dung cho kieu date from,to
              this._filterQuery[k] = `${obj[k].start},${obj[k].end}`;
            } else {
              this._filterQuery[k] = obj[k].value;
            }
          }
          // if(obj[k].value && typeof obj[k].value == 'object' &&  obj[k].value.callbackParameters!=null
          // && obj[k].value.callbackParameters.selectValue!=null
          // ){
          //   this._filterQuery[k] = obj[k].value.callbackParameters.selectValue;
          // }//Se lam filtter bi sai
        }
      }
      // console.warn('handleFilterData:',this._filterQuery);
    }
    // return;
    if (configPagefilter) {
      if (configPagefilter.customQueryFromSever) {
        this._lastCustomQueryString = JSON.stringify(
          Object.assign({}, this._customQuery)
        );
        this._customQuery = configPagefilter.customQueryFromSever({
          obj: obj,
        });
        let _lengthCustomQuery = Object.keys(this._customQuery).length;
        let _compareOldNew =
          JSON.stringify(this._customQuery) != this._lastCustomQueryString;
        // console.log('handleFilterData _customQuery:',this._customQuery,obj,this._lastCustomQueryString,_lengthCustomQuery,_compareOldNew);
        if (_lengthCustomQuery >= 0) {
          //call request list always after change filter
          if (_compareOldNew) {
            if (obj) {
              let _obj = {};
              for (let _k in obj) {
                if (_k.indexOf("_OnServer") == -1) {
                  _obj[_k] = obj[_k];
                }
              }
              // console.log("handleFilterData _obj1:", _obj);
              this._cTable.handleFilterData(_obj);
            }
            return this.requestList({
              notShowLoadingApp:
              fnGetConfigTable(this._sharedFnList,KConfigPageTable.showLoadingWhenFilter) === true
                  ? false
                  : true,
            });
          }
        }
      }
    }
    if (this._cTable != null) {
      if (obj) {
        let _obj = {};
        for (let _k in obj) {
          if (_k.indexOf("_OnServer") == -1) {
            _obj[_k] = obj[_k];
          }
        }
        console.log("handleFilterData _obj2:", _obj);
        this._cTable.handleFilterData(_obj);
      }
      // this._cTable.handleFilterData(obj);
      if (onAfterFilter != null) {
        let _data = this._cTable.getTableDataIgnorePaging();
        // console.log('handleFilterData after filter _data:', _data);
        onAfterFilter({ filter: obj, data: _data });
      }
    }
  };
  handleSearch = (text) => {
    const { onAfterSearch } = this.props;
    console.log("handleSearch:", text);
    if (this._cTable != null) {
      this._cTable.handleSearch(text);
      if (onAfterSearch != null) {
        let _data = this._cTable.getTableDataIgnorePaging();
        onAfterSearch({ search: text, data: _data });
      }
    }
  };
  handleHideCol = (listCols, opts) => {
    // console.warn("handleHideCol in mypage tablev3:", listCols,this.props.configPage);    
    if(this._cTable){
      this._cTable.handleHideCol(listCols, opts);
    }    
  };
  _updateRowInList = (obj) => {
    const dataList = this.getListData();
    const newData = HUtils.Obj.get(obj, "response.Data");
    // console.warn("_updateRowInList:", obj, dataList)

    if (newData && dataList && dataList.length > 0) {
      let _itemWillUpdate = dataList.find((v) => v.Id === newData.Id);
      if (_itemWillUpdate) {
        HUtils.updateDataWithNewData(_itemWillUpdate, newData);
        this.forceUpdate();
      }
    }
  };

  _renderTopTable = () => {
    let _pageConfig = this.getConfigFromScreenConfig("Page");
    let _show = HUtils.Obj.get(_pageConfig, "topTable.show");
    let _typeTopTable = HUtils.Obj.get(_pageConfig, "topTable.type");
    let _styleTopTable = HUtils.Obj.get(_pageConfig, "topTable.style");
    if (_typeTopTable && _show !== false) {
      return (
        <MyUI
          type={_typeTopTable}
          style={_styleTopTable}
          fnList={this._sharedFnList}
        />
      );
    }
  };
  _getPositionFilter=()=>{
    const configPagefilter = fnListMyPage.fnGetConfigPageFilter(this._sharedFnList,[])
    if(configPagefilter[KConfigPageFilter.position]){
      return configPagefilter[KConfigPageFilter.position]
    }
    return fnListConfigApp.getDefaultPositionFilter()
  }
  _renderFilter = () => {
    const configPagefilter = fnListMyPage.fnGetConfigPageFilter(this._sharedFnList,[])
    const data = this.getListObject();
    let _typeFilter = fnListConfigApp.getDefaultTypeHubFilter();
    if(configPagefilter && configPagefilter.type){
      _typeFilter = configPagefilter.type
    }
    if (data && data.ExtraData && _typeFilter) {
      return (
        <MyUI
          type={_typeFilter}
          // onUpdateCurrentFilter={(filter) => {
          //   this._currentFilter = filter;
          //   this.forceUpdate();
          // }}
          onChangeFilter={(filter,opts={})=>{
            console.warn("onChangeFilter:",filter,opts)
            //update this._filterQuery
            if(filter && Object.keys(filter).length>0){
              //reset current filter
              this._filterQuery={}
              for(let k of Object.keys(filter)){
                if(filter[k]){
                  if(filter[k].valueFilterServer!=null){
                    this._filterQuery[k] = filter[k].valueFilterServer
                  }
                  else if(filter[k].value && filter[k].value.valueQueryServer){//danh cho kieu dateromto
                    this._filterQuery[k] = filter[k].value.valueQueryServer
                  }
                  else if(filter[k].value && (HIs.isArray(filter[k].value) || HIs.isBoolean(filter[k].value) || HIs.isString(filter[k].value) )){
                    this._filterQuery[k] = filter[k].value
                  }                  
                }              
              }
            }
            if(opts.showLoadingRequestFilter!=null){
              this._showLoadingRequestFilter = opts.showLoadingRequestFilter
            }
            this._lastFilterNeedRequestServer = true;
            if(opts.lastItemChange && this._sharedFnList.fnGetConfigPageFilter(KConfigPageFilter.needCheckLastItemQueryServer)){
              this._lastItemFilterChange = opts.lastItemChange
              if(opts.lastItemChange.fieldName && filter[opts.lastItemChange.fieldName] && filter[opts.lastItemChange.fieldName].isServer!==true){
                this._lastFilterNeedRequestServer = false
              }
            }
            this._currentFilter = filter;
            this._onePage._oneFilters.updateCurrentFiters(filter);
            this.forceUpdate();
          }}
          fnList={{
            ...this._sharedFnList,
            // requestListAfterFiler: ({ filterQuery } = {}) => {
            //   this._onePage._configPagination._pageCurrent = this._onePage._configPagination._pageStart;
            //   this.requestList({ customQuery: {...this._onePage.getQueryPagination(),...filterQuery} });
            // },
            onOpenFilter: () => {
              // return this._responsivePage();
            },
          }}
        />
      );
    }
  };
  _renderBelowFilter = () => {
    const _pageConfig = this.getConfigFromScreenConfig("Page");
    const _belowFilterConfig = this.getConfigFromScreenConfig("BelowFilter");
    const _componentBelowFilter = HUtils.Obj.get(
      _pageConfig,
      "componentBelowFilter"
    );

    if (_componentBelowFilter) {
      return (
        <_componentBelowFilter
          ref={(r) => {
            this._cBelowFilter = r;
          }}
          projectId={this.getProjectId()}
        />
      );
    } else if (
      _belowFilterConfig &&
      Object.keys(_belowFilterConfig).length > 0
    ) {
      return (
        <MyUI
          type="below_filter_v3"
          projectId={this.getProjectId()}
          belowFilterConfig={_belowFilterConfig}
          belowFilterType={_belowFilterConfig.type}
        />
      );
    }
  };
  _getCurrentFilter=()=>{
    const {fnList} = this.props;
    let _filterSharedDetail = HUtils.runFnList(fnList,"fntdGetFilterSharedDetail",this,[],{disabledWarn:true});
    if(_filterSharedDetail){
      return {..._filterSharedDetail,...this._currentFilter};
    }
    return this._currentFilter;
  }
  _renderProjectHeader=()=>{
    let _projectId = this.getProjectId();
    if(_projectId){
      const _headerConfig = this.getConfigFromScreenConfig("Header");
      if(_headerConfig[KConfigPageHeader.showProjectHeader]){
        return (
          <MyUI type="solidv3_project_header" projectId={_projectId} screenCode={this.getScreenCode()}/>
        )
      }      
    }    
  }
  render() {
    const { fnList, configPage, customComponent } = this.props;
    // console.warn("render Tablev3:",this._keyTestTableV3);
    if (this.state.isLoadingOptions || this.state.isLoadingList) {
      if (this.getListData() == null) {
        return this.renderPageLoading();
      }
    }
    if(this.state.errorList || this.state.errorOptions){
      return this.renderPageError()
    }
    const configPagefilter = fnListMyPage.fnGetConfigPageFilter(this._sharedFnList,[])
    const configPageHeader = fnListMyPage.fnGetConfigPageHeader(this._sharedFnList,[])
    let _showTable = this._getTableConfig(KConfigPageTable.show)
      ? true
      : false;
    if (_showTable === false) {
      return <div></div>;
    }
    HUtils.runFnList(fnList, "updateConfigSearchBar", this, [
      configPage,
      {
        searchText: "",
        cols: [],
        component: this,
        componentSearchBar: () => {
          // console.warn("componentSearchBar Tablev3:",this._keyTestTableV3);
          return (
            <div
              key={this._keyInitTableV3}
              style={{ display: "flex", flexDirection: "row" }}
            >
              <MyUI
                type="v3_search_on_tablev5"
                fnList={{
                  ...this._sharedFnList,
                  onSearch: (v) => {
                    // console.warn("onSearch:",v);
                    this.state.textSearch = v;
                    this.requestSearch();
                  },
                }}
              />
              <MyUI
                type="tablev3_filtercolumn"
                title="Columns filter"
                fnList={{
                  fnGetListColumnFilter: ()=>{
                    if(this._cTable && this._cTable.getColumnsFilterList){
                      return this._cTable.getColumnsFilterList();
                    }
                  },
                  fnUpdate: (item, index) => {
                    // console.warn("fnUpdate:",item,index);
                    if(this._cTable && this._cTable.updateFilterColumns){
                      return this._cTable.updateFilterColumns(item, index);
                    }
                  },
                }}
              />
            </div>
          );
        },
      },
    ],{disabledWarn:false});  
    let _belowTable = this._getTableConfig(HConstant.TableConfig.belowTable);
    let _componentHeader = HUtils.Obj.get(customComponent, "componentHeader");
    // console.warn("mypage tablev3:", fnList);
    return (
      <div className="page-tablev3" id={`page-tablev3${this.getScreenCode()}`} style={{position:"relative"}}>
        {this._renderProjectHeader()}
        {_componentHeader ? (
          _componentHeader
        ) : (
          <MyUI
            type="headerv3"
            title={HUtils.Obj.get(this.props, "configPage.Title")}
            fnList={{
              ...this._sharedFnList,
              getDataTable: this.getListData,
              getRefTable: () => this._cTable,
              getApiController: this.getApiController,
              fnReloadTable: this.reload,
              fnReloadOptionsAndList: this.fnReloadOptionsAndList, // coi sau
              fnReloadOptions: this.fnReloadOptions, // coi sau
              fnAddOptions: this.fnAddOptions,
              reloadTable: this.reload,
              reloadOptionsAndList: this.fnReloadOptionsAndList,
              fnRequestUpdateMaster: this.requestUpdateMaster,
              getQuery: () => {
                let _query = {};
                if (this._cTable) {
                  let _filterQuery = this.fnGetFilterQuery();
                  let _sortQuery;
                  if(this._cTable && this._cTable.getSortQuery){
                    _sortQuery = this._cTable.getSortQuery()
                  }
                  _query = Object.assign(_query, {
                    FilterQuery: _filterQuery || {},
                    SortQuery: _sortQuery || [], //sortQuery la array
                  });
                  let _requestQuery = this.getRequestData();
                  _query = Object.assign(_query, _requestQuery);
                }
                return _query;
              },
            }}
          />
        )}
        {
            configPagefilter.show!==false && this._getPositionFilter()==="top" &&
            <div
              className="left style-filter-v3"
              id={`table-left${this.getScreenCode()}`}
              // style={{ flexGrow: 1 }}
            >
              {this._renderFilter()}
            </div>
        }
        <MyUI type="uifromconfig" className="mui-belowheader" fnList={this._sharedFnList} config={fnListMyPage.fnGetConfigPagePage(this._sharedFnList,[KConfigPagePage.belowFilter])}/>
        <div className="table-area">
          {
            configPagefilter.show!==false && this._getPositionFilter()==="left" &&
            <div
              className="left style-filter-v3 no-print"
              id={`table-left${this.getScreenCode()}`}
              // style={{ flexGrow: 1 }}
            >
              {this._renderFilter()}
            </div>
          }          
          <div
            className="right"
            style={{
              flexGrow: 1,
              // overflow: "hidden", //de cac div con ko bi vuot qua,
              // padding: "1px",
            }}
          >
            {/* <div> */}
            {1===1 && (
              <MyTableFilterData
                data={this.getListData()}
                currentFilter={this._getCurrentFilter()}
              >
                {({ dataFiltered }) => {
                  return (
                    <div
                      style={
                        {
                          // margin:"2px"
                        }
                      }
                    >
                      <MyUI
                        type="below_header_v3"
                        pageConfig={this.getConfigFromScreenConfig("Page")}
                        options={this.getOptions()}
                      />
                      <MyUI
                        type="form_add_new_v3"
                        ref={(r) => {
                          this._cFormAddNew = r;
                        }}
                        apiController={this.getApiController()}
                        options={this.getOptions()}
                        projectId={this.getProjectId()}
                        screenCode={this.getScreenCode()}
                        getOptions={this.getOptions}
                        getDataTable={this.getListData}
                        getExtraDataTable={this.getListExtraData}
                        getDataTableFilterd={this.getDataFiltered}
                        reloadTable={this.reload}
                        queryConfig={this.getRequestData}
                        headerConfig={configPageHeader}
                        addNewConfig={this._addNewConfig}
                        getQuery={() => {
                          // console.warn('getQuery',getFilterQuery);
                          let _query = this.getRequestData();
                          if (this._cTable) {
                            let _filterQuery = this.fnGetFilterQuery();
                            _query = Object.assign(_query, {
                              FilterQuery: _filterQuery || {},
                            });
                          }
                          return _query;
                        }}
                      />

                      {this._renderBelowFilter()}
                      <MyUI
                        type="chartv3"
                        // ref={(r) => {
                        //   this._cChart = r;
                        // }}
                        apiController={this.getApiController()}
                        prefixId={
                          this.props.prefixId != null
                            ? `${this.props.prefixId}`
                            : ""
                        }
                        options={this.getOptions()}
                        projectId={this.getProjectId()}
                        screenCode={this.getScreenCode()}
                        getOptions={this.getOptions}
                        getDataTable={this.getListData}
                        getExtraDataTable={this.getListExtraData}
                        getDataTableFilterd={() => {
                          return dataFiltered;
                        }}
                        reloadTable={this.reload}
                        queryConfig={this.getRequestData()}
                        headerConfig={configPageHeader}
                        getFilterQuery={() => {
                          if (this._cTable) {
                            return this.fnGetFilterQuery();
                          }
                          return {};
                        }}
                      />
                      {this._renderTopTable()}
                      {this.state.msgInUI != null &&
                        this.state.msgInUI.length > 0 && (
                          <Alert
                            className="no-print"
                            color="warning"
                            isOpen={true}
                            style={{ marginBottom: "5px" }}
                          >
                            {this.state.msgInUI}
                          </Alert>
                        )}
                      <MyUI
                        // ref={(r) => (this._cTable = r)}
                        innerRef={(r) => (this._cTable = r)}
                        type="tablev3"
                        onePage={this._onePage}
                        fnList={{
                          ...fnList,
                          ...this._sharedFnList,
                          fnGetFilterQuery: this.fnGetFilterQuery,
                          fnGetFilterSharedDetail: this.fnGetFilterSharedDetail,
                          updateConfigSearchBar: ()=>{
                            // console.log("not using this fn(updateConfigSearchBar) to table detail")
                          },
                          fn_tv3_onTablePageChange:({page,sizePerPage})=>{
                            this._onePage._configPagination._pageSize = sizePerPage;
                            this._onePage._configPagination.go(page)
                          },
                          fnReloadTable: this.reload
                        }}
                        data={dataFiltered}
                        // data={this.getListData()}
                        // extraData={this.getListExtraData()}
                        // cols={this.state.cols}
                        // tableProps={_objTable.tableProps}
                        // optionsTable={_objTable.optionsTable}
                      />
                      <div style={{maxWidth:"calc(100% - 2px)"}}>
                      {_belowTable != null &&
                        _belowTable.map((v, i) => {
                          let _data = dataFiltered;
                          if (
                            HUtils.Obj.hasOwnProperty(v.sortByFn) &&
                            Array.isArray(v.sortByFnArgs) == true
                          ) {
                            _data = HUtils.Obj[v.sortByFn].apply(this, [
                              _data,
                              ...v.sortByFnArgs,
                            ]);
                          }
                          if (
                            HUtils.Obj.hasOwnProperty(v.groupByFn) &&
                            Array.isArray(v.groupByFnArgs) == true
                          ) {
                            _data = HUtils.Obj[v.groupByFn].apply(this, [
                              _data,
                              ...v.groupByFnArgs,
                            ]);
                          }
                          // console.warn("arrays:",_data);
                          return (
                            <MyUI
                              key={i}
                              type={v.type}
                              fnList={this._sharedFnList}
                              data={_data}
                              config={v.config}
                              {...v.props}
                            />
                          );
                        })}
                      </div>
                    </div>
                  );
                }}
              </MyTableFilterData>
            )}
            {/* </div>              */}
          </div>
        </div>
        <MyUI
          type="signaturev3"
          apiController={this.getApiController()}
          options={this.getOptions()}
          projectId={this.getProjectId()}
          screenCode={this.getScreenCode()}
          getOptions={this.getOptions}
          reloadTable={this.reload}
          queryConfig={this.getRequestData()}
          headerConfig={configPageHeader}
          getFilterQuery={() => {
            if (this._cTable) {
              return this.fnGetFilterQuery();
            }
            return {};
          }}
        />
      </div>
    );
  }
}
export default PageTablev3;

import React, { Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import "./styles/common.css";
import "./styles/responsive.css";
import {
  A,
  Button,
  Card,
  CardHeader,
  TabContent,
  TabPane,
  PageExtV2,
  MyPageFromConfig,
  HUtils,
  HConstant,
  SolidLogo,
  MyUI,
  HStoreRedux,
  connect,
  HLink,
} from "../_import";
import Tabv3_ext from "./tabv3_ext";
import AuthorizationSecond from '../../../class/AuthorizationSecond' 
import ConfigTabV3 from "../../../model/ConfigTabV3"
import { fnListConfigApp } from "src/helpers/fnList";
class PageTab3L1 extends Tabv3_ext {
  constructor(props) {
    super(props);
    this.state.openMenuLeft = false;
    this.keyContent = 1;
    this._parseParams();
  }
  _parseParams = () => {
    const { setObjInSolidV3 } = this.props;
    let _infoV3 = ConfigTabV3.getInfoFromUrl()
    console.warn("_infoV3",_infoV3)
    // let _currentProjectInUrl = A.V3.getLastProjectId();
    // let _currentPorfolioIndex = A.V3.getLastIsPorfolio();
    // let _objUser = A.V3.TabV3.getObjLocalByKey(A.V3.TabV3.Key.User);
    // let _objDepartment = A.V3.TabV3.getObjLocalByKey(A.V3.TabV3.Key.Department);
    let _objToChange = {
      parsedParam: true
    };
    if(_infoV3.currentProject && _infoV3.currentProject.Id){
      _objToChange.currentProject = _infoV3.currentProject
    }
    if(_infoV3.indexProject!=null){
      _objToChange.switchPortfolioIndex = Number(_infoV3.indexProject)
    }
    if(_infoV3.currentStaff && _infoV3.currentStaff.Id){
      _objToChange.userCurrent = _infoV3.currentStaff
    }
    if(_infoV3.indexStaff!=null){
      _objToChange.userSwitchIndex = Number(_infoV3.indexStaff)
    }
    if(_infoV3.currentDepartment && _infoV3.currentDepartment.Id){
      _objToChange.departmentCurrent = _infoV3.currentDepartment
    }
    if(_infoV3.indexDepartment!=null){
      _objToChange.departmentSwitchIndex = Number(_infoV3.indexDepartment)
    }    

    if(_objToChange){
      setObjInSolidV3(_objToChange)
    }
    // if (_currentProjectInUrl) {
    //   setObjInSolidV3({
    //     currentProject: {
    //       Id: _currentProjectInUrl,
    //       ProjectId: _currentProjectInUrl,
    //     },
    //     switchPortfolioIndex: Number(_currentPorfolioIndex),
    //     userCurrent: _objUser.data,
    //     userSwitchIndex: _objUser.indexSwitch,
    //     departmentCurrent: _objDepartment.data,
    //     departmentSwitchIndex: _objDepartment.indexSwitch
    //   });
    // }
  };
  _beforeChangeTab=(item)=>{
    console.warn("_beforeChangeTab:",item)    
  }
  _getRequestByProjectStaffDeparement=(currentProject, switchPortfolioIndex, userCurrent, userSwitchIndex, departmentCurrent, departmentSwitchIndex)=>{   
    let _moreRequest = {};
    if (switchPortfolioIndex !== -1 && currentProject) {      
      _moreRequest.ProjectId = currentProject.Id;
    } 
    if (userSwitchIndex !== -1 && userCurrent) {    
      if(userCurrent.Id){
        _moreRequest.StaffGUIId = userCurrent.Id;
      }
    } 
    if (departmentSwitchIndex !== -1 && departmentCurrent) {      
      _moreRequest.UI_DeparmentId = departmentCurrent.Id;
    }
    return _moreRequest
  }
  _moreRequestDataInMyPage=()=>{
    const { currentProject, switchPortfolioIndex, userCurrent, userSwitchIndex, departmentCurrent, departmentSwitchIndex } = this.props;    
    let _moreRequest = {...this._getRequestByProjectStaffDeparement(currentProject, switchPortfolioIndex, userCurrent, userSwitchIndex, departmentCurrent, departmentSwitchIndex)};    
    return _moreRequest;
  }
  componentDidMount() {
    const { setWaitingOptionsTab1 } = this.props;
    this.requestOptions({
      cbSuccess: (response) => {
        //AS
        let _pCanRunAsUser = HUtils.Obj.get(response, "Data.Configs.Permission.CanRunAsOtherUser"); 
        if(_pCanRunAsUser){
          AuthorizationSecond.updateConfig(AuthorizationSecond.KCanRunAsOtherUser,_pCanRunAsUser);
        } 
        
        let _msgChangeAccess = HUtils.Obj.get(response, "Data.Configs.Permission.ChangeAccessPerBtnMsg"); 
        AuthorizationSecond.updateConfig(AuthorizationSecond.KMsgAccess,_msgChangeAccess);
        // console.warn("_msgChangeAccess",_msgChangeAccess)
        AuthorizationSecond.forceUpdateComponent()

        this.logoUrl = HUtils.Obj.get(response, "Data.Configs.LogoConfig.Url");        
        this.logoImg = HUtils.Obj.get(response, "Data.Configs.LogoConfig.Img");      
        setWaitingOptionsTab1(false);        
        this.forceUpdate();        
        let _objInStore = {};
        _objInStore.footerList = HUtils.Obj.get(response, "Data.Footer");        
        HStoreRedux.dispatch({ type: "setObjInSolidV3", ..._objInStore });        
      },
    });
    window.addEventListener("scroll", this.onScroll);
  }
  componentWillUnmount() {
    window.removeEventListener("scroll", this.onScroll);
  }
  onScroll = () => {
    const scrollTop = window.pageYOffset;
    const _left_panel = document.getElementById("menu-left");
    const _tab_lv3 = document.getElementById("tab_lv3");
    const _lv1_content = document.getElementById("lv1-content");
    if (_left_panel) {
      if (scrollTop >= 46) {
        _left_panel.style.top = "0px";
      } else {
        _left_panel.style.top = `${46 - scrollTop}px`;
      }
    }
    if (_tab_lv3) {
      if (scrollTop >= _tab_lv3.offsetTop && _tab_lv3.offsetTop>0) {
        _tab_lv3.classList.add("sticky");
        if (this.isMobile() === false) {
          _tab_lv3.style.width = `${_lv1_content.offsetWidth}px`;
        }
      } else if(_tab_lv3.offsetTop==0 && scrollTop<46){
        _tab_lv3.classList.remove("sticky");
      }
    }
  };
  onClickMenuLeft = () => {
    const { openMenuLeft } = this.state;
    this.setState(
      {
        openMenuLeft: !openMenuLeft,
      },
      () => {
        this.responsiveTabContent();
        let _content = document.getElementById("lv1-content");
        if (this.isMobile() === false) {
          let _tab_lv3 = document.getElementById("tab_lv3");
          if (_tab_lv3) {
            _tab_lv3.style.width = _content.style.width;
          }
        }
        HStoreRedux.dispatch({
          type: "setObjInSolidV3",
          openLeftMenu: !openMenuLeft,
        });
        this.forceUpdate();
      }
    );
  };
  onClickMenuRight = () => {
    const { openMenuRight } = this.state;
    this.setState({
      openMenuRight: !openMenuRight,
    });
  }
  getCurrentTabTitle = () => {
    const { activeTabIndex } = this.state;
    const contents = this.getContents();
    return contents?.[activeTabIndex]?.Title 
  }
  renderTab = () => {
    const opts = {
      keyRight: "Tab_Right",
      keyIcon: "Tab_Icons"
    }
    const _leftMenu = this.getMenuByPosition("left", opts);
    const _rightMenu = this.getMenuByPosition("right", opts);   
    const _currentTabTitle = this.getCurrentTabTitle();
    return (
      <div className="tab_lv1_wrapper no-print">
        <div className="tab_lv1 left">
          <div style={{ width: 50 }}>
            <Button className="btn-menu" onClick={() => this.onClickMenuLeft()}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 23 19"
              >
                <g fill="none">
                  <g fill="#555">
                    <rect y="16" width="23" height="2" rx="1.5"></rect>
                    <rect width="23" height="2" rx="1.5"></rect>
                    <rect y="8" width="23" height="2" rx="1.5"></rect>
                  </g>
                </g>
              </svg>
            </Button>
          </div>
          <div style={{ width: 30, marginRight: 24 }}>
            <a
              href={this.logoUrl || "/"}
              onClick={() => {
                // setTimeout(() => {
                //   window.location.reload();
                // }, 10);
              }}
            >
              {}
              <img
                style={{
                  width: "100%",
                  objectFit: "contain",
                  cursor: "pointer",
                  maxHeight: "30px",
                }}
                alt="logo"
                src={this.logoImg||"/config/logo.png"} //bo logo solid mac dinh
              />
            </a>
          </div>
          {_leftMenu.length > 0 &&
            _leftMenu.map((v, i) => {
              return (
                <Button
                  key={i}
                  onClick={() => {                    
                    this.changeTab({ index: i, item: v });
                  }}
                  className={`btn-tab-lv1 ${
                    this.getTabIsActive({ index: i }) ? "active" : ""
                  } ${v.ConfigsExt && v.ConfigsExt.IsDisable===true?"v3-tab-disabled":""}`}
                >
                  {v.Title}
                  {
                    v.ConfigsExt && v.ConfigsExt.CanSavePer===true &&
                    <MyUI type={"btn_change_permission"} config={v}/>
                  }
                </Button>
              );
            })}
        </div>
        {_currentTabTitle && (
            <span className="tab_lv1_title_active_mobile">
              {_currentTabTitle}
            </span>
          )}
        {_rightMenu.length > 0 && (
          <div className="tab_lv1 right">
            {_rightMenu.map((v, i) => {
              return (
                <Button
                  key={i + _leftMenu.length}
                  onClick={() => {
                    this.changeTab({ index: i + _leftMenu.length, item: v });
                  }}
                  className={`btn-tab-lv1 ${
                    this.getTabIsActive({ index: i + _leftMenu.length })
                      ? "active"
                      : ""
                  } ${v.ConfigsExt && v.ConfigsExt.IsDisable===true?"v3-tab-disabled":""}`}
                >
                  {v.Title}
                </Button>
              );
            })}
          </div>
        )}
      </div>
    );
  };
  renderLeftPanel = () => {
    const contents = this.getContents();
    const { openMenuLeft } = this.state;
    return (
      <div>
        {openMenuLeft && <div className="overlay"></div>}
        <div
          id="menu-left"
          className={openMenuLeft ? "panel-left open" : "panel-left"}
          style={{
            top: this.isMobile() ? "0px" : "46px",
            backgroundColor: "var(--blue-header)",
          }}
        >
          <div className="close-panel" style={{ padding: 10 }}>
            <i
              className="fa fa-times"
              style={{
                float: "right",
                color: "#6f7782",
                fontSize: 16,
                cursor: "pointer",
              }}
              onClick={() => this.onClickMenuLeft()}
            />
          </div>
          <div className="panel-left-menu">
            <ul>
              {contents &&
                contents.map((v, i) => {
                  return (
                    <li
                      className={
                        this.getTabIsActive({ index: i }) ? "active" : ""
                      }
                      key={i}
                      onClick={() => {
                        this.changeTab({ index: i, item: v });
                        if (this.isMobile()) {
                          this.onClickMenuLeft()
                        }
                      }}
                    >
                      <i
                        className={v.Icon || "fa fa-circle"}
                        style={{ marginRight: 10 }}
                      />
                      {v.Title}
                    </li>
                  );
                })}
            </ul>
          </div>
        </div>
      </div>
    );
  };
  renderContent = () => {
    const { setWaitingOptionsTab1 } = this.props;
    const contents = this.getContents();
    let _ui = [];
    return (
      <div className="content_lv1">
        {this.renderLeftPanel()}
        <div id="lv1-content" className="content_lv1-tab">
          <TabContent
            key={this.keyContent}
            activeTab={this.getTabActive()}
            style={{ border: "0px", background: "transparent" }}
          >
            {contents.map((v, i) => {
              let _tabId = this.getTabId({ index: i });
              let _isLoaded = this.getTabIsLoaded({ index: i });
              if (_isLoaded) {
                if(_tabId==this.getTabActive({})){
                  ConfigTabV3.setTabByLev(1,v.Id)
                }
                return (
                  <TabPane
                    key={i}
                    tabId={this.getTabId({ index: i })}
                    style={{ padding: "0px" }}
                  >
                    <MyPageFromConfig
                      configPage={v}
                      fnList={{
                        ...this._sharedFnList,
                        getPageConfigExt: () => {
                          return HUtils.Obj.get(this.getOptions(), "PageConfig_Exts")
                        },
                        getParentLevelTabIndex: this.getCurrentLevelTabIndex,
                        fntv1OnReloadUI: () => {
                          setWaitingOptionsTab1(true);
                          this.requestOptions({
                            notShowLoadingApp: true,
                            cbSuccess: () => {
                              setWaitingOptionsTab1(false);
                            },
                          });
                        },
                      }}
                    />
                  </TabPane>
                );
              } else {
                // console.warn("_isLoaded:",_isLoaded,_tabId,i,v,this.props,this.state);
                return (
                  <TabPane
                    key={i}
                    tabId={this.getTabId({ index: i })}
                    style={{ padding: "0px" }}
                  >
                    <div>........</div>
                  </TabPane>
                );
              }
            })}
          </TabContent>
        </div>
      </div>
    );
  };
  renderFooter = () => {
    return <MyUI type="footer" />;
  };
  render() {
    const {parsedParam} = this.props;
    if(parsedParam===false){
      return (
        <div className="app parsinngParams"></div>
      )
    }
    // console.warn("parsed!!!!!")
    return (
      <div className="app">
        {this.renderTab()}
        {/* For print */}
        <div className="only-print" style={{textAlign:"center",padding:"0px 15px",fontWeight:"bold"}}>
          <img
            style={{
              width: "100%",
              objectFit: "contain",
              cursor: "pointer",
              maxHeight: "30px",
            }}
            alt={fnListConfigApp.getAppTitle()}
            src={this.logoImg||"/config/logo.png"} //bo logo solid mac dinh
          />
        </div>
        {this.renderContent()}
        {this.renderFooter()}
      </div>
    );
  }
}
// export default PageTab3L1;
const mapStateToProps = (state) => {
  return {
    currentProject: state.solidv3.currentProject,
    projectList: state.solidv3.projectList,
    switchPortfolioIndex: state.solidv3.switchPortfolioIndex,
    userCurrent: state.solidv3.userCurrent,
    userSwitchIndex: state.solidv3.userSwitchIndex,
    departmentCurrent: state.solidv3.departmentCurrent,
    departmentSwitchIndex: state.solidv3.departmentSwitchIndex,
    parsedParam: state.solidv3.parsedParam,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setObjInSolidV3: (obj) => dispatch({ type: "setObjInSolidV3", ...obj }),
    setWaitingOptionsTab1: (value) => {
      dispatch({ type: "setObjInSolidV3", waitingOptionsTab1: value });
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(PageTab3L1);

import React from "react"
import { MyUIHelpers } from "@macashipo/mlib";
const MUI_textEllipsis = React.lazy(() => import("./Types/textEllipsis"));
const MUI_data_obj = React.lazy(() => import("./Types/data_obj"));
const MUI_data_tag = React.lazy(() => import("./Types/data_tag"));
const MUI_data_array = React.lazy(() => import("./Types/data_array"));

const List = {
  textEllipsis: MUI_textEllipsis,//han che su dung, tao mot div trong body tuong tu, de tinh toan width, height
  data_obj: MUI_data_obj,
  data_tag: MUI_data_tag,
  data_array: MUI_data_array,
};
const MyUIOne = {
  init() {
    MyUIHelpers.addTypesFromListLazy(List);
  },
};

export default MyUIOne;

import React, { Component } from 'react';
import MyFormControlTypes from '../../../components/MyForm/MyFormTypes';
import * as H from '../../../helpers';
import FormHelper from "./FormHelper";
import {Select, Rater} from '../../../_importLibs'
import {MyLib} from '../../../_importMyComponent'
const Select2Color=(option)=>{
  let _bg = option.value||'transparent';
  return (
    <div style={{display:'flex',alignItems:'center'}}><i className="fa fa-square" style={{color:_bg,fontSize:'20px'}}></i> &nbsp; {option.label}</div>
  )
}

class CustomSelect2 extends Component {
  constructor(props) {
    super(props);
    this.state={
      value: null,
    }
  }
  render() {
    const {item,opts} = this.props;
    let _options = [];
    let _sourceList = FormHelper.getOptionsSource(item,opts);
    // console.warn('select2: ', item, opts);
    if(_sourceList && _sourceList.length>0){
      for(let i in _sourceList){
        let _o = _sourceList[i];
        let _value = _o.value || _o.Value;
        let _text = _o.text || _o.Text || _o.label || _o.title;
        _options.push(
          {value:_value,label:_text}
        )
      }
    }
    let _disabled = FormHelper.getDisabled(item,opts);
    return (
      <Select
        onBlurResetsInput={false}
        onSelectResetsInput={false}
        autoFocus={false}
        options={_options}
        value={this.state.value}
        simpleValue
        disabled={_disabled}
        multi={true}
        closeOnSelect={false}
        clearable={false}
        // name="selected-state"
        // value={this.state.WPGGroupId}
        onChange={(newValue)=>{
          this.setState({
            value: newValue,
          },()=>{
            // if(onChange!=null){
            //   onChange(null,newValue);
            // }
            if(opts && opts.onUpdateValue){
              opts.onUpdateValue({newValue:newValue});
            }
          });
        }}
        searchable={true}
        // {...moreProps}
      />
    );
  }
}

class CustomSelect2Color extends Component {
  constructor(props) {
    super(props);
    const {item,opts} = this.props; 
    this.state={
      value: null,
    }

    let _defaultValue = FormHelper.getDefaultValue(item,opts);
    if(_defaultValue!=null){
      this.state.value = _defaultValue;
    }
  }
  
  render() {
    const {item,opts} = this.props;
    let _options = [];
    let _sourceList = FormHelper.getOptionsSource(item,opts);
    if(_sourceList && _sourceList.length>0){
      for(let i in _sourceList){
        let _o = _sourceList[i];
        let _value = _o.value || _o.Value;
        let _text = _o.text || _o.Text || _o.label || _o.title;
        _options.push(
          {value:_value,label:_text}
        )
      }
    }
    let _disabled = FormHelper.getDisabled(item,opts);
    return (
    <Select
      onBlurResetsInput={false}
      onSelectResetsInput={false}
      autoFocus={false}
      // options={options!=null?options:[]}
      options={_options}
      valueRenderer={Select2Color}
      optionRenderer={Select2Color}
      value={this.state.value}
      simpleValue
      multi={false}
      closeOnSelect={true}
      clearable={false}
      name="selected-state"
      onChange={(newValue)=>{
        this.setState({
          value: newValue,
        },()=>{
          if(opts && opts.onUpdateValue){
            opts.onUpdateValue({newValue:newValue});
          }
        });
      }}
      searchable={true}
      // {...moreProps}
    />
    )
  }
}

class CustomRate extends Component {
  constructor(props) {
    super(props);
    const {item,opts} = this.props; 
    this.state={
      value: null,
    }
    
    let _defaultValue = FormHelper.getDefaultValue(item,opts);
    // console.warn('defaut value cua Rate: ', _defaultValue);
    if(_defaultValue!=null){      
      let _value = null;
      if(typeof _defaultValue!= 'number'){
        _value = parseInt(_defaultValue);
      }
      else{
        _value = _defaultValue;        
      }
      this.state.value = _value;
    }
  }
  _onRate=({ rating, type })=>{
    const {item,opts} = this.props;
    if(opts && opts.onUpdateValue){
      opts.onUpdateValue({newValue:rating});
    }
  }
  render() {
    const {item,opts} = this.props;
    let _options = [];
    let _sourceList = FormHelper.getOptionsSource(item,opts);
    if(_sourceList && _sourceList.length>0){
      for(let i in _sourceList){
        let _o = _sourceList[i];
        let _value = _o.value || _o.Value;
        let _text = _o.text || _o.Text || _o.label || _o.title;
        _options.push(
          {value:_value,label:_text}
        )
      }
    }
    
    // let _disabled = FormHelper.getDisabled(item,opts);
    return (
        <Rater total={5} rating={this.state.value}
        onRate={this._onRate}
        >
        </Rater>
    );
  }
}

class CustomCurrency extends Component {
  constructor(props) {
    super(props);
    const {item,opts} = this.props;
    this.state={
      value: null
    }
    let _defaultValue = FormHelper.getDefaultValue(item,opts);
    if(_defaultValue!=null){
      this.state.value = _defaultValue;
    }
  }
  render() {
    const {item,opts} = this.props;
    let _disabled = FormHelper.getDisabled(item,opts);
    let _styleInput = {flex:1,height:'100%',textAlign:'right'};
    return (
      <div className="has_wrap">
        <H.InputControl 
          className={""}
          style={_styleInput}
          type={'text'}
          mask={"c"}
          disabled={_disabled}
          regexDelete={"[^(\\d)]"}
          value={this.state.value}
          onBlur={(ev,newValue)=>{
            if(opts && opts.onUpdateValue){
              opts.onUpdateValue({newValue:newValue});
            }          
          }}
          >
        </H.InputControl>
      </div>
    );
  }
}

class CustomQuality extends Component {
  constructor(props) {
    super(props);
    const {item,opts} = this.props;
    this.state={
      value: null,
    }
    let _defaultValue = FormHelper.getDefaultValue(item,opts);
    if(_defaultValue!=null){
      this.state.value = _defaultValue;
    }
  }

  _choose=(value)=>{
    const {item,opts} = this.props;
    // console.log('_choose:',value);
    this.setState({value:value},()=>{
      if(opts && opts.onUpdateValue){
        opts.onUpdateValue({newValue:value});
      } 
    })
  }

  getColor=(value)=>{
    let _sourceList = [];
    let _default = {
      '1': '#ec5463',
      '3': '#fdcd55',
      '5': '#9ed269'
    }
    let _color = _default[value]||'#000000';
    if(_sourceList!=null){
      for(let i in _sourceList){
        let _item = _sourceList[i];
        if(_item.Value==value && _item.ColorCode!=null){
          _color = _item.ColorCode;
          break;
        }
      }
    }
    return _color;
  }
  
  render() {
    const {item,opts} = this.props;
    let _more = 'row';
    let _value = this.state.value;
    let _styleDiv = {
      flexDirection:'row',
      justifyContent: 'flex-start',
    };
    let _marginLeft = '5px';
    let _margintop = '0px';
    // if(_more && _more.isColumn==true){
    //   _styleDiv.flexDirection = 'column';
    //   _marginLeft= '0px';
    //   _margintop='5px';
    // }
    return (
      <div style={{flex:1,backgroundColor:'white'}}>
        <div className="tbqm_cl"style={_styleDiv}>
          <div className={_value=="1"?"tbqm_cl_item active":"tbqm_cl_item"} style={{marginLeft:_marginLeft,marginTop:_margintop,backgroundColor:this.getColor('1')}} onClick={()=>this._choose('1')}>
            {_value=="1"&&<i className="fa fa-times"/>}
          </div>
          <div className={_value=="3"?"tbqm_cl_item active":"tbqm_cl_item"} style={{marginLeft:_marginLeft,marginTop:_margintop,backgroundColor:this.getColor('3')}} onClick={()=>this._choose('3')}>
            {_value=="3"&&<i className="fa fa-minus"/>}
          </div>
          <div className={_value=="5"?"tbqm_cl_item active":"tbqm_cl_item"} style={{marginLeft:_marginLeft,marginTop:_margintop,backgroundColor:this.getColor('5')}} onClick={()=>this._choose('5')}>
            {_value=="5"&&<i className="fa fa-check"/>}
          </div>
        </div>
      </div>
    )
  }
}

class CustomSelectList extends Component {
  constructor(props) {
    super(props);
    this.state={
      value: null,
    }
  }
  _getSelected=(value)=>{
    const {item,opts} = this.props;
    let _isMultiple = FormHelper.getMultiple(item,opts);
    let _value = value;
    if(_isMultiple){
      let _valueOfControl = this.state.value || [];
      let _index = _valueOfControl.indexOf(_value);
      if(_index==-1){
        return false;
      }
      else{
        return true;
      }
    }
    else{
      if(this.state.value!=null && this.state.value==_value ){
        return true;
      }
      else{
        return false;
      }
    }
  }
  render() {
    const {item,opts} = this.props;
    // console.warn('render select list');
    let _options = [];
    let _isMultiple = FormHelper.getMultiple(item,opts);
    let _sourceList = FormHelper.getOptionsSource(item,opts);
    let _li = [];
    if(_sourceList && _sourceList.length>0){
      for(let i in _sourceList){
        let _o = _sourceList[i];
        let _value = _o.value || _o.Value;
        let _text = _o.text || _o.Text || _o.label || _o.title;
        // _options.push(
        //   {value:_value,label:_text}
        // )
        let _classname = "";
        if(this._getSelected(_value)){
          _classname += " selected";
        }
        _li.push(
          <li key={i} className={_classname} onClick={()=>{
            if(_isMultiple){
              let _valueOfControl = this.state.value || [];
              let _index = _valueOfControl.indexOf(_value);
              if(_index==-1){
                _valueOfControl.push(_value);
              }
              else{
                _valueOfControl = _valueOfControl.split(_index,1);
              }
              this.setState({
                value: _valueOfControl
              },()=>{
                if(opts && opts.onUpdateValue){
                  opts.onUpdateValue({newValue:this.state.value});
                }
              });
            }
            else{
              this.setState({
                value: _value
              },()=>{
                if(opts && opts.onUpdateValue){
                  opts.onUpdateValue({newValue:this.state.value});
                }
              });
            }            
          }}>{_text}</li>
        )
      }
    }
    let _disabled = FormHelper.getDisabled(item,opts);
    return (
      <ul className="mui-form-c-selectlist">
        {_li}
      </ul>
    );
  }
}

class CustomDatePicker extends Component {
  constructor(props) {
    super(props);
    const {item,opts} = this.props; 
    this.state={
      startDate: null,
    }
    let _defaultValue = FormHelper.getDefaultValue(item,opts);
    if(_defaultValue!=null){
      this.state.startDate = new Date(_defaultValue);
    }
  }

  handleChange=(date)=>{
    const {item,opts} = this.props; 
    this.setState({
      startDate: date
    },()=>{
      if(opts && opts.onUpdateValue){
        let _mili = null;
        if(this.state.startDate){
          _mili = this.state.startDate.getTime();
        }
        opts.onUpdateValue({newValue:_mili});
      }
    });
  }

  render() {
    const {item,opts} = this.props;    
    let _disabled = FormHelper.getDisabled(item,opts);
    let _props = FormHelper.getPropsControl(item,opts);  
    // let _defaultValue = FormHelper.getDefaultValue(item,opts);
    let _dateFormat = "d/M/yyyy";
    if(_props.showTimeSelect==true){
      _dateFormat = "d/M/yyyy h:mm aa";
    }
    return (
      <MyLib type="reactdatepicker"
        selected={this.state.startDate}
        onChange={this.handleChange}
        showTimeSelect={false}
        timeFormat="HH:mm"
        timeIntervals={15}
        dateFormat={_dateFormat}
        timeCaption="Time"
        className="mui-form-control"
        readOnly={_disabled}
        // customInput={
        //   <input className="mui-form-control" type="text" />
        // }
        {..._props}
      />
    );
  }
}

class CustomCheckbox extends Component {
  constructor(props) {
    super(props);
    this.state={
      value: null,
    }
  }

  _onHandleChange=(e)=>{
    const {item,opts} = this.props;    
    // console.warn('_onHandleChange:',e,e.currentTarget.checked);
    if(opts && opts.onUpdateValue){
      opts.onUpdateValue({newValue:e.currentTarget.checked});
    }
  }

  render() {
    const {item,opts} = this.props;    
    let _disabled = FormHelper.getDisabled(item,opts);
    let _props = FormHelper.getPropsControl(item,opts); 
    let _defaultValue = FormHelper.getDefaultValue(item,opts); 
    return (
      <div className={`mui-form-checkbox`}>
        <input className="" 
          defaultChecked={_defaultValue} 
          type={"checkbox"} 
          onClick={this._onHandleChange} 
        />
        <label>{item.Label}</label>
      </div>
    );
  }
}

export function select2(item,opts){
  return (
      <CustomSelect2 item={item} opts={opts}/>
  )
}
export function selectlist(item,opts){
  return (
      <CustomSelectList item={item} opts={opts}/>
  )
}
export function datepicker(item,opts){
  return (
      <CustomDatePicker item={item} opts={opts}/>
  )
}
export function checkbox(item,opts){
  return (
      <CustomCheckbox item={item} opts={opts}/>
  )
}
export function select2color(item,opts){
  return (
      <CustomSelect2Color item={item} opts={opts}/>
  )
}

export function rate(item,opts){
  return (
      <CustomRate item={item} opts={opts}/>
  )
}
export function currency(item,opts){
  return (
      <CustomCurrency item={item} opts={opts}/>
  )
}
export function quality(item,opts){
  return (
      <CustomQuality item={item} opts={opts}/>
  )
}

export function listcheckbox(item,opts){
  let _type = "listcheckbox";
  if(MyFormControlTypes.Types[_type]){
    return MyFormControlTypes.Types[_type](item,opts,{});
  }
  return MyFormControlTypes.Types._default(item,opts,{});
}


import Moment from "moment";
import React, { Component } from 'react';
import {
  Button
} from '../../../_importComponent';
import {MyUI} from "@macashipo/mlib"
import {Tooltip} from '../../../_importLibs'
import {HConfig} from '../../../_import'
import ModalSignatureHistory from './ModalSignatureHistory';
//MRUSTATION
class SignatuteItem extends Component {
  constructor(props) {
    super(props);
    this.state = { 
      signatureName:null,
      signatureDate:null,
    };
  }

  componentDidMount(){
  }

  render() {
    const {title,projectId,screenCode,item,config} = this.props;
    const {getOptions,reloadTable,queryConfig,headerConfig,apiController,getFilterQuery} = this.props;
    // console.warn('this.props: ', this.props);
    let _name = item.SignedById_Name;
    let _date = null;
    if (this.state.signatureName!=null){
      _name = this.state.signatureName;
    }
    // else{
    //   if (item.SignedById_Name!=null){
    //     _name = item.SignedById_Name
    //   }
    // }
    if (this.state.signatureDate!=null){
      _date = this.state.signatureDate;
    }
    else{
      if (item.SignedDate!=null){
        _date = Moment(item.SignedDate).format(HConfig.dateFormat);
      }
    }
    
    // console.warn('_date: ', _date);
    return (
      <table className="sign">
        <tbody>
          <tr>
            <th style={{display:'flex', justifyContent:'center',alignItems:'center'}}>
              {
                item.CanViewHistory==true&&
                <Tooltip placement="top" overlay={`Xem lịch sử ký`} >
                  <Button color="link"
                    style={{padding:'2px'}}
                    onClick={()=>{
                      if (this._cModalSignatureHistory){
                        this._cModalSignatureHistory.show()
                      }
                    }}
                  >
                    {/* History */}
                    <i className="fa fa-clock-o"></i>
                  </Button>
                </Tooltip>
              }
              {
                item.FileUrl_Download!=null&&item.FileUrl_Download!=""&&
                <a href={item.FileUrl_Download} target='_blank' style={{marginLeft:'5px', marginRight:'5px'}}><i className="fa fa-file-o"></i></a>
              }
              {title}
              {
                config&&config.ShowIsChecked&&
                <input                 
                  type="checkbox"
                  checked={item.IsChecked===true?true:false}
                  onChange={()=>{}}
                  style={{margin:"5px"}}
                />
              }
            </th>
          </tr>
          <tr> 
            <td style={{height:'100px', display:'flex',flexDirection:'row',alignItems:'flex-end'}}>
              <hr />
            </td>
          </tr>
          <tr>
            <td style={{height:'40px',display:'flex',flexDirection:'row',alignItems:'center',justifyContent:'center'}}>
              <MyUI type="solid_pageheaderbutton"
                inBox = {false}
                listData = {item.ButtonList}
                projectId = {projectId}
                screenCode= {screenCode}
                getOptions = {getOptions}
                reloadTable = {reloadTable}
                // khong dung queryConfig nua, chi dung getQuery
                // queryConfig = {
                //   Object.assign(
                //     {
                //       SignatureId: item.Id,
                //     },queryConfig)
                // }
                getQuery={()=>{
                  // console.warn('getQuery',getFilterQuery);
                  let _query = {
                    SignatureId: item.Id,
                  };
                  if(getFilterQuery){
                    _query.FilterQuery = getFilterQuery() || {};              
                  }
                  return _query;
                }}
                headerConfig = {headerConfig}
                apiController={apiController}
                moreProps={{
                  successCallBack: (response)=>{
                    console.warn("successCallBack:",response);
                    if(response && response.Data && response.Data.Id && response.Data.Id == item.Id){
                      let _keys = Object.keys(response.Data);
                      for(let k of _keys){
                        item[k] = response.Data[k];
                      }
                      this.forceUpdate();
                    }
                  }
                }}
              />
            </td>
          </tr>
          <tr> 
            <td style={{height:'40px'}}>
              {
                item.IM_Signed_Text
              }
            </td>
          </tr>
        </tbody>
        <ModalSignatureHistory 
          ref={(r)=>{this._cModalSignatureHistory=r;}}
          projectId={projectId}
          screenCode={screenCode}
          idSignature={item.Id}/>
      </table>
    )
  }
}

export default SignatuteItem;
